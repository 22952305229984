var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn wiki-page-import" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("strong", [_vm._v("takahiro-games からの記事移行")]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          takahiro-games リポジトリの特定ゲームの記事を Wiki\n          にインポートします。記事数が多い場合、処理に時間がかかることがあります。\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "\n          インポートできるのはゲームタイトル全体のフォルダのみ (たとえば src/general/puzzle-dragons)\n          で、そのサブフォルダ (たとえば src/general/puzzle-dragons/イベント情報)\n          を個別でインポートすることはできません。\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      disabled: _vm.uploading,
                      label: "インポートする記事フォルダ (Markdown)",
                      description:
                        "インポートする記事ファイル (Markdown) が入ったフォルダを選択します",
                    },
                  },
                  [
                    _c("b-form-file", {
                      ref: "markdownFileField",
                      attrs: {
                        state: _vm.files.length > 0,
                        placeholder: "フォルダを選択してください",
                        directory: "",
                        multiple: "",
                        accept: ".md",
                        disabled: !_vm.takahiroAvailable,
                      },
                      model: {
                        value: _vm.files,
                        callback: function ($$v) {
                          _vm.files = $$v
                        },
                        expression: "files",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                !_vm.takahiroAvailable
                  ? _c("p", [
                      _c("strong", [
                        _vm._v(
                          "※お使いのブラウザ環境では takahiro-games\n            からの記事以降をおこなうことはできません。別なブラウザをお試しください"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.files.length > 0
                  ? _c("div", { staticClass: "mt-3" }, [
                      _vm.uploading
                        ? _c(
                            "div",
                            [
                              _c("p", [
                                _vm._v(
                                  "インポート中です。しばらくお待ちください... (" +
                                    _vm._s(_vm.proceeded) +
                                    " / " +
                                    _vm._s(_vm.files.length) +
                                    ")"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-progress",
                                { attrs: { max: _vm.files.length } },
                                [
                                  _c("b-progress-bar", {
                                    attrs: {
                                      value: _vm.proceeded,
                                      variant: "primary",
                                      striped: "",
                                      animated: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("p", [
                                _c("strong", [
                                  _vm._v(
                                    "「" +
                                      _vm._s(_vm.takahiroGameName) +
                                      "」というゲームの記事を Wiki\n                に移行します。お間違いなければこのまま続行してください。"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    "カテゴリはフォルダ構造に応じて自動設定されます"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "既にインポート済みの、同じフォルダのファイル名を持つファイルは上書きされます"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "サイドバーなどのコンテンツも、ここでインポートされたファイルに基づいて自動的に構築されます"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "【...】で始まるタイトルの記事のタイトルから【...】が削除されます"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "また、現時点では将来に解消される予定の以下の制限があります。"
                                ),
                              ]),
                              _vm._v(" "),
                              _c("ul", [
                                _c("li", [
                                  _vm._v(
                                    "カスタムヘッダのインポートはできません"
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _vm._v(
                                    "テンプレートコードの実行はおこなわれません (実行済みのテンプレートコードの結果は表示されます)"
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-3",
                                  attrs: { label: "記事の公開設定" },
                                },
                                [
                                  _c(
                                    "b-form-radio",
                                    {
                                      attrs: { value: "1" },
                                      model: {
                                        value: _vm.published,
                                        callback: function ($$v) {
                                          _vm.published = $$v
                                        },
                                        expression: "published",
                                      },
                                    },
                                    [_vm._v("すぐに公開する")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-radio",
                                    {
                                      attrs: { value: "0" },
                                      model: {
                                        value: _vm.published,
                                        callback: function ($$v) {
                                          _vm.published = $$v
                                        },
                                        expression: "published",
                                      },
                                    },
                                    [_vm._v("非公開")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-radio",
                                    {
                                      attrs: { value: "2" },
                                      model: {
                                        value: _vm.published,
                                        callback: function ($$v) {
                                          _vm.published = $$v
                                        },
                                        expression: "published",
                                      },
                                    },
                                    [_vm._v("指定日時に公開")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.published === "2"
                                ? _c(
                                    "b-form-group",
                                    {
                                      staticClass: "mt-3",
                                      attrs: { label: "公開日時" },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { staticClass: "w-25" },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "date" },
                                            model: {
                                              value: _vm.publishedDate,
                                              callback: function ($$v) {
                                                _vm.publishedDate = $$v
                                              },
                                              expression: "publishedDate",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: { type: "time" },
                                            model: {
                                              value: _vm.publishedTime,
                                              callback: function ($$v) {
                                                _vm.publishedTime = $$v
                                              },
                                              expression: "publishedTime",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "現在、 " +
                                    _vm._s(_vm.files.length) +
                                    " 個のファイルが選択されています。"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "submit", variant: "primary" },
                                  on: { click: _vm.upload },
                                },
                                [_vm._v("インポートする")]
                              ),
                            ],
                            1
                          ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.completed
              ? _c(
                  "b-card",
                  { attrs: { "header-tag": "header", "footer-tag": "footer" } },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c("strong", [_vm._v("インポート結果")]),
                    ]),
                    _vm._v(" "),
                    _c("b-alert", { attrs: { variant: "info", show: "" } }, [
                      _vm._v(
                        "インポートが完了しました。インポート結果は以下のとおりです。"
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.successImported.length > 0
                      ? _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-card-header",
                              {
                                staticClass: "p-1",
                                attrs: { "header-tag": "header" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName:
                                          "v-b-toggle.result-success-update",
                                        modifiers: {
                                          "result-success-update": true,
                                        },
                                      },
                                    ],
                                    attrs: { block: "", variant: "light" },
                                  },
                                  [
                                    _vm._v("インポート成功 "),
                                    _c(
                                      "b-badge",
                                      {
                                        attrs: { variant: "primary", pill: "" },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.successImported.length)
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-collapse",
                              {
                                attrs: {
                                  id: "result-success-update",
                                  visible: "",
                                },
                              },
                              [
                                _c(
                                  "b-list-group",
                                  _vm._l(_vm.successImported, function (file) {
                                    return _c(
                                      "b-list-group-item",
                                      { key: file.id },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(file.name) +
                                            "\n                "
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "text-muted" },
                                          [_c("br"), _vm._v(_vm._s(file.title))]
                                        ),
                                        _vm._v(" "),
                                        file.previousTitle != file.newTitle
                                          ? _c(
                                              "small",
                                              { staticClass: "text-muted" },
                                              [
                                                _c("br"),
                                                _vm._v(
                                                  "タイトルが「" +
                                                    _vm._s(file.previousTitle) +
                                                    "」から「" +
                                                    _vm._s(file.newTitle) +
                                                    "」へ変更されました\n                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.error.length > 0
                      ? _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-card-header",
                              {
                                staticClass: "p-1",
                                attrs: { "header-tag": "header" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle.result-error",
                                        modifiers: { "result-error": true },
                                      },
                                    ],
                                    attrs: { block: "", variant: "light" },
                                  },
                                  [
                                    _vm._v("インポート失敗 "),
                                    _c(
                                      "b-badge",
                                      {
                                        attrs: { variant: "danger", pill: "" },
                                      },
                                      [_vm._v(_vm._s(_vm.error.length))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-collapse",
                              { attrs: { id: "result-error", visible: "" } },
                              [
                                _c(
                                  "b-list-group",
                                  _vm._l(_vm.error, function (file) {
                                    return _c(
                                      "b-list-group-item",
                                      { key: file.name },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(file.name) +
                                            "\n                "
                                        ),
                                        _vm._l(file.errors, function (message) {
                                          return _c(
                                            "small",
                                            {
                                              key: `${file.name}-${message}`,
                                              staticClass: "text-muted",
                                            },
                                            [_c("br"), _vm._v(_vm._s(message))]
                                          )
                                        }),
                                      ],
                                      2
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }