export function convertSearchQueryStringToArray(queryString: string): string[] {
  const words: string[] = [];
  queryString
    .replace(/\\\"/g, '\x00')
    .split(/(".+?")/g)
    .forEach((w) => {
      if (w === '') {
        return;
      }
      if (w.includes('"')) {
        words.push(w.replace(/"/g, '').replace(/\x00/g, '"'));
      } else {
        words.push(
          ...w
            .split(/ |　/)
            .filter((v) => !!v)
            .map((v) => v.replace(/\x00/g, '"'))
        );
      }
    });
  return words;
}
