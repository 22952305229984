var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("strong", [_vm._v("画像認識（ルール追加画面）")]),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("複数の画像ファイルをアップロードできます。")]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      disabled: _vm.uploading,
                      label: "アップロードする画像ファイル",
                      description:
                        "アップロードする画像ファイルが入ったフォルダを選択します",
                    },
                  },
                  [
                    _c("b-form-file", {
                      attrs: {
                        state: _vm.files.length > 0,
                        placeholder: "ファイルを選択してください",
                        directory: "",
                        multiple: "",
                        accept: "image/*",
                      },
                      on: { change: _vm.handleFileChange },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      disabled: _vm.uploading,
                      label: "スプレッドシートURL（任意）",
                      description:
                        "ルールが保存されたスプレッドシートのURLを入力します",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: {
                        placeholder:
                          "ルールが保存されたスプレッドシートのURLを入力します",
                      },
                      model: {
                        value: _vm.spreadsSheetUrl,
                        callback: function ($$v) {
                          _vm.spreadsSheetUrl = $$v
                        },
                        expression: "spreadsSheetUrl",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "mt-3 spreads-sheet-url-input-form__button-container",
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              type: "submit",
                              variant: "primary",
                              disabled: _vm.spreadsSheetUrl === "",
                            },
                            on: { click: _vm.handleLoadSectionValues },
                          },
                          [
                            _vm._v(
                              "\n              ルールを取得する\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  _vm.spreadsSheetUrlMsgState === "success",
                                expression:
                                  "spreadsSheetUrlMsgState === 'success'",
                              },
                            ],
                            staticClass:
                              "spreads-sheet-url-input-form__button-container-success-msg",
                          },
                          [
                            _vm._v(
                              "\n              スプレッドシートからルールを取得しました。\n            "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.spreadsSheetUrlMsgState === "failed",
                                expression:
                                  "spreadsSheetUrlMsgState === 'failed'",
                              },
                            ],
                            staticClass:
                              "spreads-sheet-url-input-form__button-container-success-msg",
                          },
                          [
                            _vm._v(
                              "\n              スプレッドシートからデータを取得に失敗しました。\n            "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.files.length > 0
                  ? _c(
                      "div",
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { type: "submit", variant: "primary" },
                            on: { click: _vm.openModal },
                          },
                          [_vm._v("選択したファイル群の文字認識を行う")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("main-image-modal", {
                  ref: "mainImageModal",
                  attrs: {
                    file: _vm.files,
                    imageUrls: _vm.imageUrls,
                    fetchedSectionValues: _vm.fetchedSectionValues,
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }