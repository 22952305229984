import { can } from '@/service/can';

export default {
  name: 'Can',
  functional: true,
  props: {
    I: Boolean,
    not: Boolean,
    do: String,
    of: String,
  },

  render(_: any, { props, children, parent }: any): any {
    const invert = !!props.not;

    const h = parent.$createElement;
    const allowed = can(parent.$ability, props.do, props.of);

    if (invert && allowed) {
      return h();
    }

    if (!invert && !allowed) {
      return h();
    }

    return children;
  },
};
