import { fetchAllPublishedWikiPageIds } from './ArticleApi';
import {
  fetchTransferredWikiStateForGameVillage,
  insertTransferredWikiStateForGameVillage,
} from './GoogleCloudStorageApi';
import { isGapiRequestError } from '@/lib/isError';
import { APIClient } from './WikiAPIClientFactory';

export async function getTransferredWikiStateWithKamigameSource(
  api: APIClient,
  currentWikiName: string,
  sourceWikiName: string
) {
  const wikiPageIds = await fetchAllPublishedWikiPageIds(api, currentWikiName, false);

  // NOTE: トップページと雑談掲示板がデフォルトであるので、記事数がそれを超える場合は既に移行済みとみなす
  const isTransferred = wikiPageIds.length > 2;

  try {
    return await fetchTransferredWikiStateForGameVillage(currentWikiName);
  } catch (e) {
    if (!isGapiRequestError(e)) throw e;

    if (e.status === 404) {
      if (isTransferred) {
        throw e;
      }

      return await createTransferredWikiStateWithKamigameSource(currentWikiName, sourceWikiName);
    }

    throw e;
  }
}

async function createTransferredWikiStateWithKamigameSource(currentWikiName: string, sourceWikiName: string) {
  const transferredWikiState: TransferredWikiState = {
    sourceWiki: {
      name: sourceWikiName,
      apiBaseUrl:
        process.env.NODE_ENV === 'production'
          ? 'https://api-dot-vls-kamigame-wiki.appspot.com'
          : 'http://localhost:3005',
    },
    pages: { byId: {} },
    categories: { byId: {} },
    pagePartials: { byId: {} },
  };

  await insertTransferredWikiStateForGameVillage(transferredWikiState, currentWikiName);
  return transferredWikiState;
}
