function isAdminOrigin(href: string): boolean {
  let parsed, adminUrlBase;
  try {
    parsed = new URL(href);
    adminUrlBase = new URL(ADMIN_URL_BASE);
  } catch (e) {
    return false;
  }
  return parsed.origin === adminUrlBase.origin;
}

function kamigameConvertToKamigameOriginExtension() {
  const init = () => {
    const wrapper = document.getElementById('wrapper');
    if (!wrapper) {
      return;
    }

    const aTags = wrapper.getElementsByTagName('a');

    for (const aTag of aTags) {
      aTag.target = '_blank';
      aTag.rel = 'noopener';

      if (!isAdminOrigin(aTag.href)) {
        continue;
      }

      aTag.href = KAMIGAME_URL_BASE + new URL(aTag.href).pathname;
    }
  };

  document.addEventListener('kamigame-article-loaded', init);
  init();
}

export default kamigameConvertToKamigameOriginExtension;
