
import Component from 'vue-class-component';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';

@Component({
  name: 'wiki-custom-css',
  components: {
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiCustomCss extends KamigameVue {
  css: string = '';
  savedCss: string = '';

  mounted() {
    this.apiClient.GET('/wiki/{name}/custom-css', { params: { path: { name: this.wikiName } } }).then((res) => {
      if (res.error) {
        throw res.error;
      }

      this.css = res.data?.css || '';
      this.savedCss = this.css;
    });
  }

  async save() {
    this.apiClient
      .PUT('/admin/wiki/{wiki.name}/custom-css', {
        params: { path: { 'wiki.name': this.wikiName } },
        body: {
          css: this.css,
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        this.savedCss = this.css;
        this.setFlashMessage(
          'success',
          'カスタム CSS の設定が完了しました。',
          this.wikiTopUrl,
          'サイト上で内容を確認する',
          true
        );
      })
      .catch(() => {
        this.setFlashMessage('danger', 'カスタム CSS の設定に失敗しました。');
      });
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    return this.css !== this.savedCss;
  }
}
