
import KamigameVue from '@/KamigameVue';
import { components } from '@/api-client/schema';
import { chunk } from '@/lib/chunk';
import { wait } from '@/lib/wait';
import { fetchAllPublishedWikiPageIds } from '@/service/ArticleApi';
import Component from 'vue-class-component';

type CopiedWikiPageState = {
  currentWikiPageId: string;
  sourceWikiPageId: string;
  sourceWikiPageCategoryId: string;
};

type CopiedCategoryState = {
  currentCategoryName: string;
  currentCategoryDisplayName: string;
  currentCategoryId: string;
  sourceCategoryId: string;
  sourceParentCategoryId: string;
};

@Component({
  name: 'button-bulk-transfer-articles',
})
export default class ButtonCopyWiki extends KamigameVue {
  sourceWikiName: string = '';
  errorMessage: string = '';

  async clickButtonOK() {
    (this.$refs.modalLoadingCopyWiki as any).show();
    await this.copyWiki();
    (this.$refs.modalLoadingCopyWiki as any).hide();

    if (this.errorMessage) {
      (this.$refs.modalCopyWiki as any).hide();
      (this.$refs.modalError as any).show();
      return;
    }

    this.setFlashMessage('success', 'Wiki のコピーが完了しました');
  }

  async copyWiki() {
    let copiedWikiPageStates: CopiedWikiPageState[] = [];
    try {
      copiedWikiPageStates = await this.copyWikiPages();
    } catch (e: any) {
      console.error(e);
      if (e?.message) {
        this.errorMessage = e.message;
      }
      return;
    }

    let copiedCategoryState: CopiedCategoryState[] = [];
    try {
      copiedCategoryState = await this.copyCategories(copiedWikiPageStates);
    } catch (e: any) {
      console.error(e);
      if (e?.message) {
        this.errorMessage = e.message;
      }
      return;
    }

    try {
      await this.linkCategoryToWikiPages(copiedWikiPageStates, copiedCategoryState);
    } catch (e: any) {
      console.error(e);
      if (e?.message) {
        this.errorMessage = e.message;
      }
      return;
    }

    try {
      await this.copyPagePartials();
    } catch (e: any) {
      console.error(e);
      if (e?.message) {
        this.errorMessage = e.message;
      }
      return;
    }

    try {
      await this.copyPageTemplates(copiedCategoryState);
    } catch (e: any) {
      console.error(e);
      if (e?.message) {
        this.errorMessage = e.message;
      }
      return;
    }
  }

  async copyWikiPages(): Promise<CopiedWikiPageState[]> {
    const copiedWikiPageStates: CopiedWikiPageState[] = [];
    const sourcePageIds = await fetchAllPublishedWikiPageIds(this.apiClient, this.sourceWikiName);

    const currentWikiPageTitles = await this.apiClient
      .GET('/admin/wiki/{wikiName}/page/titles', {
        params: {
          path: {
            wikiName: this.wikiName,
          },
          query: {
            isDeleted: false,
            limit: 100000,
          },
        },
      })
      .then((r) => {
        if (r.error && r.response.status !== 404) {
          throw new Error(`記事タイトルの取得に失敗しました ${r.error.message}`);
        }

        return r.data;
      });

    if (!currentWikiPageTitles?.wikiPageTitles) {
      return [];
    }

    const errors: Error[] = [];
    const sourcePageIdsChunk = chunk(sourcePageIds, 10);
    for (const sourcePageIdsInChunk of sourcePageIdsChunk) {
      const promises = sourcePageIdsInChunk.map(async (sourcePageId) => {
        const sourcePageResponse = await this.apiClient.GET('/admin/wiki/{wikiName}/{pageId}', {
          params: {
            path: {
              wikiName: this.sourceWikiName,
              pageId: sourcePageId,
            },
          },
        });

        const sourcePage = sourcePageResponse.data;
        if (!sourcePage) {
          throw new Error(`記事の取得に失敗しました。コピー元記事ID: ${sourcePageId}`);
        }

        // NOTE: 公開されてない記事は下書きの状態なのでコピーしない
        if (!sourcePage.wikiPage?.publishedAt) {
          return;
        }

        if (!sourcePage.title || !sourcePage.body) {
          throw new Error('記事のタイトル、本文、ID がのどれかがありません。コピー元記事ID: ' + sourcePageId);
        }

        try {
          const { currentWikiPageId } = await this.copyWikiPage(sourcePage, currentWikiPageTitles);
          copiedWikiPageStates.push({
            currentWikiPageId,
            sourceWikiPageId: sourcePageId,
            sourceWikiPageCategoryId: sourcePage?.wikiPage?.category?.id ?? '',
          });
        } catch (e: any) {
          throw new Error(`記事のコピーに失敗しました。コピー元記事ID: ${sourcePageId}`);
        }
      });

      const results = await Promise.allSettled(promises);
      results.forEach((result) => {
        if (result.status === 'rejected') {
          errors.push(result.reason as Error);
        }
      });
      await wait();
    }

    if (errors.length > 0) {
      throw new Error(`${errors.map((e) => e).join('\n\n')}`);
    }

    return copiedWikiPageStates;
  }

  async copyWikiPage(
    sourcePage: components['schemas']['v1WikiPageWithTitleAndBody'],
    currentWikiPageTitles: components['schemas']['v1WikiPageTitleCollection']
  ): Promise<{ currentWikiPageId: string }> {
    if (!sourcePage.wikiPage) {
      throw new Error('記事の取得に失敗しました。');
    }

    // NOTE: トップページと雑談掲示板は最初からあるので移行せずに上書きする
    const currentPageSamePathFromSource = currentWikiPageTitles.wikiPageTitles?.find(
      (page) => page.path === sourcePage.wikiPage?.path
    );

    if (
      currentPageSamePathFromSource &&
      currentPageSamePathFromSource.id &&
      (currentPageSamePathFromSource.path === 'index' || currentPageSamePathFromSource.path === '掲示板')
    ) {
      const sourcePageWithoutCategory = {
        ...sourcePage,
        wikiPage: {
          ...sourcePage.wikiPage,
          category: undefined,
        },
      };

      await this.updateWikiPageDraft(sourcePageWithoutCategory, currentPageSamePathFromSource.id);
      return { currentWikiPageId: currentPageSamePathFromSource.id };
    }

    // NOTE: タイトルが同じ記事がある場合はスキップする
    const currentPageTitlesSameSourceTitle = currentWikiPageTitles.wikiPageTitles?.find(
      (page) => page.title === sourcePage.title
    );
    if (currentPageTitlesSameSourceTitle && currentPageTitlesSameSourceTitle.id) {
      return { currentWikiPageId: currentPageTitlesSameSourceTitle.id };
    }

    const draft = await this.createWikiPage(sourcePage);
    if (!draft.wikiPage?.id) {
      throw new Error('下書きの作成に失敗しました。下書きの ID がありません');
    }

    return { currentWikiPageId: draft.wikiPage.id };
  }

  async createWikiPage(page: components['schemas']['v1WikiPageWithTitleAndBody']) {
    // NOTE: id=0 とすると新規作成になる
    const draft = await this.apiClient
      .POST('/admin/wiki/{wikiName}/page/{pageId}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            pageId: '0',
          },
        },
        body: {
          title: page.title,
          description: page.wikiPage?.description ?? ' ', // NOTE: description が空だとエラーになるため空文字を設定
          body: `${page.body}\n\n<!--[source_id:${page.wikiPage?.id}]-->`, // NOTE: 検索ワード検索で元記事の ID を検索できるようにするため
          keywords: page.wikiPage?.keywords ?? '',
          noindex: page.wikiPage?.noindex ?? false,
          metaOgpImageURL: page.wikiPage?.metaOgpImageURL ?? '',
          metaThumbnailImageAutoSelect: page.wikiPage?.metaThumbnailImageAutoSelect ?? false,
          metaThumbnailImageURL: page.wikiPage?.metaThumbnailImageURL ?? '',
          titlePrefix: page.wikiPage?.titlePrefix ?? '',
          spreadsheetURL: [],
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });

    if (!draft.wikiPage?.id) {
      throw new Error('下書きの作成に失敗しました');
    }

    return draft;
  }

  async updateWikiPageDraft(page: components['schemas']['v1WikiPageWithTitleAndBody'], pageId: string) {
    await this.apiClient
      .POST('/admin/wiki/{wikiName}/page/{pageId}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            pageId,
          },
        },
        body: {
          title: page.title,
          description: page.wikiPage?.description ?? ' ', // NOTE: description が空だとエラーになるため空文字を設定
          body: page.body,
          keywords: page.wikiPage?.keywords ?? '',
          noindex: page.wikiPage?.noindex ?? false,
          metaOgpImageURL: page.wikiPage?.metaOgpImageURL ?? '',
          metaThumbnailImageAutoSelect: page.wikiPage?.metaThumbnailImageAutoSelect ?? false,
          metaThumbnailImageURL: page.wikiPage?.metaThumbnailImageURL ?? '',
          titlePrefix: page.wikiPage?.titlePrefix ?? '',
          spreadsheetURL: [],
          categoryId: page.wikiPage?.category?.id ?? '',
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
  }

  async copyCategories(copiedWikiPageStates: CopiedWikiPageState[]): Promise<CopiedCategoryState[]> {
    const copiedCategoryStates: CopiedCategoryState[] = [];

    const sourceCategoriesResponse = await this.apiClient
      .GET('/admin/wiki/{wikiName}/category', {
        params: {
          path: {
            wikiName: this.sourceWikiName,
          },
        },
      })
      .then((r) => {
        if (r.error && r.response.status !== 404) {
          throw r.error;
        }

        return r.data;
      });

    if (!sourceCategoriesResponse) {
      return [];
    }

    const sourceCategories = sourceCategoriesResponse.wikiPageCategories ?? [];

    // NOTE: 一度に大量のリクエストを送るとサーバの負荷になるため同期的に処理する
    const errors: Error[] = [];
    for (const sourceCategory of sourceCategories) {
      if (!sourceCategory.name) {
        continue;
      }

      const currentCategoryPageId = copiedWikiPageStates.find(
        (state) => state.sourceWikiPageId === sourceCategory.wikiPageID
      )?.currentWikiPageId;
      const currentCategoryResponse = await this.apiClient.POST('/admin/wiki/{name}/category', {
        params: {
          path: {
            name: this.wikiName,
          },
        },
        body: {
          name: sourceCategory.name,
          displayname: sourceCategory.displayName,
          wikiPageId: currentCategoryPageId ?? '',
        },
      });

      if (currentCategoryResponse.error && currentCategoryResponse.response.status !== 404) {
        console.error(currentCategoryResponse.error);
        errors.push(new Error('カテゴリのコピーに失敗しました。コピー元カテゴリ名: ' + sourceCategory.name));
        continue;
      }

      const currentCategory = currentCategoryResponse?.data;
      if (!currentCategory || !currentCategory.name || !currentCategory.displayName) {
        continue;
      }

      copiedCategoryStates.push({
        currentCategoryName: currentCategory.name,
        currentCategoryDisplayName: currentCategory.displayName,
        currentCategoryId: currentCategory.id ?? '',
        sourceCategoryId: sourceCategory.id ?? '',
        sourceParentCategoryId: sourceCategory.parentID ?? '',
      });
    }

    // NOTE: 一度に大量のリクエストを送るとサーバの負荷になるため同期的に処理する
    for (const copiedCategoryState of copiedCategoryStates) {
      if (!copiedCategoryState.sourceParentCategoryId) {
        continue;
      }

      const currentParentCategoryState = copiedCategoryStates.find(
        (s) => s.sourceCategoryId === copiedCategoryState.sourceParentCategoryId
      );

      if (!currentParentCategoryState) {
        continue;
      }

      await this.apiClient
        .PUT('/admin/wiki/{wikiName}/category/{id}', {
          params: {
            path: {
              wikiName: this.wikiName,
              id: copiedCategoryState.currentCategoryId,
            },
          },
          body: {
            name: copiedCategoryState.currentCategoryName,
            displayname: copiedCategoryState.currentCategoryDisplayName,
            parentID: currentParentCategoryState.currentCategoryId,
          },
        })
        .catch((e: any) => {
          console.error(e);
          errors.push(
            new Error('カテゴリ記事の設定に失敗しました。カテゴリ名: ' + copiedCategoryState.currentCategoryName)
          );
        });
    }

    if (errors.length > 0) {
      throw new Error(`${errors.map((e) => e.message).join('\n\n')}`);
    }
    return copiedCategoryStates;
  }

  async linkCategoryToWikiPages(
    copiedWikiPageStates: CopiedWikiPageState[],
    copiedCategoryState: CopiedCategoryState[]
  ) {
    const failedIds = [];
    for (const copiedWikiPageState of copiedWikiPageStates) {
      if (!copiedWikiPageState.sourceWikiPageCategoryId) {
        continue;
      }

      const linkedCategoryState = copiedCategoryState.find(
        (state) => state.sourceCategoryId === copiedWikiPageState.sourceWikiPageCategoryId
      );

      if (!linkedCategoryState) {
        continue;
      }

      const linkedPageResponse = await this.apiClient.GET('/admin/wiki/{wikiName}/{pageId}', {
        params: {
          path: {
            wikiName: this.wikiName,
            pageId: copiedWikiPageState.currentWikiPageId,
          },
        },
      });

      if (linkedPageResponse.error) {
        failedIds.push(copiedWikiPageState.currentWikiPageId);
        continue;
      }

      const linkedPage = linkedPageResponse.data;
      if (!linkedPage.wikiPage) {
        continue;
      }

      const linkedPageWithCategory = {
        ...linkedPage,
        wikiPage: {
          ...linkedPage?.wikiPage,
          category: { id: linkedCategoryState.currentCategoryId },
        },
      };

      await this.updateWikiPageDraft(linkedPageWithCategory, copiedWikiPageState.currentWikiPageId).catch((e: any) => {
        console.error(e);
        failedIds.push(copiedWikiPageState.currentWikiPageId);
      });
    }

    if (failedIds.length > 0) {
      throw new Error(`記事のカテゴリ設定に失敗しました。 記事ID: ${failedIds.join(', ')}`);
    }
  }

  async copyPagePartials() {
    const sourcePagePartialsResponse = await this.apiClient.GET('/admin/wiki/{wikiName}/page-partial', {
      params: {
        path: {
          wikiName: this.sourceWikiName,
        },
      },
    });

    if (sourcePagePartialsResponse.error && sourcePagePartialsResponse.response.status !== 404) {
      throw new Error(`コピー元の記事部品の取得に失敗しました ${sourcePagePartialsResponse.error.message}`);
    }

    const sourcePagePartials = sourcePagePartialsResponse.data?.pagePartials ?? [];

    const failedPagePartialNames: string[] = [];
    // NOTE: 一度に大量のリクエストを送るとサーバの負荷になるため同期的に処理する
    for (const sourcePagePartial of sourcePagePartials) {
      if (!sourcePagePartial.name || !sourcePagePartial.body) {
        continue;
      }

      await this.apiClient
        .POST('/admin/wiki/{wikiName}/page-partial', {
          params: {
            path: {
              wikiName: this.wikiName,
            },
          },
          body: {
            name: sourcePagePartial.name,
            body: sourcePagePartial.body,
            columns: { sheets: {} }, // NOTE: これがないと 500 エラーになる
          },
        })
        .catch((e: any) => {
          console.error(e);
          failedPagePartialNames.push(sourcePagePartial.name ?? '');
        });
    }

    if (failedPagePartialNames.length > 0) {
      throw new Error(`記事部品のコピーに失敗しました。記事部品名: ${failedPagePartialNames.join(', ')}`);
    }
  }

  async copyPageTemplates(copiedCategoryState: CopiedCategoryState[]) {
    const sourcePageTemplatesResponse = await this.apiClient.GET('/admin/wiki/{wikiName}/template', {
      params: {
        path: {
          wikiName: this.sourceWikiName,
        },
      },
    });

    if (sourcePageTemplatesResponse.error && sourcePageTemplatesResponse.response.status !== 404) {
      throw new Error(`コピー元の記事テンプレートの取得に失敗しました ${sourcePageTemplatesResponse.error.message}`);
    }

    const sourcePageTemplates = sourcePageTemplatesResponse.data?.template ?? [];

    const failedPageTemplateTitles: string[] = [];
    // NOTE: 一度に大量のリクエストを送るとサーバの負荷になるため同期的に処理する
    for (const sourcePageTemplate of sourcePageTemplates) {
      const categoryId = copiedCategoryState.find(
        (state) => state.sourceCategoryId === sourcePageTemplate.category?.id
      )?.currentCategoryId;

      await this.apiClient
        .POST('/admin/wiki/{wikiName}/template', {
          params: {
            path: {
              wikiName: this.wikiName,
            },
          },
          body: {
            title: sourcePageTemplate.title,
            body: sourcePageTemplate.body,
            description: sourcePageTemplate.description,
            keywords: sourcePageTemplate.keywords,
            categoryId: categoryId ?? '',
          },
        })
        .catch((e) => {
          console.error(e);
          failedPageTemplateTitles.push(sourcePageTemplate.title ?? '');
        });
    }

    if (failedPageTemplateTitles.length > 0) {
      throw new Error(
        `記事テンプレートのコピーに失敗しました。記事テンプレート名: ${failedPageTemplateTitles.join(', ')}`
      );
    }
  }
}
