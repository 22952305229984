const extractNotAllowedDomainsFromErrorMessage = (errorMessage: string): string[] => {
  const regexp = new RegExp(/.*Not allowed domains. \[.*\]"/gm);
  if (!regexp.test(errorMessage)) {
    return [];
  }

  return errorMessage.split('[')[1].replace(']"', '').split(' ');
};

export default extractNotAllowedDomainsFromErrorMessage;
