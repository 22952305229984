var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "wikiEditor", attrs: { id: "wikiEditor" } },
    [
      _c("kamigame-search-bar", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isDisplayingSearchBar,
            expression: "isDisplayingSearchBar",
          },
        ],
        ref: "searchBar",
        staticClass: "wiki-edit-search-bar",
        attrs: {
          target: _vm.value,
          codeMirror: _vm.$refs.tuiEditor ? _vm.codeMirror : null,
        },
        on: { replace: _vm.onReplaceText },
      }),
      _vm._v(" "),
      _c("link", { attrs: { rel: "stylesheet", href: _vm.customCssUrl } }),
      _vm._v(" "),
      _c("editor", {
        ref: "tuiEditor",
        attrs: {
          height: _vm.isFullScreen ? "100%" : "calc(100vh - 225px)",
          initialEditType: "markdown",
          previewStyle: "vertical",
          options: _vm.editorOptions,
        },
        on: { change: _vm.onEditorChange, load: _vm.onEditorLoad },
      }),
      _vm._v(" "),
      _c("image-uploader-modal", {
        ref: "imageUploaderModal",
        on: {
          selected: ({ title, file }) =>
            _vm.processImageUploader({ title, files: [file] }),
        },
      }),
      _vm._v(" "),
      _c("link-assist-modal", {
        ref: "linkAssistModal",
        attrs: { "link-assist-urls": _vm.linkAssistURLs },
        on: { selected: _vm.onlinkSelected },
      }),
      _vm._v(" "),
      _c("kamigame-wiki-page-template-select-modal", {
        ref: "wikiPageTemplateSelectModal",
        attrs: { modalRef: "wikiPageTemplateInsertModal" },
        on: { selectionSaved: _vm.onWikiPageTemplateSelected },
      }),
      _vm._v(" "),
      _c("kamigame-wiki-page-partial-select-modal", {
        ref: "wikiPagePartialSelectModal",
        attrs: { modalRef: "wikiPagePartialInsertModal" },
        on: { selectionSaved: _vm.onWikiPagePartialSelected },
      }),
      _vm._v(" "),
      _c("kamigame-wiki-partial-generate-code-modal", {
        ref: "partialGenerateCodeSelectModal",
        attrs: { mode: _vm.mode },
        on: { selectionSaved: _vm.onPartialGenerateCodeSelected },
      }),
      _vm._v(" "),
      _c("kamigame-wiki-page-keyboard-shortcut-help-modal", {
        ref: "wikiPageKeyboardShortcutHelpModalTemplate",
        attrs: { shortcuts: _vm.shortcuts },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }