var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "header",
    { staticClass: "app-header navbar" },
    [
      _c(
        "button",
        {
          staticClass: "navbar-toggler mobile-sidebar-toggler d-lg-none",
          attrs: { type: "button" },
          on: { click: _vm.mobileSidebarToggle },
        },
        [_c("span", { staticClass: "navbar-toggler-icon" })]
      ),
      _vm._v(" "),
      _c("b-link", { staticClass: "navbar-brand", attrs: { to: "#" } }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "navbar-toggler sidebar-toggler d-md-down-none",
          attrs: { type: "button" },
          on: { click: _vm.sidebarToggle },
        },
        [_c("span", { staticClass: "navbar-toggler-icon" })]
      ),
      _vm._v(" "),
      _c(
        "b-navbar-nav",
        { staticClass: "mr-3" },
        [
          _c("b-dropdown", {
            attrs: { text: _vm.wikiTitle, variant: "link" },
            on: { show: _vm.openWikiMenu },
          }),
          _vm._v(" "),
          _c("WikiSelectModal", {
            ref: "wikiSelectModal",
            attrs: { "wiki-list": _vm.joinedWiki.wiki },
            on: { wikiSelected: _vm.moveToWiki },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-link",
        {
          staticClass: "mr-auto",
          attrs: { href: _vm.wikiTopUrl, target: "_blank" },
        },
        [
          _c("i", {
            staticClass: "fa fa-external-link",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v("Wiki を表示\n  "),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "navbar-toggler aside-menu-toggler d-md-down-none",
          attrs: { type: "button" },
          on: { click: _vm.asideToggle },
        },
        [_c("span", { staticClass: "navbar-toggler-icon" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }