import { chunk } from '@/lib/chunk';
import { bulkReplace } from '@/lib/replace';
import { wait } from '@/lib/wait';
import { APIClient } from './WikiAPIClientFactory';
import { components } from '@/api-client/schema';

export async function fetchAllPublishedWikiPageIds(
  api: APIClient,
  wikiName: string,
  isDeleted = false
): Promise<string[]> {
  const pageTitlesResponse = await api
    .GET('/admin/wiki/{wikiName}/page/titles', {
      params: {
        path: {
          wikiName,
        },
        query: {
          isDeleted,
          excludesRedirected: false,
        },
      },
    })
    .then((r) => {
      if (r.error) {
        throw r.error;
      }

      return r.data;
    });

  if (!pageTitlesResponse.wikiPageTitles) {
    throw new Error('ページの取得に失敗しました。');
  }

  const titles = pageTitlesResponse.wikiPageTitles;
  const pageIds = titles.map((page) => page.id).filter((id): id is string => typeof id === 'string');

  return pageIds;
}

export async function existsDraft(api: APIClient, wikiName: string): Promise<boolean> {
  const draftsResponse = await api.GET('/admin/wiki/{wikiName}/page/draft', {
    params: {
      path: {
        wikiName,
      },
    },
  });

  if (draftsResponse.error) {
    // NOTE: 下書きがない場合は404が返ってくる
    if (draftsResponse.error.code === 404) {
      return false;
    }

    throw new Error('下書きのチェックに失敗しました。再度やり直すかエンジニアに連絡してください。');
  }

  if (!draftsResponse.data.drafts) {
    return false;
  }

  return true;
}

export async function fetchPublishedWikiPage(api: APIClient, wikiName: string, pageId: string) {
  const res = await api
    .GET('/admin/wiki/{wikiName}/{pageId}', {
      params: {
        path: {
          wikiName,
          pageId,
        },
      },
    })
    .then((r) => {
      if (r.error) {
        throw r.error;
      }

      return r.data;
    });

  return res;
}

export async function editWikiPageDraft(
  api: APIClient,
  wikiName: string,
  publishedPage: components['schemas']['v1WikiPageWithTitleAndBody'],
  draft: {
    title?: string;
    body?: string;
    description?: string;
  }
) {
  const pageId = publishedPage.wikiPage?.id;

  if (!wikiName || !pageId) {
    throw new Error('wikiName または pageId が存在しません。');
  }

  await api
    .POST('/admin/wiki/{wikiName}/page/{pageId}/draft', {
      params: {
        path: {
          wikiName,
          pageId,
        },
      },
      body: {
        title: draft.title ?? publishedPage.title,
        body: draft.body ?? publishedPage.body,
        description: draft.description ?? publishedPage.wikiPage?.description,
        keywords: publishedPage.wikiPage?.keywords,
        metaOgpImageURL: publishedPage.wikiPage?.metaOgpImageURL,
        metaThumbnailImageAutoSelect: publishedPage.wikiPage?.metaThumbnailImageAutoSelect,
        metaThumbnailImageURL: publishedPage.wikiPage?.metaThumbnailImageURL,
        noindex: publishedPage.wikiPage?.noindex,
        titlePrefix: publishedPage.wikiPage?.titlePrefix,
        categoryId: publishedPage.wikiPage?.category?.id,
      },
    })
    .then((r) => {
      if (r.error) {
        throw r.error;
      }

      return r.data;
    });
}

export async function replaceWikiPageDraft(
  api: APIClient,
  wikiName: string,
  pageId: string,
  replacements: Replacement[]
) {
  const publishedPage = await fetchPublishedWikiPage(api, wikiName, pageId);

  if (!publishedPage.title || !publishedPage.body) {
    throw new Error('編集先の title または body が空です。');
  }

  const replacedTitle = bulkReplace(publishedPage.title, replacements);
  const replacedBody = bulkReplace(publishedPage.body, replacements);
  const replacedDescription = bulkReplace(publishedPage.wikiPage?.description ?? '', replacements);

  if (
    replacedTitle === publishedPage.title &&
    replacedBody === publishedPage.body &&
    replacedDescription === publishedPage.wikiPage?.description
  ) {
    return;
  }

  await editWikiPageDraft(api, wikiName, publishedPage, {
    title: bulkReplace(publishedPage.title, replacements),
    body: bulkReplace(publishedPage.body, replacements),
    description: bulkReplace(publishedPage.wikiPage?.description ?? '', replacements),
  });
}

export async function bulkReplaceWikiPageDrafts(api: APIClient, wikiName: string, replacements: Replacement[]) {
  const pageIds = await fetchAllPublishedWikiPageIds(api, wikiName);
  const pageIdsChunk = chunk<string>(pageIds, 20);

  for (const pageIds of pageIdsChunk) {
    const promises = pageIds.map((pageId) => replaceWikiPageDraft(api, wikiName, pageId, replacements));

    await Promise.all(promises);

    wait(1000);
  }
}

async function publishDraft(api: APIClient, wikiName: string, draft: components['schemas']['v1WikiPageDraft']) {
  if (!draft.wikiPage?.id) {
    throw new Error('下書きの id が存在しません。');
  }

  const resPublish = await api
    .PUT('/admin/wiki/{wikiId}/page/{pageId}', {
      params: {
        path: {
          wikiId: wikiName,
          pageId: draft.wikiPage?.id,
        },
      },
      body: {
        body: draft.body,
        description: draft.description || ' ', // NOTE: 雑談掲示板など意図的に description を空にしている記事があるが、空だと更新できないので空白を入れる
        categoryId: draft.category?.id || '',
        keywords: draft.keywords,
        metaOgpImageURL: draft.metaOgpImageURL,
        metaThumbnailImageAutoSelect: draft.metaThumbnailImageAutoSelect,
        metaThumbnailImageURL: draft.metaThumbnailImageURL,
        noindex: draft.noindex,
        spreadsheetURL: [],
        title: draft.title,
        titlePrefix: draft.titlePrefix,
        editPermission: 'OBJ_default',
        publishedAt: new Date().toISOString(),
      },
    })
    .then((r) => {
      if (r.error) {
        throw r.error;
      }

      return r.data;
    });

  const resDelete = await api.DELETE('/admin/wiki/{wikiName}/page/{pageId}/draft', {
    params: {
      path: {
        wikiName,
        pageId: draft.wikiPage.id,
      },
    },
  });
  if (resDelete.error && resDelete.error.code !== 404) {
    throw new Error('下書きの削除に失敗しました。');
  }

  return resPublish;
}

export async function bulkPublishDrafts(api: APIClient, wikiName: string) {
  const errors: Error[] = [];

  const resDrafts = await api
    .GET('/admin/wiki/{wikiName}/page/draft', {
      params: {
        path: {
          wikiName,
        },
      },
    })
    .then((r) => {
      if (r.error) {
        throw r.error;
      }

      return r.data;
    });

  const drafts = resDrafts.drafts;
  if (!drafts) {
    throw new Error('下書きの取得に失敗しました。');
  }

  const draftsChunk = chunk(drafts, 20);
  for (const drafts of draftsChunk) {
    const promises = drafts.map((draft) => publishDraft(api, wikiName, draft));

    await Promise.all(promises);
    wait(1000);
  }
}

async function replaceDraftContentHeader(api: APIClient, wikiName: string, contentHeader: ContentHeader) {
  const publishedWikiPage = await fetchPublishedWikiPage(api, wikiName, contentHeader.pageId);

  if (!publishedWikiPage.body) {
    throw new Error('編集先の body が存在しません。');
  }

  const newBody = contentHeader.h1
    ? publishedWikiPage.body.replace(/^#\s*.+/m, `# ${contentHeader.h1}`)
    : publishedWikiPage.body;

  await editWikiPageDraft(api, wikiName, publishedWikiPage, {
    title: contentHeader.title,
    body: newBody,
    description: contentHeader.description,
  });
}

export async function bulkReplaceDraftsContentHeader(
  api: APIClient,
  wikiName: string,
  contentHeaders: ContentHeader[]
) {
  const contentHeadersChunk = chunk(contentHeaders, 20);

  for (const contentHeader of contentHeadersChunk) {
    const promises = contentHeader.map(async (c) => replaceDraftContentHeader(api, wikiName, c));

    await Promise.all(promises);
    wait(1000);
  }
}
