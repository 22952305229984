var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-input-group",
        { staticClass: "search-bar-space" },
        [
          _c("b-form-input", {
            ref: "searchFormInput",
            attrs: { type: "text", placeholder: "本文検索" },
            on: {
              input: function ($event) {
                return _vm.searchInput(true)
              },
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.cursorShortcut.apply(null, arguments)
              },
            },
            model: {
              value: _vm.searchText,
              callback: function ($$v) {
                _vm.searchText = $$v
              },
              expression: "searchText",
            },
          }),
          _vm._v(" "),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-btn",
                {
                  staticClass: "search-btn config-toggle",
                  on: { click: _vm.cursorPrev },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-arrow-up",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  staticClass: "search-btn config-toggle",
                  on: { click: _vm.cursorNext },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-arrow-down",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-btn",
                {
                  staticClass: "search-btn config-toggle",
                  on: {
                    click: () => {
                      _vm.toggleReplace = !_vm.toggleReplace
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-angle-down",
                    attrs: { "aria-hidden": "true" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-form-text",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.searchText !== "",
                      expression: "searchText !== ''",
                    },
                  ],
                  staticClass: "form-text-length",
                },
                [
                  _vm._v(
                    _vm._s(_vm.searchResultsNumber) +
                      " / " +
                      _vm._s(_vm.searchResultsCount) +
                      " 件"
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.toggleReplace
        ? _c(
            "b-input-group",
            { staticClass: "search-bar-space" },
            [
              _c("b-form-input", {
                attrs: { type: "text", placeholder: "置換" },
                model: {
                  value: _vm.searchReplace,
                  callback: function ($$v) {
                    _vm.searchReplace = $$v
                  },
                  expression: "searchReplace",
                },
              }),
              _vm._v(" "),
              _c(
                "b-input-group-append",
                [
                  _c(
                    "b-btn",
                    {
                      staticClass: "search-btn config-toggle",
                      on: { click: _vm.replace },
                    },
                    [_vm._v(" 置換 ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      staticClass: "search-btn config-toggle",
                      on: { click: _vm.replaceAll },
                    },
                    [_vm._v(" 全て置換 ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }