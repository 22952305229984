
import Component from 'vue-class-component';
import format from 'date-fns/format';
import VueRouter from 'vue-router';
import { Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';

import KamigameVue from '@/KamigameVue';
import { MarkdownEditor, CategoryTreeSelectModal, WikiPageTitleSearchModal } from '@/components';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import ButtonTranslateArticle from '@/components/Button/ButtonTranslateArticle.vue';
import { MarkdownParser } from 'kamigame-web';

import extractNotAllowedDomainsFromErrorMessage from '@/service/ExtractNotAllowedDomainsFromErrorMessage';
import { calcDiffLines } from '@/service/CalcDiffLines';
import { lint } from '@/service/TextLint';
import { extractDependingSpreadsheetsFromMarkdownText } from 'wiki-shared/template-code-util';
import { ValidationResult, validateWikiPageTitle, braceError } from '@/service/WikiPageTitleValidation';

import { convertToCssUnicodeCodePoint } from '@/service/StringConverter';
import { components } from '@/api-client/schema';

const AUTO_LIGHTMODE_COUNT = 50000;
@Component({
  name: 'wiki-edit-page',
  components: {
    'kamigame-markdown-editor': MarkdownEditor,
    'kamigame-category-tree-select-modal': CategoryTreeSelectModal,
    'kamigame-wiki-page-title-search-modal': WikiPageTitleSearchModal,
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
    'button-translate-article': ButtonTranslateArticle,
  },
})
export default class WikiEditPage extends KamigameVue {
  dateFormat = format;

  pageId: string = '';
  title: string = '';
  text: string = '';
  disabled: boolean = false;
  selectedCategory: components['schemas']['v1WikiPageCategory'] = {};
  selectedEditPermission: string = 'OBJ_default';
  editPermissions = {
    OBJ_default: 'メンバー全員が編集可能',
    OBJ_sub_admin: '副管理者と管理者が編集可能',
    OBJ_admin: '管理者のみが編集可能',
  };
  editable: boolean = true;
  noindex: boolean = false;
  savedNoindex: boolean = false;
  publishedDate: string = '';
  publishedTime: string = '';
  keywords: string = '';
  description: string = '';
  metaOgpImageURL: string = '';
  metaThumbnailImageURL: string = '';
  metaThumbnailImageAutoSelect: boolean = false;
  pageTitle: string = '';
  pageText: string = '';
  draftId: string = '';
  draftTitle: string = '';
  draftText: string = '';
  draftAuthorName: string = '';
  draftUpdatedAt?: Date;
  isRootPath = false;
  canonicalPath?: string;
  kamigameUrl: string = '';
  redirectPageTitle: components['schemas']['v1WikiPageTitle'] = {};
  selectedRedirectPageTitle: components['schemas']['v1WikiPageTitle'] = {};
  wikiPageTitles: components['schemas']['v1WikiPageTitle'][] = [];
  isLintRunning = false;
  titlePrefix: string = '';
  setsTitlePrefix: boolean = false;
  path: string = '';
  markdownTitle: string = '';
  brokenFragmentLinks: string[] = [];
  autoDraftSave: boolean = false;
  autoSaveTimeoutID: number = 0;
  titleValidationResult: ValidationResult = { warnings: [], errors: [] };
  titleFeedbackMessages: string[] = [];
  isDeleted = false;
  isGameVillage = LOCALE === 'en';

  @Watch('page')
  onPageChanged() {
    this.init();
  }

  @Watch('text')
  onTextChanged() {
    if (
      this.autoDraftSave &&
      typeof this.draftUpdatedAt !== 'undefined' &&
      this.canSaveTemplateAndDraft &&
      this.autoSaveTimeoutID === 0
    ) {
      this.autoSaveTimeoutID = window.setTimeout(() => {
        if (this.autoDraftSave) {
          this.saveAsDraft();
        }
        this.autoSaveTimeoutID = 0;
      }, 30 * 1000);
    }
  }

  @Watch('title')
  onTitleChanged() {
    this.validateTitle();
  }

  mounted() {
    this.init();
  }

  init() {
    if (this.page) {
      this.getWikiPage();
    } else {
      this.resetPageStates();
    }

    const templateId = this.usingTemplateId;
    if (!this.pageId && templateId) {
      this.apiClient
        .GET('/admin/wiki/{wikiName}/template/{templateId}', {
          params: {
            path: {
              wikiName: this.wikiName,
              templateId,
            },
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }

          return r.data;
        })
        .then((response) => {
          this.title = response.title || '';
          this.text = response.body || '';
          this.selectedCategory = response.category || {};
          this.keywords = response.keywords || '';
          this.description = response.description || '';
        });
    }

    this.$store.getters.getWiki(this.wikiName).then((wiki: components['schemas']['v1Wiki']) => {
      this.isRootPath = !!wiki.isRootPath;
    });

    this.validateTitle();
  }

  getWikiPage() {
    this.apiClient
      .GET('/admin/wiki/{wikiName}/{pageId}', {
        params: {
          path: {
            wikiName: this.wikiName,
            pageId: this.page,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then((response: components['schemas']['v1WikiPageWithTitleAndBody']) => {
        this.pageTitle = response.title || '';
        this.pageText = response.body || '';
        this.titlePrefix = response.titlePrefix || '';
        this.setsTitlePrefix = !!this.titlePrefix;
        this.isDeleted = response.wikiPage?.isDeleted ?? false;
        if (response.wikiPage) {
          this.keywords = response.wikiPage.keywords || '';
          this.description = response.wikiPage.description || '';
          this.pageId = response.wikiPage.id || '';
          this.canonicalPath = response.wikiPage.path;
          this.noindex = !!response.wikiPage.noindex;
          this.metaOgpImageURL = response.wikiPage.metaOgpImageURL || '';
          this.metaThumbnailImageURL = response.wikiPage.metaThumbnailImageURL || '';
          this.metaThumbnailImageAutoSelect = response.wikiPage.metaThumbnailImageAutoSelect || false;
          this.savedNoindex = this.noindex;
          if (response.wikiPage.category) {
            this.selectedCategory = response.wikiPage.category;
          }
          if (response.wikiPage.editPermission) {
            this.selectedEditPermission = response.wikiPage.editPermission;
            this.editable = this.$ability.can('ACTION_wiki_page_update', this.selectedEditPermission);
            this.disabled = !this.editable;
          }
          if (response.wikiPage.redirectPageTitle) {
            this.redirectPageTitle = response.wikiPage.redirectPageTitle;
          }
          if (response.wikiPage.publishedAt) {
            const publishedAt = response.wikiPage.publishedAt;
            this.publishedDate = format(new Date(publishedAt), 'yyyy-MM-dd');
            this.publishedTime = format(new Date(publishedAt), 'HH:mm');
          }
          if (response.wikiPage.path) {
            this.path = response.wikiPage.path;
            if (this.isTopPage) {
              const parser = new MarkdownParser('dummy', this.pageText, '', undefined, true, 0, false);
              this.markdownTitle = parser.getTitle();
            }
          }
        }

        if (this.pageId) {
          this.apiClient
            .GET('/admin/wiki/{wikiName}/page/{pageId}/draft', {
              params: {
                path: {
                  wikiName: this.wikiName,
                  pageId: this.pageId,
                },
              },
            })
            .then((r) => {
              if (r.error) {
                throw r.error;
              }

              return r.data;
            })
            .then((response) => {
              this.draftId = response.id || '';
              this.draftTitle = response.title || '';
              this.draftText = response.body || '';
              this.title = this.draftTitle || this.pageTitle || '';
              this.text = this.draftText || this.pageText || '';
              this.keywords = response.keywords || this.keywords;
              this.description = response.description || this.description;
              this.metaOgpImageURL = response.metaOgpImageURL || '';
              this.metaThumbnailImageURL = response.metaThumbnailImageURL || '';
              this.metaThumbnailImageAutoSelect = response.metaThumbnailImageAutoSelect || false;
              this.selectedCategory = response.category || this.selectedCategory;
              this.draftAuthorName = response.lastAuthor ? response.lastAuthor.nickname || '' : '';
              this.draftUpdatedAt = response.updatedAt ? new Date(response.updatedAt) : undefined;
              this.noindex = !!response.noindex;
              this.savedNoindex = this.noindex;
            })
            .catch(() => {
              this.title = this.pageTitle;
              this.text = this.pageText;
            })
            .finally(() => {
              if (this.text.length >= AUTO_LIGHTMODE_COUNT) {
                this.kamigameMarkdownEditor.setLightMode(true);
                this.setFlashMessage(
                  'info',
                  '文字数が多いため、自動的に軽量モードに設定されました。軽量モードの場合、動作が軽くなる代わりにプレビューの反映が遅くなります。'
                );
              }
              this.setPageDocumentTitle();
            });
        }

        this.kamigameUrl = this.createKamigameUrl();
      });
  }
  resetPageStates() {
    this.pageId = '';
    this.title = '';
    this.text = '';
    this.disabled = false;
    this.selectedCategory = {};
    this.selectedEditPermission = 'OBJ_default';
    this.editable = true;
    this.publishedDate = '';
    this.publishedTime = '';
    this.keywords = '';
    this.metaOgpImageURL = '';
    this.metaThumbnailImageURL = '';
    this.metaThumbnailImageAutoSelect = false;
    this.noindex = false;
    this.description = '';
    this.draftId = '';
    this.draftTitle = '';
    this.draftText = '';
    this.draftAuthorName = '';
    this.pageTitle = '';
    this.pageText = '';
  }

  async create(params: components['schemas']['v1CreateWikiPageRequestBody']) {
    const response = await this.apiClient
      .POST('/admin/wiki/{wikiId}/page', {
        params: {
          path: {
            wikiId: this.wikiName,
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
    if (response.wikiPage) {
      this.pageId = response.wikiPage.id || '';
    }

    if (response.historyId) {
      await this.apiClient
        .PUT('/admin/wiki/{wikiName}/history/{historyId}/diff-lines', {
          params: {
            path: {
              wikiName: this.wikiName,
              historyId: response.historyId,
            },
          },
          body: {
            diffLines: `${calcDiffLines(response.body || '', '')}`,
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }

          return r.data;
        });
    }
  }

  async update(params: components['schemas']['v1CreateWikiPageRequestBody']) {
    const response = await this.apiClient
      .PUT('/admin/wiki/{wikiId}/page/{pageId}', {
        params: {
          path: {
            wikiId: this.wikiName,
            pageId: this.pageId,
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });

    if (response.historyId) {
      const history = await this.apiClient
        .GET('/admin/wiki/{wikiName}/history/{historyId}', {
          params: {
            path: {
              wikiName: this.wikiName,
              historyId: response.historyId,
            },
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }

          return r.data;
        });
      if (history.parentId) {
        const previous = await this.apiClient
          .GET('/admin/wiki/{wikiName}/history/{historyId}', {
            params: {
              path: {
                wikiName: this.wikiName,
                historyId: history.parentId,
              },
            },
          })
          .then((r) => {
            if (r.error) {
              throw r.error;
            }

            return r.data;
          });
        const aBody = history.body || '';
        const bBody = previous.body || '';
        const diffLines = `${calcDiffLines(aBody, bBody)}`;
        await this.apiClient
          .PUT('/admin/wiki/{wikiName}/history/{historyId}/diff-lines', {
            params: {
              path: {
                wikiName: this.wikiName,
                historyId: response.historyId,
              },
            },
            body: { diffLines },
          })
          .then((r) => {
            if (r.error) {
              throw r.error;
            }

            return r.data;
          });
      }
    }
  }

  async lint() {
    this.isLintRunning = true;
    this.clearTextlint();
    await this.executeTextlint();
    this.isLintRunning = false;
  }

  getBrokenFragments(text: string) {
    const fragments: { link: string; fragment: string }[] = [];

    const fragmentPattern = /\[.+?\]\(#(.+?)\)/g; // e.g. [hoge](#h2--h3--h4)
    let match;
    while ((match = fragmentPattern.exec(text)) !== null) {
      if (match.length !== 2) {
        continue;
      }

      fragments.push({ link: match[0], fragment: match[1] });
    }

    const broken = fragments
      .filter((f) => {
        return !document.querySelector(`.kg-preview #${convertToCssUnicodeCodePoint(decodeURIComponent(f.fragment))}`);
      })
      .map((f) => f.link);

    return broken;
  }

  switchAutoDraftSave() {
    if (this.draftId === '') {
      this.saveAsDraft();
    }

    this.autoDraftSave = !this.autoDraftSave;
    if (!this.autoDraftSave) {
      this.disableAutoSave();
    }
  }

  async save() {
    this.disabled = true;

    const normalizedText = this.text.normalize('NFC');
    if (this.text !== normalizedText) {
      this.text = normalizedText;
    }

    const params: components['schemas']['v1CreateWikiPageRequestBody'] = {
      title: this.title,
      body: this.text,
      editPermission: this.selectedEditPermission,
      publishedAt: this.publishedAt.toISOString(),
      keywords: this.keywords,
      description: this.description,
      metaOgpImageURL: this.metaOgpImageURL,
      metaThumbnailImageURL: this.metaThumbnailImageURL,
      metaThumbnailImageAutoSelect: this.metaThumbnailImageAutoSelect,
      noindex: this.noindex,
      titlePrefix: this.setsTitlePrefix ? this.titlePrefix : '',
      spreadsheetURL: extractDependingSpreadsheetsFromMarkdownText(this.text),
    };
    if (this.selectedCategory.id) {
      params.categoryId = this.selectedCategory.id;
    }

    const isUpdate = !!this.pageId;
    await (isUpdate ? this.update(params) : this.create(params))
      .then(async () => {
        this.pageTitle = this.title;
        this.pageText = this.text;
        this.savedNoindex = this.noindex;
        await this.$nextTick();
        this.router.push(
          {
            name: 'wiki_page_list',
          },
          () => {
            if (isUpdate) {
              this.setFlashMessage('success', 'ページの変更を保存しました。', this.wikiUrl, '内容を確認する', true);
            } else {
              this.setFlashMessage('success', 'ページを作成しました。', this.wikiUrl, '内容を確認する', true);
            }
          }
        );

        if (!!this.draftId && !!this.page) {
          await this.apiClient
            .DELETE('/admin/wiki/{wikiName}/page/{pageId}/draft', {
              params: {
                path: {
                  wikiName: this.wikiName,
                  pageId: this.page,
                },
              },
            })
            .then((r) => {
              if (r.error) {
                throw r.error;
              }

              return r.data;
            })
            .catch(() => {
              this.setFlashMessage('danger', '下書きの削除に失敗しました');
            });
        }
      })
      .catch((e: Error) => {
        if (isUpdate) {
          const domains = extractNotAllowedDomainsFromErrorMessage(e.message);
          if (domains.length !== 0) {
            this.setFlashMessage('danger', 'これらのドメインは記事中に使用できません: ' + domains.join(', '));
            return;
          }
          this.setFlashMessage('danger', '更新に失敗しました');
        } else {
          const domains = extractNotAllowedDomainsFromErrorMessage(e.message);
          if (domains.length !== 0) {
            this.setFlashMessage('danger', 'これらのドメインは記事中に使用できません: ' + domains.join(', '));
            return;
          }
          this.setFlashMessage('danger', '作成に失敗しました');
        }
      });

    this.disabled = false;
  }

  validateTitle() {
    this.titleValidationResult = validateWikiPageTitle(this.title);
    this.titleFeedbackMessages = this.titleValidationResult.warnings.concat(this.titleValidationResult.errors);
  }

  get titleValidate() {
    return this.titleValidationResult.warnings.length === 0 && this.titleValidationResult.errors.length === 0;
  }

  get descriptionValidate() {
    return this.description.length >= 100 && this.description.length <= 130;
  }

  get publishedAt() {
    if (this.publishedDate && this.publishedTime) {
      return new Date(`${this.publishedDate}T${this.publishedTime}`);
    }

    if (this.publishedDate) {
      return new Date(`${this.publishedDate}T00:00`);
    }

    return new Date();
  }

  get formattedPublishedAt() {
    return format(this.publishedAt, 'yyyy年MM月dd日hh時mm分');
  }

  get kamigameMarkdownEditor(): any {
    return this.$refs.kamigameMarkdownEditor;
  }

  clearCategory() {
    this.selectedCategory = {};
  }

  async clearRedirect() {
    await this.apiClient
      .PUT('/admin/wiki/{wikiName}/page/{pageId}/redirect', {
        params: {
          path: {
            wikiName: this.wikiName,
            pageId: this.pageId,
          },
        },
        body: {},
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
    this.redirectPageTitle = {};
  }

  onCategorySaved(category: components['schemas']['v1WikiPageCategory']) {
    this.selectedCategory = category;
  }

  async saveAsTemplate() {
    this.disabled = true;

    const params: components['schemas']['v1CreateWikiPageTemplateRequestBody'] = {
      title: this.title,
      body: this.text,
      keywords: this.keywords,
      description: this.description,
    };
    if (this.selectedCategory.id) {
      params.categoryId = this.selectedCategory.id;
    }

    this.apiClient
      .POST('/admin/wiki/{wikiName}/template', {
        params: {
          path: {
            wikiName: this.wikiName,
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(async () => {
        this.pageTitle = this.title;
        this.pageText = this.text;
        await this.$nextTick();
        this.router.push(
          {
            name: 'wiki_page_template_list',
          },
          () => {
            this.setFlashMessage('success', 'テンプレートを保存しました。');
          }
        );
      })
      .catch((e: Error) => {
        const domains = extractNotAllowedDomainsFromErrorMessage(e.message);
        if (domains.length !== 0) {
          this.setFlashMessage('danger', 'これらのドメインは記事中に使用できません: ' + domains.join(', '));
          return;
        }
        this.setFlashMessage('danger', 'テンプレートの保存に失敗しました。');
      })
      .finally(() => {
        this.disabled = false;
      });
  }

  async executeTextlint() {
    const result = await lint(this.kamigameMarkdownEditor.editor.getMarkdown());
    if (!result.isPassed) {
      this.kamigameMarkdownEditor.setLintMessages(result.messages);
    }

    return result;
  }

  clearTextlint() {
    this.kamigameMarkdownEditor.clearLintMessages();
  }

  async saveAsDraft() {
    if (!this.canSaveTemplateAndDraft) {
      return;
    }
    this.disabled = true;

    const params: components['schemas']['v1EditWikiPageDraftRequestBody'] = {
      title: this.title,
      body: this.text,
      keywords: this.keywords,
      description: this.description,
      spreadsheetURL: extractDependingSpreadsheetsFromMarkdownText(this.text),
      noindex: this.noindex,
      metaOgpImageURL: this.metaOgpImageURL,
      metaThumbnailImageURL: this.metaThumbnailImageURL,
      metaThumbnailImageAutoSelect: this.metaThumbnailImageAutoSelect,
      titlePrefix: this.setsTitlePrefix ? this.titlePrefix : '',
    };
    if (this.selectedCategory.id) {
      params.categoryId = this.selectedCategory.id;
    }

    this.apiClient
      .POST('/admin/wiki/{wikiName}/page/{pageId}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            pageId: this.page || '0',
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(async (response) => {
        if (!this.page && response.wikiPage) {
          this.pageId = response.wikiPage.id || '';
          this.draftTitle = this.title;
          this.draftText = this.text;
          await this.$nextTick();
          this.router.push(
            {
              name: 'wiki_page_edit',
              params: {
                name: this.wikiName,
                paths: this.pageId,
              },
            },
            () => {
              this.draftSaveSuccessfullProcess(response);
            }
          );
        } else {
          this.draftSaveSuccessfullProcess(response);
        }
      })
      .catch((e) => {
        const domains = extractNotAllowedDomainsFromErrorMessage(e.message);
        if (domains.length !== 0) {
          this.setFlashMessage('danger', 'これらのドメインは記事中に使用できません: ' + domains.join(', '));
          return;
        }
        this.setFlashMessage('danger', '下書きの保存に失敗しました。');
      })
      .finally(() => {
        this.disabled = false;
      });
  }

  draftSaveSuccessfullProcess(responseDraft: components['schemas']['v1WikiPageDraft']) {
    this.draftId = responseDraft.id || '';
    this.draftTitle = responseDraft.title || '';
    this.draftText = responseDraft.body || '';
    this.draftAuthorName = responseDraft.lastAuthor ? responseDraft.lastAuthor.nickname || '' : '';
    this.draftUpdatedAt = responseDraft.updatedAt ? new Date(responseDraft.updatedAt) : undefined;
    this.$bvToast.toast(this.dateFormat(this.draftUpdatedAt as Date, 'HH:mm:ss に下書きを保存しました'), {
      title: '下書きを保存しました',
      autoHideDelay: 5000,
      variant: 'success',
    });
    this.disabled = false;
  }

  deleteDraft() {
    this.apiClient
      .DELETE('/admin/wiki/{wikiName}/page/{pageId}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            pageId: this.page,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(() => {
        this.resetDraft();
        this.title = this.pageTitle;
        this.text = this.pageText;
        this.setFlashMessage('success', '下書きを削除しました');

        if (this.publishedDate === '' && this.publishedTime === '') {
          this.apiClient
            .DELETE('/admin/wiki/{wikiId}/page/{pageId}', {
              params: {
                path: {
                  wikiId: this.wikiName,
                  pageId: this.page,
                },
              },
            })
            .then((r) => {
              if (r.error) {
                throw r.error;
              }

              return r.data;
            })
            .then(async () => {
              await this.$nextTick();
              this.router.push(
                {
                  name: 'wiki_page_list',
                  params: {
                    name: this.wikiName,
                  },
                },
                () => {
                  this.setFlashMessage('success', '作成中の記事を破棄しました。');
                }
              );
            });
        }
      });
  }

  resetDraft() {
    this.draftId = '';
    this.draftTitle == '';
    this.draftText == '';
    this.draftAuthorName = '';
    this.draftUpdatedAt = undefined;
  }

  showCheckDeleteDraftModal() {
    const modal = this.$refs.checkDeleteDraftModal as BModal;
    modal.show();
  }

  showCheckPublishModal() {
    if (this.title === '' || this.description === '') {
      this.setFlashMessage('danger', '記事タイトルと description を入力してください');
      return;
    }

    const modal = this.$refs.checkPublishModal as BModal;
    modal.show();
  }

  get isEditorClean(): boolean {
    return (
      (this.title === this.pageTitle && this.text === this.pageText) ||
      (this.title === this.draftTitle && this.text === this.draftText)
    );
  }

  disableAutoSave() {
    window.clearTimeout(this.autoSaveTimeoutID);
    this.autoSaveTimeoutID = 0;
    this.autoDraftSave = false;
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
    this.disableAutoSave();
  }

  createKamigameUrl() {
    const basePath = `${this.isRootPath ? KAMIGAME_URL_BASE : WIKI_URL_BASE}/${encodeURIComponent(this.wikiName)}`;
    const pagePath = this.canonicalPath ? `/${this.canonicalPath}` : `/page/${this.page}`;

    return `${basePath}${pagePath}.html`;
  }

  async openRedirectMenu() {
    if (!this.wikiPageTitles || this.wikiPageTitles.length === 0) {
      const response = await this.apiClient
        .GET('/admin/wiki/{wikiName}/page/titles', {
          params: {
            path: {
              wikiName: this.wikiName,
            },
            query: {
              excludeRedirected: true,
            },
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }

          return r.data;
        });
      if (!response.wikiPageTitles) {
        return;
      }
      this.wikiPageTitles = response.wikiPageTitles.filter((p) => p.id !== this.pageId);
    }

    const modal = this.$refs.redirectMenu as BModal;
    modal.show();
  }

  onWikiPageTitleSet(selectedWikiPageTitle: components['schemas']['v1WikiPageTitle']) {
    this.selectedRedirectPageTitle = selectedWikiPageTitle;
  }

  async setWikiPageRedirect() {
    const params: components['schemas']['v1UpdateWikiPageRedirectRequestBody'] = {
      redirectPageId: this.selectedRedirectPageTitle.id,
    };

    await this.apiClient
      .PUT('/admin/wiki/{wikiName}/page/{pageId}/redirect', {
        params: {
          path: {
            wikiName: this.wikiName,
            pageId: this.pageId,
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
    this.redirectPageTitle = this.selectedRedirectPageTitle;
  }

  async cancelWikiPageRedirect() {
    this.selectedRedirectPageTitle = {};
  }

  async confirmNoindex(changed: boolean) {
    if (
      changed &&
      !window.confirm(
        'noindex 設定をおこなうと検索エンジンからのインデックスが拒否されてしまいます。続行してもよろしいですか？'
      )
    ) {
      await this.$nextTick();
      this.noindex = false;
    } else if (
      !changed &&
      !window.confirm(
        'noindex 設定を解除すると検索エンジンからのインデックスが許可されてしまいます。続行してもよろしいですか？'
      )
    ) {
      await this.$nextTick();
      this.noindex = true;
    }
  }

  showMarkdownTitleChangeModal() {
    const modal = this.$refs.checkMarkdownTitleChangeModal as BModal;
    modal.show();
  }

  showBrokenFragmentsModal() {
    const modal = this.$refs.checkBrokenFragmentsModal as BModal;
    modal.show();
  }

  showModalBeforeSave() {
    if (this.isTopPage) {
      const parser = new MarkdownParser('dummy', this.text, '', undefined, true, 0, false);
      if (this.markdownTitle !== parser.getTitle()) {
        this.showMarkdownTitleChangeModal();
        return;
      }
    }

    const brokenFragments = this.getBrokenFragments(this.text);
    if (brokenFragments.length > 0) {
      this.brokenFragmentLinks = brokenFragments;
      this.showBrokenFragmentsModal();
      return;
    }

    this.showCheckPublishModal();
  }

  showRestoreModal() {
    const modal = this.$refs.restorePage as BModal;
    modal.show();
  }

  toggleMetaThumbnailImageAutoSelect(value: boolean) {
    if (value) {
      this.metaThumbnailImageURL = '';
    }
  }

  setPageDocumentTitle() {
    if (!this.pageTitle && !this.draftTitle) {
      return;
    }

    if (this.draftTitle) {
      this.setDocumentTitle(`${this.draftTitle} | ${this.routeDocumentTitle}`);
      return;
    }

    this.setDocumentTitle(`${this.pageTitle} | ${this.routeDocumentTitle}`);
  }

  restorePage() {
    this.apiClient
      .POST('/admin/wiki/{wikiId}/page/{pageId}/restore', {
        params: {
          path: {
            wikiId: this.wikiName,
            pageId: this.page,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(() => {
        this.setFlashMessage('success', 'ページを復元しました。');
        this.isDeleted = false;
      })
      .catch(() => {
        this.setFlashMessage('danger', 'ページの復元に失敗しました。');
      });
  }

  get wikiUrl() {
    return `${WIKI_URL_BASE}/${encodeURIComponent(this.wikiName)}/page/${this.pageId}.html`;
  }

  get wikiName() {
    return this.route.params.name;
  }

  get page() {
    return this.route.params.paths;
  }

  get usingTemplateId() {
    if (!this.route.query.templateId) {
      return null;
    }
    if (this.route.query.templateId.length === 0) {
      return null;
    }
    if (Array.isArray(this.route.query.templateId)) {
      return null;
    }

    return this.route.query.templateId;
  }

  get canSaveTemplateAndDraft() {
    return !this.titleValidationResult.errors.includes(braceError) && this.text.trim() !== '';
  }

  get canSaveContent() {
    return (
      !this.disabled &&
      this.titleValidationResult.errors.length === 0 &&
      this.description.trim() !== '' &&
      this.text.trim() !== ''
    );
  }

  get isDraftContents() {
    return this.title === this.draftTitle && this.text === this.draftText;
  }

  get isPublished() {
    return this.publishedDate !== '';
  }

  get isScheduledPage() {
    const publishedTime = this.publishedAt.getTime();
    const currentTime = new Date().getTime();
    return publishedTime > currentTime;
  }

  get isRedirected() {
    return !!this.redirectPageTitle.id;
  }

  get isTopPage() {
    return this.path === 'index';
  }

  get menuTitleForPublishing() {
    if (this.isDeleted) {
      return '公開予定にする';
    }

    if (this.isScheduledPage) {
      return '予約公開';
    }

    return '公開する';
  }

  get annotationTitleForPublishing() {
    if (this.isDeleted) {
      return '公開予定にする';
    }

    if (this.isScheduledPage) {
      return `${this.formattedPublishedAt}に予約公開する`;
    }

    return '公開する';
  }
}
