export const loadImage = (ocrFile: File): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const img = new Image();
      img.onload = () => resolve(img);
      img.onerror = () => reject(new Error('画像の読み込みに失敗しました。'));
      if (e.target && e.target.result) {
        img.src = e.target.result as string;
      } else {
        reject(new Error('ファイルの読み込みに失敗しました。'));
      }
    };
    reader.onerror = () => reject(new Error('ファイルの読み込みに失敗しました。'));
    reader.readAsDataURL(ocrFile);
  });
};

export const loadImagesFromURLs = (urls: string[]) => {
  return urls.map((url) => {
    const image = new Image();
    image.src = url;
    image.crossOrigin = 'Anonymous';
    return image;
  });
};
