var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      !_vm.editable
        ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _vm._v(
              "この記事の編集権限がないため、閲覧専用モードで表示しています。"
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.isRedirected
        ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _vm._v(
              "この記事はリダイレクトされるため編集できません。編集するにはリダイレクト設定を解除してください"
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "Can",
        { attrs: { I: "", do: "ACTION_wiki_config_noindex" } },
        [
          _vm.savedNoindex
            ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
                _vm._v(
                  "この記事には noindex が設定されています。検索エンジンによるインデックスを有効にしたい場合は noindex\n      設定を解除してください"
                ),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "animated fadeIn wiki-page-edit" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-modal",
                    {
                      attrs: { id: "deleteDraftModal", lazy: "" },
                      on: { ok: _vm.deleteDraft },
                    },
                    [
                      _vm.publishedDate && _vm.publishedTime
                        ? [
                            _c("p", [
                              _vm._v(
                                "下書きを削除すると全てのユーザがこの下書きを利用できなくなります"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v("下書きを削除してもよろしいですか？"),
                            ]),
                          ]
                        : [
                            _c("p", [_vm._v("作成中の記事を破棄します")]),
                            _vm._v(" "),
                            _c("p", [_vm._v("破棄してもよろしいですか？")]),
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "page-config",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _vm.isDeleted
                            ? _c(
                                "b-badge",
                                { attrs: { pill: "", variant: "danger" } },
                                [_vm._v("この記事は削除されています")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          id: "inputTitle",
                                          disabled:
                                            !_vm.editable || _vm.isRedirected,
                                          state: _vm.titleValidate,
                                          type: "text",
                                          placeholder: "タイトルを入力",
                                        },
                                        model: {
                                          value: _vm.title,
                                          callback: function ($$v) {
                                            _vm.title = $$v
                                          },
                                          expression: "title",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              directives: [
                                                {
                                                  name: "b-toggle",
                                                  rawName:
                                                    "v-b-toggle.metaMenu",
                                                  modifiers: { metaMenu: true },
                                                },
                                              ],
                                              staticClass: "config-toggle",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-cogs",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.titleFeedbackMessages.length > 0
                                        ? _c(
                                            "b-form-invalid-feedback",
                                            _vm._l(
                                              _vm.titleFeedbackMessages,
                                              function (message, idx) {
                                                return _c("div", { key: idx }, [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(message) +
                                                      "\n                    "
                                                  ),
                                                ])
                                              }
                                            ),
                                            0
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-row",
                            { staticClass: "justify-content-between" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c(
                                    "b-form-text",
                                    { staticClass: "form-text-length" },
                                    [_vm._v(_vm._s(_vm.title.length) + " 文字")]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-right",
                                  attrs: { cols: "9" },
                                },
                                [
                                  _vm.page
                                    ? _c(
                                        "b-link",
                                        {
                                          attrs: {
                                            target: "_blank",
                                            href: _vm.kamigameUrl,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-external-link",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v(
                                            "記事を開く\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.pageId
                                    ? _c(
                                        "b-link",
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            target: "_blank",
                                            to: {
                                              name: "wiki_page_history_list",
                                              params: { pageId: _vm.pageId },
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "icon-clock",
                                          }),
                                          _vm._v(
                                            " 編集履歴を表示\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-collapse",
                            { attrs: { id: "metaMenu", visible: !_vm.pageId } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "mr-sm-2",
                                              attrs: { for: "categoryConfig" },
                                            },
                                            [_vm._v("カテゴリ")]
                                          ),
                                          _vm._v(" "),
                                          _vm.selectedCategory.displayName
                                            ? _c(
                                                "b-button-group",
                                                [
                                                  _c(
                                                    "b-btn",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "b-modal",
                                                          rawName:
                                                            "v-b-modal.categoryMenu",
                                                          modifiers: {
                                                            categoryMenu: true,
                                                          },
                                                        },
                                                      ],
                                                      attrs: {
                                                        id: "buttonChangeCategory",
                                                        disabled:
                                                          !_vm.editable ||
                                                          _vm.isRedirected,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-folder",
                                                        attrs: {
                                                          "aria-hidden": "true",
                                                        },
                                                      }),
                                                      _vm._v(
                                                        "\n                         \n                        " +
                                                          _vm._s(
                                                            _vm.selectedCategory
                                                              .displayName
                                                          ) +
                                                          "\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-btn",
                                                    {
                                                      attrs: {
                                                        id: "buttonClearCategory",
                                                        disabled:
                                                          !_vm.editable ||
                                                          _vm.isRedirected,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.clearCategory,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fa fa-close",
                                                        attrs: {
                                                          "aria-hidden": "true",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "b-btn",
                                                {
                                                  directives: [
                                                    {
                                                      name: "b-modal",
                                                      rawName:
                                                        "v-b-modal.categoryMenu",
                                                      modifiers: {
                                                        categoryMenu: true,
                                                      },
                                                    },
                                                  ],
                                                  attrs: {
                                                    id: "buttonSetCategory",
                                                    variant:
                                                      "outline-secondary",
                                                    disabled:
                                                      !_vm.editable ||
                                                      _vm.isRedirected,
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fa fa-plus",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  }),
                                                  _vm._v(
                                                    " カテゴリを設定する\n                    "
                                                  ),
                                                ]
                                              ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "kamigame-category-tree-select-modal",
                                        {
                                          attrs: {
                                            modalId: "categoryMenu",
                                            modalTitle: "カテゴリを設定",
                                          },
                                          on: {
                                            selectionSaved: _vm.onCategorySaved,
                                          },
                                        }
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "mr-sm-2",
                                              attrs: { for: "categoryConfig" },
                                            },
                                            [_vm._v("編集権限")]
                                          ),
                                          _vm._v(" "),
                                          _c("b-form-select", {
                                            attrs: {
                                              id: "selectPermission",
                                              disabled:
                                                !_vm.editable ||
                                                _vm.isRedirected,
                                              options: _vm.editPermissions,
                                            },
                                            model: {
                                              value: _vm.selectedEditPermission,
                                              callback: function ($$v) {
                                                _vm.selectedEditPermission = $$v
                                              },
                                              expression:
                                                "selectedEditPermission",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !_vm.isDeleted
                                        ? _c(
                                            "b-input-group",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "label",
                                                {
                                                  staticClass: "mr-sm-2",
                                                  attrs: { for: "publishedAt" },
                                                },
                                                [_vm._v("公開日時")]
                                              ),
                                              _vm._v(" "),
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "inputPublishedAtDate",
                                                  disabled:
                                                    !_vm.editable ||
                                                    _vm.isRedirected,
                                                  type: "date",
                                                },
                                                model: {
                                                  value: _vm.publishedDate,
                                                  callback: function ($$v) {
                                                    _vm.publishedDate = $$v
                                                  },
                                                  expression: "publishedDate",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("b-form-input", {
                                                attrs: {
                                                  id: "inputPublishedAtTime",
                                                  disabled:
                                                    !_vm.editable ||
                                                    _vm.isRedirected,
                                                  type: "time",
                                                },
                                                model: {
                                                  value: _vm.publishedTime,
                                                  callback: function ($$v) {
                                                    _vm.publishedTime = $$v
                                                  },
                                                  expression: "publishedTime",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "mr-sm-2",
                                              attrs: { for: "keyword" },
                                            },
                                            [_vm._v("keywords")]
                                          ),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "inputKeyword",
                                              disabled: _vm.isRedirected,
                                            },
                                            model: {
                                              value: _vm.keywords,
                                              callback: function ($$v) {
                                                _vm.keywords = $$v
                                              },
                                              expression: "keywords",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "b-form-text",
                                            { staticClass: "form-text-length" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.keywords.length) +
                                                  " 文字"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "mr-sm-2",
                                              attrs: { for: "description" },
                                            },
                                            [_vm._v("description")]
                                          ),
                                          _vm._v(" "),
                                          _c("b-form-textarea", {
                                            attrs: {
                                              id: "inputDescription",
                                              disabled: _vm.isRedirected,
                                              state: _vm.descriptionValidate,
                                              rows: "2",
                                            },
                                            model: {
                                              value: _vm.description,
                                              callback: function ($$v) {
                                                _vm.description = $$v
                                              },
                                              expression: "description",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "b-form-text",
                                            { staticClass: "form-text-length" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.description.length) +
                                                  " 文字"
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.description.length < 100 ||
                                          _vm.description.length > 130
                                            ? _c(
                                                "b-form-invalid-feedback",
                                                {
                                                  attrs: {
                                                    state:
                                                      _vm.descriptionValidate,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "descriptionの推奨文字数は100～130文字です"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _vm.description.length === 0
                                            ? _c(
                                                "b-form-invalid-feedback",
                                                {
                                                  attrs: {
                                                    state:
                                                      _vm.descriptionValidate,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "descriptionは必須です"
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "Can",
                                        {
                                          attrs: {
                                            I: "",
                                            do: "ACTION_wiki_config_noindex",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  attrs: {
                                                    disabled: !_vm.editable,
                                                  },
                                                  on: {
                                                    change: _vm.confirmNoindex,
                                                  },
                                                  model: {
                                                    value: _vm.noindex,
                                                    callback: function ($$v) {
                                                      _vm.noindex = $$v
                                                    },
                                                    expression: "noindex",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        noindex を有効にする (検索エンジンによるインデックスを拒否する) (内部スタッフ向け設定)\n                      "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "Can",
                                        {
                                          attrs: {
                                            I: "",
                                            do: "ACTION_wiki_page_title_set",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-input-group",
                                            { staticClass: "mt-3" },
                                            [
                                              _c(
                                                "b-form-checkbox",
                                                {
                                                  model: {
                                                    value: _vm.setsTitlePrefix,
                                                    callback: function ($$v) {
                                                      _vm.setsTitlePrefix = $$v
                                                    },
                                                    expression:
                                                      "setsTitlePrefix",
                                                  },
                                                },
                                                [
                                                  _c("div", [
                                                    _vm._v(
                                                      "タイトルのプレフィックスを個別に設定する"
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-warning",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "※ Wiki の設定は無効になります"
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _vm.setsTitlePrefix
                                                ? _c("b-form-input", {
                                                    staticClass: "ml-2",
                                                    attrs: { type: "text" },
                                                    model: {
                                                      value: _vm.titlePrefix,
                                                      callback: function ($$v) {
                                                        _vm.titlePrefix = $$v
                                                      },
                                                      expression: "titlePrefix",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "mr-sm-2",
                                              attrs: { for: "categoryConfig" },
                                            },
                                            [_vm._v("リダイレクト先:")]
                                          ),
                                          _vm._v(" "),
                                          _vm.redirectPageTitle.title
                                            ? _c(
                                                "p",
                                                [
                                                  _vm._v(
                                                    "\n                      「" +
                                                      _vm._s(
                                                        _vm.redirectPageTitle
                                                          .title
                                                      ) +
                                                      "」\n                      "
                                                  ),
                                                  _c(
                                                    "b-link",
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.editable,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.clearRedirect,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        リダイレクト設定を解除する\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c(
                                                "p",
                                                [
                                                  _c(
                                                    "b-link",
                                                    {
                                                      attrs: {
                                                        disabled: !_vm.editable,
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.openRedirectMenu,
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                        リダイレクト先を設定する\n                      "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-modal",
                                        {
                                          ref: "redirectMenu",
                                          attrs: {
                                            "modal-class": [
                                              "redirect-setting-modal",
                                            ],
                                            "hide-header": "",
                                            centered: "",
                                            "no-fade": "",
                                            lazy: "",
                                          },
                                          on: {
                                            ok: _vm.setWikiPageRedirect,
                                            cancel: _vm.cancelWikiPageRedirect,
                                          },
                                        },
                                        [
                                          _c(
                                            "kamigame-wiki-page-title-search-modal",
                                            {
                                              attrs: {
                                                data: _vm.wikiPageTitles,
                                              },
                                              on: {
                                                wikiPageTitleSelected:
                                                  _vm.onWikiPageTitleSet,
                                              },
                                            }
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "6" } },
                                    [
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "mr-sm-2",
                                              attrs: { for: "metaOgpImageURL" },
                                            },
                                            [_vm._v("OGP 画像URL")]
                                          ),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "metaOgpImageURL",
                                              disabled: _vm.isRedirected,
                                            },
                                            model: {
                                              value: _vm.metaOgpImageURL,
                                              callback: function ($$v) {
                                                _vm.metaOgpImageURL = $$v
                                              },
                                              expression: "metaOgpImageURL",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("b-img", {
                                        staticClass: "img-sample",
                                        attrs: {
                                          blank: _vm.metaOgpImageURL === "",
                                          src: _vm.metaOgpImageURL,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "b-input-group",
                                        { staticClass: "mt-3" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "mr-sm-2",
                                              attrs: {
                                                for: "metaThumbnailImageURL",
                                              },
                                            },
                                            [_vm._v("meta サムネイルURL")]
                                          ),
                                          _vm._v(" "),
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "metaThumbnailImageURL",
                                              disabled:
                                                _vm.isRedirected ||
                                                _vm.metaThumbnailImageAutoSelect,
                                            },
                                            model: {
                                              value: _vm.metaThumbnailImageURL,
                                              callback: function ($$v) {
                                                _vm.metaThumbnailImageURL = $$v
                                              },
                                              expression:
                                                "metaThumbnailImageURL",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-checkbox",
                                        {
                                          on: {
                                            change:
                                              _vm.toggleMetaThumbnailImageAutoSelect,
                                          },
                                          model: {
                                            value:
                                              _vm.metaThumbnailImageAutoSelect,
                                            callback: function ($$v) {
                                              _vm.metaThumbnailImageAutoSelect =
                                                $$v
                                            },
                                            expression:
                                              "metaThumbnailImageAutoSelect",
                                          },
                                        },
                                        [
                                          _c("div", [
                                            _vm._v(
                                              "記事の最初に使用されている画像をmetaサムネイルURLに設定する"
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("b-img", {
                                        staticClass: "img-sample",
                                        attrs: {
                                          blank:
                                            _vm.metaThumbnailImageURL === "",
                                          src: _vm.metaThumbnailImageURL,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("b-input-group", {
                                        staticClass: "mt-3",
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("kamigame-markdown-editor", {
                        ref: "kamigameMarkdownEditor",
                        attrs: {
                          disabled: !_vm.editable || _vm.isRedirected,
                          editsWikiPage: true,
                          description: _vm.description,
                          mode: "page",
                        },
                        on: { save: _vm.saveAsDraft },
                        model: {
                          value: _vm.text,
                          callback: function ($$v) {
                            _vm.text = $$v
                          },
                          expression: "text",
                        },
                      }),
                      _vm._v(" "),
                      _vm.editable && !_vm.isRedirected
                        ? _c(
                            "div",
                            { attrs: { slot: "footer" }, slot: "footer" },
                            [
                              _c(
                                "div",
                                { staticClass: "card-top" },
                                [
                                  _c(
                                    "b-dropdown",
                                    {
                                      staticClass: "save_button",
                                      attrs: {
                                        disabled: !_vm.canSaveTemplateAndDraft,
                                        variant: "primary",
                                        split: "",
                                        text: "下書き保存",
                                      },
                                      on: { click: _vm.saveAsDraft },
                                    },
                                    [
                                      _c(
                                        "b-dropdown-item",
                                        {
                                          staticClass: "item-save",
                                          attrs: {
                                            disabled: !_vm.canSaveContent,
                                          },
                                          on: {
                                            click: function ($event) {
                                              _vm.draftId
                                                ? _vm.showCheckDeleteDraftModal()
                                                : _vm.showModalBeforeSave()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.menuTitleForPublishing)
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: _vm.autoDraftSave
                                          ? "success"
                                          : "",
                                        disabled: !_vm.canSaveTemplateAndDraft,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.switchAutoDraftSave()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.autoDraftSave ? "✔ " : ""
                                          ) +
                                          "自動下書き保存\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { disabled: _vm.isLintRunning },
                                      on: { click: _vm.lint },
                                    },
                                    [_vm._v("校閲をする")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "Can",
                                    {
                                      attrs: {
                                        I: "",
                                        do: "ACTION_wiki_page_restore",
                                      },
                                    },
                                    [
                                      _vm.isDeleted
                                        ? _c(
                                            "b-button",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.showRestoreModal()
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-history",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                              _vm._v(" 復元する"),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "Can",
                                    {
                                      attrs: {
                                        I: "",
                                        do: "ACTION_chat_gpt_use",
                                      },
                                    },
                                    [
                                      _vm.isGameVillage
                                        ? _c("button-translate-article", {
                                            attrs: {
                                              pageId: _vm.pageId,
                                              isReloadedOnSuccess: true,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.isLintRunning
                                    ? _c("b-spinner", {
                                        attrs: {
                                          variant: "primary",
                                          label: "Spinning",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  !_vm.usingTemplateId
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "save-as-template float-right",
                                          attrs: {
                                            disabled:
                                              !_vm.canSaveTemplateAndDraft,
                                          },
                                          on: { click: _vm.saveAsTemplate },
                                        },
                                        [_vm._v("テンプレートとして保存")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", [
                                _vm.draftId && _vm.isDraftContents
                                  ? _c(
                                      "span",
                                      { attrs: { id: "draft-annotation" } },
                                      [
                                        _vm._v(
                                          "\n                この内容は " +
                                            _vm._s(_vm.draftAuthorName) +
                                            " によって\n                " +
                                            _vm._s(
                                              _vm.dateFormat(
                                                _vm.draftUpdatedAt,
                                                "yyyy/MM/dd HH:mm:ss"
                                              )
                                            ) +
                                            " に作成された下書きです\n                "
                                        ),
                                        !_vm.disabled &&
                                        _vm.draftId &&
                                        _vm.isDraftContents
                                          ? _c(
                                              "span",
                                              { staticClass: "float-bottom" },
                                              [
                                                _vm._v(
                                                  "\n                  (\n                  "
                                                ),
                                                _c(
                                                  "a",
                                                  {
                                                    attrs: { href: "#" },
                                                    on: {
                                                      click: function ($event) {
                                                        $event.preventDefault()
                                                        return _vm.showCheckDeleteDraftModal.apply(
                                                          null,
                                                          arguments
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.annotationTitleForPublishing
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                                _vm._v("/\n                  "),
                                                _c(
                                                  "a",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-modal",
                                                        rawName:
                                                          "v-b-modal.deleteDraftModal",
                                                        modifiers: {
                                                          deleteDraftModal: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: { href: "#" },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "text-danger",
                                                      },
                                                      [_vm._v("削除する")]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(
                                                  "\n                  )\n                "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm.draftId && !_vm.isDraftContents
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                変更された内容は下書きにまだ保存されていません。 下書きの最終更新は\n                " +
                                          _vm._s(
                                            _vm.dateFormat(
                                              _vm.draftUpdatedAt,
                                              "yyyy/MM/dd HH:mm:ss"
                                            )
                                          ) +
                                          " です。\n              "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _vm.canSaveContent &&
                              _vm.isScheduledPage &&
                              !_vm.isEditorClean
                                ? _c("div", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.formattedPublishedAt) +
                                        "に\n              "
                                    ),
                                    _c(
                                      "a",
                                      {
                                        attrs: { href: "#" },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            _vm.draftId
                                              ? _vm.showCheckDeleteDraftModal()
                                              : _vm.showModalBeforeSave()
                                          },
                                        },
                                      },
                                      [_vm._v("予約公開する")]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "b-modal",
                                {
                                  ref: "checkDeleteDraftModal",
                                  attrs: { lazy: "" },
                                  on: { ok: _vm.showModalBeforeSave },
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "\n                現在保存されている下書きは削除されます\n                "
                                    ),
                                    _c("br"),
                                    _vm._v(
                                      "下書きを削除すると全てのユーザがこの下書きを利用できなくなります\n              "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("p", [_vm._v("変更内容を保存しますか？")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-modal",
                                {
                                  ref: "checkMarkdownTitleChangeModal",
                                  on: { ok: _vm.showCheckPublishModal },
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "この記事は h1 タイトルが変更されています。保存を続行しますか？"
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-modal",
                                {
                                  ref: "checkBrokenFragmentsModal",
                                  on: { ok: _vm.showCheckPublishModal },
                                },
                                [
                                  _c("p", [
                                    _vm._v(
                                      "リンク切れの可能性のあるページ内リンクが見つかりました"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "ul",
                                    _vm._l(
                                      _vm.brokenFragmentLinks,
                                      function (link) {
                                        return _c("li", { key: link }, [
                                          _vm._v(_vm._s(link)),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                  _vm._v(" "),
                                  _c("p", [_vm._v("保存を続行しますか？")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-modal",
                                {
                                  ref: "checkPublishModal",
                                  attrs: { lazy: "" },
                                  on: {
                                    ok: function ($event) {
                                      return _vm.save()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "p",
                                    [
                                      _vm.isScheduledPage
                                        ? [
                                            _vm._v(
                                              "\n                  この記事は\n                  "
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticStyle: {
                                                  "font-weight": "700",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.formattedPublishedAt
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(
                                              "に予約公開されます\n                "
                                            ),
                                          ]
                                        : _vm.isDeleted
                                        ? [
                                            _vm._v(
                                              "\n                  この記事は削除されているため、現在の編集内容を公開予定の記事として設定します。"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "\n                  実際に公開するには記事を復元してください。\n                "
                                            ),
                                          ]
                                        : [_vm._v("この記事は公開されます")],
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("p", [_vm._v("よろしいですか？")]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-modal",
                                {
                                  ref: `restorePage`,
                                  on: {
                                    ok: function ($event) {
                                      return _vm.restorePage()
                                    },
                                  },
                                },
                                [_c("p", [_vm._v("この記事を復元しますか？")])]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: !_vm.isEditorClean },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }