
import Component from 'vue-class-component';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-publish-config',
  components: {
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiPublishConfig extends KamigameVue {
  name: string = '';
  deprecated: string = '';
  noindex: boolean = true;
  isPrivate: boolean = false;
  isRootPath: boolean = false;
  isUpdatedAtHidden: string = '';
  isNoAd: string = '';
  shouldSitemapShrink: boolean = false;
  wiki: components['schemas']['v1Wiki'] = {};

  savedDeprecated: string = '';
  savedNoindex: boolean = true;
  savedIsPrivate: boolean = false;
  savedIsRootPath: boolean = false;
  savedIsUpdatedAtHidden: string = '';
  savedIsNoAd: string = '';
  savedShouldSitemapShrink: boolean = false;

  mounted() {
    this.$store.getters.getWiki(this.wikiName).then((wiki: components['schemas']['v1Wiki']) => {
      this.wiki = wiki;
      this.name = wiki.name || '';
      this.noindex = !!wiki.noindex;
      this.isPrivate = !!wiki.isPrivate;
      this.isRootPath = !!wiki.isRootPath;
      this.shouldSitemapShrink = !!wiki.shouldSitemapShrink;

      if (wiki.meta) {
        const deprecatedConfig = wiki.meta.filter((v) => v.name === 'deprecated').shift();
        if (deprecatedConfig) {
          this.deprecated = deprecatedConfig.value || '';
        }

        const isUpdatedAtHiddenConfig = wiki.meta.filter((v) => v.name === 'is-updated-at-hidden').shift();
        if (isUpdatedAtHiddenConfig) {
          this.isUpdatedAtHidden = isUpdatedAtHiddenConfig.value || '';
        }

        const isNoAdConfig = wiki.meta.filter((v) => v.name === 'is-no-ad').shift();
        if (isNoAdConfig) {
          this.isNoAd = isNoAdConfig.value || '';
        }
      }

      this.updateSavedContentStatus();
    });
  }

  async save() {
    const request: components['schemas']['v1EditWikiPublishConfigRequest'] = {};

    if (this.noindex !== !!this.wiki.noindex) {
      request.noindex = { value: !!this.noindex };
    }

    if (this.isPrivate !== !!this.wiki.isPrivate) {
      request.isPrivate = { value: !!this.isPrivate };
    }

    if (this.isRootPath !== !!this.wiki.isRootPath) {
      request.isRootPath = { value: !!this.isRootPath };
    }

    if (this.shouldSitemapShrink !== !!this.wiki.shouldSitemapShrink) {
      request.shouldSitemapShrink = { value: !!this.shouldSitemapShrink };
    }

    const wikiResp = await this.apiClient.PATCH('/admin/wiki/{name}/publish-config', {
      params: { path: { name: this.name } },
      body: request,
    });
    if (wikiResp.error) {
      throw wikiResp.error;
    }
    const wiki = wikiResp.data;
    this.wiki.noindex = wiki.noindex;
    this.wiki.isPrivate = wiki.isPrivate;
    this.wiki.isRootPath = wiki.isRootPath;
    this.wiki.shouldSitemapShrink = wiki.shouldSitemapShrink;

    if (this.deprecated !== this.savedDeprecated) {
      const wikiMetaDeprecated = await this.apiClient
        .PUT('/admin/wiki/{wiki.name}/meta/{name}', {
          params: {
            path: {
              'wiki.name': this.name,
              name: 'deprecated',
            },
          },
          body: {
            value: this.deprecated,
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }
          return r.data;
        });
      this.wiki.meta = (this.wiki.meta || []).map((wikiMeta) => {
        if (wikiMeta.name === 'deprecated') {
          return Object.assign(wikiMeta, wikiMetaDeprecated);
        } else {
          return wikiMeta;
        }
      });
    }

    if (this.isUpdatedAtHidden !== this.savedIsUpdatedAtHidden) {
      const wikiMetaIsUpdatedAtHidden = await this.apiClient
        .PUT('/admin/wiki/{wiki.name}/meta/{name}', {
          params: {
            path: {
              'wiki.name': this.name,
              name: 'is-updated-at-hidden',
            },
          },
          body: {
            value: this.isUpdatedAtHidden,
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }
          return r.data;
        });
      this.wiki.meta = (this.wiki.meta || []).map((wikiMeta) => {
        if (wikiMeta.name === 'is-updated-at-hidden') {
          return Object.assign(wikiMeta, wikiMetaIsUpdatedAtHidden);
        } else {
          return wikiMeta;
        }
      });
    }

    if (this.isNoAd !== this.savedIsNoAd) {
      const wikiMetaIsNoAd = await this.apiClient
        .PUT('/admin/wiki/{wiki.name}/meta/{name}', {
          params: {
            path: {
              'wiki.name': this.name,
              name: 'is-no-ad',
            },
          },
          body: {
            value: this.isNoAd,
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }
          return r.data;
        });
      this.wiki.meta = (this.wiki.meta || []).map((wikiMeta) => {
        if (wikiMeta.name === 'is-no-ad') {
          return Object.assign(wikiMeta, wikiMetaIsNoAd);
        } else {
          return wikiMeta;
        }
      });
    }

    this.$store.commit('setWiki', { wiki: this.wiki });

    this.updateSavedContentStatus();

    this.setFlashMessage('success', '変更を保存しました。', this.wikiTopUrl, 'サイト上で内容を確認する', true);
  }

  updateSavedContentStatus() {
    this.savedDeprecated = this.deprecated;
    this.savedNoindex = this.noindex;
    this.savedIsPrivate = this.isPrivate;
    this.savedIsRootPath = this.isRootPath;
    this.savedIsUpdatedAtHidden = this.isUpdatedAtHidden;
    this.savedIsNoAd = this.isNoAd;
    this.savedShouldSitemapShrink = this.savedShouldSitemapShrink;
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    return (
      this.deprecated !== this.savedDeprecated ||
      this.noindex !== this.savedNoindex ||
      this.isPrivate !== this.savedIsPrivate ||
      this.isRootPath !== this.savedIsRootPath ||
      this.isUpdatedAtHidden !== this.savedIsUpdatedAtHidden ||
      this.isNoAd !== this.savedIsNoAd ||
      this.shouldSitemapShrink !== this.savedShouldSitemapShrink
    );
  }
}
