var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("button", {
    staticClass: "sidebar-minimizer",
    attrs: { type: "button" },
    on: {
      click: function ($event) {
        _vm.sidebarMinimize()
        _vm.brandMinimize()
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }