import { isInvalidToken, getToken } from './GoogleApiClient';

export async function fetchSpreadsheetSheetByTitle(
  spreadsheetId: string,
  sheetTitle: string,
  valueRenderOption: string = 'FORMATTED_VALUE'
) {
  return await window.gapi.client.sheets.spreadsheets.values
    .get({
      spreadsheetId: spreadsheetId,
      range: sheetTitle,
      valueRenderOption,
    })
    .then((resp) => resp)
    .catch(async (err: Error) => {
      if (isInvalidToken(err)) {
        await getToken();
        return await window.gapi.client.sheets.spreadsheets.values.get({
          spreadsheetId: spreadsheetId,
          range: sheetTitle,
          valueRenderOption,
        });
      } else {
        console.log(err);
        throw new Error(`「${sheetTitle}」の取得に失敗しました。`);
      }
    });
}

export async function fetchSpreadsheetMeta(spreadsheetId: string) {
  return await window.gapi.client.sheets.spreadsheets
    .get({ spreadsheetId: spreadsheetId })
    .then((resp) => resp)
    .catch(async (err: any) => {
      if (isInvalidToken(err)) {
        await getToken();
        return await window.gapi.client.sheets.spreadsheets.get({ spreadsheetId: spreadsheetId });
      } else {
        console.log(err);
        throw new Error('スプレッドシートの取得に失敗しました。');
      }
    });
}

export async function insertRowToSheetByTitle(
  row: string[],
  spreadsheetId: string,
  sheetTitle: string,
  insertDataOption: string = 'INSERT_ROWS'
) {
  const req = {
    spreadsheetId,
    range: sheetTitle,
    valueInputOption: 'USER_ENTERED',
    insertDataOption,
    resource: {
      values: [row],
    },
  };

  try {
    await window.gapi.client.sheets.spreadsheets.values.append(req);
  } catch (err: any) {
    if (isInvalidToken(err)) {
      await getToken();
      await window.gapi.client.sheets.spreadsheets.values.append(req);
    } else {
      console.log(err);
      throw new Error('スプレッドシートの書き込みに失敗しました。');
    }
  }
}

type WikiState = {
  pages: {
    byId: {
      [pageId: string]: {
        id: string;
        sourceId: string;
      };
    };
  };
  categories: {
    byId: {
      [categoryId: string]: {
        id: string;
        sourceId: string;
      };
    };
  };
  pagePartials: {
    byId: {
      [pagePartialId: string]: {
        id: string;
        sourceId: string;
      };
    };
  };
};
