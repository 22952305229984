
import Component from 'vue-class-component';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import { MarkdownEditor } from '@/components';

@Component({
  name: 'wiki-menu-edit',
  components: {
    'kamigame-markdown-editor': MarkdownEditor,
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiMenuEdit extends KamigameVue {
  text: string = '';
  loaded: boolean = false;
  disabled: boolean = false;

  savedText: string = '';

  async loadMetaInformation(): Promise<void> {
    this.$route.meta.label = this.target;

    return this.apiClient.GET('/wiki/{name}', { params: { path: { name: this.wikiName } } }).then((response) => {
      if (response.error) {
        throw response.error;
      }

      const menu = response.data.markdownTextWidget
        ? response.data.markdownTextWidget.filter((r) => r.name === this.target).pop()
        : null;
      this.text = menu && menu.body ? menu.body : '';
      this.savedText = this.text;
    });
  }

  mounted() {
    this.loadMetaInformation()
      .then(() => {
        this.loaded = true;
      })
      .catch(() => {
        this.loaded = true;
      });
  }

  beforeRouteUpdate(to: VueRouter, from: VueRouter, next: () => void) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
    this.loadMetaInformation();
    next();
  }

  async save() {
    if (this.disabled) {
      return;
    }
    this.apiClient
      .PUT('/admin/wiki/{wiki.name}/markdown-text-widget', {
        params: {
          path: { 'wiki.name': this.wikiName },
        },
        body: {
          name: this.target,
          body: this.text,
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        this.savedText = this.text;
        this.setFlashMessage('success', '変更を保存しました。', this.wikiTopUrl, 'サイト上で内容を確認する', true);
      });
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    return this.text !== this.savedText;
  }

  get target() {
    return this.route.params.target;
  }

  get wikiName() {
    return this.route.params.name;
  }
}
