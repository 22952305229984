
import KamigameVue from '@/KamigameVue';
import { fetchSpreadsheetSheetByTitle } from '@/service/SpreadsheetApi';
import { parseSpreadsheetIdFromUrl } from '@/service/SpreadsheetUrl';
import Component from 'vue-class-component';

import { existsDraft, bulkReplaceDraftsContentHeader } from '@/service/ArticleApi';

import { mapHeaderToColumns } from '@/lib/spreadsheet';

@Component({
  name: 'button-bulk-replace-content-header',
})
export default class ButtonBulkReplaceContentHeader extends KamigameVue {
  spreadsheetUrl: string = '';
  sheetTitle: string = '';

  async bulkReplaceContentHeader() {
    const existsDraftResult = await existsDraft(this.apiClient, this.wikiName);

    if (existsDraftResult) {
      this.setFlashMessage(
        'danger',
        '公開されていない下書きが存在します。全ての下書きを公開/削除してから実行してください。'
      );
      return;
    }

    const spreadsSheetId = parseSpreadsheetIdFromUrl(this.spreadsheetUrl);

    if (!spreadsSheetId) {
      this.setFlashMessage('danger', 'シートIDの取得に失敗しました。');
      return;
    }

    const res = await fetchSpreadsheetSheetByTitle(spreadsSheetId, this.sheetTitle, 'UNFORMATTED_VALUE').catch(
      (e: Error) => {
        return e;
      }
    );

    if (res instanceof Error) {
      this.setFlashMessage('danger', 'シートの取得に失敗しました。');
      return;
    }

    const cells = res.result.values;
    if (!cells) {
      this.setFlashMessage('danger', 'シート名を間違えているか、シートの値が存在しません。');
      return;
    }

    const headerToColumns = mapHeaderToColumns(cells);
    const pageIds = headerToColumns['Page ID'];
    const titles = headerToColumns['Title'];
    const h1s = headerToColumns['H1'];
    const descriptions = headerToColumns['Description'];
    if (!pageIds) {
      this.setFlashMessage('danger', '「Page ID」列が存在しません');
      return;
    }

    const contentHeaders: ContentHeader[] = pageIds
      .map((pageId, index) => {
        const title = titles ? titles[index] : undefined;
        const h1 = h1s ? h1s[index] : undefined;
        const description = descriptions ? descriptions[index] : undefined;
        return {
          pageId,
          title,
          h1,
          description,
        };
      })
      .filter((p) => p.pageId);

    try {
      await bulkReplaceDraftsContentHeader(this.apiClient, this.wikiName, contentHeaders);
    } catch (e) {
      this.setFlashMessage('danger', '記事ヘッダー群一括更新に失敗しました。エンジニアに連絡してください。');
      console.error(e);
      return;
    }

    this.setFlashMessage('success', '記事ヘッダー群一括更新に成功しました。下書きを確認してください。');
  }
}
