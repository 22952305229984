<template>
  <footer class="app-footer">
    <span><a href="https://genesisui.com">Real</a> &copy; 2018 creativeLabs.</span>
    <span class="ml-auto">Powered by <a href="https://genesisui.com">GenesisUI</a></span>
  </footer>
</template>
<script>
export default {
  name: 'c-footer'
}
</script>
