
import format from 'date-fns/format';
import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';
import { wikiImageUpload, exportImageUploadCSV, fileValidateChunk } from '@/service/ImageUploader';
import { wikiGetImageWithServingURL } from '@/service/WikiGetImageWithServingURL';
import { detectAnimationGif } from '../../lib/image';

type SuccessResult = { name: string; url: string };

@Component({
  name: 'wiki-image-bulk-upload',
})
export default class WikiImageBulkUpload extends KamigameVue {
  files: File[] = [];
  success: SuccessResult[] = [];
  error: File[] = [];
  uploading: boolean = false;

  get proceeded() {
    return this.success.length + this.error.length;
  }

  get completed() {
    return this.files.length < this.proceeded;
  }

  async upload() {
    this.uploadingStart();

    const chunkMaximumBytes = 30 * 1000 * 1000;
    const imageFiles = [];
    const gifFiles = [];

    for (const file of this.files) {
      if (await detectAnimationGif(file)) {
        gifFiles.push(file);
        continue;
      }
      imageFiles.push(file);
    }

    const chunkedImageResult = fileValidateChunk(imageFiles, chunkMaximumBytes);
    const chunkedGifResult = fileValidateChunk(gifFiles, chunkMaximumBytes);

    this.error = [...chunkedImageResult.error, ...chunkedGifResult.error];

    await this.uploadChunkedFiles(chunkedImageResult.chunkedFiles, false);
    await this.uploadChunkedFiles(chunkedGifResult.chunkedFiles, true);

    this.uploadingEnd();
  }

  async uploadChunkedFiles(chunkedFiles: File[][], isGif: boolean) {
    await chunkedFiles.reduce(async (chain, chunk) => {
      await chain;
      try {
        const res = await wikiImageUpload(this.$store.getters.sessionId, this.wikiName, isGif, chunk);
        await Promise.all(
          Array.from(res).map(async ([name, id]) => {
            const image = await wikiGetImageWithServingURL(this.apiClient, id);
            this.success.push({ name, url: image.url || '' });
          })
        );
      } catch (e) {
        console.error(e);
        this.error.push(...chunk);
      }
    }, Promise.resolve());
  }

  exportCSV() {
    const csvName: string = `${this.wikiName}-image-list-${format(new Date(), 'yyyyMMddHHmmss')}.csv`;
    exportImageUploadCSV(this.success, csvName);
  }

  uploadingStart() {
    this.uploading = true;
    this.success = [];
    this.error = [];
  }
  uploadingEnd() {
    this.uploading = false;
    this.files = [];
  }
}
