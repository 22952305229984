
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

import KamigameVue from '@/KamigameVue';
import DatasheetsDB from '@/indexeddb/DatasheetsDB';
import { BModal } from 'bootstrap-vue';

@Component({
  name: 'kamigame-wiki-partial-generate-code-modal',
})
export default class PartialGenerateCodeModal extends KamigameVue {
  errorMessage: string = '';
  selected: null | string = null;
  itemsSelected: string[] = [];
  updatedAt: Date | null = null;
  datasheetsNames: { [key: string]: string[] } | null = null;

  @Prop()
  mode!: 'page' | 'menu' | 'template' | 'partial';

  @Watch('selected')
  onChangeSelected() {
    this.itemsSelected = [];
  }

  show() {
    this.load().then(() => {
      this.errorMessage = '';
      if (this.mode !== 'partial') {
        this.errorMessage = 'この機能は記事部品作成/編集時のみ使用できます。';
      } else if (this.datasheetsNames === null) {
        this.errorMessage = 'この機能を使用する場合は「データシートの取得」を行ってください。';
      }
      (this.$refs.modalRef as BModal).show();
    });
  }

  async load() {
    const wikiId = await this.wikiId;
    await DatasheetsDB.load(wikiId);
    this.updatedAt = DatasheetsDB.getHistory();
    const datasheets = DatasheetsDB.getDatasheetsDB();
    if (datasheets === null) {
      return;
    }
    this.datasheetsNames = this.getDataSheetsName(datasheets);
  }

  getDataSheetsName(datasheets: { [key: string]: { [key: string]: string }[] }) {
    const datasheetsNames: { [key: string]: string[] } = {};
    for (const items in datasheets) {
      if (datasheets[items].length > 0) {
        const datasheetsName: string[] = [];
        for (const item in datasheets[items][0]) {
          datasheetsName.push(item);
        }
        datasheetsNames[items] = datasheetsName;
      }
    }
    return datasheetsNames;
  }

  onSelectionCanceled() {
    this.clear();
  }

  onSelectionSaved() {
    if (this.selected === null || this.itemsSelected.length === 0) {
      return;
    }
    const code = this.generateCode();
    this.$emit('selectionSaved', code);
    this.clear();
  }

  clear() {
    this.selected = null;
    this.itemsSelected = [];
  }

  generateCode() {
    let code = '';
    const br = '\r\n';
    code += `<% for(var ${this.selected}_アイテム of ${this.selected}) {%>` + br;
    for (let item in this.itemsSelected) {
      code += `<%- ${this.selected}_アイテム.${this.itemsSelected[item]} %>` + br;
    }
    code += '<% } %>' + br;
    return code;
  }
}
