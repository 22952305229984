var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          [
            _c("base-config-card", {
              attrs: { cols: 6, "wiki-for-dashboard": _vm.wikiForDashboard },
            }),
            _vm._v(" "),
            _c("member-request-list-card", {
              attrs: {
                cols: 6,
                "wiki-member-request-array": _vm.wikiMemberRequestArray,
              },
            }),
            _vm._v(" "),
            _c("histories", {
              attrs: {
                cols: 6,
                limit: _vm.wikiPageHistoriesLimit,
                "wiki-page-histories": _vm.wikiPageHistories,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }