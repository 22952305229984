
import { components } from '@/api-client/schema';
import KamigameVue from '@/KamigameVue';
import format from 'date-fns/format';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'member-request-list-card',
})
export default class MemberRequestListCard extends KamigameVue {
  @Prop()
  cols!: number;
  @Prop()
  wikiMemberRequestArray!: components['schemas']['v1WikiMemberRequest'][];

  dateFormat: any;

  memberRequestFields = [
    { key: 'applicant', label: '参加希望者' },
    { key: 'createdAt', label: '申請日時' },
  ];
  memberRequestTableItems: object[] = [];

  @Watch('wikiMemberRequestArray')
  onChangeWikiMemberRequestArray(newValue: components['schemas']['v1WikiMemberRequest'][]) {
    this.memberRequestTableItems = newValue.map((wikiMemberRequest) => ({
      applicant: wikiMemberRequest.user?.nickname,
      createdAt: wikiMemberRequest.createdAt,
    }));
  }

  async mounted() {
    this.dateFormat = format;
  }
}
