export interface WikiPageAdvice {
  id?: string;
  path?: string;
  game?: string;
  articleId?: string;
  title?: string;
  body?: string;
  createdAt?: Date;
  isHandled?: boolean;
}

export interface WikiPageAdviceApi {
  id?: string;
  path?: string;
  game?: string;
  article_id?: string;
  title?: string;
  body?: string;
  created_at?: Date;
  is_handled?: boolean;
}

export function convertAdviceFromApiToModel(apiModel: WikiPageAdviceApi): WikiPageAdvice {
  return {
    id: apiModel.id,
    path: apiModel.path,
    game: apiModel.game,
    articleId: apiModel.article_id,
    title: apiModel.title,
    body: apiModel.body,
    createdAt: apiModel.created_at,
    isHandled: apiModel.is_handled,
  };
}
