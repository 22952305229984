
import VueRouter from 'vue-router';
import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';
import { Sidebar, Breadcrumb } from '@/components';
import routes from '@/router/manage';

interface NavItem {
  name: string;
  url?: string;
  icon?: string;
  variant?: string;
  class?: string;
  children?: NavItem[];
}

@Component({
  name: 'manage-container',
  components: {
    Sidebar,
    Breadcrumb,
  },
})
export default class ManageContainer extends KamigameVue {
  beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    this.clearAllFlashMessages();
    next();
  }

  generateConfigNavItems(): NavItem[] {
    return routes
      .filter((route) => this.$ability.can(route.meta.requiredPermission, 'OBJ_default'))
      .map((route) => {
        return {
          name: route.meta.label,
          icon: route.meta.icon,
          url: route.path,
        };
      });
  }

  get list() {
    return this.route.matched.filter((r) => {
      if (r.meta && r.meta.index) {
        return false;
      }

      return true;
    });
  }
}
