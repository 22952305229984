function kamigameLinkAssistExtension(editor: any) {
  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem({
    type: 'button',
    options: {
      name: 'link',
      className: 'tui-link tui-toolbar-icons',
      event: 'show-link-modal',
      tooltip: 'リンクを作成',
    },
  });

  editor.eventManager.addEventType('show-link-modal');
  editor.commandManager.addCommand('markdown', {
    name: 'show-link-modal',
    exec() {
      editor.eventManager.emit('show-link-modal');
    },
  });
}

export default kamigameLinkAssistExtension;
