const decodePosString = (str: string) => {
  return str.split(',').map(v => parseInt(v, 36));
};

const applyPseudoTagNameToChildren = (blockElement: HTMLElement) => {
  const contexts = ['blockquote', 'table'];
  const tagName = blockElement.tagName.toLowerCase();

  if (contexts.includes(tagName)) {
    blockElement.querySelectorAll(':scope *[data-md-block]').forEach((e: Element) => {
      if (!(e instanceof HTMLElement)) {
        return;
      }

      e.dataset.mdPseudoTagName = tagName;
    });
  }
};

const getTagName = (node: HTMLElement) => {
  return node.dataset.mdPseudoTagName || node.tagName.toLowerCase();
};

const focusPreview = (
  editor: CodeMirror.Editor,
  type: string,
  previewResult: HTMLElement,
  ...args: (string | number)[]
) => {
  const method: { [key: string]: Function } = {
    block: highlightMarkdownBlockPresentations,
    inline: highlightMarkdownInlinePresentations,
    cell: highlightMarkdownTableCellInlinePresentations,
  };

  if (method[type]) {
    method[type](editor, parseInt(previewResult.dataset.fastmatterLines || '0', 10), ...args);
  }
};

const unfocusPreview = (
  editor: CodeMirror.Editor,
  type: string,
  previewResult: HTMLElement,
  ...args: (string | number)[]
) => {
  const method: { [key: string]: Function } = {
    block: clearHighlightMarkdownBlockPresentations,
    inline: clearHighlightMarkdownInlinePresentations,
    cell: clearHighlightMarkdownTableCellInlinePresentations,
  };
  if (method[type]) {
    method[type](editor, parseInt(previewResult.dataset.fastmatterLines || '0', 10), ...args);
  }
};

const bindFocusEvent = (
  cm: CodeMirror.Editor,
  node: HTMLElement,
  previewResult: HTMLElement,
  type: string,
  ...args: (string | number)[]
) => {
  node.addEventListener('mouseenter', () => focusPreview(cm, type, previewResult, ...args));
  node.addEventListener('mouseleave', () => unfocusPreview(cm, type, previewResult, ...args));
};

const bindToBlockElement = (cm: CodeMirror.Editor, node: HTMLElement, previewResult: HTMLElement) => {
  applyPseudoTagNameToChildren(node);
  if (getTagName(node) === 'table') {
    return;
  }

  const pos = decodePosString(node.dataset.mdBlock || '');
  const blockTagName = getTagName(node);

  bindFocusEvent(cm, node, previewResult, 'block', pos[0], pos[1]);

  const inlines = node.querySelectorAll(':scope > *[data-md-inline]');
  inlines.forEach((inline: Element) => {
    if (!(inline instanceof HTMLElement)) {
      return;
    }

    const inlinePos = decodePosString(inline.dataset.mdInline || '');
    bindFocusEvent(cm, inline, previewResult, 'inline', blockTagName, pos[0], pos[1], inlinePos[0], inlinePos[1]);
  });
};

const bindToTableHeaderElement = (
  cm: CodeMirror.Editor,
  node: HTMLElement,
  previewResult: HTMLElement,
  basePos: number[],
  index: number
) => {
  const headerStart = basePos[0] + index;
  bindFocusEvent(cm, node, previewResult, 'block', headerStart, headerStart);

  const cells = node.querySelectorAll(':scope > th');
  cells.forEach((cell, column) => {
    const inlines = cell.querySelectorAll(':scope > *[data-md-inline]');
    inlines.forEach((inline: Element) => {
      if (!(inline instanceof HTMLElement)) {
        return;
      }

      bindToTableCellElement(cm, inline, previewResult, headerStart, column);
    });
  });
};

const bindToTableCellElement = (
  cm: CodeMirror.Editor,
  node: HTMLElement,
  previewResult: HTMLElement,
  start: number,
  column: number,
  span: number = 0
) => {
  const colspan = parseInt((node.parentNode as HTMLElement).getAttribute('colspan') || '1', 10) - 1 + span;
  const inlinePos = decodePosString(node.dataset.mdInline || '');
  bindFocusEvent(cm, node, previewResult, 'cell', start, column + colspan, inlinePos[0], inlinePos[1]);
};

const bindToTableBodyElement = (
  cm: CodeMirror.Editor,
  table: HTMLTableElement,
  previewResult: HTMLElement,
  headers: HTMLTableRowElement[],
  basePos: number[]
) => {
  const rowspanCounts: { [key: number]: number } = {};

  const headerLines = (headers.length || 1) + 1;
  const bodies = table.querySelectorAll('tbody > tr');
  bodies.forEach((body: Element, row: number) => {
    if (!(body instanceof HTMLElement)) {
      return;
    }

    const bodyStart = basePos[0] + headerLines + row;
    bindFocusEvent(cm, body, previewResult, 'block', bodyStart, bodyStart);

    const cells = body.querySelectorAll(':scope > td');
    cells.forEach((cell, column) => {
      const inlines = cell.querySelectorAll(':scope > *[data-md-inline]');
      inlines.forEach((inline: Element) => {
        if (!(inline instanceof HTMLElement)) {
          return;
        }

        const rowspan = parseInt((inline.parentNode as HTMLElement).getAttribute('rowspan') || '1', 10) - 1;
        if (rowspan > 0) {
          for (let i = 1; i <= rowspan; i++) {
            const targetRow = i + row;
            if (!rowspanCounts[targetRow]) {
              rowspanCounts[targetRow] = 0;
            }
            rowspanCounts[targetRow]++;
          }
        }

        bindToTableCellElement(cm, inline, previewResult, bodyStart, column, rowspanCounts[row]);
      });
    });
  });
};

const bindToTableElement = (cm: CodeMirror.Editor, node: HTMLTableElement, previewResult: HTMLElement) => {
  const basePos = decodePosString(node.dataset.mdBlock || '');
  const headers = Array.from(node.querySelectorAll('thead > tr')).filter(
    (header: Element) => header instanceof HTMLTableRowElement
  ) as HTMLTableRowElement[];
  headers.forEach((header: HTMLTableRowElement, index: number) => {
    bindToTableHeaderElement(cm, header, previewResult, basePos, index);
  });

  bindToTableBodyElement(cm, node, previewResult, headers, basePos);
};

const highlightMarkdownBlockPresentations = (
  cm: CodeMirror.Editor,
  fastmatterLines: number,
  from: number,
  to: number
) => {
  for (let i = from; i <= to; i++) {
    cm.addLineClass(i + fastmatterLines, 'background', 'highlight-markdown-block-representations');
  }
};

const clearHighlightMarkdownBlockPresentations = (
  cm: CodeMirror.Editor,
  fastmatterLines: number,
  from: number,
  to: number
) => {
  for (let i = from; i <= to; i++) {
    cm.removeLineClass(i + fastmatterLines, 'background', 'highlight-markdown-block-representations');
  }
};

const detectMarkdownInlinePresentations = (
  cm: CodeMirror.Editor,
  fastmatterLines: number,
  blockTagName: string,
  blockFrom: number,
  blockTo: number,
  inlineFrom: number,
  inlineTo: number
) => {
  let offset = 0;
  let targetLine = 0;
  let targetFrom = 0;
  let targetTo = 0;

  const blockTagOffset: { [key: string]: RegExp } = {
    li: /^[\s]*?([+\-\*]|[0-9]+[\.\)])\s?/,
    blockquote: /^[\s]*?[\s>]+\s?/,
  };

  for (let i = blockFrom; i <= blockTo; i++) {
    const content = cm.getDoc().getLine(i + fastmatterLines);
    let tagOffset = 0;
    if (blockTagOffset[blockTagName]) {
      const found = content.match(blockTagOffset[blockTagName]);
      if (found) {
        tagOffset += found[0].length;
      }
    }

    if (inlineFrom <= offset + content.length) {
      targetLine = i;
      targetFrom = inlineFrom - offset + tagOffset;
      targetTo = inlineTo - offset + tagOffset + 1;
      break;
    }

    offset += content.length + tagOffset;
  }

  if (targetLine) {
    return [targetLine, targetFrom, targetTo];
  }

  return [];
};

const highlightMarkdownInlinePresentations = (
  cm: CodeMirror.Editor,
  fastmatterLines: number,
  blockTagName: string,
  blockFrom: number,
  blockTo: number,
  inlineFrom: number,
  inlineTo: number
) => {
  const [targetLine, targetFrom, targetTo] = detectMarkdownInlinePresentations(
    cm,
    fastmatterLines,
    blockTagName,
    blockFrom,
    blockTo,
    inlineFrom,
    inlineTo
  );
  if (targetLine) {
    cm.getDoc().markText(
      { line: targetLine + fastmatterLines, ch: targetFrom },
      { line: targetLine + fastmatterLines, ch: targetTo },
      {
        className: 'highlight-markdown-inline-representations',
      }
    );
  }
};

const clearHighlightMarkdownInlinePresentations = (
  cm: CodeMirror.Editor,
  fastmatterLines: number,
  blockTagName: string,
  blockFrom: number,
  blockTo: number,
  inlineFrom: number,
  inlineTo: number
) => {
  const [targetLine, targetFrom, targetTo] = detectMarkdownInlinePresentations(
    cm,
    fastmatterLines,
    blockTagName,
    blockFrom,
    blockTo,
    inlineFrom,
    inlineTo
  );
  if (targetLine) {
    cm.getDoc()
      .findMarks(
        { line: targetLine + fastmatterLines, ch: targetFrom },
        { line: targetLine + fastmatterLines, ch: targetTo }
      )
      .forEach(marker => {
        marker.clear();
      });
  }
};

const highlightMarkdownTableCellInlinePresentations = (
  cm: CodeMirror.Editor,
  fastmatterLines: number,
  blockLine: number,
  column: number,
  inlineFrom: number,
  inlineTo: number
) => {
  const content = cm.getDoc().getLine(blockLine + fastmatterLines);
  const prefixLength = content
    .split('|')
    .slice(0, column + 2)
    .join('|')
    .replace(/(\|\s*)[^\s][^|]*$/, '$1').length;

  cm.getDoc().markText(
    { line: blockLine + fastmatterLines, ch: prefixLength + inlineFrom },
    { line: blockLine + fastmatterLines, ch: prefixLength + inlineTo + 1 },
    {
      className: 'highlight-markdown-inline-representations',
    }
  );
};

const clearHighlightMarkdownTableCellInlinePresentations = (
  cm: CodeMirror.Editor,
  fastmatterLines: number,
  blockLine: number,
  column: number,
  inlineFrom: number,
  inlineTo: number
) => {
  const content = cm.getDoc().getLine(blockLine + fastmatterLines);
  const prefixLength = content
    .split('|')
    .slice(0, column + 2)
    .join('|')
    .replace(/(\|\s*)[^\s][^|]*$/, '$1').length;
  cm.getDoc()
    .findMarks(
      { line: blockLine + fastmatterLines, ch: prefixLength + inlineFrom },
      { line: blockLine + fastmatterLines, ch: prefixLength + inlineTo + 1 }
    )
    .forEach(marker => {
      marker.clear();
    });
};

export { bindToBlockElement, bindToTableElement };
