import Vue from 'vue';
import Component from 'vue-class-component';
import { createAPIClient } from '@/service/WikiAPIClientFactory';
import { KamigameCommentApiClient } from './comment-api-client/KamigameCommentAPIClient';
import { createCommentApiClient } from './service/CommentAPIClientFactory';
import { DEFAULT_DOCUMENT_TITLE } from './constants';
import { components } from './api-client/schema';

Component.registerHooks([
  // for vue-router
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate',
]);

@Component
export default class KamigameVue extends Vue {
  beforeUpdate() {
    this.setDocumentTitle(this.routeDocumentTitle);
  }

  get apiClient() {
    return createAPIClient({ token: this.$store.getters.sessionId });
  }

  get user() {
    return this.$store.state.user;
  }

  get flashes() {
    return this.$store.state.flashes;
  }

  get commentApi(): KamigameCommentApiClient {
    return createCommentApiClient();
  }

  setFlashMessage(
    variant: string,
    message: string,
    linkTo?: string,
    linkLabel?: string,
    linkNewTab?: boolean,
    flashMillisecond: number = 5000
  ): number {
    window.scrollTo(0, 0);
    const flashId = window.setTimeout(() => {
      this.clearFlashMessage(flashId);
    }, flashMillisecond);
    this.$store.commit('setFlashMessage', { flashId, variant, message, linkTo, linkLabel, linkNewTab });
    return flashId;
  }

  clearFlashMessage(flashId: number) {
    this.$store.commit('clearFlashMessage', flashId);
    window.clearTimeout(flashId);
  }

  clearAllFlashMessages() {
    this.$store.commit('clearAllFlashMessages');
  }

  setDocumentTitle(documentTitle: string) {
    this.$store.commit('setDocumentTitle', documentTitle);
  }

  get route() {
    return this.$route;
  }

  get router() {
    return this.$router;
  }

  get wikiName(): string {
    return this.route.params.name || '';
  }

  get wikiTopUrl(): string {
    return `${WIKI_URL_BASE}/${this.wikiName}`;
  }

  get wikiId(): Promise<string> {
    return (this.$store.getters.getWiki(this.wikiName) as Promise<components['schemas']['v1Wiki']>).then(
      (wiki) => wiki.id as string
    );
  }

  get routeDocumentTitle(): string {
    if (this.route.meta.lazyLabel) {
      return `${this.route.meta.lazyLabel(this.route)} - ${DEFAULT_DOCUMENT_TITLE}`;
    }
    if (!this.route.meta || !this.route.meta.label) {
      return DEFAULT_DOCUMENT_TITLE;
    }

    return `${this.route.meta.label} - ${DEFAULT_DOCUMENT_TITLE}`;
  }
}
