
import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import { Watch } from 'vue-property-decorator';

import KamigameVue from '@/KamigameVue';
import { Paging } from '@/components';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-page-history-list',
  components: {
    'kamigame-paging': Paging,
  },
})
export default class WikiPageHistoryList extends KamigameVue {
  dateFormat = format;

  loading = false;
  histories: components['schemas']['v1WikiPageHistory'][] = [];
  wikiPage?: components['schemas']['v1WikiPage'];
  historyFields = [
    { key: 'createdAt', label: '日時' },
    { thStyle: { width: '25%' }, key: 'title', label: 'タイトル' },
    { thStyle: { width: '45%' }, key: 'body', label: '本文' },
    { key: 'author', label: '編集者' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions = [
    { text: '最終更新日が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '最終更新日が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'タイトル順', value: { sortedBy: 'title', sortOrder: 'ASC' } },
  ];
  perRequestHistoriesNum = 20;
  totalHisotiesNum = 0;
  searchString = '';
  selectedSortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  kamigamePaging = this.$refs.kamigamePaging as Paging;

  @Watch('pageId')
  onPageIdChanged() {
    this.getHistories();
  }

  async mounted() {
    this.kamigamePaging = this.$refs.kamigamePaging as Paging;
    this.getHistories();
  }

  async getHistories(startAt: number = 0) {
    this.loading = true;

    const getHistoriesParams = {
      limit: this.perRequestHistoriesNum,
      offset: startAt,
      searchWords: this.searchWords,
      sortedBy: this.selectedSortCondition.sortedBy,
      sortOrder: this.selectedSortCondition.sortOrder,
    };
    const fetcher = this.pageId
      ? this.apiClient
          .GET('/admin/wiki/{wikiName}/page/{pageId}/history', {
            params: {
              path: {
                wikiName: this.wikiName,
                pageId: this.pageId,
              },
              query: getHistoriesParams,
            },
          })
          .then((r) => {
            if (r.error) {
              throw r.error;
            }

            return r.data;
          })
      : this.apiClient
          .GET('/admin/wiki/{wikiName}/history', {
            params: {
              path: {
                wikiName: this.wikiName,
              },
              query: getHistoriesParams,
            },
          })
          .then((r) => {
            if (r.error) {
              throw r.error;
            }

            return r.data;
          });

    fetcher
      .then((response: components['schemas']['v1WikiPageHistoryCollection']) => {
        this.loading = false;
        this.histories = response.history || [];
        this.wikiPage = response.wikiPage;
        this.totalHisotiesNum = response.numOfTotalHistories || 0;
        if (startAt === 0) {
          this.kamigamePaging.resetPaging();
        }
      })
      .catch((e: any) => {
        if (e.code && e.code === 404) {
          this.histories = [];
          this.loading = false;
        }
      });
  }

  changeDataRange(startAt: number) {
    this.getHistories(startAt);
  }

  navigateToDetail(history: components['schemas']['v1WikiPageHistory']) {
    const a = history.parentId;
    const b = history.id;

    if (b) {
      const routeName = this.pageId ? 'wiki_page_history' : 'wiki_history';
      const params: any = {
        b,
        pageId: this.pageId,
      };
      if (a) {
        params.a = a;
      }
      this.router.push({ name: routeName, params });
    }
  }

  showRevertModal(index: number) {
    const modal = this.$refs[`alertRevert_${index}`] as BModal;
    modal.show();
  }

  async revert(history: components['schemas']['v1WikiPageHistory']) {
    if (!this.wikiPage) {
      return;
    }

    const defaultPublishedDate = new Date(`${format(new Date(), 'yyyy-MM-dd')} 00:00`);
    this.apiClient
      .PUT('/admin/wiki/{wikiId}/page/{pageId}', {
        params: {
          path: {
            wikiId: this.wikiName,
            pageId: this.pageId,
          },
        },
        body: {
          title: history.title,
          body: history.body,
          categoryId: this.wikiPage.category ? this.wikiPage.category.id : '',
          editPermission: this.wikiPage.editPermission,
          publishedAt: this.wikiPage
            ? this.wikiPage.publishedAt || defaultPublishedDate.toISOString()
            : defaultPublishedDate.toISOString(),
          keywords: this.wikiPage.keywords ?? '',
          description: this.wikiPage.description ?? '',
          noindex: this.wikiPage.noindex ?? false,
          metaOgpImageURL: this.wikiPage.metaOgpImageURL ?? '',
          metaThumbnailImageURL: this.wikiPage.metaThumbnailImageURL ?? '',
          metaThumbnailImageAutoSelect: this.wikiPage.metaThumbnailImageAutoSelect ?? false,
          titlePrefix: this.wikiPage.titlePrefix ?? '',
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }
        return r.data;
      })
      .then(() => {
        this.setFlashMessage('success', '記事の内容を指定されたバージョンまで戻しました。');
      });
  }

  get wikiName() {
    return this.route.params.name;
  }

  get pageId() {
    return this.route.params.pageId;
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.searchString);
  }
}
