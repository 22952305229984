import { applyPatternMatchingToRegions } from '@/lib/image-scanner';
import { loadImage } from './loadImage';
import { ImageRecognitionResult, SectionValue } from './MainImageModal.vue';

export const runImageRecognition = async (
  files: File[],
  regions: Record<string, [number, number, number, number, number]>,
  templateImages: HTMLImageElement[]
) => {
  return await Promise.all(
    files.map(async (file: File) => {
      try {
        const image = await loadImage(file);
        return await applyPatternMatchingToRegions(image, templateImages, regions);
      } catch (e) {
        console.log(e);
        return {};
      }
    })
  );
};

export const adjustImageRecognitionResults = (
  imageRecognitionResults: ImageRecognitionResult[],
  sectionValues: SectionValue[]
): Record<string, string>[] => {
  return imageRecognitionResults.map((result) => {
    const adjustedImageRecognitionResult: Record<string, string> = {};
    Object.keys(result.imageRecognition).forEach((key, i) => {
      adjustedImageRecognitionResult[sectionValues[i].name] = result.imageRecognition[key].text;
    });
    return adjustedImageRecognitionResult;
  });
};
