var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "router-link",
    {
      staticClass: "nav-item nav-dropdown",
      class: _vm.classes,
      attrs: { tag: "li", to: _vm.url, disabled: "" },
    },
    [
      _c(
        "div",
        {
          staticClass: "nav-link nav-dropdown-toggle",
          on: { click: _vm.handleClick },
        },
        [_c("i", { class: _vm.icon }), _vm._v(" " + _vm._s(_vm.name))]
      ),
      _vm._v(" "),
      _c("ul", { staticClass: "nav-dropdown-items" }, [_vm._t("default")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }