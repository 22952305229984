
import format from 'date-fns/format';
import VueRouter from 'vue-router';
import { Watch } from 'vue-property-decorator';
import { BModal } from 'bootstrap-vue';

import KamigameVue from '@/KamigameVue';
import { MarkdownEditor } from '@/components';
import Component from 'vue-class-component';

import DatasheetsLoadButton from '@/components/DatasheetsLoadButton.vue';
import extractNotAllowedDomainsFromErrorMessage from '@/service/ExtractNotAllowedDomainsFromErrorMessage';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-page-partial-edit',
  components: {
    'datasheets-loader-button': DatasheetsLoadButton,
    'kamigame-markdown-editor': MarkdownEditor,
  },
})
export default class WikiPagePartialEdit extends KamigameVue {
  dateFormat = format;

  pagePartialID = '';
  name: string = '';
  text: string = '';
  partialName = '';
  partialText = '';
  disabled: boolean = false;
  editable: boolean = true;
  draftId: string = '';
  draftName: string = '';
  draftText: string = '';
  draftAuthorName: string = '';
  draftUpdatedAt?: Date;
  autoDraftSave: boolean = false;
  autoSaveTimeoutID: number = 0;

  @Watch('pagePartial')
  onPagePartialChanged() {
    this.init();
  }

  @Watch('text')
  onTextChanged() {
    if (
      this.autoDraftSave &&
      typeof this.draftUpdatedAt !== 'undefined' &&
      !this.disabled &&
      this.autoSaveTimeoutID === 0
    ) {
      this.autoSaveTimeoutID = window.setTimeout(() => {
        if (this.autoDraftSave) {
          this.saveAsDraft();
        }
        this.autoSaveTimeoutID = 0;
      }, 30 * 1000);
    }
  }

  $refs!: {
    Editor: MarkdownEditor;
    checkDeleteDraftModal: BModal;
    checkPublishModal: BModal;
    confirmPublishModal: BModal;
  };

  mounted() {
    this.init();
  }

  async init() {
    if (this.pagePartial) {
      await this.getWikiPagePartial();
    } else {
      this.resetPagePartialStates();
    }

    this.setPartialDocumentTitle();
    this.editable = this.$ability.can('ACTION_wiki_page_partial_update', 'OBJ_default');
  }

  onDatasheetsLoaded() {
    this.$refs.Editor.onEditorChange();
  }

  async getWikiPagePartial() {
    await this.apiClient
      .GET('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialID}', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialID: this.pagePartial,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(async (response) => {
        this.name = response.name || '';
        this.text = response.body || '';
        this.partialName = this.name;
        this.partialText = this.text;
        if (response.id) {
          this.pagePartialID = response.id;
        }
        if (this.pagePartialID) {
          await this.loadDraft();
        }
      });
  }

  resetPagePartialStates() {
    this.pagePartialID = '';
    this.name = '';
    this.text = '';
    this.disabled = false;
    this.editable = true;
  }

  async create(params: components['schemas']['v1CreateWikiPagePartialRequest']) {
    await this.apiClient
      .POST('/admin/wiki/{wikiName}/page-partial', {
        params: {
          path: {
            wikiName: this.wikiName,
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then((response) => {
        if (response.id) {
          this.pagePartialID = response.id || '';
        }
      });
  }

  async update(params: components['schemas']['v1EditWikiPagePartialRequest']) {
    await this.apiClient
      .PUT('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialName}', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialName: this.pagePartialID,
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
  }

  async save() {
    if (this.disabled) {
      return;
    }

    if (this.name === '') {
      this.setFlashMessage('danger', '記事部品名を入力してください');
      return;
    }

    this.disabled = true;

    const params = {
      name: this.name,
      body: this.text,
      columns: this.extractRequiredColumns(),
    };

    const isUpdate = !!this.pagePartialID;
    await (isUpdate ? this.update(params) : this.create(params))
      .then(async () => {
        this.partialName = this.name;
        this.partialText = this.text;

        if (this.draftId) {
          await this.deleteDraft();
        }

        this.router.push(
          {
            name: 'wiki_page_partial_list',
          },
          () => {
            if (isUpdate) {
              this.setFlashMessage('success', '記事部品を更新しました。');
            } else {
              this.setFlashMessage('success', '記事部品を保存しました。');
            }
          }
        );
      })
      .catch((e: Error) => {
        if (isUpdate) {
          const domains = extractNotAllowedDomainsFromErrorMessage(e.message);
          if (domains.length !== 0) {
            this.setFlashMessage('danger', 'これらのドメインは記事中に使用できません: ' + domains.join(', '));
            return;
          }
          this.setFlashMessage('danger', '記事部品の更新に失敗しました。');
        } else {
          const domains = extractNotAllowedDomainsFromErrorMessage(e.message);
          if (domains.length !== 0) {
            this.setFlashMessage('danger', 'これらのドメインは記事中に使用できません: ' + domains.join(', '));
            return;
          }
          this.setFlashMessage('danger', '記事部品の作成に失敗しました');
        }
      });

    this.disabled = false;
  }

  get isDraftContents() {
    return this.name === this.draftName && this.text === this.draftText;
  }

  async loadDraft() {
    await this.apiClient
      .GET('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialID}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialID: this.pagePartialID,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }
        return r.data;
      })
      .then((response) => {
        this.draftId = response.id || '';
        this.draftName = response.name || '';
        this.draftText = response.body || '';
        this.draftAuthorName = response.lastAuthor ? response.lastAuthor.nickname || '' : '';
        this.draftUpdatedAt = response.updatedAt ? new Date(response.updatedAt) : undefined;
        this.name = this.draftName || this.name;
        this.text = this.draftText || this.text;
      });
  }

  async saveAsDraft() {
    if (this.disabled) {
      return;
    }

    if (this.name === '') {
      this.setFlashMessage('danger', '記事部品名を入力してください');
      return;
    }

    if (!this.pagePartialID) {
      // 新規作成の場合、公開の確認モーダルを表示
      this.$refs.confirmPublishModal.show();
    } else {
      // 既存の場合は下書き保存を行う
      await this.performSaveAsDraft();
    }
  }

  async confirmPublish() {
    await this.performSaveAsDraft();
  }

  async performSaveAsDraft() {
    this.disabled = true;

    const params = {
      name: this.name,
      body: this.text,
      columns: this.extractRequiredColumns(),
    };

    await this.apiClient
      .POST('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialID}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialID: this.pagePartialID || '0',
          },
        },
        body: params,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(async (response) => {
        if (!this.pagePartial && response.wikiPagePartial && response.wikiPagePartial.id) {
          this.pagePartialID = response.wikiPagePartial.id;
          this.draftName = this.name;
          this.draftText = this.text;
          await this.$nextTick();
          this.router.push(
            {
              name: 'wiki_page_partial_edit',
              params: {
                name: this.wikiName,
                wikiPagePartialID: this.pagePartialID,
              },
            },
            () => {
              this.draftSaveSuccessfullProcess(response);
            }
          );
        } else {
          this.draftSaveSuccessfullProcess(response);
        }
      })
      .catch((e) => {
        this.setFlashMessage('danger', '下書きの保存に失敗しました。');
      })
      .finally(() => {
        this.disabled = false;
      });
  }

  draftSaveSuccessfullProcess(response: components['schemas']['v1WikiPagePartialDraft']) {
    this.draftId = response.id || '';
    this.draftName = response.name || '';
    this.draftText = response.body || '';
    this.draftAuthorName = response.lastAuthor ? response.lastAuthor.nickname || '' : '';
    this.draftUpdatedAt = response.updatedAt ? new Date(response.updatedAt) : undefined;
    this.$bvToast.toast(this.dateFormat(this.draftUpdatedAt as Date, 'HH:mm:ss に下書きを保存しました'), {
      title: '下書きを保存しました',
      autoHideDelay: 5000,
      variant: 'success',
    });
  }

  async deleteDraft() {
    await this.apiClient
      .DELETE('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialID}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialID: this.pagePartialID,
          },
        },
      })
      .then(() => {
        this.name = this.partialName;
        this.text = this.partialText;
        this.resetDraft();
        this.setFlashMessage('success', '下書きを削除しました。');
      })
      .catch(() => {
        this.setFlashMessage('danger', '下書きの削除に失敗しました。');
      });
  }

  resetDraft() {
    this.draftId = '';
    this.draftName = '';
    this.draftText = '';
    this.draftAuthorName = '';
    this.draftUpdatedAt = undefined;
  }

  showCheckDeleteDraftModal() {
    const modal = this.$refs.checkDeleteDraftModal;
    modal.show();
  }

  showCheckPublishModal() {
    if (this.name === '') {
      this.setFlashMessage('danger', '記事部品テンプレートの名前 を入力してください');
      return;
    }

    const modal = this.$refs.checkPublishModal;
    modal.show();
  }

  switchAutoDraftSave() {
    if (this.draftId === '') {
      this.saveAsDraft();
    }

    this.autoDraftSave = !this.autoDraftSave;
    if (!this.autoDraftSave) {
      this.disableAutoSave();
    }
  }

  disableAutoSave() {
    window.clearTimeout(this.autoSaveTimeoutID);
    this.autoSaveTimeoutID = 0;
    this.autoDraftSave = false;
  }

  extractRequiredColumns(): components['schemas']['v1ColumnNameListPerSheets'] {
    type SheetNameToIteratorNameMap = Map<string, string>;
    const extractSheetNameToIteratorNameMapFromEjs = (text: string): SheetNameToIteratorNameMap => {
      const sheetNameToIteratorNameMap: SheetNameToIteratorNameMap = new Map();

      const matchedTags = text.match(/<%\s+.*?\s+%>/g);
      if (!matchedTags) {
        return sheetNameToIteratorNameMap;
      }

      matchedTags.forEach((matchedTag) => {
        const matchedTexts = matchedTag.match(/[^\s]*\s+of\s+[^)\s]*/g);
        if (!matchedTexts) {
          return;
        }

        matchedTexts.forEach((matchedText) => {
          const iteratorNameAndSheetName = matchedText.split('of').map((t) => t.trim());
          sheetNameToIteratorNameMap.set(iteratorNameAndSheetName[1], iteratorNameAndSheetName[0]);
        });
      });

      return sheetNameToIteratorNameMap;
    };

    const requiredColumnsPerSheet: components['schemas']['v1ColumnNameListPerSheets'] = { sheets: {} };
    if (typeof requiredColumnsPerSheet.sheets === 'undefined') {
      return requiredColumnsPerSheet;
    }
    const sheetNameToIteratorNameMap = extractSheetNameToIteratorNameMapFromEjs(this.text);
    if (sheetNameToIteratorNameMap.size === 0) {
      return requiredColumnsPerSheet;
    }

    for (const [sheetName, iteratorName] of sheetNameToIteratorNameMap) {
      const matchArray = this.text.match(new RegExp(iteratorName + '\\.[^\\s]*[^);\\s]', 'g')) ?? [];
      const uniqueMatchArray = [...new Set(matchArray)];
      const columnNames = uniqueMatchArray.map((match) => match.split('.')[1]);

      requiredColumnsPerSheet.sheets[sheetName] = { columns: columnNames };
    }

    return requiredColumnsPerSheet;
  }

  isEditorClean(): boolean {
    return (
      (this.name === this.partialName && this.text === this.partialText) ||
      (this.name === this.draftName && this.text === this.draftText)
    );
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    if (this.isEditorClean()) {
      next();
      return;
    }

    if (window.confirm('変更が保存されていませんが、このページから離れますか？')) {
      next();
    }
  }

  setPartialDocumentTitle() {
    if (!this.partialName) {
      return;
    }

    this.setDocumentTitle(`${this.partialName} | ${this.routeDocumentTitle}`);
  }

  get pagePartial() {
    return this.route.params.wikiPagePartialID;
  }
}
