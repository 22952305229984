// 画像のみをアップロードする業務委託者向けの wiki で他メニューを隠す
// https://github.com/voyagegroup/lighthouse-studio/issues/3286

const wikiNameForContributors = 'kamigame-contributor';

export function hideMenuForContributors() {
  // メンバー管理が必要なとき等は kgstaff=true クエリパラメータをつけることでバイパスできるようにする
  if (location.search.includes('kgstaff=true')) {
    return;
  }

  if (!location.pathname.startsWith(`/${wikiNameForContributors}`)) {
    return;
  }

  // 「一括画像アップロード」ページ以外にアクセスしようとした場合は強制リダイレクトする
  if (!location.pathname.includes('/page/image-upload')) {
    location.pathname = `/${wikiNameForContributors}/page/image-upload`;
    return;
  }

  const nav = document.querySelector('ul.nav');
  if (!nav) {
    return;
  }

  let kijikanri = null;

  for (const child of nav.children) {
    const d = child.querySelector('div');
    if (!d) {
      continue;
    }

    // 「記事管理」以外のメニューを不可視にする
    const title = d.textContent?.trim();
    if (title === '記事管理') {
      kijikanri = child;
    } else {
      (child as HTMLElement).style.display = 'none';
    }
  }

  if (!kijikanri) {
    return;
  }

  const menus = kijikanri.querySelectorAll('.nav-item');
  for (const m of menus) {
    // 「画像一括アップロード」以外のメニューを不可視にする
    const title = m.textContent?.trim();
    if (title !== '画像一括アップロード') {
      (m as HTMLElement).style.display = 'none';
    }
  }
}
