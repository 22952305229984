
import Component from 'vue-class-component';
import LiquorTree from 'vls-liquor-tree';
import { Prop } from 'vue-property-decorator';

import KamigameVue from '@/KamigameVue';
import { components } from '@/api-client/schema';

@Component({
  name: 'kamigame-wiki-page-template-select-modal',
  components: {
    [LiquorTree.name]: LiquorTree,
  },
})
export default class WikiPageTemplateSelectModal extends KamigameVue {
  @Prop()
  modalRef!: string;

  @Prop()
  modalTitle!: string;

  filter = '';
  searchString = '';
  wikiPageTemplates: any[] = [];
  selectedWikiPageTemplate: components['schemas']['v1WikiPageTemplateTitleAndBody'] = {};
  wikiPageTemplatesOptions = {
    filter: {
      emptyText: '指定されたテンプレートが見つかりませんでした',
      matcher: (query: string, node: any) => node.text.includes(query),
    },
  };

  async created() {
    this.wikiPageTemplates = (
      (
        await this.apiClient
          .GET('/admin/wiki/{name}/template/title-bodies', {
            params: {
              path: {
                name: this.wikiName,
              },
            },
          })
          .then((r) => {
            if (r.error) {
              throw r.error;
            }

            return r.data;
          })
      ).templateTitleAndBodies || []
    ).map((e) => {
      return this.transformWikiPageTemplateTitleAndBodyToTreeItem(e);
    });
  }

  transformWikiPageTemplateTitleAndBodyToTreeItem(
    wikiPageTemplateTitleAndBody: components['schemas']['v1WikiPageTemplateTitleAndBody']
  ) {
    return {
      text: wikiPageTemplateTitleAndBody.title,
      data: wikiPageTemplateTitleAndBody,
    };
  }

  onWikiPageTemplateSelected(node: any) {
    this.searchString = node.text;
    this.selectedWikiPageTemplate = node.data;
  }

  onSelectionSaved() {
    const selected = this.wikiPageTemplateTree.selected();
    if (!selected) {
      return;
    }

    this.$emit('selectionSaved', this.selectedWikiPageTemplate);
    this.clear();
  }

  onSelectionCanceled() {
    this.clear();

    this.$emit('selectionCanceled');
  }

  onSearchStringChanged() {
    const input = this.$refs.input as Vue;
    if (document.activeElement !== input.$el) {
      return;
    }

    this.filter = this.searchString;
  }

  focusTree(event: KeyboardEvent) {
    if (event.isComposing) {
      return;
    }

    const firstNode = (this.$refs.wikiPageTemplatesTree as any).find({ state: { visible: true } })[0];
    if (!firstNode) {
      return;
    }

    firstNode.vm.focus();
  }

  clear() {
    this.filter = '';
    this.searchString = '';
    const selected = this.wikiPageTemplateTree.selected();
    if (selected) {
      selected.unselect();
    }
  }

  onShownModal() {
    (this.$refs.input as HTMLElement).focus();
  }

  get wikiPageTemplateTree() {
    return this.$refs.wikiPageTemplatesTree as any;
  }
}
