
import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';

import KamigameVue from '@/KamigameVue';
import { Paging } from '@/components';
import { components } from '@/api-client/schema';

type SortedBy = 'lastUpdatedAt' | 'name';
type SortOrder = 'ASC' | 'DESC';
type SortCondition = { sortedBy: SortedBy; sortOrder: SortOrder };

@Component({
  name: 'wiki-page-partial-draft-list',
  components: {
    'kamigame-paging': Paging,
  },
})
export default class WikiPagePartialDraftList extends KamigameVue {
  dateFormat = format;

  loading = false;

  drafts: components['schemas']['v1WikiPagePartialDraft'][] = [];
  fields = [
    { thStyle: { width: '50%' }, key: 'name', label: '記事名' },
    { key: 'lastUpdatedAt', label: '最終更新日時' },
    { key: 'lastAuthor', label: '最終更新者' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions: { text: string; value: SortCondition }[] = [
    { text: '最終更新日が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '最終更新日が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'タイトル順', value: { sortedBy: 'name', sortOrder: 'ASC' } },
  ];
  perPageNum = 20;
  inputWords = '';
  searchWords = '';
  selectedSortCondition: SortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  startAt = 0;

  async mounted() {
    this.loading = true;

    try {
      const res = await this.apiClient
        .GET('/admin/wiki/{wikiName}/page-partial/draft/list', {
          params: {
            path: {
              wikiName: this.wikiName,
            },
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }

          return r.data;
        });
      this.drafts = res.drafts || [];
    } catch (e: any) {
      if (e.code && e.code === 404) {
        this.drafts = [];
      }
    } finally {
      this.loading = false;
    }
  }

  async deleteDraft(wikiPagePartialID: string, index: number) {
    await this.apiClient
      .DELETE('/admin/wiki/{wikiName}/page-partial/{wikiPagePartialID}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            wikiPagePartialID,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
    this.drafts.splice(index, 1);
    this.setFlashMessage('success', '下書きを削除しました。');
  }

  showDeleteModal(index: number) {
    const modal = this.$refs[`deleteDraft_${index}`] as BModal;
    modal.show();
  }

  changeDataRange(startAt: number) {
    this.startAt = startAt;
  }

  get totalPageNum() {
    return this.drafts.length || 0;
  }

  get endAt() {
    return this.startAt + this.perPageNum;
  }

  get pagedDrafts() {
    const keywordFilter = (draft: components['schemas']['v1WikiPagePartialDraft']) => {
      return draft.name?.includes(this.searchWords);
    };

    const sortFn = (
      a: components['schemas']['v1WikiPagePartialDraft'],
      b: components['schemas']['v1WikiPagePartialDraft']
    ) => {
      const diff = {
        lastUpdatedAt: () => {
          if (!a.updatedAt || !b.updatedAt) {
            return 0;
          }

          return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
        },
        name: () => {
          if (!a.name || !b.name) {
            return 0;
          }

          return a.name.localeCompare(b.name);
        },
      }[this.selectedSortCondition.sortedBy]();

      return this.selectedSortCondition.sortOrder === 'ASC' ? diff : -diff;
    };

    return this.drafts.filter(keywordFilter).sort(sortFn);
  }
}
