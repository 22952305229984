import {
  specialMarkup,
  pagePartialRegexp,
  tableClassRegexp,
  relativeUrlRegexp,
  urlRegexp,
  spreadSheetFunction,
} from '@/lib/replace/regexp';
import { nanoid } from 'nanoid';

type Block = Record<string, string>;

export const replaceBlocks = (mdContent: string) => {
  // NOTE: 範囲が大きいものを先に置換すること
  const regexps = [
    specialMarkup,
    pagePartialRegexp,
    tableClassRegexp,
    relativeUrlRegexp,
    urlRegexp,
    spreadSheetFunction,
  ];

  const blocks: Block = {};
  let output = mdContent;

  for (const regexp of regexps) {
    output = output.replace(regexp, (match) => {
      const id = nanoid(5);
      blocks[id] = match;
      return `{{${id}}}`;
    });
  }

  return { replacedText: output, blocks };
};

const cssClassRegexp = /\[(.+?)\]([\s\S]*?)\[\/(\1)\]/g;

export const replaceCssClass = (markdownText: string) => {
  const blocks: Block = {};

  const output = markdownText.replace(cssClassRegexp, (match, left, center, right) => {
    if (left === right) {
      const ids = [nanoid(5), nanoid(5)];

      const leftBlock = `[${left}]`;
      const rightBlock = `[/${right}]`;

      blocks[ids[0]] = leftBlock;
      blocks[ids[1]] = rightBlock;

      return `{{${ids[0]}}}${center}{{${ids[1]}}}`;
    }

    return match;
  });

  return { replacedText: output, blocks };
};

export const restoreCssClass = (mdContent: string, blocks: Block) => {
  const placeholderRegex = /{{([A-Za-z0-9_-]{5})}}/g;
  return mdContent.replace(placeholderRegex, (match, id) => {
    return blocks[id] ?? match;
  });
};

export const restoreBlocks = (mdContent: string, blocks: Block): { result: string; isSuccess: boolean } => {
  const restoredBlocks: string[] = [];

  const placeholderRegex = /{{([A-Za-z0-9_-]{5})}}/g;
  const replaced = mdContent.replace(placeholderRegex, (match, id) => {
    if (!blocks[id]) {
      return match;
    }

    restoredBlocks.push(id);
    return blocks[id];
  });

  const nonRestoredBlocks = Object.keys(blocks).filter((id) => !restoredBlocks.includes(id));
  const nonRestoredValues = nonRestoredBlocks.map((id) => blocks[id]);

  if (nonRestoredBlocks.length > 0) {
    console.info('Not all blocks are restored');
    console.info(nonRestoredValues);
    return { result: replaced, isSuccess: false };
  }

  return { result: replaced, isSuccess: true };
};

export const replaceAndRestore = (text: string, converter: (text: string) => string) => {
  const { replacedText, blocks } = replaceBlocks(text);
  const restoredText = converter(replacedText);
  const { result, isSuccess } = restoreBlocks(restoredText, blocks);

  return { result, isSuccess };
};
