const GAMEVILLAGE_BUCKET_NAME = 'gv-wiki-state';

// TODO: ローカルの場合は実際にアップロードしないようにする

const getTransferredWikiStateObjectName = (wikiName: string) => {
  return `${process.env.NODE_ENV}-${location.hostname}-${wikiName}.json`;
};

export const fetchTransferredWikiStateForGameVillage = async (wikiName: string) => {
  const objectName = getTransferredWikiStateObjectName(wikiName);

  const response = await window.gapi.client.request({
    path: `https://storage.googleapis.com/storage/v1/b/${GAMEVILLAGE_BUCKET_NAME}/o/${objectName}?alt=media`,
    method: 'GET',
  });

  return response.result as TransferredWikiState;
};

export const insertTransferredWikiStateForGameVillage = async (wikiState: TransferredWikiState, wikiName: string) => {
  const objectName = getTransferredWikiStateObjectName(wikiName);

  await window.gapi.client.request({
    path: `https://storage.googleapis.com/upload/storage/v1/b/${GAMEVILLAGE_BUCKET_NAME}/o`,
    method: 'POST',
    params: { name: objectName, uploadType: 'media' },
    body: JSON.stringify(wikiState),
  });

  // NOTE: 状態ファイルは即時で最新状態を反映させたいためキャッシュさせない
  await window.gapi.client.request({
    path: `https://storage.googleapis.com/storage/v1/b/${GAMEVILLAGE_BUCKET_NAME}/o/${objectName}`,
    method: 'PUT',
    body: JSON.stringify({ cacheControl: 'private, no-store, no-cache, must-revalidate' }),
  });
};
