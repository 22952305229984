var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-row",
    [
      _c(
        "b-col",
        { attrs: { cols: "12" } },
        [
          _c(
            "b-card",
            { attrs: { "header-tag": "header", "footer-tag": "footer" } },
            [
              _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Chat GPT の校正"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "b-button",
                    { on: { click: _vm.proofreadText } },
                    [
                      _vm.isProofReading
                        ? _c("b-spinner", { attrs: { label: "Loading..." } })
                        : _vm._e(),
                      _vm._v("校正する"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("b-form-textarea", {
                        attrs: {
                          id: "textarea",
                          placeholder: "校正したい文章を入力",
                          rows: "10",
                          "max-rows": "10",
                        },
                        model: {
                          value: _vm.proofreadedText,
                          callback: function ($$v) {
                            _vm.proofreadedText = $$v
                          },
                          expression: "proofreadedText",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("b-form-textarea", {
                        attrs: { id: "textarea", rows: "10", "max-rows": "10" },
                        model: {
                          value: _vm.proofreadingResult,
                          callback: function ($$v) {
                            _vm.proofreadingResult = $$v
                          },
                          expression: "proofreadingResult",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }