
import KamigameVue from '@/KamigameVue';
import format from 'date-fns/format';
import Component from 'vue-class-component';

interface TableItem {
  _id: string;
  domainName: string;
  creatorNickname: string;
  createdAt: string;
}

@Component({
  name: 'wiki-domain-allowlist',
  components: {},
})
export default class WikiDomainAllowlist extends KamigameVue {
  tableFields = [
    { key: 'domainName', label: 'ドメイン名', sortable: true },
    { key: 'creatorNickname', label: '追加者', sortable: true },
    { key: 'createdAt', label: '追加日時', sortable: true },
    { key: '_operation', label: '操作' },
  ];
  tableItems: TableItem[] = [];
  sortBy = 'createdAt';
  sortDesc = true;

  inputDomain: string = '';

  async mounted(): Promise<void> {
    const listWikiDomainAllowlistResponse = await this.apiClient
      .GET('/admin/wiki/{name}/domain-allowlist', { params: { path: { name: this.wikiName } } })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r;
      })
      .catch(() => {
        this.$bvToast.toast(`ドメインリストの取得に失敗しました`, {
          title: '取得',
          autoHideDelay: 5000,
          variant: 'danger',
        });
      });
    if (!listWikiDomainAllowlistResponse || !listWikiDomainAllowlistResponse.data) {
      console.error('listWikiDomainAllowlistResponse is falsy');
      return;
    }

    listWikiDomainAllowlistResponse.data.wikiAllowedDomains.forEach((wikiAllowedDomain) => {
      const tableItem: TableItem = {
        _id: wikiAllowedDomain.id,
        domainName: wikiAllowedDomain.name,
        creatorNickname: wikiAllowedDomain.creator?.nickname ?? '',
        createdAt: format(new Date(wikiAllowedDomain.createdAt), 'yyyy/MM/dd HH:mm:ss'),
      };
      this.tableItems.push(tableItem);
    });
  }

  async handleAddDomain(): Promise<void> {
    if (this.inputDomain === '') {
      return;
    }

    const allowedDomainResp = await this.apiClient
      .POST('/admin/wiki/{wikiName}/domain-allowlist', {
        params: { path: { wikiName: this.wikiName } },
        body: { domain: this.inputDomain },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .catch((e: Error) => {
        console.error(e);
        if (e.message.includes('Duplicate entry')) {
          this.$bvToast.toast(`すでに登録されているドメインです`, {
            title: '追加',
            autoHideDelay: 5000,
            variant: 'warning',
          });
          return;
        }

        this.$bvToast.toast(`ドメインの追加に失敗しました`, {
          title: '追加',
          autoHideDelay: 5000,
          variant: 'danger',
        });
      });

    const allowedDomain = allowedDomainResp;
    if (!allowedDomain) {
      console.error('allowedDomain is falsy');
      return;
    }

    this.tableItems.push({
      _id: allowedDomain.id,
      domainName: allowedDomain.name,
      creatorNickname: allowedDomain.creator?.nickname ?? '',
      createdAt: format(new Date(allowedDomain.createdAt), 'yyyy/MM/dd HH:mm:ss'),
    });
    this.inputDomain = '';
    this.$bvToast.toast(`「${allowedDomain.name}」を追加しました`, {
      title: '追加',
      autoHideDelay: 5000,
      variant: 'success',
    });
  }

  async handleRemoveDomain(id: string): Promise<void> {
    const deletedItem = this.tableItems.find((tableItem) => tableItem._id === id);
    if (!deletedItem) {
      console.error('deletedItem is falsy');
      return;
    }

    const deleteWikiDomainAllowlistResponse = await this.apiClient
      .DELETE('/admin/wiki/{wikiName}/domain-allowlist/{id}', { params: { path: { wikiName: this.wikiName, id } } })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r;
      })
      .catch(() => {
        this.$bvToast.toast(`ドメインの削除に失敗しました`, {
          title: '削除',
          autoHideDelay: 5000,
          variant: 'danger',
        });
      });
    if (!deleteWikiDomainAllowlistResponse) {
      console.error('deleteWikiDomainAllowlistResponse is falsy');
      return;
    }

    this.tableItems = this.tableItems.filter((tableItem) => tableItem._id !== id);
    this.$bvToast.toast(`「${deletedItem.domainName}」を削除しました`, {
      title: '削除',
      autoHideDelay: 5000,
      variant: 'success',
    });
  }
}
