var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c(
                  "b-card",
                  { attrs: { "header-tag": "header", "footer-tag": "footer" } },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c("strong", [_vm._v("アーカイブ設定")]),
                    ]),
                    _vm._v(" "),
                    _vm.isArchived
                      ? _c("div", { staticClass: "mb-3" }, [
                          _vm._v(
                            "この Wiki を管理画面の Wiki 一覧に表示するようにします"
                          ),
                        ])
                      : _c("div", { staticClass: "mb-3" }, [
                          _vm._v(
                            "この Wiki を管理画面の Wiki 一覧から非表示にします"
                          ),
                        ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { slot: "footer" }, slot: "footer" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "success" },
                            on: { click: _vm.toggleArchive },
                          },
                          [_vm._v("実行する")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }