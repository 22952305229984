
import Component from 'vue-class-component';
import KamigameVue from '@/KamigameVue';
import FileUploadWithPreview from 'file-upload-with-preview';
import storage from 'local-storage-fallback';
import { LOCAL_STORAGE_KEYS } from '@/constants';

@Component({
  name: 'image-uploader-modal',
})
export default class ImageUploaderModal extends KamigameVue {
  imagePreview: FileUploadWithPreview | null = null;
  uploadImageTitle = '';
  imageFile: File | null = null;
  isPopupImage = storage.getItem(LOCAL_STORAGE_KEYS.IS_POPUP_IMAGE_INSERT_MODE) === 'true';

  get imageUploadingDisabled() {
    return this.uploadImageTitle === '' || this.imageFile === null;
  }

  mounted() {
    this.imagePreview = new FileUploadWithPreview('imageUpload');
  }

  selected() {
    if (this.imageFile) {
      this.$emit('selected', { title: this.uploadImageTitle, file: this.imageFile });
    }
    this.hide();
  }

  clearImage() {
    this.imageFile = null;
    this.imagePreview!.clearPreviewPanel();
  }

  reset() {
    this.uploadImageTitle = '';
    this.clearImage();
  }

  onImageUploaded(image: any) {
    if (image.target && image.target.files && image.target.files.length > 0) {
      if (this.uploadImageTitle === '') {
        const name: string = image.target.files[0].name;
        this.uploadImageTitle = name.replace(/\.[^/.]+$/, '');
      }
      this.imageFile = image.target.files[0];
    }
  }

  onPopupImageChanged() {
    storage.setItem(LOCAL_STORAGE_KEYS.IS_POPUP_IMAGE_INSERT_MODE, this.isPopupImage ? 'true' : 'false');
  }

  show() {
    const imageUploaderModal = this.$refs.imageUploaderModal as any;
    imageUploaderModal.show();
  }

  hide() {
    this.reset();
    const imageUploaderModal = this.$refs.imageUploaderModal as any;
    imageUploaderModal.hide();
  }
}
