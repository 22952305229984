import * as byteData from 'byte-data';
import encoding from 'text-encoding';

export function convertStringToUTF16LEArray(str: string): Uint8Array {
  const byteArray = [
    255,
    254, // BOM
  ];

  for (let i = 0; i < str.length; i++) {
    byteArray.push(...byteData.pack(str.charCodeAt(i), { bits: 16 }));
  }

  return new Uint8Array(byteArray);
}

export function convertUTF16LEArrayToString(byteArray: Uint8Array): string {
  return byteData.unpackArray(byteArray, { bits: 16 }).reduce((a, c) => a + String.fromCharCode(c), '');
}

export function convertUTF8ArrayToString(byteArray: Uint8Array): string {
  return new encoding.TextDecoder('utf-8').decode(byteArray);
}

export function convertToCssUnicodeCodePoint(s: string): string {
  return [...s].map((c) => '\\' + c.charCodeAt(0).toString(16).padStart(4, '0')).join('');
}
