import { applyOCRToRegions } from '@/lib/image-scanner';
import { loadImage } from './loadImage';
import { OcrResult, SectionValue } from './MainImageModal.vue';

type OcrErr = {
  msgs: string[];
  files: File[];
};

export const runOCR = async (
  files: File[],
  regions: Record<string, [number, number, number, number, number]>,
  signal: AbortSignal
) => {
  const ocrErr: OcrErr = {
    msgs: [],
    files: [],
  };

  const results = await Promise.all(
    files.map(async (file: File) => {
      try {
        const image = await loadImage(file);
        return await applyOCRToRegions(image, regions, signal);
      } catch (e) {
        if (e instanceof DOMException && e.name === 'AbortError') {
          return {
            result: false,
            ocr: {},
          };
        }
        ocrErr.msgs.push(`${file.name}のOCR実行中にエラーが発生しました。`);
        ocrErr.files.push(file);

        const failedOcr: Record<string, { best_threshold: number; text: string }> = {};
        Object.keys(regions).forEach((key) => {
          failedOcr[key] = {
            best_threshold: 0,
            text: '処理エラー',
          };
        });

        return {
          result: false,
          ocr: { ...failedOcr },
        };
      }
    })
  );

  return {
    results,
    ocrErr,
  };
};

export const adjustOcrResults = (ocrResults: OcrResult[], sectionValues: SectionValue[]): Record<string, string>[] => {
  return ocrResults.map((result) => {
    const adjustedOcrResult: Record<string, string> = {};
    Object.keys(result.ocr).forEach((key, i) => {
      adjustedOcrResult[sectionValues[i].name] = result.ocr[key].text;
    });
    return adjustedOcrResult;
  });
};
