var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal.modal-copy-wiki",
              modifiers: { "modal-copy-wiki": true },
            },
          ],
          attrs: { block: "" },
        },
        [_vm._v("Wiki コピー")]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalCopyWiki",
          attrs: {
            id: "modal-copy-wiki",
            title: "Wiki コピー",
            size: "lg",
            "no-close-on-backdrop": "",
            static: "",
          },
          on: { ok: _vm.clickButtonOK },
        },
        [
          _c("div", { staticClass: "danger-announce" }, [
            _c("strong", [
              _vm._v(
                "🚨コピー後、元の状態には戻せません（手動で戻す必要があります）🚨"
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      ・別 Wiki の（記事・カテゴリ・記事部品・記事テンプレート）をコピーします"
            ),
            _c("br"),
            _vm._v("\n      ・コピーした記事は下書きとして保存されます"),
            _c("br"),
            _vm._v(
              "\n      ・この Wiki の（攻略トップ・雑談掲示板）の下書きはコピーした内容で置き換えられます\n    "
            ),
          ]),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _c(
            "p",
            [
              _vm._v(
                "\n      コピー元の Wiki 名 (例：管理画面 URL /shinki-cs/page の shink-cs の部分)\n      "
              ),
              _c("b-form-input", {
                attrs: { placeholder: _vm.sourceWikiName },
                model: {
                  value: _vm.sourceWikiName,
                  callback: function ($$v) {
                    _vm.sourceWikiName = $$v
                  },
                  expression: "sourceWikiName",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalLoadingCopyWiki",
          attrs: { "hide-header": "", "hide-footer": "", static: "" },
        },
        [
          _c(
            "div",
            { staticClass: "text-center" },
            [
              _c("p", [
                _vm._v(_vm._s(_vm.sourceWikiName) + " の内容をコピー中です"),
              ]),
              _vm._v(" "),
              _c("b-spinner", {
                staticStyle: { width: "3rem", height: "3rem" },
                attrs: { label: "Large Spinner" },
              }),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalError",
          attrs: {
            title: "エラー",
            size: "lg",
            "no-close-on-backdrop": "",
            static: "",
          },
        },
        [_c("pre", [_vm._v(_vm._s(_vm.errorMessage))])]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }