import firebase from 'firebase/app';
import 'firebase/database';

if (typeof FIREBASE_APP_ID !== 'undefined' && !firebase.apps.length) {
  const firebaseConfig = {
    apiKey: FIREBASE_API_KEY,
    databaseURL: `https://${FIREBASE_REALTIME_DATABASE_HOST_FOR_ADMIN_SERVICE}`,
    projectId: GCP_PROJECT_ID,
    appId: FIREBASE_APP_ID,
  };
  firebase.initializeApp(firebaseConfig);

  if (location.hostname === 'localhost') {
    firebase.database().useEmulator('localhost', 9000);
  }
}

export default firebase;
