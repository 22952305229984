function kamigameLightModeExtension(editor: any) {
  let isActive = false;
  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem({
    type: 'button',
    options: {
      name: 'toggle-light-mode',
      className: `tui-editor-custom-button tui-lightmode ${isActive ? 'active' : ''}`,
      command: 'toggle-light-mode',
      event: 'toggle-light-mode',
      tooltip: '軽量モードの切り替え',
    },
  });

  const items = toolbar.getItems();
  const button = items.filter((v: any) => v.getName() === 'toggle-light-mode').pop();
  editor.eventManager.addEventType('toggle-light-mode');
  editor.commandManager.addCommand('markdown', {
    name: 'toggle-light-mode',
    exec: () => {
      isActive = !isActive;
      isActive ? button.el.classList.add('active') : button.el.classList.remove('active');
      editor.eventManager.emit('toggle-light-mode', isActive);
    },
  });
}

export default kamigameLightModeExtension;
