var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "table",
    { staticClass: "table" },
    [
      _vm._t("header"),
      _vm._v(" "),
      _c(
        "draggable",
        {
          attrs: { element: "tbody" },
          on: { change: _vm.onChange },
          model: {
            value: _vm.list,
            callback: function ($$v) {
              _vm.list = $$v
            },
            expression: "list",
          },
        },
        _vm._l(_vm.list, function ([id, data], index) {
          return _c(
            "tr",
            { key: id },
            [_vm._t("row", null, { id: id, data: data, index: index })],
            2
          )
        }),
        0
      ),
      _vm._v(" "),
      _vm._t("footer"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }