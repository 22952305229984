var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn wiki-page-import" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("strong", [_vm._v("テンプレートコード実行")]),
                ]),
                _vm._v(" "),
                _c(
                  "p",
                  [
                    _c("b-button", { on: { click: _vm.fetchSpreadsheet } }, [
                      _vm._v(
                        "スプレッドシートからデータを取得し、テンプレートコードを実行する"
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("b-table", {
                  attrs: {
                    striped: "",
                    hover: "",
                    fields: _vm.fields,
                    items: _vm.spreadsheetsValue,
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell(lastUpdatedAt)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            _vm._s(
                              data.item.lastUpdatedAt
                                ? _vm.dateFormat(
                                    new Date(data.item.lastUpdatedAt),
                                    "yyyy/MM/dd HH:mm:ss"
                                  )
                                : "-"
                            )
                          ),
                        ]
                      },
                    },
                    {
                      key: "cell(error)",
                      fn: function (data) {
                        return [
                          _vm._v(
                            _vm._s(
                              data.item.error
                                ? `⚠️スプレッドシートの取得中にエラーが発生しました (${data.item.error.message})`
                                : ""
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                }),
                _vm._v(" "),
                _c(
                  "b-modal",
                  {
                    ref: "fetchingSpreadsheetModal",
                    attrs: { "hide-header": "", "hide-footer": "", static: "" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "text-center" },
                      [
                        _c("p", [_vm._v("シートの情報を取得中です")]),
                        _vm._v(" "),
                        _c("b-spinner", {
                          staticStyle: { width: "3rem", height: "3rem" },
                          attrs: { label: "Large Spinner" },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }