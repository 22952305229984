import createClient from 'openapi-fetch';
import type { paths } from '../api-client/schema';

const anonymousToken = 'anonymous';

// 単純に ReturnType<typeof createClient<paths>> 等としてもうまく型が定義できないため、ジェネリクス関数の返り値の型を取得するためのハック
// https://knmts.com/as-a-engineer-97/
class CreateClientWrapper<T extends {}> {
  func = () => createClient<T>();
}

export type APIClient = ReturnType<CreateClientWrapper<paths>['func']>;

export function createAPIClient(options: { uri?: string; token?: string }): APIClient {
  const token = options.token || anonymousToken;
  const url = `${options.uri || API_URL_BASE}/`;
  const client = createClient<paths>({
    baseUrl: url,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return client;
}
