var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      ref: "imageUploaderModal",
      class: { "kamigame-file-draggable": true },
      attrs: { centered: "", static: "", title: "画像をアップロード" },
    },
    [
      _c(
        "div",
        { staticClass: "mb-3" },
        [
          _c("label", { attrs: { for: "imageTitle" } }, [
            _vm._v("画像のキャプション"),
          ]),
          _vm._v(" "),
          _c("b-form-input", {
            attrs: {
              state: _vm.uploadImageTitle !== "",
              id: "imageTitle",
              placeholder: "画像のキャプションを入力してください。",
            },
            model: {
              value: _vm.uploadImageTitle,
              callback: function ($$v) {
                _vm.uploadImageTitle = $$v
              },
              expression: "uploadImageTitle",
            },
          }),
          _vm._v(" "),
          _c(
            "b-form-invalid-feedback",
            { attrs: { state: _vm.uploadImageTitle !== "" } },
            [_vm._v("キャプションは必須です")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "custom-file-container",
          attrs: { "data-upload-id": "imageUpload" },
        },
        [
          _c("label", [
            _vm._v("画像\n      "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imageFile !== null,
                    expression: "imageFile !== null",
                  },
                ],
              },
              [
                _vm._v("("),
                _c(
                  "a",
                  {
                    staticClass: "custom-file-container__image-clear",
                    attrs: { href: "javascript:void(0)" },
                    on: { click: _vm.clearImage },
                  },
                  [_vm._v("画像の選択を解除")]
                ),
                _vm._v(")"),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("label", { staticClass: "custom-file-container__custom-file" }, [
            _c("input", {
              staticClass:
                "custom-file-container__custom-file__custom-file-input",
              attrs: {
                id: "imageUploadFileInput",
                type: "file",
                accept: "image/png,image/jpeg,image/gif",
              },
              on: { change: _vm.onImageUploaded },
            }),
            _vm._v(" "),
            _c("span", {
              staticClass:
                "custom-file-container__custom-file__custom-file-control",
            }),
          ]),
          _vm._v(" "),
          _c("b-form-text", [
            _vm._v("5 MB 以下 の JPEG, PNG, GIF(アニメーションGIF含む) のみ"),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "custom-file-container__image-preview article-image",
          }),
          _vm._v(" "),
          _c(
            "b-form-checkbox",
            {
              on: { change: _vm.onPopupImageChanged },
              model: {
                value: _vm.isPopupImage,
                callback: function ($$v) {
                  _vm.isPopupImage = $$v
                },
                expression: "isPopupImage",
              },
            },
            [_vm._v("拡大タグで挿入する")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("template", { slot: "modal-footer" }, [
        _c(
          "div",
          [
            _c("b-button", { on: { click: _vm.hide } }, [_vm._v("キャンセル")]),
            _vm._v(" "),
            _c(
              "b-button",
              {
                attrs: {
                  variant: "primary",
                  disabled: _vm.imageUploadingDisabled,
                },
                on: { click: _vm.selected },
              },
              [_vm._v("この画像を記事に挿入")]
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }