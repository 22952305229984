import axios, { AxiosInstance } from 'axios';

import {
  WikiPageAdvice,
  convertAdviceFromApiToModel,
  WikiPageAdviceApi,
} from '@/comment-api-client/models/WikiPageAdvice';

interface KamigameCommentApiClientOptions {
  baseUri?: string;
}

export class KamigameCommentApiClient {
  instance: AxiosInstance;

  constructor(options: KamigameCommentApiClientOptions) {
    this.instance = axios.create({
      baseURL: options.baseUri,
    });
  }

  async listWikiPageAdvice(game: string, isHandled: boolean = false): Promise<WikiPageAdvice[]> {
    const data = (await this.instance.get(`/article-advice?game=${game}&is_handled=${isHandled}`))
      .data as WikiPageAdviceApi[];
    if (!data || !Array.isArray(data)) {
      return data;
    }

    return data.map((d) => convertAdviceFromApiToModel(d));
  }

  async handleWikiPageAdvice(id: string): Promise<void> {
    await this.instance.put(`/article-advice/${id}/handle`);
  }
}
