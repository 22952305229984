
import Component from 'vue-class-component';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import { SortableTable } from '@/components';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';

type Item = {
  title: string;
  word: string;
  description: string;
  url: string;
};

@Component({
  name: 'wiki-tooltip',
  components: {
    'kamigame-sortable-table': SortableTable,
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiTooltip extends KamigameVue {
  savedItems: [symbol, Item][] = [];
  items: [symbol, Item][] = [];

  async mounted() {
    try {
      const tooltipResponses = await this.apiClient
        .GET('/wiki/{name}/tooltip', {
          params: {
            path: {
              name: this.wikiName,
            },
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }

          return r.data;
        });
      if (typeof tooltipResponses.tooltip === 'undefined') {
        throw new Error('tooltip is undefined');
      }

      tooltipResponses.tooltip?.forEach((tooltip) => {
        this.items.push([
          Symbol(),
          {
            title: tooltip.title || '',
            word: tooltip.word || '',
            description: tooltip.description || '',
            url: tooltip.url || '',
          },
        ]);
      });
      this.savedItems = JSON.parse(JSON.stringify(this.items));
    } catch (e) {
      this.setFlashMessage('danger', 'データの取得に失敗しました。通信環境などを確認してみてください。');
    }
  }

  addRow() {
    this.items.push([Symbol(), { title: '', word: '', description: '', url: '' }]);
  }

  removeRow(id: symbol) {
    this.items = this.items.filter(([itemId]) => itemId !== id);
  }

  async save() {
    const errors: string[] = [];
    this.items.forEach(([id, item]) => {
      const index = this.items.findIndex(([itemId]) => itemId === id);
      if (item.title === '') {
        errors.push(`${index + 1}行目の「タイトル」が空です。`);
      }
      if (item.word === '') {
        errors.push(`${index + 1}行目の「単語」が空です。`);
      }
      if (item.word.length < 2) {
        errors.push(`${index + 1}行目の「単語」が短すぎます。`);
      }
      if (item.description === '') {
        errors.push(`${index + 1}行目の「説明」が空です。`);
      }
      const duplicateIndex = this.items.findIndex(([itemId, item2]) => itemId !== id && item2.word === item.word);
      if (duplicateIndex !== -1) {
        errors.push(`${index + 1}行目の「単語」が${duplicateIndex + 1}行目と重複しています。`);
      }
    });
    if (errors.length > 0) {
      for (const error of errors) {
        this.setFlashMessage('warning', error);
      }
      return;
    }
    try {
      await this.apiClient
        .PUT('/admin/wiki/{wiki.name}/tooltip', {
          params: {
            path: {
              'wiki.name': this.wikiName,
            },
          },
          body: {
            tooltip: this.items.map(([, item]) => item),
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }
        });
      this.setFlashMessage('success', '保存しました。');
      this.savedItems = JSON.parse(JSON.stringify(this.items));
    } catch (e) {
      this.setFlashMessage('danger', '保存に失敗しました。通信環境などを確認してみてください。');
    }
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    if (this.items.length !== this.savedItems.length) {
      return true;
    }
    for (let i = 0; i < this.items.length; ++i) {
      if (
        this.items[i][1].title !== this.savedItems[i][1].title ||
        this.items[i][1].word !== this.savedItems[i][1].word ||
        this.items[i][1].description !== this.savedItems[i][1].description ||
        this.items[i][1].url !== this.savedItems[i][1].url
      ) {
        return true;
      }
    }
    return false;
  }

  isNotEmpty(word: string) {
    return word !== '';
  }
}
