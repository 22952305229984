
import Component from 'vue-class-component';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';

@Component({
  name: 'wiki-publish-config',
  components: {
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiAllowedIPAddress extends KamigameVue {
  allowedIPAddress: string = '';
  savedAllowedIPAddress: string = '';

  mounted() {
    this.apiClient.GET('/admin/wiki/{name}/allowed-ip', { params: { path: { name: this.wikiName } } }).then((r) => {
      if (r.error) {
        throw r.error;
      }

      this.allowedIPAddress = r.data.ipAddress || '';
      this.savedAllowedIPAddress = this.allowedIPAddress;
    });
  }

  save() {
    this.apiClient
      .PUT('/admin/wiki/{wiki.name}/allowed-ip', {
        params: { path: { 'wiki.name': this.wikiName } },
        body: { ipAddress: this.allowedIPAddress },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        this.savedAllowedIPAddress = this.allowedIPAddress;
        this.setFlashMessage('success', '変更を保存しました。');
      })
      .catch(() => {
        this.setFlashMessage('danger', '保存に失敗しました。');
      });
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    return this.allowedIPAddress !== this.savedAllowedIPAddress;
  }
}
