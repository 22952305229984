
import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-access-analytics',
})
export default class WikiAccessAnalytics extends KamigameVue {
  analyticsData?: components['schemas']['v1WikiAccessAnalytics'] = {};
  analyticsChart: any;
  analyticsIdPrefix: string = 'analyticsChartDays';
  analyticsDurations: number[] = [7, 30, 60, 90];

  async mounted() {
    const api = this.apiClient;
    this.analyticsData = (
      await api.GET('/admin/wiki/{name}/access-analytics', {
        params: { path: { name: this.wikiName } },
      })
    ).data;
    if (!this.analyticsData || !this.analyticsData.AnalyticsEntries) {
      return;
    }

    this.analyticsData.AnalyticsEntries.reverse();
    this.createAnalyticsChart();
  }

  createAnalyticsChart() {
    this.analyticsDurations.forEach((analyticsDuration) => {
      if (!this.analyticsData?.AnalyticsEntries) {
        return;
      }

      const canvas = <HTMLCanvasElement>this.$el.querySelector(`#${this.analyticsIdPrefix}${analyticsDuration}`);
      const ctx = canvas.getContext('2d');
      if (!ctx) {
        return;
      }

      const entries =
        this.analyticsData.AnalyticsEntries.slice(-analyticsDuration) || this.analyticsData.AnalyticsEntries;

      // Chart.js のアップデートに追従できていなかったので以下のコミットで実装を削除
      // https://github.com/voyagegroup/kamigame-wiki/commit/18279ad0c84e43263e78344dd6990057488ae7f9
      // TODO: Chart.js で描画する
    });
  }
}
