
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { components } from '@/api-client/schema';

@Component
export default class WikiDataSearchAndSelect extends Vue {
  @Prop({ default: () => [] }) readonly sheets!: components['schemas']['v1WikiDataSheet'][];
  @Prop({ default: () => [] }) selectedSheetIds!: string[];

  searchQuery: string = '';
  isInitialLoad: boolean = true;

  @Watch('sheets')
  onSheetsChanged() {
    if (this.isInitialLoad) {
      this.selectAll();
      this.isInitialLoad = false;
    }
  }

  get filteredSheets(): components['schemas']['v1WikiDataSheet'][] {
    if (!this.searchQuery) {
      return this.sheets;
    }
    return this.sheets.filter((sheet) => {
      return sheet.sheetName && sheet.sheetName.toLowerCase().includes(this.searchQuery.toLowerCase());
    });
  }

  isSheetFiltered(sheet: components['schemas']['v1WikiDataSheet']): boolean {
    return this.filteredSheets.includes(sheet);
  }

  onSelectedSheetIdsChanged() {
    this.updateSelectedSheetIds();
  }

  selectAll() {
    const filteredSheetIds = this.filteredSheets.map((sheet) => sheet.id).filter((id): id is string => !!id);
    this.selectedSheetIds = Array.from(new Set([...this.selectedSheetIds, ...filteredSheetIds]));
    this.updateSelectedSheetIds();
  }

  clearSelection() {
    const filteredSheetIds = this.filteredSheets.map((sheet) => sheet.id).filter((id): id is string => !!id);
    this.selectedSheetIds = this.selectedSheetIds.filter((id) => !filteredSheetIds.includes(id));
    this.updateSelectedSheetIds();
  }

  updateSelectedSheetIds() {
    this.$emit('update:selectedSheetIds', this.selectedSheetIds);
  }
}
