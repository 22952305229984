// ejs.min.cjs を Webpack 環境でバンドルしようとすると ejs.min.cjs 内で使われている require 文が静的に解決できずバンドルに失敗する
// このため、Webpack 環境（admin）では ejs をグローバルに読み込み、Node.js 環境（wiki）では import するような作りにしている

let ejsForNode = null;

export const loadEjsForNode = async () => {
  if (typeof window !== 'undefined') {
    throw new Error('loadEjsForNode must be called in Node.js environment');
  }

  if (ejsForNode) {
    return;
  }

  ejsForNode = await import(/* webpackIgnore: true */ './ejs.min.cjs');
};

class PagePartialSheetData {
  constructor(condition, body, data) {
    this.condition = condition;
    this.body = body;
    this.data = data;
  }

  render() {
    if (typeof window !== 'undefined') {
      // ブラウザ環境では事前にグローバルに ejs を読み込んでおく前提
      if (!window.ejs) {
        throw new Error('ejs not loaded. Please load ejs before using PagePartialSheetData.render()');
      }
      return window.ejs.render(this.body, this.data || null);
    } else {
      // Node.js 環境では import された ejs を使う
      if (!ejsForNode) {
        throw new Error('ejs not loaded. Please call loadEjsForNode before using PagePartialSheetData.render()');
      }

      return ejsForNode.render(this.body, this.data || null);
    }
  }

  createTemplateLiteral() {
    return `{template ${this.condition}}`;
  }
}

export default PagePartialSheetData;
