var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c(
                  "b-card",
                  { attrs: { "header-tag": "header", "footer-tag": "footer" } },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c("i", { staticClass: "fa fa-align-justify" }),
                      _vm._v(" "),
                      _c("strong", [_vm._v("作業レポート出力")]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-row col-md-6" }, [
                      _c(
                        "div",
                        { staticClass: "form-group col-md-6" },
                        [
                          _c("label", [_vm._v("開始日")]),
                          _vm._v(" "),
                          _c("b-form-input", {
                            attrs: { type: "date" },
                            model: {
                              value: _vm.startDate,
                              callback: function ($$v) {
                                _vm.startDate = $$v
                              },
                              expression: "startDate",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "form-group col-md-6" },
                        [
                          _c("label", [_vm._v("終了日")]),
                          _vm._v(" "),
                          _c("b-form-input", {
                            attrs: { type: "date" },
                            model: {
                              value: _vm.endDate,
                              callback: function ($$v) {
                                _vm.endDate = $$v
                              },
                              expression: "endDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-group col-md-6" },
                      [
                        _c("label", [_vm._v("対象ユーザー")]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          { staticClass: "user-list" },
                          _vm._l(_vm.selectedUsers, function (selectedUser) {
                            return _c(
                              "li",
                              { key: selectedUser.id },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(selectedUser.nickname) +
                                    "\n                "
                                ),
                                _c(
                                  "b-link",
                                  {
                                    staticStyle: { color: "red" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeConditonedUser(
                                          selectedUser.id
                                        )
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fa fa-close" })]
                                ),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c("b-form-select", {
                          attrs: { options: _vm.userOptions },
                          on: { input: _vm.addUserCondition },
                          model: {
                            value: _vm.selectedUserID,
                            callback: function ($$v) {
                              _vm.selectedUserID = $$v
                            },
                            expression: "selectedUserID",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { attrs: { slot: "footer" }, slot: "footer" },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "success" },
                            on: { click: _vm.download },
                          },
                          [_vm._v("CSV をダウンロードする")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }