var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _vm._t("data-list"),
      _vm._v(" "),
      _c("b-pagination", {
        ref: "pagenateComponent",
        staticClass: "my-0",
        attrs: {
          align: "center",
          "total-rows": _vm.dataLength,
          "per-page": _vm.perPage,
        },
        on: { input: _vm.onPaginationNumChanged },
        model: {
          value: _vm.currentPage,
          callback: function ($$v) {
            _vm.currentPage = $$v
          },
          expression: "currentPage",
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }