import axios from 'axios';
import { ImageUrlExtractionResult, SectionValue } from './MainImageModal.vue';
import { loadImage } from './loadImage';
import { wikiGetImageWithServingURL } from '@/service/WikiGetImageWithServingURL';
import { APIClient } from '@/service/WikiAPIClientFactory';

type ImageData = {
  id: string;
  name: string;
  url: string;
  wikiId: string;
};

export const runImageUrlExtraction = async (
  token: string,
  wikiName: string,
  ignoreOptimizeImage: boolean,
  files: File[],
  regions: Record<string, [number, number, number, number, number]>,
  api: APIClient
) => {
  const allResult: ImageUrlExtractionResult[] = [];
  for (const file of files) {
    const image = await loadImage(file);
    const resizedImages = resizeImages(image, regions);
    const imageResults = await wikiImageUpload(token, wikiName, ignoreOptimizeImage, resizedImages);
    const result = await fetchImageDetails(api, imageResults);

    allResult.push({
      imageUrlExtraction: result,
    });
  }

  return allResult;
};

const resizeImages = (image: HTMLImageElement, regions: Record<string, [number, number, number, number, number]>) => {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  canvas.width = image.width;
  canvas.height = image.height;
  ctx.drawImage(image, 0, 0, image.width, image.height);

  const modifiedImages: File[] = [];

  for (const [key, region] of Object.entries(regions)) {
    let [x, y, w, h] = region;

    const imageData = ctx.getImageData(x, y, w, h);
    const modified = document.createElement('canvas');
    const modifiedCtx = modified.getContext('2d') as CanvasRenderingContext2D;
    modified.width = imageData.width;
    modified.height = imageData.height;

    const clonedImageData = new ImageData(new Uint8ClampedArray(imageData.data), imageData.width, imageData.height);
    modifiedCtx.putImageData(clonedImageData, 0, 0);

    modifiedImages.push(toFileObjFromBase64(modified.toDataURL('image/png'), `${key}`));
  }

  return modifiedImages;
};

const toFileObjFromBase64 = (base64: string, name: string) => {
  const bin = atob(base64.replace(/^.*,/, ''));

  const buffer = new Uint8Array(bin.length);
  for (var i = 0; i < bin.length; i++) {
    buffer[i] = bin.charCodeAt(i);
  }

  return new File([buffer.buffer], name, { type: 'image/png' });
};

const wikiImageUpload = (token: string, wikiName: string, ignoreOptimizeImage: boolean, files: File[]) => {
  const data = new FormData();
  data.append('ignoreOptimizeImage', ignoreOptimizeImage ? 'true' : 'false');
  for (let i = 0; i < files.length; i++) {
    data.append('image', files[i]);
  }

  return ImageUpload(`Bearer ${token}`, `${API_URL_BASE}/admin/wiki/${wikiName}/image/upload`, data);
};

const ImageUpload = async (authorization: string, url: string, data: FormData) => {
  const res = await axios({
    method: 'POST',
    url,
    headers: {
      Authorization: authorization,
      'Content-Type': 'multipart/form-data',
    },
    data,
  });

  const result: Array<ImageData> = Object.values((res.data as any).images);

  return result;
};

const fetchImageDetails = async (
  api: APIClient,
  imageResults: Array<ImageData>
): Promise<Record<string, ImageData>> => {
  const imagePromises = imageResults.map(async (imageData, i) => {
    const image = await wikiGetImageWithServingURL(api, imageData.id);
    const url = encodeURI(image.url || '');
    return {
      id: imageData.id,
      name: imageData.name,
      url: url,
      wikiId: imageData.wikiId,
    };
  });

  const resolvedImages = await Promise.all(imagePromises);

  const result: Record<string, ImageData> = {};
  resolvedImages.forEach((image, i) => {
    result[`imageUrlExtraction${i}`] = image;
  });

  return result;
};

export const adjustImageUrlExtractionResults = (
  imageUrlExtractionResults: ImageUrlExtractionResult[],
  sectionValues: SectionValue[]
) => {
  return imageUrlExtractionResults.map((result, i) => {
    const adjustImageRecognitionResult: Record<string, string> = {};
    Object.keys(result.imageUrlExtraction).forEach((key, i) => {
      adjustImageRecognitionResult[sectionValues[i].name] = result.imageUrlExtraction[key].url;
    });
    return adjustImageRecognitionResult;
  });
};
