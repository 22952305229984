
import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';
import { Paging } from '@/components';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-page-search',
  components: {
    'kamigame-paging': Paging,
  },
})
export default class WikiPageSearch extends KamigameVue {
  fields = [
    { thStyle: { width: '25%' }, key: 'title', label: '記事名', formatter: this.formatter },
    { key: 'body', label: '本文', formatter: this.formatter },
  ];

  loading = false;
  pages: components['schemas']['v1WikiPageWithTitleAndBodyCollection'] = { wikiPage: [] };
  perRequestWikiPageNum = 20;
  totalPageNum = 0;
  searchString = '';
  bodySearchString = '';
  emptyText = '検索ワードを入力してください';
  includesDeleted = false;

  async getWikiPages(startAt: number = 0) {
    if (!this.searchString) {
      this.emptyText = '検索ワードを入力してください';
      return;
    }

    this.loading = true;
    this.bodySearchString = this.searchString;

    await this.apiClient
      .GET('/admin/wiki/{wikiName}/page/title-bodies', {
        params: {
          path: {
            wikiName: this.wikiName,
          },
          query: {
            limit: this.perRequestWikiPageNum,
            offset: startAt,
            searchWords: this.searchWords,
            isDeleted: this.includesDeleted ? undefined : false,
            includesBodyOnSearch: true,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r;
        }

        return r.data;
      })
      .then((res) => {
        this.pages = res;
        this.totalPageNum = res.numOfTotalWikiPages || 0;
      })
      .catch((e: any) => {
        if (e.response && e.response.status === 404) {
          this.pages = { wikiPage: [] };
          this.emptyText = '指定した条件に該当する記事が見つかりません';
        } else {
          this.setFlashMessage('danger', '記事の検索に失敗しました');
        }
      });

    this.loading = false;
  }

  changeDataRange(startAt: number) {
    this.getWikiPages(startAt);
  }

  formatter(value: string) {
    const searched: string[] = [];
    const searchResult: string[][] = [];
    if (!this.bodySearchString || !value) {
      return value;
    }

    this.searchWords.forEach((word) => {
      value.split('\n').forEach((v) => {
        if (v.includes(word)) {
          searched.push(v);
        }
      });
    });

    const toUnicode = (word: string) => {
      return [...word].map((w) => '\\u' + w.charCodeAt(0).toString(16).padStart(4, '0')).join('');
    };

    searched.forEach((v) => {
      searchResult.push(v.split(new RegExp(`(${this.searchWords.map((word) => toUnicode(word)).join('|')})`, 'gu')));
    });

    return searchResult;
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.bodySearchString);
  }
}
