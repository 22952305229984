
import format from 'date-fns/format';
import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';
import { manageImageUpload, exportImageUploadCSV, fileValidateChunk } from '@/service/ImageUploader';
import { wikiGetImageWithServingURL } from '@/service/WikiGetImageWithServingURL';

type Result = { name: string; url: string };

@Component({
  name: 'manage-image-bulk-upload',
})
export default class ManageImageBulkUpload extends KamigameVue {
  files: File[] = [];
  success: Result[] = [];
  error: File[] = [];
  uploading: boolean = false;

  get proceeded() {
    return this.success.length + this.error.length;
  }

  get completed() {
    return this.files.length < this.proceeded;
  }

  async upload() {
    this.uploadingStart();
    const chunkMaximumBytes = 30 * 1000 * 1000;
    const { chunkedFiles, error } = fileValidateChunk(this.files, chunkMaximumBytes);
    this.error = error;
    await chunkedFiles.reduce(async (chain, chunk) => {
      await chain;
      try {
        const res = await manageImageUpload(this.$store.getters.sessionId, chunk);
        await Promise.all(
          Array.from(res).map(async ([name, id]) => {
            const image = await wikiGetImageWithServingURL(this.apiClient, id);
            this.success.push({ name, url: image.url || '' });
          })
        );
      } catch (e) {
        console.error(e);
        this.error.push(...chunk);
      }
    }, Promise.resolve());
    this.uploadingEnd();
  }

  uploadingStart() {
    this.uploading = true;
    this.success = [];
    this.error = [];
  }
  uploadingEnd() {
    this.uploading = false;
    this.files = [];
  }

  exportCSV() {
    const csvName: string = `image-list-${format(new Date(), 'yyyyMMddHHmmss')}.csv`;
    exportImageUploadCSV(this.success, csvName);
  }
}
