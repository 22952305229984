var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      ref: _vm.modalRef,
      attrs: {
        title: _vm.modalTitle || "記事部品を選択",
        lazy: "",
        centered: "",
      },
      on: {
        ok: _vm.onSelectionSaved,
        cancel: _vm.onSelectionCanceled,
        shown: _vm.onShownModal,
        hide: _vm.clear,
      },
    },
    [
      _c("b-form-input", {
        ref: "input",
        attrs: { type: "text", placeholder: "記事部品名で絞り込む" },
        on: {
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
            )
              return null
            $event.preventDefault()
            if (
              $event.ctrlKey ||
              $event.shiftKey ||
              $event.altKey ||
              $event.metaKey
            )
              return null
            return _vm.focusTree($event)
          },
          input: _vm.onSearchStringChanged,
        },
        model: {
          value: _vm.searchString,
          callback: function ($$v) {
            _vm.searchString = $$v
          },
          expression: "searchString",
        },
      }),
      _vm._v(" "),
      _c("tree", {
        ref: "wikiPagePartialsTree",
        attrs: {
          data: _vm.wikiPagePartials,
          filter: _vm.filter,
          options: _vm.wikiPagePartialsOptions,
        },
        on: { "node:selected": _vm.onWikiPagePartialSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }