
import Component from 'vue-class-component';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-base-config',
  components: {
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiBaseConfig extends KamigameVue {
  name: string = '';
  title: string = '';
  description: string = '';
  pageTitlePrefix: string = '';
  titleSuffix: string = '';
  teamName: string = '';
  teamSnsUrl: string = '';
  wiki: components['schemas']['v1Wiki'] = {};
  savedName: string = '';
  savedTitle: string = '';
  savedDescription: string = '';
  savedPageTitlePrefix: string = '';
  savedTitleSuffix: string = '';
  savedTeamName: string = '';
  savedTeamSnsUrl: string = '';
  gaMeasurementId: string = '';

  mounted() {
    this.$store.getters.getWiki(this.wikiName).then((wiki: components['schemas']['v1Wiki']) => {
      this.wiki = wiki;
      this.name = wiki.name || '';
      this.title = wiki.title || '';
      this.description = wiki.description || '';
      this.titleSuffix = wiki.titleSuffix || '';
      this.teamName = wiki.teamName || '';
      this.teamSnsUrl = wiki.teamSNSURL || '';
      this.gaMeasurementId = wiki.gaMeasurementID || '';

      if (wiki.meta) {
        const pageTitlePrefixConfig = wiki.meta.filter((v) => v.name === 'page-title-prefix').shift();
        if (pageTitlePrefixConfig) {
          this.pageTitlePrefix = pageTitlePrefixConfig.value || '';
        }
      }

      this.updateSavedContentState();
    });
  }

  async save() {
    if (this.pageTitlePrefix.includes('【') || this.pageTitlePrefix.includes('】')) {
      this.setFlashMessage('danger', 'タイトルキーワードに 【 または 】 を含めることはできません');
      return;
    }
    if (this.gaMeasurementId && !this.gaMeasurementId.startsWith('G-')) {
      this.setFlashMessage('danger', 'Google Analytics の測定 ID は G- から始まる文字列を入力してください');
      return;
    }

    this.apiClient
      .PUT('/admin/wiki/{name}', {
        params: { path: { name: this.name } },
        body: {
          title: this.title,
          description: this.description,
          titleSuffix: this.titleSuffix,
          teamName: this.teamName,
          teamSNSURL: this.teamSnsUrl,
          gaMeasurementId: this.gaMeasurementId,
        },
      })
      .then((res) => {
        if (res.error) {
          throw res.error;
        }

        const wiki = res.data as components['schemas']['v1Wiki'];
        this.wiki.title = wiki.title;
        this.wiki.description = wiki.description;
        this.wiki.titleSuffix = wiki.titleSuffix;
        this.wiki.teamName = wiki.teamName;
        this.wiki.teamSNSURL = wiki.teamSNSURL;
        return this.apiClient.PUT('/admin/wiki/{wiki.name}/meta/{name}', {
          params: {
            path: { 'wiki.name': this.name, name: 'page-title-prefix' },
          },
          body: { value: this.pageTitlePrefix },
        });
      })
      .then((resp) => {
        if (resp.error) {
          throw resp.error;
        }

        const wikiMetaPageTitlePrefix = resp.data;
        this.wiki.meta = (this.wiki.meta || []).map((wikiMeta: components['schemas']['v1WikiMeta']) => {
          if (wikiMeta.name === 'page-title-prefix') {
            return Object.assign(wikiMeta, wikiMetaPageTitlePrefix);
          } else {
            return wikiMeta;
          }
        });

        this.updateSavedContentState();

        this.$store.commit('setWiki', { wiki: this.wiki });

        this.setFlashMessage('success', '変更を保存しました。', this.wikiTopUrl, 'サイト上で内容を確認する', true);
      });
  }

  updateSavedContentState() {
    this.savedName = this.name;
    this.savedTitle = this.title;
    this.savedDescription = this.description;
    this.savedPageTitlePrefix = this.pageTitlePrefix;
    this.savedTitleSuffix = this.titleSuffix;
    this.savedTeamName = this.teamName;
    this.savedTeamSnsUrl = this.teamSnsUrl;
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    return (
      this.title !== this.savedTitle ||
      this.description !== this.savedDescription ||
      this.pageTitlePrefix !== this.savedPageTitlePrefix ||
      this.titleSuffix !== this.savedTitleSuffix ||
      this.teamName !== this.savedTeamName ||
      this.teamSnsUrl !== this.savedTeamSnsUrl
    );
  }
}
