
import Component from 'vue-class-component';
import FileUploadWithPreview from 'file-upload-with-preview';

import KamigameVue from '@/KamigameVue';
import MainImageModal from '../../components/screenshotScanner/MainImageModal.vue';
import { fetchSpreadsSheetData } from '@/components/screenshotScanner/spreadsSheet';

@Component({
  components: {
    'main-image-modal': MainImageModal,
  },
})
export default class ScreenshotScanner extends KamigameVue {
  iconUpload?: FileUploadWithPreview;
  iconUploadDisabled = true;
  iconUploading = false;
  files: File[] = [];
  imageUrls: String[] = [];
  spreadsSheetUrl: string = '';
  spreadsSheetUrlMsgState: 'none' | 'success' | 'failed' = 'none';
  fetchedSectionValues: any[] = [];

  uploading: boolean = false;

  hasUploadedIcon = false;
  hasUploadedHeader = false;

  existsUnsavedIcon = false;
  existsUnsavedHeader = false;

  mounted() {
    let script = document.createElement('script');
    script.src = 'https://docs.opencv.org/4.8.0/opencv.js';
    script.async = true;

    document.body.appendChild(script);

    this.iconUpload = new FileUploadWithPreview('icon');

    window.addEventListener('fileUploadWithPreview:imagesAdded', (e: any) => {
      if (e.detail.uploadId === 'icon') {
        this.iconUploadDisabled = false;
        this.hasUploadedIcon = true;
        this.existsUnsavedIcon = true;
      } else if (e.detail.uploadId === 'header') {
        this.hasUploadedHeader = true;
        this.existsUnsavedHeader = true;
      }
    });

    const iconUploaderPreview = document.getElementById('iconUploaderPreview');
    if (iconUploaderPreview) {
      iconUploaderPreview.addEventListener('kamigame:fileDropped', (e: any) => {
        if (e.detail.files && this.iconUpload) {
          this.iconUpload.addFiles(e.detail.files);
        }
      });
    }
  }

  openModal() {
    const modal = this.$refs.mainImageModal as any;
    modal.show();
  }

  handleFileChange(event: Event) {
    const selectedFiles = (event.target as HTMLInputElement).files!;
    const supportedExtensions = ['.jpeg', '.jpg', '.png'];
    const excludedFiles: File[] = [];
    const filteredFiles: File[] = [];
    Array.from(selectedFiles).filter((file) => {
      const fileName = file.name.toLowerCase();
      if (supportedExtensions.some((ext) => fileName.endsWith(ext))) {
        filteredFiles.push(file);
      } else {
        excludedFiles.push(file);
      }
    });

    if (excludedFiles.length > 0) {
      const excludedFileNames = excludedFiles.map((file) => file.name).join(', ');
      alert(`以下のファイルは除外されました \n ${excludedFileNames}`);
    }

    this.files = filteredFiles;
    this.imageUrls = filteredFiles.map((file) => URL.createObjectURL(file));
  }

  async loadSectionValuesFromSpreadsSheetUrl() {
    const result = await fetchSpreadsSheetData(this.spreadsSheetUrl);
    if (result === undefined || result.length === 0) {
      this.spreadsSheetUrlMsgState = 'failed';
      return;
    }
    this.spreadsSheetUrlMsgState = 'success';

    this.fetchedSectionValues = result.slice(1).map((value) => {
      return {
        name: value[0] ?? '',
        rule: value[1] ?? 'not-selected',
        spreadsSheetURL: value[2] ?? '',
        trimData:
          value[3] && value[4] && value[5] && value[6]
            ? { x: Number(value[3]), y: Number(value[4]), width: Number(value[5]), height: Number(value[6]) }
            : { x: 120, y: 270, width: 400, height: 240 },
        canPreview: true,
      };
    });
  }

  handleLoadSectionValues() {
    this.spreadsSheetUrlMsgState = 'none';
    this.fetchedSectionValues = [];

    this.loadSectionValuesFromSpreadsSheetUrl();
  }
}
