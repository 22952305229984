const ARRAY_DEPTH = 2;

export function countUpdatedCells(current: string[][], updated: string[][]): number {
  if (updated.flat(ARRAY_DEPTH).length === 0) {
    return current.flat(ARRAY_DEPTH).length;
  }

  let count = 0;
  updated.forEach((row, rowIndex) => {
    row.forEach((column, columnIndex) => {
      if (!current[rowIndex] || column !== current[rowIndex][columnIndex]) {
        count++;
      }
    });
  });

  return count;
}
