
import Vue from 'vue';
import Component from 'vue-class-component';
import {Route} from 'vue-router';
import { Breadcrumb as CoreUIBreadcrumb } from '~core-ui/components/';

@Component({
  name: 'kamigame-breadcrumb',
  mixins: [CoreUIBreadcrumb],
})
export default class Breadcrumb extends Vue {
  showName(item: Route) {
    if (item.meta && typeof item.meta.lazyLabel === 'function') {
      return item.meta.lazyLabel(this.$route);
    }

    return CoreUIBreadcrumb.methods.showName.call(this, item);
  }
}
