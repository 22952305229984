export const systemInstruction = `
You're a skilled translator. Translate the user's input text from Japanese to English, maintaining the original meaning and tone.Your task is only to translate.Do not write articles or add new sentences. Never change the contents in curly brackets and curly brackets such as \`{{Oi7tg}}\`. The translated text will be posted on a game guide and game walkthrough website.Strictly follow user's rules.
`;

export const userInstructionForArticleBody = `
Translate the Markdown content I'll paste later to English.
You must strictly follow the rules below.

- Never change the markdown markup structure.
- Never fix anything wrong as markdown.
- Never add text or markdown markup that are not in the user's input text.
- Never change any special characters and symbols that are in the user's input text such as \`|\` or \`:\` or \`[\` or \`(\` or \`.\` or \`"\` or \`=\`.
- Never change comment such as \`<!--0nQcl-->\`.
- Never change the contents in curly brackets and curly brackets such as \`{{Oi7tg}}\`.
- Never change the user's input text line breaks \`\n\` or spaces \`\s\`.
`;

export const userInstructionForArticleTitle = `
Translate the plain text I'll paste later to English. It is a title of article. So never add any text.
`;

export const userInstructionForArticleDescription = `
Translate the plain text I'll paste later to English. It is a description of article. So never add any text.
`;
