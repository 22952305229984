
import Component from 'vue-class-component';

import KamigameVue from '@/KamigameVue';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-archive-config',
  components: {},
})
export default class WikiBaseConfig extends KamigameVue {
  isArchived: boolean = false;
  wiki: components['schemas']['v1Wiki'] = {};

  mounted() {
    this.$store.getters.getWiki(this.wikiName).then((wiki: components['schemas']['v1Wiki']) => {
      this.wiki = wiki;
      this.isArchived = !!wiki.isArchived;
    });
  }

  async toggleArchive() {
    const newIsArchived = !this.isArchived;

    this.wiki.isArchived = newIsArchived;
    try {
      await this.apiClient
        .PUT('/admin/wiki/{name}/archive-config', {
          params: { path: { name: this.wiki.name ?? '' } },
          body: { isArchived: newIsArchived },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }
        });
    } catch (e) {
      console.error(e);
      this.setFlashMessage('danger', 'アーカイブ設定の変更に失敗しました');
      return;
    }

    this.$store.commit('setWiki', { wiki: this.wiki });

    const route = this.$router.resolve({ name: 'wiki_dashboard', params: { name: this.wiki.name! } });
    window.location.href = route.href;
  }
}
