export const convertObjectsToCSV = (objects: { [key: string]: any }[]): string => {
  if (objects.length === 0) {
    return '';
  }

  const headers = Object.keys(objects[0]);
  const headerRow = headers.join(',');

  const dataRows = objects.map((obj) =>
    headers
      .map((header) => {
        const value = header in obj ? String(obj[header]) : '';
        return value.includes(',') || value.includes('"') || value.includes('\n')
          ? `"${value.replace(/"/g, '""')}"`
          : value;
      })
      .join(',')
  );

  return [headerRow, ...dataRows].join('\n');
};
