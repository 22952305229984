import { replaceBlocks, restoreBlocks } from './replace/replaceBlock';

export function bulkReplace(str: string, replacements: Replacement[]) {
  return replacements.reduce((acc, replacement: Replacement) => {
    const regexp = new RegExp(replacement.old, 'g');
    return acc.replace(regexp, replacement.new);
  }, str);
}

/** 記法や URL 中の単語以外を置換する */
export function bulkSafeReplace(str: string, replacements: Replacement[]) {
  const result = replaceBlocks(str);
  const replacedText = bulkReplace(result.replacedText, replacements);
  return restoreBlocks(replacedText, result.blocks).result;
}

export function replaceWikiPathInLink(markdownText: string, sourceWikiName: string, currentWikiName: string) {
  const regexp = new RegExp(`/${sourceWikiName}/.*?\.html`, 'g');
  return markdownText.replace(regexp, (match) => {
    return match.replace(new RegExp(`/${sourceWikiName}/`, 'g'), `/${currentWikiName}/`);
  });
}

export function replaceLinkPageId(markdownText: string, sourcePageId: string, currentPageId: string) {
  const linkRegexp = /\/(\d+?)\.html.*?/g;

  return markdownText.replace(linkRegexp, (match, id) => {
    if (!sourcePageId || !currentPageId) {
      return match;
    }

    return match.replace(new RegExp(sourcePageId, 'g'), currentPageId);
  });
}
