var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "app flex-row align-items-center" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c(
              "b-col",
              { attrs: { md: "auto", sm: "auto", lg: "auto" } },
              [
                _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c("b-card-body", { staticClass: "p-4" }, [
                      _vm.loaded && !_vm.errored
                        ? _c(
                            "div",
                            [
                              _c("p", [
                                _vm._v(
                                  "\n                あなたはゲーム攻略 Wiki「" +
                                    _vm._s(_vm.wiki.title) +
                                    "」(" +
                                    _vm._s(_vm.wikiName) +
                                    ") の編集メンバーとして招待されています。\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("b-card", [
                                _c(
                                  "h3",
                                  { staticStyle: { "font-size": "14px" } },
                                  [
                                    _c("b-img", {
                                      attrs: {
                                        src: _vm.wiki.iconImgURL,
                                        width: "45px",
                                        height: "45px",
                                        rounded: "",
                                      },
                                    }),
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.wiki.title) +
                                        "\n                "
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("dl", { staticClass: "mt-3" }, [
                                  _c("dt", [
                                    _vm._v(
                                      "この Wiki で取り扱うゲームタイトル"
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("dd", [_vm._v(_vm._s(_vm.wiki.game))]),
                                  _vm._v(" "),
                                  _c("dt", [_vm._v("説明文")]),
                                  _vm._v(" "),
                                  _c("dd", [
                                    _vm._v(_vm._s(_vm.wiki.description)),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "\n                この招待を受諾すると、あなたは「" +
                                    _vm._s(_vm.wiki.title) +
                                    "」のコンテンツ類の作成や編集に携わることが可能となります。\n              "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  "よろしければ下のボタンを押下し、この招待を受諾してください。"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: { block: "", variant: "primary" },
                                  on: { click: _vm.join },
                                },
                                [_vm._v("招待を受諾し、 Wiki の編集に参加する")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.loaded
                        ? _c("div", [_c("p", [_vm._v("読み込み中...")])])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.errored
                        ? _c("div", [
                            _c("h2", [_vm._v("無効な招待リンクです")]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "この招待リンクは利用制限に達したか、もしくは不正な形式のリンクです。"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _vm._v(
                                "大変お手数をおかけしますが、この招待リンクを送った方に問い合わせてください。"
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }