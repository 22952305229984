import { fetchPagePartialSheetData, PagePartialSheetData, extractTemplate } from 'wiki-shared/page-partial';

export default class PagePartialParser {
  game: string = '';
  failedTemplates: string[] = [];
  templateResults: { [key: string]: string } = {};
  timeoutId: number = 0;

  constructor(game: string) {
    this.game = game;
  }

  getPagePartialMarkdownsByContent(content: string): { [key: string]: string } {
    const convertConditionToTemplateLiteral = (condition: string): string => {
      return `{template ${condition}}`;
    };

    const fetchPagePartials = (templateConditions: string[]): void => {
      window.clearTimeout(this.timeoutId);
      this.timeoutId = window.setTimeout(() => {
        fetchPagePartialSheetData(API_URL_BASE, this.game, templateConditions).then((response) => {
          const pagePartialSheetDataJson = response.data.data.wikiPagePartialSheetDataList;
          const pagePartialSheetDataCollection: { [templateLiteral: string]: PagePartialSheetData } = {};

          if (pagePartialSheetDataJson.wikiPagePartialSheetDataList) {
            pagePartialSheetDataJson.wikiPagePartialSheetDataList.forEach((el) => {
              const p = new PagePartialSheetData(el.condition, el.body, el.data);
              pagePartialSheetDataCollection[p.createTemplateLiteral()] = p;
            });
          }

          templateConditions.forEach((templateCondition) => {
            const template = convertConditionToTemplateLiteral(templateCondition);
            const rendered = pagePartialSheetDataCollection[template].render();
            if (!rendered) {
              this.failedTemplates.push(template);
            }
            this.templateResults[template] = rendered;
          });
          document.dispatchEvent(new Event('kamigame-page-partial-fetched'));
        });
      }, 1500);
    };

    const { templateConditionMatches } = extractTemplate(content);
    const unfetchedPagePartials = templateConditionMatches.filter(
      (templateMatch) => !Object.keys(this.templateResults).includes(convertConditionToTemplateLiteral(templateMatch))
    );
    if (unfetchedPagePartials.length > 0) {
      fetchPagePartials(unfetchedPagePartials);
    }

    return this.templateResults;
  }
}
