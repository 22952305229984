var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-button", { attrs: { block: "" }, on: { click: _vm.clickButton } }, [
        _vm._v("移行元の更新チェック" + _vm._s(_vm.loadingText)),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }