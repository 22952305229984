
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { HotTable } from '@handsontable/vue';
import KamigameVue from '@/KamigameVue';

import { components } from '@/api-client/schema';

interface updatedDataSheetConfig {
  isUpdated: boolean;
  updatedPos: boolean[][];
}

@Component({
  name: 'kamigame-wiki-data-sheet-tab-table',
  components: {
    HotTable,
  },
})
export default class WikiDataSheetTabTable extends KamigameVue {
  @Prop()
  data?: components['schemas']['v1WikiDataSheetCollection'];

  @Prop()
  selectedSheetIds?: string[];

  @Prop()
  comparedData?: components['schemas']['v1WikiDataSheetCollection'];

  @Prop({ default: false })
  isUpdateSheet?: boolean;

  @Prop({ default: false })
  isOperationableSheet?: boolean;

  @Prop({ default: false })
  isAddableDataSheet?: boolean;

  @Watch('data', { deep: true })
  onChangeData() {
    if (this.data?.sheets?.length === 1 && !this.data.sheets[0].id) {
      // シートの追加時
      this.selectedSheetIndex = 0;
    }

    this.readSelectedSheetDataInDisplayTable();
  }

  @Watch('comparedData', { deep: true })
  onChangeComparedData() {
    this.createUpdatedDataPosConfig();
    this.readSelectedSheetDataInDisplayTable();
  }

  @Watch('selectedSheetIds')
  onChangeSelectedSheetIds() {
    this.switchTab(this.selectedTabIndex);
  }

  selectedSheetIndex: number | null = null;
  selectedTabIndex: number | null = null;
  updatedDataPosConfig: updatedDataSheetConfig[] = [];
  tableSettings: any = {
    data: [['ID']],
    minCols: 10,
    minRows: 20,
    minSpareRows: 1,
    minSpareCols: 1,
    colWidths: 100,
    fixedRowsTop: 1,
    colHeaders: true,
    rowHeaders: true,
    allowInsertColumn: true,
    allowInsertRow: true,
    allowRemoveColumn: true,
    allowRemoveRow: true,
    allowEmpty: true,
    manualColumnResize: true,
    autoColumnSize: true,
    wordWrap: false,
    readOnly: true,
  };

  get currentSheet(): components['schemas']['v1WikiDataSheet'] | undefined {
    const index = this.selectedSheetIndex;
    if (!this.data || !this.data.sheets || index === null || index === -1) {
      return undefined;
    }

    return this.data.sheets[index];
  }

  mounted() {
    if (!this.data || !this.data.sheets) {
      return;
    }

    if (this.data.sheets.length > 0) {
      this.selectedSheetIndex = 0;
      this.createUpdatedDataPosConfig();
      this.readSelectedSheetDataInDisplayTable();
    }
  }

  async switchTab(tabIndex: number | null) {
    if (!this.data || !this.data.sheets) {
      return;
    }

    if (tabIndex === null) {
      this.selectedSheetIndex = null;
      return;
    }
    this.selectedTabIndex = tabIndex;

    // 更新時は受け取ったdata(updateData)を選択シートとする
    const selectedSheetIds = this.isUpdateSheet
      ? this.data.sheets.map((sheet) => sheet.id)
      : this.selectedSheetIds ?? [];
    const selectedSheetId = selectedSheetIds[tabIndex];
    if (!selectedSheetId) {
      this.selectedSheetIndex = null;
      return;
    }

    const index = this.data.sheets.findIndex((sheet) => sheet.id === selectedSheetId);

    if (this.data.sheets && index !== -1 && this.data.sheets[index] && !this.data.sheets[index].sheetData) {
      this.$emit('need-fetching-selected-sheet-data', index);
    }

    this.selectedSheetIndex = index;
    this.readSelectedSheetDataInDisplayTable();
    this.$emit('change-selected-sheet-index', this.selectedSheetIndex);
  }

  readSelectedSheetDataInDisplayTable() {
    const hot = this.retrieveHandsontable();
    if (!hot) {
      return;
    }

    const data = this.currentSheet && this.currentSheet.sheetData ? this.currentSheet.sheetData : '[[]]';
    hot.loadData(JSON.parse(data));
    this.changeUpdatedPosColor(hot);
    // Handsontable の初期化に時間が必要なため少し時間をあける
    window.setTimeout(() => {
      hot.render();
    }, 10);
  }

  retrieveHandsontable() {
    const component = this.$refs.dataSheet as any;
    if (!component) {
      return undefined;
    }

    return component.hotInstance;
  }

  startEditingSheetName(e: Event) {
    const target = e.target as HTMLInputElement;
    target.readOnly = false;
    target.focus();
  }

  endEditingSheetName(e: Event, index: number) {
    if (!this.data || !this.data.sheets || this.selectedSheetIndex === null) {
      return;
    }

    const target = e.target as HTMLInputElement;
    target.readOnly = true;
    this.$emit('change-data-sheet-title', target.value);
  }

  createTab() {
    this.$emit('on-creating-tab');
  }

  editSheetTitle() {
    this.$emit('edit-sheet-title');
  }

  deleteDataSheet() {
    this.$emit('delete-data-sheet');
  }

  findUpdatedDataPos(data: string[][], comparedData: string[][]): updatedDataSheetConfig {
    const updatedPos = data.map((row, rowIndex) => {
      return row.map((col, colIndex) => col !== comparedData[rowIndex][colIndex]);
    });

    return {
      isUpdated: updatedPos.findIndex((row) => row.indexOf(true) > -1) > -1,
      updatedPos: updatedPos,
    };
  }

  createUpdatedDataPosConfig() {
    if (!this.data || !this.data.sheets || !this.comparedData || !this.comparedData.sheets) {
      return;
    }

    this.updatedDataPosConfig = [];

    const normalizeRowColSize = (data: string[][], rowSize: number, colSize: number): string[][] => {
      const normalizedData = data.slice();

      for (let row = 0; row < rowSize; row++) {
        if (!normalizedData[row]) {
          normalizedData[row] = [];
        }
        for (let col = 0; col < colSize; col++) {
          if (normalizedData[row][col] === undefined || normalizedData[row][col] === null) {
            normalizedData[row][col] = '';
          }
        }
      }

      return normalizedData;
    };

    for (let i = 0; i < this.data.sheets.length; i++) {
      const updatedDataSheetData = JSON.parse(this.data.sheets[i].sheetData || '[[]]') as string[][];
      const comparedDataSheetData = JSON.parse(this.comparedData.sheets[i].sheetData || '[[]]') as string[][];

      const maxRowSize = Math.max(updatedDataSheetData.length, comparedDataSheetData.length);
      const maxColSize = Math.max(
        Math.max(
          ...updatedDataSheetData.map((row) => row.length),
          Math.max(...comparedDataSheetData.map((row) => row.length))
        )
      );

      this.updatedDataPosConfig.push(
        this.findUpdatedDataPos(
          normalizeRowColSize(updatedDataSheetData, maxRowSize, maxColSize),
          normalizeRowColSize(comparedDataSheetData, maxRowSize, maxColSize)
        )
      );
    }
  }

  changeUpdatedPosColor(hot: any) {
    if (this.selectedSheetIndex === null || !this.updatedDataPosConfig[this.selectedSheetIndex]) {
      return;
    }

    this.updatedDataPosConfig[this.selectedSheetIndex].updatedPos.forEach((row, rowIndex) => {
      row.forEach((col, colIndex) => {
        if (col) {
          hot.setCellMeta(rowIndex, colIndex, 'className', 'bg-success');
        }
      });
    });
  }

  isSheetSelected(index: number): boolean {
    if (!this.data || !this.data.sheets || !this.selectedSheetIds) {
      return false;
    }

    if (this.isUpdateSheet) {
      return true;
    }

    return this.selectedSheetIds.includes(this.data.sheets[index].id ?? '');
  }
}
