const recommendedMaxTitleLength = 30;

function isEmpty(title: string): boolean {
  return title.trim() === '';
}

function isValidLength(title: string): boolean {
  return title.length <= recommendedMaxTitleLength;
}

function startsWithBrace(title: string): boolean {
  return /^【.*?】/.test(title);
}

export interface ValidationResult {
  warnings: ReadonlyArray<string>;
  errors: ReadonlyArray<string>;
}

export const emptyError = 'タイトルは必須です';
export const lengthWarning = 'タイトルの長さが30文字を超えています';
export const braceError = '【...】でタイトルを始めることはできません';

export function validateWikiPageTitle(title: string) {
  const warnings = [];
  const errors = [];

  if (!isValidLength(title)) {
    warnings.push(lengthWarning);
  }

  if (isEmpty(title)) {
    errors.push(emptyError);
  }

  if (startsWithBrace(title)) {
    errors.push(braceError);
  }

  const result: ValidationResult = {
    warnings,
    errors,
  };

  return result;
}
