var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn wiki-page-import" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("strong", [_vm._v("画像一括アップロード")]),
                ]),
                _vm._v(" "),
                _c("p", [_vm._v("複数の画像ファイルをアップロードできます。")]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      disabled: _vm.uploading,
                      label: "アップロードする画像ファイル",
                      description:
                        "アップロードする画像ファイルが入ったフォルダを選択します",
                    },
                  },
                  [
                    _c("b-form-file", {
                      attrs: {
                        state: _vm.files.length > 0,
                        placeholder: "ファイルを選択してください",
                        directory: "",
                        multiple: "",
                        accept: "image/*",
                      },
                      model: {
                        value: _vm.files,
                        callback: function ($$v) {
                          _vm.files = $$v
                        },
                        expression: "files",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.files.length > 0
                  ? _c("div", { staticClass: "mt-3" }, [
                      _vm.uploading
                        ? _c(
                            "div",
                            [
                              _c("p", [
                                _vm._v(
                                  "アップロード中です。しばらくお待ちください... (" +
                                    _vm._s(_vm.proceeded) +
                                    " / " +
                                    _vm._s(_vm.files.length) +
                                    ")"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-progress",
                                { attrs: { max: _vm.files.length } },
                                [
                                  _c("b-progress-bar", {
                                    attrs: {
                                      value: _vm.proceeded,
                                      variant: "primary",
                                      striped: "",
                                      animated: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _c(
                            "div",
                            [
                              _c("p", [
                                _vm._v(
                                  "現在、 " +
                                    _vm._s(_vm.files.length) +
                                    " 個の画像が選択されています。"
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "submit", variant: "primary" },
                                  on: { click: _vm.upload },
                                },
                                [_vm._v("アップロードする")]
                              ),
                            ],
                            1
                          ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.completed
              ? _c(
                  "b-card",
                  { attrs: { "header-tag": "header", "footer-tag": "footer" } },
                  [
                    _c(
                      "div",
                      { attrs: { slot: "header" }, slot: "header" },
                      [
                        _c("strong", [_vm._v("アップロード結果")]),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.csvExportConfirm",
                                modifiers: { csvExportConfirm: true },
                              },
                            ],
                            staticClass: "ml-2",
                            attrs: { size: "sm", variant: "outline-primary" },
                          },
                          [
                            _c("i", { staticClass: "fa fa-download" }),
                            _vm._v(" CSVエクスポート\n          "),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-modal",
                          {
                            attrs: {
                              id: "csvExportConfirm",
                              "hide-header": "",
                              centered: "",
                              "no-fade": "",
                              lazy: "",
                            },
                            on: { ok: _vm.exportCSV },
                          },
                          [
                            _c("p", [
                              _vm._v(
                                "画像のファイル名と URL 一覧を CSV ファイルとしてエクスポートします。"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", [_vm._v("実行しますか？")]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c("b-alert", { attrs: { variant: "info", show: "" } }, [
                      _vm._v(
                        "アップロードが完了しました。アップロード結果は以下のとおりです。"
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.success.length > 0
                      ? _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-card-header",
                              {
                                staticClass: "p-1",
                                attrs: { "header-tag": "header" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName:
                                          "v-b-toggle.result-success-create",
                                        modifiers: {
                                          "result-success-create": true,
                                        },
                                      },
                                    ],
                                    attrs: { block: "", variant: "light" },
                                  },
                                  [
                                    _vm._v("\n              アップロード成功 "),
                                    _c(
                                      "b-badge",
                                      {
                                        attrs: { variant: "primary", pill: "" },
                                      },
                                      [_vm._v(_vm._s(_vm.success.length))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-collapse",
                              {
                                attrs: {
                                  id: "result-success-create",
                                  visible: "",
                                },
                              },
                              [
                                _c(
                                  "b-list-group",
                                  _vm._l(_vm.success, function (s) {
                                    return _c(
                                      "b-list-group-item",
                                      { key: s.url },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(s.name) +
                                            "\n                "
                                        ),
                                        _c(
                                          "small",
                                          { staticClass: "text-muted" },
                                          [_c("br"), _vm._v(_vm._s(s.url))]
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.error.length > 0
                      ? _c(
                          "b-card",
                          { attrs: { "no-body": "" } },
                          [
                            _c(
                              "b-card-header",
                              {
                                staticClass: "p-1",
                                attrs: { "header-tag": "header" },
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "b-toggle",
                                        rawName: "v-b-toggle.result-error",
                                        modifiers: { "result-error": true },
                                      },
                                    ],
                                    attrs: { block: "", variant: "light" },
                                  },
                                  [
                                    _vm._v("\n              アップロード失敗 "),
                                    _c(
                                      "b-badge",
                                      {
                                        attrs: { variant: "danger", pill: "" },
                                      },
                                      [_vm._v(_vm._s(_vm.error.length))]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-collapse",
                              { attrs: { id: "result-error", visible: "" } },
                              [
                                _c(
                                  "b-list-group",
                                  _vm._l(_vm.error, function (file) {
                                    return _c(
                                      "b-list-group-item",
                                      { key: `${file.name}${file.size}` },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(file.name) +
                                            "\n              "
                                        ),
                                      ]
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }