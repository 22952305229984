var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("strong", [_vm._v("OCR 処理デモ")]),
                ]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { label: "アップロードする画像ファイル" } },
                  [
                    _c("b-form-file", {
                      attrs: { accept: "image/*" },
                      model: {
                        value: _vm.ocrFile,
                        callback: function ($$v) {
                          _vm.ocrFile = $$v
                        },
                        expression: "ocrFile",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label:
                        "処理対象の領域 (x, y, w, h, threshold (0 を指定すると複数候補))",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { placeholder: "0, 0, 300, 300, 128" },
                      model: {
                        value: _vm.ocrRegion,
                        callback: function ($$v) {
                          _vm.ocrRegion = $$v
                        },
                        expression: "ocrRegion",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { type: "submit", variant: "primary" },
                    on: { click: _vm.processOcrImage },
                  },
                  [_vm._v("画像を処理する")]
                ),
                _vm._v(" "),
                _vm.ocrResult
                  ? _c("div", { staticClass: "mt-3" }, [
                      _c("h3", [_vm._v("処理結果")]),
                      _vm._v(" "),
                      _c("pre", [_vm._v(_vm._s(_vm.ocrResult.ocr))]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.binaryImages.length > 0
                  ? _c(
                      "div",
                      { staticClass: "mt-3" },
                      [
                        _c("h3", [_vm._v("二値化後の画像")]),
                        _vm._v(" "),
                        _vm._l(_vm.binaryImages, function (image) {
                          return _c("div", { key: image.threshold }, [
                            _c("p", [
                              _vm._v("閾値: " + _vm._s(image.threshold)),
                            ]),
                            _vm._v(" "),
                            _c("p", [
                              _c("img", {
                                attrs: {
                                  src: image.image,
                                  alt: "二値化後の画像",
                                },
                              }),
                            ]),
                          ])
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("strong", [_vm._v("テンプレートマッチングデモ")]),
                ]),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  { attrs: { label: "アップロードする画像ファイル" } },
                  [
                    _c("b-form-file", {
                      attrs: { accept: "image/*" },
                      model: {
                        value: _vm.templateTargetFile,
                        callback: function ($$v) {
                          _vm.templateTargetFile = $$v
                        },
                        expression: "templateTargetFile",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label:
                        "処理対象の領域 (x, y, w, h, threshold (ただし threshold は未使用))",
                    },
                  },
                  [
                    _c("b-form-input", {
                      attrs: { placeholder: "0, 0, 300, 300, 128" },
                      model: {
                        value: _vm.templateRegion,
                        callback: function ($$v) {
                          _vm.templateRegion = $$v
                        },
                        expression: "templateRegion",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-form-group",
                  {
                    attrs: {
                      label:
                        "アップロードするテンプレート画像ファイル（複数可）",
                    },
                  },
                  [
                    _c("b-form-file", {
                      attrs: { accept: "image/*", multiple: "" },
                      model: {
                        value: _vm.templateFiles,
                        callback: function ($$v) {
                          _vm.templateFiles = $$v
                        },
                        expression: "templateFiles",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-button",
                  {
                    attrs: { type: "submit", variant: "primary" },
                    on: { click: _vm.processTemplateMatching },
                  },
                  [_vm._v("テンプレートマッチングを実行する")]
                ),
                _vm._v(" "),
                _vm.templateMatchResult
                  ? _c("div", { staticClass: "mt-3" }, [
                      _c("h3", [_vm._v("マッチング結果")]),
                      _vm._v(" "),
                      _c("pre", [_vm._v(_vm._s(_vm.templateMatchResult))]),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }