function kamigameFullScreenExtension(editor: any) {
  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem('divider');
  toolbar.addItem({
    type: 'button',
    options: {
      name: 'toggle-full-screen',
      className: 'tui-editor-custom-button fa fa-arrows-alt full-screen-button',
      event: 'toggle-full-screen',
      tooltip: 'フルスクリーンモードの切り替え',
    },
  });

  editor.eventManager.addEventType('toggle-full-screen');
  editor.commandManager.addCommand('markdown', {
    name: 'toggle-full-screen',
    exec() {
      editor.eventManager.emit('toggle-full-screen');
    },
  });
}

export default kamigameFullScreenExtension;
