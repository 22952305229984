import { RouteConfig } from 'vue-router';

import ManageDashboard from '@/views/Manage/ManageDashboard.vue';
import ManageGlobalDomainAllowlist from '@/views/Manage/ManageGlobalDomainAllowlist.vue';
import ManageImageBulkUpload from '@/views/Manage/ManageImageBulkUpload.vue';
import ManageWikiList from '@/views/Manage/ManageWikiList.vue';

export const MANAGE_ROUTE_CONFIG = {
  dashboard: {
    name: 'manage_dashboard',
    path: '/-/manage/dashboard',
  },
  imageBulkUpload: {
    name: 'manage_image_bulk_upload',
    path: '/-/manage/image-upload',
  },
  globalDomainAllowlist: {
    name: 'manage_global_domain_allowlist',
    path: '/-/manage/domain-allowlist',
  },
  wikiList: {
    name: 'manage_wiki_list',
    path: '/-/manage/wiki-list',
  },
};

interface ExtendedRouteConfig extends RouteConfig {
  meta: {
    icon: string;
    label: string;
    requiredPermission: string;
  };
}

export default [
  {
    name: MANAGE_ROUTE_CONFIG.dashboard.name,
    path: MANAGE_ROUTE_CONFIG.dashboard.path,
    component: ManageDashboard,
    meta: {
      icon: 'icon-speedometer',
      label: 'ダッシュボード',
      requiredPermission: 'ACTION_manage',
    },
  },
  {
    name: MANAGE_ROUTE_CONFIG.imageBulkUpload.name,
    path: MANAGE_ROUTE_CONFIG.imageBulkUpload.path,
    component: ManageImageBulkUpload,
    meta: {
      icon: 'icon-picture',
      label: '画像一括アップロード',
      requiredPermission: 'ACTION_manage',
    },
  },
  {
    name: MANAGE_ROUTE_CONFIG.globalDomainAllowlist.name,
    path: MANAGE_ROUTE_CONFIG.globalDomainAllowlist.path,
    component: ManageGlobalDomainAllowlist,
    meta: {
      icon: 'icon-check',
      label: 'ドメイン許可設定',
      requiredPermission: 'ACTION_global_domain_allowlist_read',
    },
  },
  {
    name: MANAGE_ROUTE_CONFIG.wikiList.name,
    path: MANAGE_ROUTE_CONFIG.wikiList.path,
    component: ManageWikiList,
    meta: {
      icon: 'icon-list',
      label: 'Wiki 一覧',
      requiredPermission: 'ACTION_manage',
    },
  },
] as ExtendedRouteConfig[];
