var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn wiki-page-history-list" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("strong", [_vm._v("記事検索")]),
                ]),
                _vm._v(" "),
                _c(
                  "b-container",
                  { staticClass: "mb-3", attrs: { fluid: "" } },
                  [
                    _c(
                      "b-form",
                      {
                        on: {
                          submit: function ($event) {
                            $event.preventDefault()
                            return _vm.getWikiPages()
                          },
                        },
                      },
                      [
                        _c(
                          "b-input-group",
                          { staticClass: "mt-3" },
                          [
                            _c("b-form-input", {
                              attrs: { placeholder: "検索ワードを入力" },
                              model: {
                                value: _vm.searchString,
                                callback: function ($$v) {
                                  _vm.searchString = $$v
                                },
                                expression: "searchString",
                              },
                            }),
                            _vm._v(" "),
                            _c("b-button", { attrs: { type: "submit" } }, [
                              _vm._v("検索"),
                            ]),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mt-3",
                            model: {
                              value: _vm.includesDeleted,
                              callback: function ($$v) {
                                _vm.includesDeleted = $$v
                              },
                              expression: "includesDeleted",
                            },
                          },
                          [_vm._v("削除済みも含める")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "kamigame-paging",
                      {
                        ref: "kamigamePaging",
                        staticClass: "mt-3",
                        attrs: {
                          dataLength: _vm.totalPageNum,
                          "parent-per-page": _vm.perRequestWikiPageNum,
                          fields: _vm.fields,
                        },
                        on: { "change-data-range": _vm.changeDataRange },
                      },
                      [
                        _c(
                          "b-table",
                          {
                            attrs: {
                              slot: "data-list",
                              responsive: "",
                              fields: _vm.fields,
                              items: _vm.pages.wikiPage,
                              "empty-text": _vm.emptyText,
                              "show-empty": "",
                              busy: _vm.loading,
                            },
                            slot: "data-list",
                            scopedSlots: _vm._u([
                              {
                                key: "cell(title)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "wiki_page_edit",
                                            params: {
                                              paths: data.item.wikiPage.id,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        data.item.wikiPage.path === "index"
                                          ? _c("span", [
                                              _c("i", {
                                                staticClass: "fa fa-home",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                              _vm._v(
                                                "トップページ\n                  "
                                              ),
                                            ])
                                          : data.value.length == 0
                                          ? _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  data.item.title ||
                                                    "(タイトルなし)"
                                                )
                                              ),
                                            ])
                                          : _vm._l(
                                              data.value,
                                              function (searchedLines, index) {
                                                return _c(
                                                  "p",
                                                  { key: index },
                                                  [
                                                    _vm._l(
                                                      searchedLines,
                                                      function (
                                                        word,
                                                        wordIndex
                                                      ) {
                                                        return [
                                                          _vm.searchWords.filter(
                                                            (v) => v === word
                                                          ).length > 0
                                                            ? [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    key: wordIndex,
                                                                    staticClass:
                                                                      "highlight",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        word
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  _vm._s(word)
                                                                ),
                                                              ],
                                                        ]
                                                      }
                                                    ),
                                                  ],
                                                  2
                                                )
                                              }
                                            ),
                                      ],
                                      2
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(body)",
                                fn: function (data) {
                                  return [
                                    data.value.length == 0
                                      ? [
                                          _vm._l(
                                            data.item.body
                                              .split("\n")
                                              .slice(0, 5),
                                            function (bodyRow, index) {
                                              return _c("p", { key: index }, [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(bodyRow) +
                                                    "\n                  "
                                                ),
                                              ])
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c("p", [
                                            _vm._v(
                                              "--------（入力されたキーワードは本文に存在しませんでした）--------"
                                            ),
                                          ]),
                                        ]
                                      : _vm._l(
                                          data.value,
                                          function (searchedLines, index) {
                                            return _c(
                                              "p",
                                              { key: index },
                                              [
                                                _vm._l(
                                                  searchedLines,
                                                  function (word, wordIndex) {
                                                    return [
                                                      _vm.searchWords.filter(
                                                        (v) => v === word
                                                      ).length > 0
                                                        ? [
                                                            _c(
                                                              "span",
                                                              {
                                                                key: wordIndex,
                                                                staticClass:
                                                                  "highlight",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(word)
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        : [
                                                            _vm._v(
                                                              _vm._s(word)
                                                            ),
                                                          ],
                                                    ]
                                                  }
                                                ),
                                              ],
                                              2
                                            )
                                          }
                                        ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text-center my-2",
                                attrs: { slot: "table-busy" },
                                slot: "table-busy",
                              },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle",
                                }),
                                _vm._v(" "),
                                _c("strong", [_vm._v("読み込み中...")]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }