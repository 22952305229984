var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "app flex-row align-items-center" }, [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c(
          "b-row",
          { staticClass: "justify-content-center" },
          [
            _c(
              "b-col",
              { attrs: { md: "auto", sm: "auto", lg: "auto" } },
              [
                _c(
                  "b-card",
                  { attrs: { "no-body": "" } },
                  [
                    _c("FinalForm", {
                      attrs: { submit: _vm.save },
                      scopedSlots: _vm._u([
                        {
                          key: "default",
                          fn: function (props) {
                            return _c(
                              "form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    $event.stopPropagation()
                                    return props.handleSubmit.apply(
                                      null,
                                      arguments
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-card-body",
                                  { staticClass: "p-4" },
                                  [
                                    _c("h2", [_vm._v("神ゲー攻略 Wiki 開設")]),
                                    _vm._v(" "),
                                    _c("p", { staticClass: "text-muted" }, [
                                      _vm._v(
                                        "必要事項を記入のうえ「作成」ボタンを押してください。"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "h4",
                                      [
                                        _vm._v("サイト名の設定 "),
                                        _c(
                                          "b-badge",
                                          { attrs: { variant: "info" } },
                                          [_vm._v("必須")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v("Wiki のサイト名を設定します。"),
                                    ]),
                                    _vm._v(" "),
                                    _c("FinalField", {
                                      attrs: {
                                        name: "title",
                                        validate: (v) =>
                                          _vm.validateRequired(v, "サイト名"),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (props) {
                                              return _c(
                                                "b-input-group",
                                                { staticClass: "mb-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group-prepend",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "input-group-text",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon-doc",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "input",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "form-control",
                                                        class: {
                                                          "is-invalid":
                                                            props.meta.error &&
                                                            props.meta.touched,
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          name: props.name,
                                                          placeholder:
                                                            "サイト名を入力してください",
                                                        },
                                                      },
                                                      props.events
                                                    )
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(props.meta.error)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "h4",
                                      [
                                        _vm._v("URL の設定 "),
                                        _c(
                                          "b-badge",
                                          { attrs: { variant: "info" } },
                                          [_vm._v("必須")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "\n                  Wiki の URL を設定します。Wiki の URL は小文字の英数字 (a-z, 0-9) かハイフン (-) のみで構成された 24\n                  文字以内の文字列である必要があります。\n                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("FinalField", {
                                      attrs: {
                                        name: "name",
                                        validate: (v) =>
                                          _vm.validateWikiName(v),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (props) {
                                              return _c(
                                                "b-input-group",
                                                { staticClass: "mb-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group-prepend",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "input-group-text",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon-globe",
                                                          }),
                                                          _vm._v(
                                                            " kamigame.jp/wiki/"
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "input",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "form-control",
                                                        class: {
                                                          "is-invalid":
                                                            props.meta.error &&
                                                            props.meta.touched,
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          name: props.name,
                                                          placeholder:
                                                            "URL を入力してください",
                                                        },
                                                      },
                                                      props.events
                                                    )
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(props.meta.error)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "h4",
                                      [
                                        _vm._v("取り扱いゲームタイトルの設定 "),
                                        _c(
                                          "b-badge",
                                          { attrs: { variant: "info" } },
                                          [_vm._v("必須")]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "Wiki で取り扱うゲームタイトルを設定します。"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("FinalField", {
                                      attrs: {
                                        name: "game",
                                        validate: (v) =>
                                          _vm.validateRequired(
                                            v,
                                            "ゲームタイトル"
                                          ),
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (props) {
                                              return _c(
                                                "b-input-group",
                                                { staticClass: "mb-3" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "input-group-prepend",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "input-group-text",
                                                        },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "icon-game-controller",
                                                          }),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "input",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "form-control",
                                                        class: {
                                                          "is-invalid":
                                                            props.meta.error &&
                                                            props.meta.touched,
                                                        },
                                                        attrs: {
                                                          type: "text",
                                                          name: props.name,
                                                          placeholder:
                                                            "ゲームタイトルを入力してください",
                                                        },
                                                      },
                                                      props.events
                                                    )
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "invalid-feedback",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(props.meta.error)
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("h4", [_vm._v("Wiki 説明文の設定")]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "\n                  この Wiki\n                  についての説明文を設定します。はじめて訪問する方や、編集に参加する方にとってわかりやすい文章にしましょう。\n                "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("FinalField", {
                                      attrs: { name: "description" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function (props) {
                                              return _c(
                                                "b-input-group",
                                                { staticClass: "mb-3" },
                                                [
                                                  _c(
                                                    "textarea",
                                                    _vm._g(
                                                      {
                                                        staticClass:
                                                          "form-control",
                                                        attrs: {
                                                          rows: "6",
                                                          name: props.name,
                                                          placeholder:
                                                            "説明文を入力してください",
                                                        },
                                                      },
                                                      props.events
                                                    )
                                                  ),
                                                ]
                                              )
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("b-form-textarea", {
                                      attrs: { rows: 10, readonly: "" },
                                      model: {
                                        value: _vm.term,
                                        callback: function ($$v) {
                                          _vm.term = $$v
                                        },
                                        expression: "term",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        model: {
                                          value: _vm.termAccepted,
                                          callback: function ($$v) {
                                            _vm.termAccepted = $$v
                                          },
                                          expression: "termAccepted",
                                        },
                                      },
                                      [_vm._v("利用規約に同意する")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-card-footer",
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-danger",
                                        attrs: {
                                          show:
                                            !props.submitting &&
                                            props.hasSubmitErrors,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              props.submitErrors
                                                ? props.submitErrors.FORM_ERROR
                                                : ""
                                            ) +
                                            "\n                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        attrs: {
                                          type: "submit",
                                          variant: "success",
                                          block: "",
                                          disabled:
                                            !_vm.termAccepted ||
                                            props.hasValidationErrors ||
                                            props.submitting,
                                        },
                                      },
                                      [_vm._v("この内容で Wiki を開設する")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          },
                        },
                      ]),
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }