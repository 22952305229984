
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import KamigameVue from '@/KamigameVue';
import { WikiPageTitleSearchModal } from '@/components';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-page-existance-state-update-modal',
  components: {
    'kamigame-wiki-page-title-search-modal': WikiPageTitleSearchModal,
  },
})
export default class WikiPageExistanceStateUpdateModal extends KamigameVue {
  @Prop()
  deletedWikiPageId!: string;

  wikiPageTitles: components['schemas']['v1WikiPageTitle'][] = [];
  selectedWikiPageTitleId: string = '';
  isDeletion: boolean = false;

  async mounted() {
    const response = await this.apiClient
      .GET('/admin/wiki/{wikiName}/page/titles', {
        params: {
          path: {
            wikiName: this.wikiName,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
    if (!response.wikiPageTitles) {
      return;
    }

    this.wikiPageTitles = response.wikiPageTitles.filter(
      (wikiPageTitle) => wikiPageTitle.id !== this.deletedWikiPageId
    );
  }

  onWikiPageTitleSet(selectedWikiPageTitle: components['schemas']['v1WikiPageTitle']) {
    this.selectedWikiPageTitleId = selectedWikiPageTitle.id || '';
  }

  updateWikiPageExistanceState() {
    if (!this.deletedWikiPageId) {
      return;
    }

    if (!this.isDeletion && !this.selectedWikiPageTitleId) {
      return;
    }

    this.isDeletion ? this.deleteWikiPage() : this.setRedirect();
  }

  setRedirect() {
    this.apiClient
      .PUT('/admin/wiki/{wikiName}/page/{pageId}/redirect', {
        params: {
          path: {
            wikiName: this.wikiName,
            pageId: this.deletedWikiPageId,
          },
        },
        body: {
          redirectPageId: this.selectedWikiPageTitleId,
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(() => {
        this.setFlashMessage('success', '記事のリダイレクト先設定に成功しました');
        this.$emit('wikiPageDeleted');
      })
      .catch(() => {
        this.setFlashMessage('danger', '記事のリダイレクト設定に失敗しました');
      })
      .finally(() => {
        this.clear();
      });
  }

  deleteWikiPage() {
    this.apiClient
      .DELETE('/admin/wiki/{wikiId}/page/{pageId}', {
        params: {
          path: {
            wikiId: this.wikiName,
            pageId: this.deletedWikiPageId,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(() => {
        this.setFlashMessage('success', '記事の削除に成功しました');
        this.$emit('wikiPageDeleted');
      })
      .catch(() => {
        this.setFlashMessage('danger', '記事の削除に失敗しました');
      })
      .finally(() => {
        this.clear();
      });
  }

  clear() {
    this.selectedWikiPageTitleId = '';
    this.isDeletion = false;
  }
}
