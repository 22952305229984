import axios from 'axios';

export async function videoUpload(
  token: string,
  editor: any,
  videos: File[],
  onEditorChange: () => void
): Promise<void> {
  await Promise.all(
    videos.map(async (video) => {
      editor.insertText(uploadingText(video.name));
      const startPos = editor.getCodeMirror().posFromIndex(editor.getMarkdown().indexOf(uploadingText(video.name)));
      const endPos = { line: startPos.line, ch: startPos.ch + uploadingText(video.name).length };

      try {
        const result = await upload(token, video);
        // 動画の最適化が終わって CloudFront 経由でアクセスできるようになるまで待つ
        await waitVideoUrlToBeValid(result.url);
        editor.getCodeMirror().replaceRange(result.url, startPos, endPos);
      } catch (e) {
        console.error(e);
        editor.getCodeMirror().replaceRange(`${video.name} のアップロードに失敗しました`, startPos, endPos);
      }
    })
  );

  onEditorChange();
}

function uploadingText(text: string): string {
  return `${text} をアップロードしています...`;
}

async function waitVideoUrlToBeValid(url: string) {
  const maxRetryCount = 20;
  let tryCount = 0;
  let delay = 5000;
  while (tryCount <= maxRetryCount) {
    await new Promise((resolve) => setTimeout(resolve, delay));
    const response = await axios.get(url, { validateStatus: () => true });
    if (response.status === 200) {
      return;
    }
    tryCount += 1;
  }

  throw new Error('Failed to get video url');
}

async function upload(
  token: string,
  file: File
): Promise<{ name: string; url: string; original_gcs_path: string; transcoded_gcs_path: string }> {
  const data = new FormData();
  data.append('video', file);

  const res = await axios({
    method: 'POST',
    url: `${API_URL_BASE}/admin/video/upload`,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data,
  });
  const videos = (res.data as any).videos;
  if (!videos[file.name]) {
    throw new Error('アップロードに失敗しました');
  }

  return videos[file.name];
}
