
import Component from 'vue-class-component';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-slack-config',
  components: {
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiSlackConfig extends KamigameVue {
  name: string = '';
  saveDisabled: boolean = true;

  notifyArticleCreateAndUpdate: boolean = false;
  notifyArticleDelete: boolean = false;
  notifyArticleDraftUpdate: boolean = false;
  notifyPartialCreateAndUpdate: boolean = false;
  notifyCategoryCreateAndUpdate: boolean = false;
  notifyDatasheetsCreateAndUpdate: boolean = false;
  notifyChannelName: string = '';

  savedNotifyArticleCreateAndUpdate: boolean = false;
  savedNotifyArticleDelete: boolean = false;
  savedNotifyArticleDraftUpdate: boolean = false;
  savedNotifyPartialCreateAndUpdate: boolean = false;
  savedNotifyCategoryCreateAndUpdate: boolean = false;
  savedNotifyDatasheetsCreateAndUpdate: boolean = false;
  savedNotifyChannelName: string = '';

  async mounted() {
    try {
      const slackConfig = await this.apiClient
        .GET('/admin/wiki/{wikiName}/slack-config', {
          params: { path: { wikiName: this.wikiName } },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }
          return r.data;
        });
      this.notifyArticleCreateAndUpdate = slackConfig.notifyArticleCreateAndUpdate || false;
      this.notifyArticleDelete = slackConfig.notifyArticleDelete || false;
      this.notifyArticleDraftUpdate = slackConfig.NotifyArticleDraftUpdate || false;
      this.notifyPartialCreateAndUpdate = slackConfig.notifyPartialCreateAndUpdate || false;
      this.notifyCategoryCreateAndUpdate = slackConfig.notifyCategoryCreateAndUpdate || false;
      this.notifyDatasheetsCreateAndUpdate = slackConfig.notifyDatasheetsCreateAndUpdate || false;
      this.notifyChannelName = slackConfig.notifyChannelName || '';
      this.updateSavedContentStatus();
      this.saveDisabled = false;
    } catch (e) {
      this.setFlashMessage('danger', 'データの取得に失敗しました。通信環境などを確認してみてください。');
    }
  }

  async save() {
    const request: components['schemas']['v1WikiSlackConfig'] = {
      wikiName: this.wikiName,
      notifyArticleCreateAndUpdate: this.notifyArticleCreateAndUpdate,
      notifyArticleDelete: this.notifyArticleDelete,
      NotifyArticleDraftUpdate: this.notifyArticleDraftUpdate,
      notifyPartialCreateAndUpdate: this.notifyPartialCreateAndUpdate,
      notifyCategoryCreateAndUpdate: this.notifyCategoryCreateAndUpdate,
      notifyDatasheetsCreateAndUpdate: this.notifyDatasheetsCreateAndUpdate,
      notifyChannelName: this.notifyChannelName,
    };
    try {
      this.saveDisabled = true;
      await this.apiClient
        .PUT('/admin/wiki/{wikiName}/slack-config', {
          params: {
            path: { wikiName: this.wikiName },
          },
          body: request,
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }
        });
      this.updateSavedContentStatus();
      this.setFlashMessage('success', '変更を保存しました。');
    } catch (e) {
      console.log(e);
      this.setFlashMessage('danger', '変更の保存に失敗しました。もう一度お試しください。');
    } finally {
      this.saveDisabled = false;
    }
  }

  updateSavedContentStatus() {
    this.savedNotifyArticleCreateAndUpdate = this.notifyArticleCreateAndUpdate;
    this.savedNotifyArticleDelete = this.notifyArticleDelete;
    this.savedNotifyArticleDraftUpdate = this.notifyArticleDraftUpdate;
    this.savedNotifyPartialCreateAndUpdate = this.notifyPartialCreateAndUpdate;
    this.savedNotifyCategoryCreateAndUpdate = this.notifyCategoryCreateAndUpdate;
    this.savedNotifyDatasheetsCreateAndUpdate = this.notifyDatasheetsCreateAndUpdate;
    this.savedNotifyChannelName = this.notifyChannelName;
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    return (
      this.savedNotifyArticleCreateAndUpdate !== this.notifyArticleCreateAndUpdate ||
      this.savedNotifyArticleDelete !== this.notifyArticleDelete ||
      this.savedNotifyArticleDraftUpdate !== this.notifyArticleDraftUpdate ||
      this.savedNotifyPartialCreateAndUpdate !== this.notifyPartialCreateAndUpdate ||
      this.savedNotifyCategoryCreateAndUpdate !== this.notifyCategoryCreateAndUpdate ||
      this.savedNotifyDatasheetsCreateAndUpdate !== this.notifyDatasheetsCreateAndUpdate ||
      this.savedNotifyChannelName !== this.notifyChannelName
    );
  }
}
