
import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';

import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-create',
})
export default class WikiInvite extends KamigameVue {
  loaded = false;
  errored = false;

  wiki: components['schemas']['v1Wiki'] = {};
  wikiMemberInvite: components['schemas']['v1WikiMemberInvite'] = {};

  mounted() {
    Promise.all([
      this.apiClient.GET('/wiki/{name}', { params: { path: { name: this.wikiName } } }).then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      }),
      this.apiClient
        .GET('/wiki/{wikiName}/member-invite/{id}', {
          params: { path: { wikiName: this.wikiName, id: this.inviteId } },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }

          return r.data;
        }),
    ])
      .then((results) => {
        this.loaded = true;

        [this.wiki, this.wikiMemberInvite] = results;
      })
      .catch((e) => {
        this.loaded = true;
        this.errored = true;
      });
  }

  async join() {
    this.apiClient
      .POST('/wiki/{wikiName}/member-invite/{id}', {
        params: {
          path: {
            wikiName: this.wikiName,
            id: this.inviteId,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(() => this.$store.getters.getAbility(this.wikiName, true))
      .then(() => {
        this.setFlashMessage('success', '招待を受諾しました');

        this.router.push({
          name: 'wiki_dashboard',
          params: { name: this.wikiName },
        });
      });
  }

  get inviteId(): string {
    return this.route.params.id || '';
  }
}
