var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn wiki-page-advice-list" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c(
                  "div",
                  { attrs: { slot: "header" }, slot: "header" },
                  [
                    _c("strong", [_vm._v("記事指摘一覧")]),
                    _vm._v("\n          ["),
                    _c("b-link", { on: { click: _vm.switchHandled } }, [
                      _vm._v(
                        _vm._s(
                          _vm.isHandled
                            ? "未対応の指摘を表示"
                            : "対応済みの指摘を表示"
                        )
                      ),
                    ]),
                    _vm._v("]\n        "),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "b-table",
                  {
                    attrs: {
                      responsive: "",
                      fields: _vm.fields,
                      items: _vm.advices,
                      "per-page": _vm.perPage,
                      "current-page": _vm.currentPage,
                      "empty-text": _vm.isHandled
                        ? "対応済みの指摘はありません"
                        : "未対応の指摘はありません",
                      "show-empty": "",
                      busy: _vm.loading,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(title)",
                        fn: function (data) {
                          return [
                            data.item.isHandled
                              ? _c("span", { staticClass: "edit-page" }, [
                                  _vm._v(_vm._s(data.item.title)),
                                ])
                              : _c(
                                  "b-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "wiki_page_edit",
                                        params: { paths: data.item.articleId },
                                      },
                                    },
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(_vm._s(data.item.title)),
                                    ]),
                                  ]
                                ),
                          ]
                        },
                      },
                      {
                        key: "cell(body)",
                        fn: function (data) {
                          return [
                            _vm._v(
                              "\n            " +
                                _vm._s(data.item.body) +
                                "\n          "
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(_operation)",
                        fn: function (data) {
                          return [
                            !data.item.isHandled
                              ? _c(
                                  "b-button",
                                  {
                                    staticClass: "m-1",
                                    attrs: {
                                      size: "sm",
                                      variant: "outline-primary",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.showHandleAdviceModal(
                                          data.index
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("対応済みにする")]
                                )
                              : _c("div", [_vm._v("対応済み")]),
                            _vm._v(" "),
                            _c(
                              "b-modal",
                              {
                                ref: `handleAdvice_${data.index}`,
                                attrs: {
                                  "hide-header": "",
                                  centered: "",
                                  "no-fade": "",
                                  lazy: "",
                                },
                                on: {
                                  ok: function ($event) {
                                    return _vm.handleAdvice(data.item.id)
                                  },
                                },
                              },
                              [_c("p", [_vm._v("対応完了としますか？")])]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "text-center my-2",
                        attrs: { slot: "table-busy" },
                        slot: "table-busy",
                      },
                      [
                        _c("b-spinner", { staticClass: "align-middle" }),
                        _vm._v(" "),
                        _c("strong", [_vm._v("読み込み中...")]),
                      ],
                      1
                    ),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }