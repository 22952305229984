function kamigameLoadGametoolExtension(editor: any) {
  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem({
    type: 'button',
    options: {
      name: 'load-gametool',
      className: `tui-editor-custom-button tui-gametool`,
      command: 'load-gametool',
      event: 'load-gametool',
      tooltip: 'ゲームツール読み込み',
    },
  });

  editor.eventManager.addEventType('load-gametool');
  editor.commandManager.addCommand('markdown', {
    name: 'load-gametool',
    exec: () => {
      editor.eventManager.emit('load-gametool');
    },
  });
}

export default kamigameLoadGametoolExtension;
