
import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';

import KamigameVue from '@/KamigameVue';
import { Paging } from '@/components';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-deleted-page-list',
  components: {
    'kamigame-paging': Paging,
  },
})
export default class WikiDeletedPageList extends KamigameVue {
  dateFormat = format;

  loading = false;
  pages: components['schemas']['v1WikiPageForListCollection'] = { wikiPage: [] };
  fields = [
    { thStyle: { width: '55%' }, key: 'title', label: '記事名' },
    { key: 'lastUpdatedAt', label: '削除日' },
    { key: 'lastUpdater', label: '削除者' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions = [
    { text: '削除日が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '削除日が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'タイトル順', value: { sortedBy: 'title', sortOrder: 'ASC' } },
  ];
  perRequestWikiPageNum = 20;
  totalPageNum = 0;
  searchString = '';
  selectedSortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  kamigamePaging = this.$refs.kamigamePaging as Paging;

  async mounted() {
    this.kamigamePaging = this.$refs.kamigamePaging as Paging;
    this.getWikiPages();
  }

  async getWikiPages(startAt: number = 0) {
    this.loading = true;
    await this.apiClient
      .GET('/wiki/{wikiName}/page', {
        params: {
          path: {
            wikiName: this.wikiName,
          },
          query: {
            isDeleted: true,
            limit: this.perRequestWikiPageNum,
            offset: startAt,
            searchWords: this.searchWords,
            sortedBy: this.selectedSortCondition.sortedBy,
            sortOrder: this.selectedSortCondition.sortOrder,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r;
        }

        return r.data;
      })
      .then((res) => {
        this.loading = false;
        this.pages.wikiPage = res.wikiPage;
        this.totalPageNum = res.numOfTotalWikiPages || 0;
        if (startAt === 0) {
          this.kamigamePaging.resetPaging();
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          this.pages.wikiPage = [];
          this.totalPageNum = 0;
          this.loading = false;
        }
      });
  }

  changeDataRange(startAt: number) {
    this.getWikiPages(startAt);
  }

  async restorePage(pageId: string, index: number) {
    this.apiClient
      .POST('/admin/wiki/{wikiId}/page/{pageId}/restore', {
        params: {
          path: {
            wikiId: this.wikiName,
            pageId,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(() => {
        if (this.pages.wikiPage === undefined) {
          return;
        }

        this.pages.wikiPage.splice(index, 1);
        this.setFlashMessage('success', 'ページを復元しました。');
      });
  }

  showRestoreModal(index: number) {
    const modal = this.$refs[`restorePage_${index}`] as BModal;
    modal.show();
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.searchString);
  }
}
