function kamigameWikiPageTemplateInsertExtension(editor: any) {
  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem({
    type: 'button',
    options: {
      name: 'wiki-page-template-insert',
      className: 'tui-editor-custom-button fa fa-file-text-o',
      event: 'show-wiki-page-template-insert-modal',
      tooltip: 'テンプレートを読み込む',
    },
  });

  editor.eventManager.addEventType('show-wiki-page-template-insert-modal');
}

export default kamigameWikiPageTemplateInsertExtension;
