function kamigameWikiPagePartialInsertExtension(editor: any) {
  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem('divider');
  toolbar.addItem({
    type: 'button',
    options: {
      name: 'wiki-page-partial-insert',
      className: 'tui-editor-custom-button fa fa-puzzle-piece',
      event: 'show-wiki-page-partial-insert-modal',
      tooltip: '記事部品を挿入',
    },
  });

  editor.eventManager.addEventType('show-wiki-page-partial-insert-modal');
  editor.commandManager.addCommand('markdown', {
    name: 'show-wiki-page-partial-insert-modal',
    exec() {
      editor.eventManager.emit('show-wiki-page-partial-insert-modal');
    },
  });
}

export default kamigameWikiPagePartialInsertExtension;
