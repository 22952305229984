
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';
import VueRouter from 'vue-router';
import { Watch } from 'vue-property-decorator';

import { SortableTable } from '@/components';
import KamigameVue from '@/KamigameVue';
import { WikiPageTitleSearchModal } from '@/components';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-nav',
  components: {
    'kamigame-sortable-table': SortableTable,
    'kamigame-wiki-page-titles-search-modal': WikiPageTitleSearchModal,
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiNav extends KamigameVue {
  items: [symbol, components['schemas']['v1WikiNav']][] = [];
  wikiPageTitles: components['schemas']['v1WikiPageTitle'][] = [];
  selectedWikiPageTitle: components['schemas']['v1WikiPageTitle'] = {};
  takahiroMenu: any = {};
  takahiroGames: any[] = [];
  selectedImportTakahiroGame: string = '';

  draftItems: components['schemas']['v1WikiNav'][] = [];
  savedItems: components['schemas']['v1WikiNav'][] = [];

  @Watch('items', { deep: true })
  onUpdateItems(newValue: [symbol, components['schemas']['v1WikiNav']][]) {
    this.draftItems = JSON.parse(JSON.stringify(newValue.map(([_, v]) => v)));
  }

  async mounted() {
    this.apiClient.GET('/wiki/{name}/nav', { params: { path: { name: this.wikiName } } }).then((response) => {
      if (response.error) {
        throw response.error;
      }

      this.items = (response.data.nav || []).map(
        (nav) => [Symbol(), nav] as [symbol, components['schemas']['v1WikiNav']]
      );
      const itemsJson = JSON.stringify(this.items.map(([_, v]) => v));
      this.savedItems = JSON.parse(itemsJson);
      this.draftItems = JSON.parse(itemsJson);
    });
  }

  addRow() {
    this.items.push([
      Symbol(),
      {
        wikiPageID: this.selectedWikiPageTitle.id,
        wikiPageTitle: this.selectedWikiPageTitle.title,
      },
    ]);
  }

  removeRow(id: symbol) {
    const renew = new Map(this.items);
    const toBeRemoved = renew.get(id);
    if (
      !toBeRemoved ||
      !window.confirm(
        `本当にこの項目を削除してもよろしいですか？\n項目名: ${toBeRemoved.name}\nリンク先: ${toBeRemoved.wikiPageTitle}`
      )
    ) {
      return;
    }
    renew.delete(id);

    this.items = Array.from(renew.entries());
  }

  async save() {
    const navList: components['schemas']['v1ListWikiNavWithWiki'] = {
      nav: Array.from(new Map(this.items).values()).map((v, k) => Object.assign(v, { sortScore: k })),
    };
    this.apiClient
      .PUT('/admin/wiki/{wiki.name}/nav', {
        params: {
          path: {
            'wiki.name': this.wikiName,
          },
        },
        body: navList,
      })
      .then((response) => {
        if (response.error) {
          throw response.error;
        }

        const itemsJson = JSON.stringify(this.items.map(([_, v]) => v));
        this.savedItems = JSON.parse(itemsJson);
        this.draftItems = JSON.parse(itemsJson);
        this.setFlashMessage('success', '変更を保存しました。', this.wikiTopUrl, 'サイト上で内容を確認する', true);
      });
  }

  async importFromTakahiro() {
    const gameMenu = this.takahiroMenu[this.selectedImportTakahiroGame];
    const navList: components['schemas']['v1ListWikiNavWithWiki'] = {
      nav: gameMenu.map((m: any, i: Number) => {
        const [name, rawPathName] = m;
        const pathName = rawPathName
          .replace(/\.html$/, '')
          .split('/')
          .slice(2)
          .map(decodeURIComponent)
          .join('/');

        return { name, wikiPageCanonicalName: pathName, sortScore: i };
      }),
    };

    this.apiClient
      .PUT('/admin/wiki/{wiki.name}/nav', { params: { path: { 'wiki.name': this.wikiName } }, body: navList })
      .then((response) => {
        if (response.error) {
          throw response.error;
        }

        this.setFlashMessage('success', '変更を保存しました。', this.wikiTopUrl, 'サイト上で内容を確認する', true);

        this.items = (response.data.nav || []).map(
          (nav) => [Symbol(), nav] as [symbol, components['schemas']['v1WikiNav']]
        );
      })
      .catch((e) => {
        this.setFlashMessage(
          'danger',
          'グローバルナビのインポートに失敗しました。事前に takahiro-games からの記事移行が完了しているかどうかを確認してください。'
        );
      });
  }

  loadWikiPageTitles() {
    if (this.wikiPageTitles && this.wikiPageTitles.length > 0) {
      return;
    }

    return this.apiClient
      .GET('/admin/wiki/{wikiName}/page/titles', { params: { path: { wikiName: this.wikiName } } })
      .then((response) => {
        if (response.error) {
          throw response.error;
        }

        if (!response.data.wikiPageTitles) {
          return;
        }

        this.wikiPageTitles = response.data.wikiPageTitles;
      });
  }

  async showAddLinkWikiPageModal() {
    await this.loadWikiPageTitles();

    const modal = this.$refs.modalSelectWikiPage_new as BModal;
    modal.show();
  }

  async showSetLinkWikiPageModal(index: number) {
    await this.loadWikiPageTitles();

    const modal = this.$refs[`modalSelectWikiPage_${index}`] as BModal;
    modal.show();
  }

  onWikiPageTitleSet(selectedWikiPageTitle: components['schemas']['v1WikiPageTitle']) {
    this.selectedWikiPageTitle = selectedWikiPageTitle;
  }

  setLinkWikiPage(id: symbol) {
    const items = new Map(this.items);
    const toBeChangedLinkPage = items.get(id);
    if (!toBeChangedLinkPage) {
      return;
    }

    toBeChangedLinkPage.wikiPageID = this.selectedWikiPageTitle.id;
    toBeChangedLinkPage.wikiPageTitle = this.selectedWikiPageTitle.title;

    this.items = Array.from(items.entries());
  }

  resetSearchModal() {
    (this.$refs.selectWikiPageTitle as WikiPageTitleSearchModal).reset();
  }

  resetSearchNewModal() {
    (this.$refs.selectWikiPageTitleNew as WikiPageTitleSearchModal).reset();
  }

  loadTakahiroMenu() {
    fetch('https://kamigame.jp/menu.json?from-wiki')
      .then((response) => response.json())
      .then((obj) => {
        this.takahiroMenu = obj;
        this.takahiroGames = [{ value: '', text: 'インポート対象のタイトルを選択してください' }].concat(
          Object.keys(this.takahiroMenu).map((k) => ({ value: k, text: k }))
        );
      });
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    if (this.draftItems.length !== this.savedItems.length) {
      return true;
    }

    for (let i = 0; i < this.draftItems.length; ++i) {
      if (
        this.draftItems[i].name !== this.savedItems[i].name ||
        this.draftItems[i].wikiPageID !== this.savedItems[i].wikiPageID ||
        this.draftItems[i].wikiPageTitle !== this.savedItems[i].wikiPageTitle
      ) {
        return true;
      }
    }

    return false;
  }
}
