
import format from 'date-fns/format';
import Component from 'vue-class-component';
import * as JsDiff from 'diff';

import KamigameVue from '@/KamigameVue';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-page-history',
})
export default class WikiPageHistory extends KamigameVue {
  dateFormat = format;

  aHistory?: components['schemas']['v1WikiPageHistory'];
  bHistory?: components['schemas']['v1WikiPageHistory'];

  diffLines: any[] = [];
  pageId: string = '';
  lastUpdater: string = '';
  lastUpdatedAt: Date = new Date();

  async mounted() {
    const api = this.apiClient;

    const a = this.route.params.a;
    const b = this.route.params.b;

    let aBody = '';
    if (a) {
      this.aHistory = await api
        .GET('/admin/wiki/{wikiName}/history/{historyId}', {
          params: {
            path: {
              wikiName: this.wikiName,
              historyId: a,
            },
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }

          return r.data;
        });
      aBody = this.aHistory?.body || '';
    }
    this.bHistory = await api
      .GET('/admin/wiki/{wikiName}/history/{historyId}', {
        params: {
          path: {
            wikiName: this.wikiName,
            historyId: b,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });

    if (this.bHistory?.wikiPage && this.bHistory?.wikiPage?.id) {
      this.pageId = this.bHistory?.wikiPage?.id;
    } else {
      this.pageId = this.route.params.pageId;
    }

    if (this.bHistory?.author) {
      this.lastUpdater = this.bHistory?.author?.nickname || '';
    }
    this.lastUpdatedAt = (this.bHistory?.createdAt ? new Date(this.bHistory?.createdAt) : undefined) || new Date();

    this.diffLines = JsDiff.diffLines(aBody || '', this.bHistory?.body || '')
      .map((l) => {
        let type = '';
        if (l.added) {
          type = 'added';
        } else if (l.removed) {
          type = 'removed';
        }

        return l.value
          .replace(/\s$/, '')
          .split(/\n/)
          .map((value) => ({ type, value }));
      })
      .reduce((acc, c) => acc.concat(c), []);
  }
}
