export function mapHeaderToColumns(cells: string[][]): { [header: string]: string[] } {
  const headers = cells[0];
  const headerToColumns: { [header: string]: string[] } = {};

  headers.forEach((header, i) => {
    headerToColumns[header] = cells.slice(1).map((row) => row[i] ?? '');
  });

  return headerToColumns;
}
