import storage from 'local-storage-fallback';

const configKey = 'kamigame-markdown-editor-view-mode-config';

const previewMode = [
  { label: '標準', width: '' },
  { label: 'iPhone 7', width: '375px', default: true },
  { label: 'Galaxy S5', width: '360px' },
  { label: 'iPhone 5s', width: '320px' },
  { label: 'iPhone 7 Plus', width: '414px' },
  { label: 'iPad Mini', width: '768px' },
];

function modifyButtonToBeBootstrapDropdown(id: string, button: HTMLElement) {
  button.id = id;
  button.dataset.toggle = 'dropdown';
  button.setAttribute('aria-haspopup', 'true');
  button.setAttribute('aria-expanded', 'false');
}

function createDropdownMenu(button: HTMLElement): HTMLElement | undefined {
  if (!button.parentElement) {
    return;
  }

  const menu = document.createElement('div');
  menu.className = 'dropdown-menu';
  menu.style.position = 'absolute';
  menu.style.left = `${button.offsetLeft}px`;
  menu.style.top = `${button.offsetHeight}px`;
  menu.setAttribute('aria-labelledby', button.id);

  previewMode.forEach((v, k) => {
    menu.appendChild(createDropdownMenuItem(v.label, k.toString(), !!v.default));
  });
  button.parentElement.appendChild(menu);

  return menu;
}

function createDropdownMenuItem(label: string, key: string, isDefault: boolean): HTMLElement {
  const item = document.createElement('a');
  item.className = 'dropdown-item';
  item.textContent = label;
  item.dataset.key = key;
  item.href = '#';
  if (isDefault) {
    item.classList.add('active');
  }
  return item;
}

function applyMenuCloseListener(button: HTMLElement, menu: HTMLElement, editor: any) {
  const closeListener = (e: Event) => {
    if (!menu.classList.contains('show')) {
      return;
    }

    const targetElement = e.target as HTMLElement;
    if (
      !targetElement ||
      targetElement.id === button.id ||
      targetElement.classList.contains('dropdown-item') ||
      targetElement.classList.contains('dropdown-menu')
    ) {
      return;
    }

    menu.classList.remove('show');
    document.removeEventListener('click', closeListener);
  };

  editor.eventManager.addEventType('mobile-preview');
  editor.eventManager.listen('mobile-preview', () => {
    if (menu.classList.contains('show')) {
      menu.classList.remove('show');
      document.removeEventListener('click', closeListener);
    } else {
      menu.classList.add('show');
      document.addEventListener('click', closeListener);
    }
  });
}

function applyMenuItemEvent(menu: HTMLElement, editor: any) {
  menu.addEventListener('click', (e) => {
    const targetElement = e.target as HTMLElement;
    if (!targetElement || !targetElement.classList.contains('dropdown-item')) {
      return;
    }

    for (const child of menu.children) {
      if ((child as HTMLElement).dataset.key === targetElement.dataset.key) {
        child.classList.add('active');
      } else {
        child.classList.remove('active');
      }
    }

    const key = parseInt(targetElement.dataset.key || '0', 10);
    const selected = previewMode[key];
    if (!selected) {
      editor.eventManager.emit('mobile-preview');
      return;
    }

    const previewArea = editor.layout.el.querySelector('.te-preview .kamigame-editor-contents') as HTMLElement;
    if (!previewArea) {
      editor.eventManager.emit('mobile-preview');
      return;
    }
    previewArea.style.width = selected.width;
    editor.eventManager.emit('mobile-preview');
    storage.setItem(configKey, selected.label);
  });
}

function setUpButton(buttonId: string, editor: any): HTMLElement | undefined {
  const editorWrapper = editor.layout.el;
  if (!editorWrapper) {
    return;
  }

  const button = editorWrapper.querySelector('.tui-editor-custom-button.mobile-preview') as HTMLElement;
  if (!button) {
    return;
  }
  modifyButtonToBeBootstrapDropdown(buttonId, button);

  return button;
}

function toggleViewModeExtension(editor: any) {
  const mode = storage.getItem(configKey) || '';
  if (mode !== '') {
    previewMode.forEach((v, k) => {
      if (v.label !== mode) {
        previewMode[k].default = false;
        return;
      }
      previewMode[k].default = true;
    });
  }

  const defaultMode = previewMode.find((v) => v.default);
  if (defaultMode) {
    const previewArea = editor.layout.el.querySelector('.te-preview .kamigame-editor-contents') as HTMLElement;
    if (!previewArea) {
      const setDefaultPreview = () => {
        const previewArea = editor.layout.el.querySelector('.te-preview .kamigame-editor-contents') as HTMLElement;
        previewArea.style.width = defaultMode.width || '';
        document.removeEventListener('kamigame-set-default-preview', setDefaultPreview);
      };
      document.addEventListener('kamigame-set-default-preview', setDefaultPreview);
    } else {
      previewArea.style.width = defaultMode.width || '';
    }
  }

  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem('divider');
  toolbar.addItem({
    type: 'button',
    options: {
      name: 'mobile-preview',
      className: 'tui-editor-custom-button mobile-preview fa fa-mobile dropdown-toggle',
      event: 'mobile-preview',
      tooltip: 'モバイル端末の見た目でプレビュー',
    },
  });

  const button = setUpButton('mobilePreviewButton', editor);
  if (!button) {
    return;
  }
  const menu = createDropdownMenu(button);
  if (!menu) {
    return;
  }
  applyMenuItemEvent(menu, editor);
  applyMenuCloseListener(button, menu, editor);
}

export default toggleViewModeExtension;
