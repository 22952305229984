export default function extractDataSheetNamesFromEjs(template: string) {
  const dataSheetNameRegexp = /of (.+)\)/g;
  const sheetNames: string[] = [];
  let dataSheetNameMatches: RegExpExecArray | null;

  while ((dataSheetNameMatches = dataSheetNameRegexp.exec(template)) !== null) {
    const sheetName = dataSheetNameMatches[1];
    sheetNames.push(sheetName);
  }

  return sheetNames;
}
