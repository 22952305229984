
import Component from 'vue-class-component';
import LiquorTree from 'vls-liquor-tree';
import { Prop } from 'vue-property-decorator';

import KamigameVue from '@/KamigameVue';

import { components } from '@/api-client/schema';

@Component({
  name: 'kamigame-category-tree-select-modal',
  components: {
    [LiquorTree.name]: LiquorTree,
  },
})
export default class CategoryTreeSelectModal extends KamigameVue {
  @Prop()
  modalId!: string;

  @Prop()
  modalTitle!: string;

  saveNewCategoryMessage = '';
  saveNewCategoryClass = '';
  filter = '';
  newCategoryName = '';
  selectedCategory: components['schemas']['v1WikiPageCategory'] = {};
  flashShowingSecond = 5;

  categoryTreeOptions = {
    parentSelect: true,
    filter: {
      emptyText: '指定されたカテゴリが見つかりませんでした',
      matcher: (query: string, node: any) => {
        if (node.states.selected) {
          return true;
        }

        return node.data.displayName.startsWith(query);
      },
    },
    fetchData: (node: any) => {
      const fetcher = node.id === 'root' ? this.fetchParentCategories() : this.fetchChildrenCategories(node.data);
      return fetcher.then((categories: components['schemas']['v1WikiPageCategory'][]) => {
        return categories.map((c) => this.transformCategoryToTreeItem(c));
      });
    },
  };

  get categoryTree() {
    return this.$refs.categoryTree as any;
  }

  get selectedParentName() {
    if (!this.selectedCategory || !this.selectedCategory.displayName) {
      return '';
    }

    return this.selectedCategory.displayName.split('/')[0];
  }

  get prefixedFilter() {
    const prefix = this.selectedParentName ? `${this.selectedParentName}/` : '';

    return `${prefix}${this.filter}`;
  }

  fetchParentCategories() {
    return this.apiClient
      .GET('/wiki/{name}/category', {
        params: {
          path: {
            name: this.wikiName,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then((response) => {
        return response.wikiPageCategories || [];
      });
  }

  fetchChildrenCategories(category: components['schemas']['v1WikiPageCategory']) {
    return this.apiClient
      .GET('/wiki/{wikiName}/category/{id}', {
        params: {
          path: {
            wikiName: this.wikiName,
            id: category.id || '',
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then((response) => {
        return response.wikiPageCategories || [];
      });
  }

  setFlash(flash: { message: string; class: string }) {
    this.saveNewCategoryMessage = flash.message;
    this.saveNewCategoryClass = flash.class;
    setTimeout(() => {
      this.saveNewCategoryMessage = '';
    }, this.flashShowingSecond * 1000);
  }

  transformCategoryToTreeItem(category: components['schemas']['v1WikiPageCategory']) {
    return {
      id: category.id,
      text: category.name,
      data: category,
      isBatch: !category.parentID,
    };
  }

  addAndSelectNewCategory(category: components['schemas']['v1WikiPageCategory']) {
    const newNode = Object.assign(this.transformCategoryToTreeItem(category), {
      state: { selected: true },
    });

    if (category.parentID) {
      const parent = this.categoryTree.find({
        data: {
          id: category.parentID,
        },
      });
      if (parent[0] && parent[0].states.expanded) {
        parent[0].append(newNode);
      }
    } else {
      this.categoryTree.append(newNode);
    }

    this.filter = '';
    this.selectedCategory = category;
  }

  clear() {
    this.filter = '';
    this.newCategoryName = '';
    this.selectedCategory = {};
    const selected = this.categoryTree.tree.selected();
    if (selected) {
      selected.unselect();
    }

    this.$emit('clear');
  }

  onCategorySelected(node: any) {
    //    this.filter = node.data.displayName;
    this.filter = '';

    node.expand();
    this.selectedCategory = node.data;
  }

  onSelectionSaved() {
    const selected = this.categoryTree.tree.selected();
    if (!selected) {
      return;
    }

    this.filter = '';
    this.$emit('selectionSaved', this.selectedCategory);
  }

  onSelectionCanceled() {
    this.clear();

    this.$emit('selectionCanceled');
  }

  onSaveNewCategory() {
    const selected = this.selectedCategory;

    let parentId = undefined;
    let displayName = this.newCategoryName;

    if (selected) {
      parentId = selected.parentID ? selected.parentID : selected.id;
      if (this.selectedParentName) {
        displayName = `${this.selectedParentName}/${this.newCategoryName}`;
      }
    }

    const category = {
      name: this.newCategoryName,
      parentID: parentId,
      displayName,
    };
    this.apiClient
      .POST('/admin/wiki/{name}/category', {
        params: {
          path: {
            name: this.wikiName,
          },
        },
        body: category,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then((result: components['schemas']['v1WikiPageCategory']) => {
        this.setFlash({
          message: 'カテゴリを保存しました',
          class: 'text-success',
        });
        this.clear();
        this.addAndSelectNewCategory(result);

        this.fetchParentCategories();
      })
      .catch((e) => {
        this.setFlash({
          message: 'カテゴリの保存に失敗しました',
          class: 'text-danger',
        });
        console.error(e);
      });
  }
}
