import axios from 'axios';

import { APIClient } from './WikiAPIClientFactory';

export async function uploadWikiDataSheet(api: APIClient, wikiName: string, serializedData: string): Promise<string> {
  const { url, bucketPath } = await api
    .POST('/admin/wiki/{name}/data-sheet/upload-url', {
      params: {
        path: {
          name: wikiName,
        },
      },
      body: {},
    })
    .then((r) => {
      if (r.error) {
        throw r.error;
      }

      return r.data;
    });

  const jsonFile = new File([serializedData], `${Date.now()}.json`, { type: 'application/json' });
  if (!url) {
    throw new Error('No URL to upload wiki data is found');
  }
  if (!bucketPath) {
    throw new Error('No storage bucket path is found');
  }

  await axios.put(url, jsonFile, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });

  return bucketPath;
}

export async function downloadWikiDataSheet(api: APIClient, wikiName: string, bucketPath: string): Promise<any> {
  const { url } = await api
    .POST('/admin/wiki/{name}/data-sheet/download-url', {
      params: {
        path: {
          name: wikiName,
        },
      },
      body: {
        bucketPath,
      },
    })
    .then((r) => {
      if (r.error) {
        throw r.error;
      }

      return r.data;
    });
  if (!url) {
    throw new Error('No URL to download wiki data is found');
  }

  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/octet-stream',
    },
  });

  return JSON.stringify(response.data);
}
