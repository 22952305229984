var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ol",
    { staticClass: "breadcrumb" },
    _vm._l(_vm.list, function (item, index) {
      return _c(
        "li",
        { staticClass: "breadcrumb-item" },
        [
          _vm.isLast(index)
            ? _c("span", { staticClass: "active" }, [
                _vm._v(_vm._s(_vm.showName(item))),
              ])
            : _c("router-link", { attrs: { to: item } }, [
                _vm._v(_vm._s(_vm.showName(item))),
              ]),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }