var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      !_vm.editable
        ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _vm._v(" 編集権限がないため、閲覧専用モードで表示しています。 "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "animated fadeIn wiki-page-partial-edit" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c(
                        "div",
                        { attrs: { slot: "header" }, slot: "header" },
                        [
                          _c("b-form-input", {
                            attrs: {
                              disabled: !_vm.editable,
                              type: "text",
                              placeholder: "記事部品テンプレートの名前を入力",
                            },
                            model: {
                              value: _vm.name,
                              callback: function ($$v) {
                                _vm.name = $$v
                              },
                              expression: "name",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("kamigame-markdown-editor", {
                        ref: "Editor",
                        attrs: { disabled: !_vm.editable, mode: "partial" },
                        on: { save: _vm.save },
                        model: {
                          value: _vm.text,
                          callback: function ($$v) {
                            _vm.text = $$v
                          },
                          expression: "text",
                        },
                      }),
                      _vm._v(" "),
                      _vm.editable
                        ? _c(
                            "div",
                            { attrs: { slot: "footer" }, slot: "footer" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    disabled: _vm.disabled,
                                  },
                                  on: { click: _vm.save },
                                },
                                [_vm._v("保存")]
                              ),
                              _vm._v(" "),
                              _c("datasheets-loader-button", {
                                attrs: {
                                  disabled: _vm.disabled,
                                  onLoaded: _vm.onDatasheetsLoaded,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }