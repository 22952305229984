
import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';

import { bulkPublishDrafts } from '@/service/ArticleApi';

@Component({
  name: 'button-bulk-publish-drafts',
})
export default class ButtonBulkPublishDrafts extends KamigameVue {
  spreadsheetUrl: string = '';
  sheetTitle: string = '';

  async bulkPublishDrafts() {
    try {
      await bulkPublishDrafts(this.apiClient, this.wikiName);
    } catch (e) {
      this.setFlashMessage('danger', '下書きの公開に失敗しました。');
      console.error(e);
      return;
    }

    this.setFlashMessage('success', '全ての下書きを公開しました。');
  }
}
