var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      !_vm.editable
        ? _c("b-alert", { attrs: { show: "", variant: "warning" } }, [
            _vm._v(" 編集権限がないため、閲覧専用モードで表示しています。 "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "animated fadeIn wiki-page-template-edit" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "page-config",
                          attrs: { slot: "header" },
                          slot: "header",
                        },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  disabled: !_vm.editable,
                                  type: "text",
                                  placeholder: "タイトルを入力",
                                },
                                model: {
                                  value: _vm.title,
                                  callback: function ($$v) {
                                    _vm.title = $$v
                                  },
                                  expression: "title",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-btn",
                                    {
                                      directives: [
                                        {
                                          name: "b-toggle",
                                          rawName: "v-b-toggle.metaMenu",
                                          modifiers: { metaMenu: true },
                                        },
                                      ],
                                      staticClass: "config-toggle",
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fa fa-cogs",
                                        attrs: { "aria-hidden": "true" },
                                      }),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-collapse",
                            { staticClass: "mt-2", attrs: { id: "metaMenu" } },
                            [
                              _c(
                                "b-input-group",
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "mr-sm-2",
                                      attrs: { for: "categoryConfig" },
                                    },
                                    [_vm._v("カテゴリ")]
                                  ),
                                  _vm._v(" "),
                                  _vm.selectedCategory.displayName
                                    ? _c(
                                        "b-button-group",
                                        [
                                          _c(
                                            "b-btn",
                                            {
                                              directives: [
                                                {
                                                  name: "b-modal",
                                                  rawName:
                                                    "v-b-modal.categoryMenu",
                                                  modifiers: {
                                                    categoryMenu: true,
                                                  },
                                                },
                                              ],
                                              attrs: {
                                                disabled: !_vm.editable,
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-folder",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                              _vm._v(
                                                "\n                     \n                    " +
                                                  _vm._s(
                                                    _vm.selectedCategory
                                                      .displayName
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "b-btn",
                                            {
                                              attrs: {
                                                disabled: !_vm.editable,
                                              },
                                              on: { click: _vm.clearCategory },
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fa fa-close",
                                                attrs: {
                                                  "aria-hidden": "true",
                                                },
                                              }),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-btn",
                                        {
                                          directives: [
                                            {
                                              name: "b-modal",
                                              rawName: "v-b-modal.categoryMenu",
                                              modifiers: { categoryMenu: true },
                                            },
                                          ],
                                          attrs: {
                                            variant: "outline-secondary",
                                            disabled: !_vm.editable,
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-plus",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v(" カテゴリを設定する"),
                                        ]
                                      ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("kamigame-category-tree-select-modal", {
                                attrs: {
                                  modalId: "categoryMenu",
                                  modalTitle: "カテゴリを設定",
                                },
                                on: { selectionSaved: _vm.onCategorySaved },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-input-group",
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "mr-sm-2",
                                      attrs: { for: "keyword" },
                                    },
                                    [_vm._v("keywords")]
                                  ),
                                  _vm._v(" "),
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.keywords,
                                      callback: function ($$v) {
                                        _vm.keywords = $$v
                                      },
                                      expression: "keywords",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-input-group",
                                { staticClass: "mt-3" },
                                [
                                  _c(
                                    "label",
                                    {
                                      staticClass: "mr-sm-2",
                                      attrs: { for: "description" },
                                    },
                                    [_vm._v("description")]
                                  ),
                                  _vm._v(" "),
                                  _c("b-form-input", {
                                    model: {
                                      value: _vm.description,
                                      callback: function ($$v) {
                                        _vm.description = $$v
                                      },
                                      expression: "description",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("kamigame-markdown-editor", {
                        attrs: { disabled: !_vm.editable, mode: "template" },
                        on: { save: _vm.save },
                        model: {
                          value: _vm.text,
                          callback: function ($$v) {
                            _vm.text = $$v
                          },
                          expression: "text",
                        },
                      }),
                      _vm._v(" "),
                      _vm.editable
                        ? _c(
                            "div",
                            { attrs: { slot: "footer" }, slot: "footer" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    variant: "primary",
                                    disabled: _vm.disabled,
                                  },
                                  on: { click: _vm.save },
                                },
                                [_vm._v("保存")]
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }