function kamigameImageExtension(editor: any) {
  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem('divider');
  toolbar.addItem({
    type: 'button',
    options: {
      name: 'image',
      className: 'tui-image tui-toolbar-icons',
      event: 'show-image-modal',
      tooltip: '画像貼り付け',
    },
  });

  editor.eventManager.addEventType('show-image-modal');
  editor.commandManager.addCommand('markdown', {
    name: 'show-image-modal',
    exec() {
      editor.eventManager.emit('show-image-modal');
    },
  });
}

export default kamigameImageExtension;
