import Vue from 'vue';

import wikiRoutes from '@/router/wiki';
import manageRoutes from '@/router/manage';
import VueRouter from 'vue-router';
import WikiContainer from '@/containers/WikiContainer.vue';
import ManageContainer from '@/containers/ManageContainer.vue';
import WikiCreate from '@/views/Wiki/WikiCreate.vue';
import WikiInvite from '@/views/Wiki/WikiInvite.vue';
import ErrorPage from '@/views/ErrorPage.vue';
import StatusCheckPage from '@/views/StatusCheckPage.vue';
import { MANAGE_ROUTE_CONFIG } from '@/router/manage';
import { Store } from '@/store';
import { cannot } from '@/service/can';

Vue.use(VueRouter);

const route = {
  wiki: wikiRoutes,
  manage: manageRoutes,
};

function createErrorLocation(to: any, params?: any) {
  return {
    name: 'error',
    replace: true,
    params: Object.assign({ any: to.path.split('/').slice(1) }, params || {}),
  };
}

export function setupRouter(store: Store) {
  const router = new VueRouter({
    mode: 'history',
    base: ADMIN_WEB_BASE_PATH,
    routes: [
      {
        path: '/',
        redirect: { name: 'wiki_create' },
      },
      {
        name: 'wiki_create',
        path: '/-/wiki',
        component: WikiCreate,
        meta: {
          label: 'Wiki 新規作成',
          requiredPermission: 'ACTION_manage',
        },
      },
      {
        name: 'wiki_invite',
        path: '/-/invite/:name/:id',
        component: WikiInvite,
        meta: {
          label: 'Wiki 編集メンバー招待',
          requiredPermission: 'ACTION_wiki_member_join',
        },
      },
      {
        name: 'status_check',
        path: '/-/status',
        component: StatusCheckPage,
        props: true,
        meta: {
          requiredPermission: 'ACTION_default',
        },
      },
      {
        name: 'manage',
        path: '/-/manage',
        redirect: MANAGE_ROUTE_CONFIG.dashboard.path,
        component: ManageContainer,
        children: route.manage,
        meta: {
          label: 'ホーム',
        },
      },
      {
        name: 'dashboard',
        path: '/:name',
        redirect: '/:name/dashboard',
        component: WikiContainer,
        children: route.wiki,
        meta: {
          label: 'ホーム',
        },
      },
      {
        name: 'error',
        path: '/:any*',
        component: ErrorPage,
        props: true,
        meta: {
          requiredPermission: 'ACTION_default',
        },
      },
    ],
  });

  router.beforeEach(async (to, _, next) => {
    if (to.name === 'status_check' || to.name === 'auth_callback' || to.name === 'error') {
      next();
      return;
    }

    try {
      if (to.params.name) {
        await store.getters.getWiki(to.params.name);
      }

      await store.getters.getAbility(to.params.name);
      next();
    } catch (e) {
      next(
        createErrorLocation(to, {
          reason: '指定された Wiki が見つからないか、 API サーバとの接続に失敗しました。',
          error: e,
        })
      );
    }
  });
  router.beforeEach(async (to, _, next) => {
    const requiredPermission = to.meta.requiredPermission;
    try {
      const ability = await store.getters.getAbility(to.params.name);
      if (cannot(ability, requiredPermission, to.meta.authzSubject)) {
        next(
          createErrorLocation(to, {
            reason: 'このページを閲覧する権限がありません。',
            error: new Error(
              `The specified route requires ${requiredPermission} but the current user does not have this permission`
            ),
          })
        );
        return;
      }
    } catch (e) {
      next();
      return;
    }

    next();
  });

  const loginButton = document.getElementById('kamigameLoginButton') as IdKamigameLoginButton;
  if (loginButton) {
    router.beforeEach((to, from, next) => {
      if (to.name === 'status_check') {
        next();
        return;
      }

      loginButton
        .getUser()
        .then((user) => {
          if (!user) {
            loginButton.click();
            next(false);
            return;
          }

          store.commit('setUser', user);
          next();
        })
        .catch(() => {
          next();
        });
    });
  }

  return router;
}
