import { Ability } from '@casl/ability';
import VueRouter from 'vue-router';

type Dictionary<T> = { [key: string]: T };
interface NavItem {
  name: string;
  url?: string;
  icon?: string;
  variant?: string;
  class?: string;
  children?: NavItem[];
}

function createNavFromRoute(
  name: string,
  router: VueRouter,
  ability: Ability,
  params: Dictionary<string> = {},
  icon?: string,
  label?: string
): NavItem {
  const result: NavItem = {
    name: label || name,
    icon,
  };
  const matched = router.resolve({ name, params });
  if (!matched) {
    return result;
  }
  const route = matched.route;
  const activeMenuCorrespondingToRoute: { [key: string]: string } = {
    wiki_page_create: 'wiki_page_list',
    wiki_page_partial_new: 'wiki_page_list',
  };

  if (
    route.name === router.currentRoute.name ||
    route.name === activeMenuCorrespondingToRoute[router.currentRoute.name || '']
  ) {
    result.variant = 'current active';
  }

  const requiredPermission = route.meta.requiredPermission || 'ACTION_wiki_manage';
  const subject = route.meta.authzSubject || 'OBJ_default';
  if (requiredPermission !== 'ACTION_default' && ability.cannot(requiredPermission, subject)) {
    result.variant = 'disabled';
  }

  if (!label && route.meta && route.meta.label) {
    result.name = route.meta.label;
  }
  result.url = route.fullPath;

  return result;
}

function filterDisabledNavItem(i: NavItem): boolean {
  if (i.children) {
    return i.children.length > 0;
  }

  return !i.variant || !i.variant.includes('disabled');
}

export default function (name: string, ability: Ability, router: VueRouter): { items: NavItem[] } {
  const wikiSetting: NavItem = {
    name: 'Wiki 設定',
    icon: 'icon-settings',
    children: [
      createNavFromRoute('wiki_base_config', router, ability, { name }),
      createNavFromRoute('wiki_nav', router, ability, { name }),
      createNavFromRoute('wiki_edit_menu', router, ability, { name, target: 'left-menu' }, '', '左メニュー設定'),
      createNavFromRoute('wiki_edit_menu', router, ability, { name, target: 'right-menu' }, '', '右メニュー設定'),
      createNavFromRoute('wiki_edit_menu', router, ability, { name, target: 'priority-menu' }, '', '優先メニュー設定'),
      createNavFromRoute('wiki_edit_menu', router, ability, { name, target: 'copyright' }, '', '権利表記設定'),
      createNavFromRoute('wiki_custom_header', router, ability, { name }),
      createNavFromRoute(
        'wiki_edit_menu',
        router,
        ability,
        { name, target: 'custom-footer' },
        '',
        'カスタムフッター設定'
      ),
      createNavFromRoute('wiki_tooltip', router, ability, { name }),
      createNavFromRoute('wiki_icon_and_header_image', router, ability, { name }),
      createNavFromRoute('wiki_custom_css', router, ability, { name }),
      createNavFromRoute('wiki_publish_config', router, ability, { name }),
      createNavFromRoute('wiki_slack_config', router, ability, { name }),
      createNavFromRoute('wiki_allowed_ip_address_config', router, ability, { name }),
      createNavFromRoute('wiki_archive_config', router, ability, { name }),
      createNavFromRoute('wiki_domain_allowlist', router, ability, { name }),
      createNavFromRoute('wiki_event_notification', router, ability, { name }),
    ].filter(filterDisabledNavItem),
  };
  for (const child of wikiSetting.children || []) {
    if (child.variant && child.variant.includes('active')) {
      wikiSetting.class = 'open';
    }
  }
  const wikiPageManage: NavItem = {
    name: '記事管理',
    icon: 'icon-list',
    children: [
      createNavFromRoute('wiki_page_list', router, ability, { name }),
      createNavFromRoute('wiki_page_draft_list', router, ability, { name }),
      createNavFromRoute('wiki_history_list', router, ability, { name }),
      createNavFromRoute('wiki_page_template_list', router, ability, { name }),
      createNavFromRoute('wiki_deleted_page_list', router, ability, { name }),
      createNavFromRoute('wiki_page_category_list', router, ability, { name }),
      createNavFromRoute('wiki_page_partial_list', router, ability, { name }),
      createNavFromRoute('wiki_page_partial_draft_list', router, ability, { name }),
      createNavFromRoute('wiki_page_search', router, ability, { name }),
      createNavFromRoute('wiki_redirected_page_list', router, ability, { name }),
      createNavFromRoute('wiki_page_advice_list', router, ability, { name }),
      createNavFromRoute('wiki_page_import', router, ability, { name }),
      createNavFromRoute('wiki_image_bulk_upload', router, ability, { name }),
    ].filter(filterDisabledNavItem),
  };
  for (const child of wikiPageManage.children || []) {
    if (child.variant && child.variant.includes('active')) {
      wikiPageManage.class = 'open';
    }
  }

  const wikiPageMigrate: NavItem = {
    name: '脱タカヒロ (内部向け)',
    icon: 'icon-rocket',
    children: [
      createNavFromRoute('wiki_import_takahiro', router, ability, { name }),
      createNavFromRoute('wiki_template_code', router, ability, { name }),
    ].filter(filterDisabledNavItem),
  };
  for (const child of wikiPageMigrate.children || []) {
    if (child.variant && child.variant.includes('active')) {
      wikiPageMigrate.class = 'open';
    }
  }

  const wikiTools: NavItem = {
    name: 'ツール',
    icon: 'icon-wrench',
    children: [
      createNavFromRoute('chatgpt_proofreading', router, ability, { name }),
      createNavFromRoute('set_up_wiki', router, ability, { name }),
      createNavFromRoute('wiki_screenshot_scanner', router, ability, { name }),
    ],
  };
  for (const child of wikiTools.children || []) {
    if (child.variant && child.variant.includes('active')) {
      wikiTools.class = 'open';
    }
  }

  const menu = {
    items: [
      createNavFromRoute('dashboard', router, ability, { name }, 'icon-speedometer'),
      createNavFromRoute('wiki_page_create', router, ability, { name }, 'icon-note'),
      wikiPageManage,
      createNavFromRoute('wiki_member_list', router, ability, { name }, 'icon-people'),
      createNavFromRoute('wiki_member_invite_list', router, ability, { name }, 'icon-people'),
      wikiSetting,
      createNavFromRoute('wiki_data_spreadsheet_sync', router, ability, { name }, 'icon-grid'),
      createNavFromRoute('wiki_work_report', router, ability, { name }, 'icon-docs'),
      wikiTools,
      wikiPageMigrate,
    ].filter(filterDisabledNavItem),
  };

  return menu;
}
