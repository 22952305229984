
import Component from 'vue-class-component';
import FileUploadWithPreview from 'file-upload-with-preview';
import VueRouter from 'vue-router';

import KamigameVue from '@/KamigameVue';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import { wikiImageUpload } from '@/service/ImageUploader';
import { wikiGetImageWithServingURL } from '@/service/WikiGetImageWithServingURL';

@Component({
  name: 'wiki-icon-and-header-image',
  components: {
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiIconAndHeaderImage extends KamigameVue {
  iconUpload?: FileUploadWithPreview;
  headerUpload?: FileUploadWithPreview;

  iconUploadDisabled = true;
  iconUploading = false;
  headerUploadDisabled = true;
  headerUploading = false;

  hasUploadedIcon = false;
  hasUploadedHeader = false;

  existsUnsavedIcon = false;
  existsUnsavedHeader = false;

  mounted() {
    this.iconUpload = new FileUploadWithPreview('icon');
    this.headerUpload = new FileUploadWithPreview('header');
    this.fetchConfiguredImages();

    window.addEventListener('fileUploadWithPreview:imagesAdded', (e: any) => {
      if (e.detail.uploadId === 'icon') {
        this.iconUploadDisabled = false;
        this.hasUploadedIcon = true;
        this.existsUnsavedIcon = true;
      } else if (e.detail.uploadId === 'header') {
        this.headerUploadDisabled = false;
        this.hasUploadedHeader = true;
        this.existsUnsavedHeader = true;
      }
    });

    const iconUploaderPreview = document.getElementById('iconUploaderPreview');
    if (iconUploaderPreview) {
      iconUploaderPreview.addEventListener('kamigame:fileDropped', (e: any) => {
        if (e.detail.files && this.iconUpload) {
          this.iconUpload.addFiles(e.detail.files);
        }
      });
    }
    const headerUploaderPreview = document.getElementById('headerUploaderPreview');
    if (headerUploaderPreview) {
      headerUploaderPreview.addEventListener('kamigame:fileDropped', (e: any) => {
        if (e.detail.files && this.headerUpload) {
          this.headerUpload.addFiles(e.detail.files);
        }
      });
    }
  }

  clearIconImage() {
    this.hasUploadedIcon = false;
    this.iconUploadDisabled = true;
  }

  clearHeaderImage() {
    this.hasUploadedHeader = false;
    this.headerUploadDisabled = true;
  }

  async fetchConfiguredImages() {
    const wiki = (await this.apiClient.GET('/wiki/{name}', { params: { path: { name: this.wikiName } } })).data;
    if (!wiki) {
      return;
    }

    if (wiki.iconImgURL && this.iconUpload) {
      this.applyImageToPreview(this.iconUpload, wiki.iconImgURL || '');
      this.hasUploadedIcon = true;
    }
    if (wiki.headerImgURL && this.headerUpload) {
      this.applyImageToPreview(this.headerUpload, wiki.headerImgURL || '');
      this.hasUploadedHeader = true;
    }
  }

  applyImageToPreview(uploader: FileUploadWithPreview, url: string) {
    uploader.imagePreview.style.backgroundImage = `url("${url}")`;
  }

  async upload(uploader: FileUploadWithPreview): Promise<any> {
    const files = uploader.cachedFileArray;
    if (!files) {
      return Promise.reject(new Error('files is not found'));
    }

    return wikiImageUpload(this.$store.getters.sessionId, this.wikiName, false, files).then(
      (result: Map<string, string>) => {
        return Promise.resolve(result.values().next().value);
      }
    );
  }

  uploadIcon() {
    if (!this.iconUpload) {
      return;
    }
    this.iconUploading = true;
    this.upload(this.iconUpload)
      .then((id: string): Promise<any> => {
        return wikiGetImageWithServingURL(this.apiClient, id).then(() => {
          return this.apiClient
            .PUT('/admin/wiki/{wiki.name}/icon', {
              params: {
                path: {
                  'wiki.name': this.wikiName,
                },
              },
              body: {
                image: { id },
              },
            })
            .then((r) => {
              if (r.error) {
                throw r.error;
              }
              return r;
            });
        });
      })
      .then(() => {
        this.iconUploading = false;
        this.existsUnsavedIcon = false;
        this.setFlashMessage('success', 'アイコン画像のアップロードが正常に完了しました。');
      })
      .catch(() => {
        this.iconUploading = false;
        this.setFlashMessage('danger', 'アイコン画像のアップロードでエラーが発生しました。');
      });
  }

  uploadHeader() {
    if (!this.headerUpload) {
      return;
    }
    this.headerUploading = true;
    this.upload(this.headerUpload)
      .then((id: string): Promise<any> => {
        return wikiGetImageWithServingURL(this.apiClient, id).then(() => {
          return this.apiClient
            .PUT('/admin/wiki/{wiki.name}/header', {
              params: {
                path: {
                  'wiki.name': this.wikiName,
                },
              },
              body: {
                image: { id },
              },
            })
            .then((r) => {
              if (r.error) {
                throw r.error;
              }

              return r;
            });
        });
      })
      .then(() => {
        this.headerUploading = false;
        this.existsUnsavedHeader = false;
        this.setFlashMessage('success', 'ヘッダ画像のアップロードが正常に完了しました。');
      })
      .catch(() => {
        this.headerUploading = false;
        this.setFlashMessage('danger', 'ヘッダ画像のアップロードでエラーが発生しました。');
      });
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    return this.existsUnsavedIcon || this.existsUnsavedHeader;
  }
}
