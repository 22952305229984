var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("b-button", { attrs: { block: "" }, on: { click: _vm.clickButton } }, [
        _vm._v("記事一括移行"),
      ]),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          ref: "modalBulkTransferArticles",
          attrs: {
            title: "記事一括移行",
            size: "lg",
            "no-close-on-backdrop": "",
            static: "",
          },
          on: { ok: _vm.bulkTransferArticles },
        },
        [
          _c("div", [_vm._v("神ゲー管理画面の記事を一括移行します")]),
          _vm._v(" "),
          _c("div", [
            _vm._v("\n      ポイント"),
            _c("br"),
            _vm._v("\n      ・既に移行済みの記事は移行しません "),
            _c("br"),
            _vm._v("\n      ・移行した記事は削除記事一覧画面で確認できます"),
            _c("br"),
            _vm._v("\n      ・移行元と移行後の記事IDの変換も実施します\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v(
                "\n      移行元の wiki 名 (URL のパスになる部分)：\n      "
              ),
              _c("b-form-input", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.existsSourceWikiName,
                    expression: "!existsSourceWikiName",
                  },
                ],
                attrs: { placeholder: _vm.sourceWikiName },
                model: {
                  value: _vm.sourceWikiName,
                  callback: function ($$v) {
                    _vm.sourceWikiName = $$v
                  },
                  expression: "sourceWikiName",
                },
              }),
              _vm._v(" "),
              _c(
                "p",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.existsSourceWikiName,
                      expression: "existsSourceWikiName",
                    },
                  ],
                },
                [_vm._v(_vm._s(_vm.sourceWikiName))]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }