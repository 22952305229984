var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("イベント通知")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: function ($event) {
                              $event.stopPropagation()
                              $event.preventDefault()
                              return _vm.save.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c(
                            "table",
                            {
                              staticClass:
                                "table wiki-event-notification-table",
                            },
                            [
                              _c("thead", { staticClass: "thead-light" }, [
                                _c("tr", [
                                  _c("th", { staticClass: "operation" }),
                                  _vm._v(" "),
                                  _c("th", { attrs: { scope: "col" } }, [
                                    _vm._v("項目名"),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { attrs: { scope: "col" } }, [
                                    _vm._v("リンク先"),
                                  ]),
                                  _vm._v(" "),
                                  _c("th", { attrs: { scope: "col" } }, [
                                    _vm._v("期限"),
                                  ]),
                                ]),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.items, function ([id, data]) {
                                return _c("tr", { key: id }, [
                                  _c(
                                    "td",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "outline-danger" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeRow(id)
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-trash",
                                          }),
                                          _vm._v(" 削除"),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("b-input", {
                                        attrs: { required: true },
                                        model: {
                                          value: data.title,
                                          callback: function ($$v) {
                                            _vm.$set(data, "title", $$v)
                                          },
                                          expression: "data.title",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("b-input", {
                                        attrs: { required: true },
                                        model: {
                                          value: data.linkUrl,
                                          callback: function ($$v) {
                                            _vm.$set(data, "linkUrl", $$v)
                                          },
                                          expression: "data.linkUrl",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "datetime-local",
                                          required: true,
                                        },
                                        model: {
                                          value: data.deadlineDatetime,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              data,
                                              "deadlineDatetime",
                                              $$v
                                            )
                                          },
                                          expression: "data.deadlineDatetime",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                              _vm._v(" "),
                              _c("tfoot", [
                                _c("tr", [
                                  _c(
                                    "td",
                                    { attrs: { colspan: "4" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "outline-primary" },
                                          on: { click: _vm.addRow },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fa fa-plus",
                                          }),
                                          _vm._v(" 項目を追加"),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { attrs: { slot: "footer" }, slot: "footer" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "submit", variant: "success" },
                                },
                                [_vm._v("保存する")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: _vm.existsUnsavedChanges },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }