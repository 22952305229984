import { gptMarkdownTranslator } from '@/lib/translator/gpt';
import {
  systemInstruction,
  userInstructionForArticleBody,
  userInstructionForArticleDescription,
  userInstructionForArticleTitle,
} from './gptInstruction';
import { APIClient } from '@/service/WikiAPIClientFactory';

export const translateArticleBody = async (api: APIClient, wikiName: string, body: string) => {
  return gptMarkdownTranslator(api, wikiName, body, systemInstruction, userInstructionForArticleBody);
};

export const translateArticleTitle = async (api: APIClient, wikiName: string, title: string) => {
  return gptMarkdownTranslator(api, wikiName, title, systemInstruction, userInstructionForArticleTitle);
};

export const translateArticleDescription = async (api: APIClient, wikiName: string, description: string) => {
  return gptMarkdownTranslator(api, wikiName, description, systemInstruction, userInstructionForArticleDescription);
};
