import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import { abilitiesPlugin } from '@casl/vue';

import App from '@/App.vue';
import Can from '@/components/Can';
import { setupRouter } from '@/router';
import { ability, store } from '@/store';

// 例外を握りつぶしてしまうためコメントアウト
// https://app.asana.com/0/1203256184350557/1203901694042980/f

// setupSentry();

const loginButton = document.getElementById('kamigameLoginButton') as IdKamigameLoginButton;
if (loginButton) {
  const initVue = () => {
    Vue.use(BootstrapVue);
    Vue.use(abilitiesPlugin, ability);
    Vue.component('Can', Can);

    const router = setupRouter(store);

    // tslint:disable-next-line:no-unused-expression
    new Vue({
      router,
      store,
      el: '#app',
      components: {
        App,
      },
      render(h) {
        return h('app');
      },
    });
  };

  if (loginButton.loaded) {
    initVue();
  }
  loginButton.addEventListener('load', initVue);
}
