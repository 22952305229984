
import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';

import KamigameVue from '@/KamigameVue';
import { Paging, CategoryTreeSelectModal } from '@/components';
import { convertSearchQueryStringToArray } from '@/service/SearchQueryStringConverter';
import { components } from '@/api-client/schema';

@Component({
  name: 'wiki-page-template-list',
  components: {
    'kamigame-category-tree-select-modal': CategoryTreeSelectModal,
    'kamigame-paging': Paging,
  },
})
export default class WikiPageTemplateList extends KamigameVue {
  dateFormat = format;

  loading = false;
  templates: components['schemas']['v1WikiPageTemplateCollection'] = { template: [] };
  fields = [
    { thStyle: { width: '45%' }, key: 'title', label: 'テンプレート名' },
    { key: 'category', label: 'カテゴリ' },
    { key: 'lastUpdatedAt', label: '最終更新日時' },
    { key: 'creator', label: '作成者' },
    { key: 'lastAuthor', label: '最終更新者' },
    { thStyle: { width: '20%' }, key: '_operation', label: '操作' },
  ];
  sortConditions = [
    { text: '最終更新日が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '最終更新日が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'テンプレート名順', value: { sortedBy: 'title', sortOrder: 'ASC' } },
  ];
  perRequestWikiPageTemplatesNum = 20;
  totalTemplatesNum = 0;
  searchString = '';
  selectedSortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  kamigamePaging = this.$refs.kamigamePaging as Paging;
  categorySeclectTemplateItemIndex = 0;

  async mounted() {
    this.kamigamePaging = this.$refs.kamigamePaging as Paging;
    this.getWikiPageTemplates();
  }

  async getWikiPageTemplates(startAt: number = 0) {
    this.loading = true;
    await this.apiClient
      .GET('/admin/wiki/{wikiName}/template', {
        params: {
          path: {
            wikiName: this.wikiName,
          },
          query: {
            limit: this.perRequestWikiPageTemplatesNum,
            offset: startAt,
            searchWords: this.searchWords,
            sortedBy: this.selectedSortCondition.sortedBy,
            sortOrder: this.selectedSortCondition.sortOrder,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r;
        }

        return r.data;
      })
      .then((res) => {
        this.loading = false;
        this.templates.template = res.template;
        this.totalTemplatesNum = res.numOfTotalTemplates || 0;
        if (startAt === 0) {
          this.kamigamePaging.resetPaging();
        }
      })
      .catch((e) => {
        if (e.response && e.response.status === 404) {
          this.templates.template = [];
          this.loading = false;
        }
      });
  }

  showDeleteModal(index: number) {
    const modal = this.$refs[`alertDelete_${index}`] as BModal;
    modal.show();
  }

  async deleteTemplate(template: components['schemas']['v1WikiPageTemplate']) {
    if (!template.id) {
      return;
    }

    this.apiClient
      .DELETE('/admin/wiki/{wikiName}/template/{templateId}', {
        params: {
          path: {
            wikiName: this.wikiName,
            templateId: template.id,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then((response) => {
        this.setFlashMessage('success', 'テンプレートを削除しました。');

        if (this.templates.template) {
          this.templates.template = this.templates.template.filter((t) => t.id !== template.id);
        }
      });
  }

  changeDataRange(startAt: number) {
    this.getWikiPageTemplates(startAt);
  }

  onPageCategorySelected(category: components['schemas']['v1WikiPageCategory'], itemIndex: number) {
    if (!this.templates.template || !this.templates.template[itemIndex] || !this.templates.template[itemIndex].id) {
      return;
    }

    const templateId = this.templates.template[itemIndex].id!;
    this.apiClient
      .PUT('/admin/wiki/{wikiName}/template/{templateId}/category', {
        params: {
          path: {
            wikiName: this.wikiName,
            templateId,
          },
        },
        body: {
          categoryId: category.id,
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(() => {
        this.setFlashMessage('success', 'カテゴリを設定しました。');
        if (!this.templates.template || !this.templates.template[itemIndex]) {
          return;
        }

        this.$set(this.templates.template[itemIndex], 'category', category);
      });
  }

  unlinkWikiPageCategory(template: components['schemas']['v1WikiPageTemplate']) {
    if (!template.id) {
      return;
    }

    this.apiClient
      .PUT('/admin/wiki/{wikiName}/template/{templateId}/category', {
        params: {
          path: {
            wikiName: this.wikiName,
            templateId: template.id,
          },
        },
        body: {
          categoryId: '0',
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      })
      .then(() => {
        this.setFlashMessage('success', 'カテゴリ設定を解除しました。');
        template.category = undefined;
      });
  }

  get searchWords() {
    return convertSearchQueryStringToArray(this.searchString);
  }
}
