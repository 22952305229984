
import Component from 'vue-class-component';
import { MarkdownParser } from 'kamigame-web';
import format from 'date-fns/format';

import KamigameVue from '@/KamigameVue';
import { braceError as titleBraceError, validateWikiPageTitle } from '@/service/WikiPageTitleValidation';
import { extractDependingSpreadsheetsFromMarkdownText } from 'wiki-shared/template-code-util';
import { components } from '@/api-client/schema';

enum PublishedState {
  Published = '1',
  Draft = '0',
  Reserved = '2',
}

@Component({
  name: 'wiki-page-import',
})
export default class WikiPageImport extends KamigameVue {
  files: File[] = [];
  successImported: any[] = [];
  error: any[] = [];
  uploading: boolean = false;
  takahiroAvailable: boolean = false;
  published: string = '1';
  publishedDate: string = '';
  publishedTime: string = '';

  get proceeded() {
    return this.successImported.length + this.error.length;
  }

  get completed() {
    return this.files.length < this.proceeded;
  }

  mounted() {
    const markdownFileField = (this.$refs.markdownFileField as Vue).$el;
    const input = markdownFileField.querySelector('input[type="file"]') as any;

    this.takahiroAvailable = input.webkitdirectory;
  }

  data() {
    return {
      files: [],
      success: [],
      error: [],
      uploading: false,
      published: PublishedState.Published,
      publishedDate: '',
      publishedTime: '',
    };
  }

  async uploadBulkTakahiroCategoryChunk(chunk: components['schemas']['BulkTakahiroWikiPageCategoryRequestCategory'][]) {
    const bulkTakahiroRequest: components['schemas']['v1BulkTakahiroWikiPageCategoryRequestBody'] = {
      categories: chunk,
    };
    await this.apiClient
      .PUT('/admin/wiki/{wikiId}/category/bulk-takahiro', {
        params: {
          path: {
            wikiId: this.wikiName,
          },
        },
        body: bulkTakahiroRequest,
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }
      });
  }

  async uploadBulkTakahiroChunk(chunk: components['schemas']['v1BulkTakahiroWikiPageRequestArticle'][]) {
    const bulkTakahiroRequest: components['schemas']['v1BulkTakahiroWikiPageRequestBody'] = {
      articles: chunk,
    };
    const res = (
      await this.apiClient.PUT('/admin/wiki/{wikiId}/page/bulk-takahiro', {
        params: {
          path: {
            wikiId: this.wikiName,
          },
        },
        body: bulkTakahiroRequest,
      })
    ).data;

    (res?.result || []).forEach((v) => {
      if (v.error) {
        this.error.push({ name: v.file, errors: ['サーバでの処理中に予期しないエラーが発生しました', v.error] });
      } else {
        this.successImported.push({
          title: v.title || '(タイトルなし)',
          name: v.file,
        });
      }
    });
  }

  async upload() {
    this.uploading = true;
    this.successImported = [];
    this.error = [];

    const entries: any[] = await Promise.all(
      this.files.map(
        (f: File) =>
          new Promise<any>((resolve) => {
            const fileName = (f as any).webkitRelativePath || f.name;

            if (!f.name.endsWith('.md')) {
              this.error.push({ name: fileName, errors: ['Markdown ファイルではありません'] });
              resolve(null);
              return;
            }
            const reader = new FileReader();
            reader.onload = (event) => {
              if (!event.target) {
                resolve(null);
                return;
              }

              resolve({
                name: fileName,
                body: (event.target as any).result,
              });
            };
            reader.readAsText(f);
          })
      )
    );

    const [bulkTakahiroWikiPageRequestArticles, bulkTakahiroWikiPageRequestCategories] =
      await this.translateEntriesToArticlesForImport(entries);

    const chunkSize = 10;

    const bulkTakahiroChunk: components['schemas']['v1BulkTakahiroWikiPageRequestArticle'][][] = [];
    for (let i = 0; i < Math.ceil(bulkTakahiroWikiPageRequestArticles.length / chunkSize); i++) {
      bulkTakahiroChunk.push(bulkTakahiroWikiPageRequestArticles.slice(i * chunkSize, i * chunkSize + chunkSize));
    }

    const bulkTakahiroCategoryChunk: components['schemas']['BulkTakahiroWikiPageCategoryRequestCategory'][][] = [];
    for (let i = 0; i < Math.ceil(bulkTakahiroWikiPageRequestCategories.length / chunkSize); i++) {
      bulkTakahiroCategoryChunk.push(
        bulkTakahiroWikiPageRequestCategories.slice(i * chunkSize, i * chunkSize + chunkSize)
      );
    }

    await bulkTakahiroCategoryChunk.reduce(async (chain, chunk) => {
      await chain;
      await this.uploadBulkTakahiroCategoryChunk(chunk);
      return;
    }, Promise.resolve());

    await bulkTakahiroChunk.reduce(async (chain, chunk) => {
      await chain;
      await this.uploadBulkTakahiroChunk(chunk);
      return;
    }, Promise.resolve());

    this.reset();
  }

  async translateEntriesToArticlesForImport(
    entries: any[]
  ): Promise<
    [
      components['schemas']['v1BulkTakahiroWikiPageRequestArticle'][],
      components['schemas']['BulkTakahiroWikiPageCategoryRequestCategory'][]
    ]
  > {
    const bulkTakahiroWikiPageRequestArticles: components['schemas']['v1BulkTakahiroWikiPageRequestArticle'][] = [];

    const categories = new Set<string>();

    for (const entry of entries) {
      if (!entry || !entry.name || !entry.body) {
        continue;
      }

      const defaultParser = new MarkdownParser('dummy', entry.body, '', undefined);
      const name = entry.name;
      const title = defaultParser.meta.title || defaultParser.getTitle() || '';
      const body = entry.body;
      const keywords = defaultParser.meta.keywords || '';
      const description = defaultParser.meta.description || '';
      const noindex = !!defaultParser.meta.noindex;
      let updatedAt: string = defaultParser.meta.updated_at || '';
      let publishedAt = '';
      if (updatedAt) {
        publishedAt = updatedAt;
      } else {
        if (this.published === PublishedState.Published) {
          publishedAt = new Date().toISOString();
        } else if (this.published === PublishedState.Reserved) {
          publishedAt = new Date(
            `${this.publishedDate || format(new Date(), 'yyyy-MM-dd')} ${this.publishedTime || '00:00'}`
          ).toISOString();
        }
        updatedAt = publishedAt;
      }
      const createdAt = updatedAt;

      const importedTitle = validateWikiPageTitle(title).errors.includes(titleBraceError)
        ? title.replace(/^【.+?】/, '')
        : title;

      bulkTakahiroWikiPageRequestArticles.push({
        file: name,
        title: importedTitle,
        body,
        spreadsheetURL: extractDependingSpreadsheetsFromMarkdownText(entry.body),
        editPermission: 'OBJ_default',
        keywords,
        description,
        publishedAt,
        noindex,
        updatedAt,
        createdAt,
      });
      categories.add(name.split('/').slice(1, -1).join('/'));
    }

    const bulkTakahiroWikiPageRequestCategories: components['schemas']['BulkTakahiroWikiPageCategoryRequestCategory'][] =
      Array.from(categories)
        .filter((category: string) => !!category)
        .map((category: string) => {
          const components = category.split('/');
          const parentName = components.slice(0, -1).join('/');
          const name = components.pop();

          return { parentName, name };
        });

    return [bulkTakahiroWikiPageRequestArticles, bulkTakahiroWikiPageRequestCategories];
  }

  reset() {
    this.uploading = false;
    this.files = [];
    this.published = PublishedState.Published;
    this.publishedDate = '';
    this.publishedTime = '';
  }

  get exampleURL() {
    return `${WIKI_URL_BASE}/${encodeURIComponent(this.wikiName)}/page/12345.html`;
  }

  get takahiroGameName() {
    if (this.files.length <= 0) {
      return '';
    }

    const file = this.files[0] as any;
    const game = (file.webkitRelativePath as string).split('/')[0];

    return game;
  }
}
