var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn wiki-page-history-list" },
      [
        _c(
          "b-col",
          { attrs: { cols: "12" } },
          [
            _c(
              "b-card",
              { attrs: { "header-tag": "header", "footer-tag": "footer" } },
              [
                _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                  _c("strong", [_vm._v("リダイレクト済み記事一覧")]),
                ]),
                _vm._v(" "),
                _c(
                  "b-container",
                  { staticClass: "mb-3", attrs: { fluid: "" } },
                  [
                    _c(
                      "b-row",
                      [
                        _c(
                          "b-col",
                          { attrs: { lg: "4" } },
                          [
                            _c(
                              "b-form",
                              { attrs: { inline: "" } },
                              [
                                _c(
                                  "b-input-group",
                                  { staticClass: "mt-3" },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "mt-1 mr-sm-2",
                                        attrs: { for: "categoryConfig" },
                                      },
                                      [_vm._v("カテゴリ")]
                                    ),
                                    _vm._v(" "),
                                    !_vm.filteredByEmptyCategory
                                      ? _c(
                                          "span",
                                          { staticClass: "mr-2" },
                                          [
                                            _vm.selectedCategory.displayName
                                              ? _c(
                                                  "b-button-group",
                                                  [
                                                    _c(
                                                      "b-btn",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "b-modal",
                                                            rawName:
                                                              "v-b-modal.categoryMenu",
                                                            modifiers: {
                                                              categoryMenu: true,
                                                            },
                                                          },
                                                        ],
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-folder",
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          "\n                         \n                        " +
                                                            _vm._s(
                                                              _vm
                                                                .selectedCategory
                                                                .displayName
                                                            ) +
                                                            "\n                      "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-btn",
                                                      {
                                                        on: {
                                                          click:
                                                            _vm.clearCategory,
                                                        },
                                                      },
                                                      [
                                                        _c("i", {
                                                          staticClass:
                                                            "fa fa-close",
                                                          attrs: {
                                                            "aria-hidden":
                                                              "true",
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _c(
                                                  "b-btn",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-modal",
                                                        rawName:
                                                          "v-b-modal.categoryMenu",
                                                        modifiers: {
                                                          categoryMenu: true,
                                                        },
                                                      },
                                                    ],
                                                    attrs: {
                                                      variant:
                                                        "outline-secondary",
                                                    },
                                                  },
                                                  [_vm._v("(未選択)")]
                                                ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "b-form-checkbox",
                                      {
                                        staticClass: "mt-1",
                                        model: {
                                          value: _vm.filteredByEmptyCategory,
                                          callback: function ($$v) {
                                            _vm.filteredByEmptyCategory = $$v
                                          },
                                          expression: "filteredByEmptyCategory",
                                        },
                                      },
                                      [_vm._v("カテゴリ未設定記事のみ表示")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("kamigame-category-tree-select-modal", {
                              attrs: {
                                modalId: "categoryMenu",
                                modalTitle: "カテゴリを設定",
                                data: _vm.category,
                              },
                              on: { selectionSaved: _vm.onCategorySaved },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { lg: "4" } },
                          [
                            _c("b-form-select", {
                              staticClass: "w-100 mt-3",
                              attrs: { options: _vm.sortConditions },
                              on: {
                                input: function ($event) {
                                  return _vm.getWikiPages()
                                },
                              },
                              model: {
                                value: _vm.selectedSortCondition,
                                callback: function ($$v) {
                                  _vm.selectedSortCondition = $$v
                                },
                                expression: "selectedSortCondition",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-col",
                          { attrs: { lg: "4" } },
                          [
                            _c(
                              "b-form",
                              {
                                on: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.getWikiPages()
                                  },
                                },
                              },
                              [
                                _c(
                                  "b-input-group",
                                  { staticClass: "mt-3" },
                                  [
                                    _c("b-form-input", {
                                      attrs: {
                                        placeholder: "検索ワードを入力",
                                      },
                                      model: {
                                        value: _vm.searchString,
                                        callback: function ($$v) {
                                          _vm.searchString = $$v
                                        },
                                        expression: "searchString",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      { attrs: { type: "submit" } },
                                      [_vm._v("検索")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "kamigame-paging",
                  {
                    ref: "kamigamePaging",
                    attrs: {
                      dataLength: _vm.totalPageNum,
                      "parent-per-page": _vm.perRequestWikiPageNum,
                    },
                    on: { "change-data-range": _vm.changeDataRange },
                  },
                  [
                    _c(
                      "b-table",
                      {
                        attrs: {
                          slot: "data-list",
                          responsive: "",
                          fields: _vm.fields,
                          items: _vm.pages.wikiPage,
                          "empty-text":
                            "指定した条件に該当する記事が見つかりません",
                          "show-empty": "",
                          busy: _vm.loading,
                        },
                        slot: "data-list",
                        scopedSlots: _vm._u([
                          {
                            key: "cell(title)",
                            fn: function (data) {
                              return [
                                _c(
                                  "b-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "wiki_page_edit",
                                        params: {
                                          paths: data.item.wikiPage.id,
                                        },
                                      },
                                    },
                                  },
                                  [
                                    data.item.wikiPage.path === "index"
                                      ? _c("span", [
                                          _c("i", {
                                            staticClass: "fa fa-home",
                                            attrs: { "aria-hidden": "true" },
                                          }),
                                          _vm._v(
                                            "トップページ\n                "
                                          ),
                                        ])
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              data.item.title ||
                                                "(タイトルなし)"
                                            )
                                          ),
                                        ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  [
                                    _c("br"),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fa fa-arrow-right",
                                      attrs: { "aria-hidden": "true" },
                                    }),
                                    _vm._v(
                                      "\n                  「\n                "
                                    ),
                                    _c(
                                      "b-link",
                                      {
                                        attrs: {
                                          to: {
                                            name: "wiki_page_edit",
                                            params: {
                                              paths:
                                                data.item.wikiPage
                                                  .redirectPageTitle.id,
                                            },
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            data.item.wikiPage.redirectPageTitle
                                              .title
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(
                                      "」へリダイレクトされます\n              "
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(category)",
                            fn: function (data) {
                              return [
                                data.item.wikiPage.category.displayName
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            data.item.wikiPage.category
                                              .displayName
                                          ) +
                                          "\n              "
                                      ),
                                    ])
                                  : _c("span", [_vm._v(" なし ")]),
                              ]
                            },
                          },
                          {
                            key: "cell(published)",
                            fn: function (data) {
                              return [
                                _vm.isPublished(data.item)
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "b-link",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName: "v-b-tooltip",
                                              },
                                            ],
                                            staticClass: "m-1",
                                            attrs: {
                                              title: "記事をサイト上で確認する",
                                              href: _vm.getWikiUrl(
                                                data.item.wikiPage.id
                                              ),
                                              target: "_blank",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                  公開済み\n                  "
                                            ),
                                            _c("i", {
                                              staticClass:
                                                "fa fa-external-link",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c("br"),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(
                                              data.item.hasDraft
                                                ? "（下書き）"
                                                : ""
                                            ) +
                                            "\n              "
                                        ),
                                      ],
                                      1
                                    )
                                  : data.item.wikiPage.publishedAt
                                  ? _c("span", [
                                      _vm._v(
                                        "\n                未公開\n                "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                (" +
                                          _vm._s(
                                            _vm.dateFormat(
                                              new Date(
                                                data.item.wikiPage.publishedAt
                                              ),
                                              "yyyy/MM/dd HH:mm:ss"
                                            )
                                          ) +
                                          " に公開)\n              "
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        "\n                未公開\n                "
                                      ),
                                      _c("br"),
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            data.item.hasDraft
                                              ? "（下書き）"
                                              : ""
                                          ) +
                                          "\n              "
                                      ),
                                    ]),
                              ]
                            },
                          },
                          {
                            key: "cell(lastUpdatedAt)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.dateFormat(
                                        new Date(
                                          data.item.wikiPage.lastUpdatedAt
                                        ),
                                        "yyyy/MM/dd HH:mm:ss"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(privilege)",
                            fn: function (data) {
                              return [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      {
                                        OBJ_default: "メンバー全員が編集可",
                                        OBJ_sub_admin:
                                          "副管理者と管理者が編集可",
                                        OBJ_admin: "管理者のみ編集可",
                                      }[data.item.wikiPage.editPermission] ||
                                        "-"
                                    ) +
                                    "\n            "
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(history)",
                            fn: function (data) {
                              return [
                                _c(
                                  "b-link",
                                  {
                                    attrs: {
                                      to: {
                                        name: "wiki_page_history_list",
                                        params: {
                                          pageId: data.item.wikiPage.id,
                                        },
                                      },
                                    },
                                  },
                                  [_vm._v("履歴を表示")]
                                ),
                              ]
                            },
                          },
                          {
                            key: "cell(_operation)",
                            fn: function (data) {
                              return [
                                _c(
                                  "Can",
                                  {
                                    attrs: {
                                      I: "",
                                      do: "ACTION_wiki_page_delete",
                                    },
                                  },
                                  [
                                    data.item.wikiPage.path !== "index"
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "m-1",
                                            attrs: {
                                              size: "sm",
                                              variant: "outline-danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showDeleteModal(
                                                  data.index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-trash-o",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                            _vm._v(" 削除\n                "),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-modal",
                                  {
                                    ref: `deletePage_${data.index}`,
                                    attrs: {
                                      "hide-header": "",
                                      centered: "",
                                      "no-fade": "",
                                      lazy: "",
                                    },
                                    on: {
                                      ok: function ($event) {
                                        return _vm.deletePage(
                                          data.item.wikiPage.id,
                                          data.index
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("p", [
                                      _vm._v(
                                        "リダイレクト設定された記事を削除するとリダイレクトは行われなくなります"
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("p", [
                                      _vm._v(
                                        "本当に続行してもよろしいですか？"
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "text-center my-2",
                            attrs: { slot: "table-busy" },
                            slot: "table-busy",
                          },
                          [
                            _c("b-spinner", { staticClass: "align-middle" }),
                            _vm._v(" "),
                            _c("strong", [_vm._v("読み込み中...")]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }