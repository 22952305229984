import { Ability } from '@casl/ability';

const defaultAction = 'ACTION_wiki_manage';
const defaultSubject = 'OBJ_default';

export function can(ability: Ability, action: string = defaultAction, subject: string = defaultSubject): boolean {
  if (action === 'ACTION_default') {
    return true;
  }

  return ability.can(action, subject);
}

export function cannot(ability: Ability, action: string = defaultAction, subject: string = defaultSubject): boolean {
  return !can(ability, action, subject);
}
