export const EVENT_NAME_SHOW_WIKI_PAGE_KEYBOARD_SHORTCUT_HELP_MODAL = 'show-wiki-page-keyboard-shortcut-help-modal';

export function kamigameWikiPageShowKeyboarShortcutHelpExtention(editor: any) {
  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem('divider');
  toolbar.addItem({
    type: 'button',
    options: {
      name: 'wiki-page-keyboard-shortcut-help',
      className: 'tui-editor-custom-button fa fa-question',
      event: EVENT_NAME_SHOW_WIKI_PAGE_KEYBOARD_SHORTCUT_HELP_MODAL,
      tooltip: 'キーボードショートカットのヘルプを表示',
    },
  });

  editor.eventManager.addEventType(EVENT_NAME_SHOW_WIKI_PAGE_KEYBOARD_SHORTCUT_HELP_MODAL);
  editor.commandManager.addCommand('markdown', {
    name: EVENT_NAME_SHOW_WIKI_PAGE_KEYBOARD_SHORTCUT_HELP_MODAL,
    exec() {
      editor.eventManager.emit(EVENT_NAME_SHOW_WIKI_PAGE_KEYBOARD_SHORTCUT_HELP_MODAL);
    },
  });
}
