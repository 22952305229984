var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "wrapper" }, [
    _c(
      "div",
      { staticClass: "animated fadeIn" },
      [
        this.memberRequests.length > 0
          ? _c(
              "b-row",
              [
                _c(
                  "b-col",
                  { attrs: { cols: "12" } },
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "wiki-member-request-list",
                        attrs: {
                          "header-tag": "header",
                          "footer-tag": "footer",
                        },
                      },
                      [
                        _c(
                          "div",
                          { attrs: { slot: "header" }, slot: "header" },
                          [
                            _c("strong", [
                              _vm._v(
                                "Wiki 編集メンバー参加申請一覧 (" +
                                  _vm._s(_vm.memberRequests.length) +
                                  ")"
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("b-table", {
                          attrs: {
                            hover: "",
                            fields: _vm.memberRequestsFields,
                            items: _vm.memberRequests,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "cell(user)",
                                fn: function (data) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          data.item.request.user.nickname
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(createdAt)",
                                fn: function (data) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.dateFormat(
                                            new Date(
                                              data.item.request.createdAt
                                            ),
                                            "yyyy/MM/dd HH:mm:ss"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(description)",
                                fn: function (data) {
                                  return [
                                    data.item.request.more
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "white-space": "pre-wrap",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data.item.request.description
                                              )
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              data.item.request
                                                .truncatedDescription
                                            ) + "\n                "
                                          ),
                                          data.item.request
                                            .truncatedDescription !==
                                          data.item.request.description
                                            ? _c(
                                                "span",
                                                [
                                                  _vm._v(
                                                    "\n                  ... "
                                                  ),
                                                  _c(
                                                    "b-link",
                                                    {
                                                      staticClass:
                                                        "description-opener",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          data.item.request.more = true
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("全文を表示")]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ]),
                                  ]
                                },
                              },
                              {
                                key: "cell(_operation)",
                                fn: function (data) {
                                  return [
                                    data.item.status === null
                                      ? _c(
                                          "b-button-group",
                                          [
                                            _c(
                                              "Can",
                                              {
                                                attrs: {
                                                  I: "",
                                                  do: "ACTION_wiki_member_approve",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.approve(
                                                          data.item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("承認")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-button",
                                                  {
                                                    attrs: {
                                                      variant: "secondary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.reject(
                                                          data.item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("拒否")]
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "Can",
                                              {
                                                attrs: {
                                                  not: "",
                                                  I: "",
                                                  do: "ACTION_wiki_member_approve",
                                                },
                                              },
                                              [_c("span", [_vm._v("-")])]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    data.item.status === "approved"
                                      ? _c(
                                          "span",
                                          { staticClass: "text-success" },
                                          [_vm._v("承認済み")]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    data.item.status === "rejected"
                                      ? _c(
                                          "span",
                                          { staticClass: "text-muted" },
                                          [_vm._v("拒否済み")]
                                        )
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            155811604
                          ),
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "b-row",
          [
            _c(
              "b-col",
              { attrs: { cols: "12" } },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "wiki-member-list",
                    attrs: { "header-tag": "header", "footer-tag": "footer" },
                  },
                  [
                    _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                      _c("strong", [
                        _vm._v(
                          "Wiki 編集メンバー一覧 (" +
                            _vm._s(_vm.members.length) +
                            ")"
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-container",
                      { staticClass: "mb-3", attrs: { fluid: "" } },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { attrs: { lg: "6" } },
                              [
                                _c("b-form-select", {
                                  staticClass: "w-100 mt-3",
                                  attrs: { options: _vm.sortConditions },
                                  on: {
                                    input: function ($event) {
                                      return _vm.getWikiMembers()
                                    },
                                  },
                                  model: {
                                    value: _vm.selectedSortCondition,
                                    callback: function ($$v) {
                                      _vm.selectedSortCondition = $$v
                                    },
                                    expression: "selectedSortCondition",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-col",
                              { attrs: { lg: "6" } },
                              [
                                _c(
                                  "b-form",
                                  {
                                    on: {
                                      submit: function ($event) {
                                        $event.preventDefault()
                                        return _vm.getWikiMembers()
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      { staticClass: "mt-3" },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            placeholder: "検索ワードを入力",
                                          },
                                          model: {
                                            value: _vm.searchString,
                                            callback: function ($$v) {
                                              _vm.searchString = $$v
                                            },
                                            expression: "searchString",
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-button",
                                          { attrs: { type: "submit" } },
                                          [_vm._v("検索")]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "kamigame-paging",
                      {
                        ref: "kamigamePaging",
                        attrs: {
                          dataLength: _vm.totalPageNum,
                          "parent-per-page": _vm.perRequestWikiMemberNum,
                        },
                        on: { "change-data-range": _vm.changeDataRange },
                      },
                      [
                        _c(
                          "b-table",
                          {
                            attrs: {
                              slot: "data-list",
                              responsive: "",
                              fields: _vm.membersFields,
                              items: _vm.members,
                              "empty-text":
                                "表示可能なメンバーが見つかりません",
                              "show-empty": "",
                              busy: _vm.loading,
                            },
                            slot: "data-list",
                            scopedSlots: _vm._u([
                              {
                                key: "cell(user)",
                                fn: function (data) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(data.item.member.user.nickname) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(permission)",
                                fn: function (data) {
                                  return [
                                    data.item.member.isAdmin
                                      ? _c("span", [_vm._v(" 管理者 ")])
                                      : _c(
                                          "b-row",
                                          [
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "b-input-group",
                                                  [
                                                    _c(
                                                      "b-form-select",
                                                      {
                                                        attrs: {
                                                          disabled:
                                                            _vm.$ability.cannot(
                                                              "ACTION_wiki_admin_change",
                                                              "OBJ_default"
                                                            ),
                                                        },
                                                        model: {
                                                          value:
                                                            data.item
                                                              .permission,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              data.item,
                                                              "permission",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "data.item.permission",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value: "ADMIN",
                                                            },
                                                          },
                                                          [_vm._v("管理者")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value:
                                                                "SUB_ADMIN",
                                                            },
                                                          },
                                                          [_vm._v("副管理者")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "option",
                                                          {
                                                            domProps: {
                                                              value: "MEMBER",
                                                            },
                                                          },
                                                          [_vm._v("メンバー")]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "Can",
                                                      {
                                                        attrs: {
                                                          I: "",
                                                          do: "ACTION_wiki_admin_change",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.changePermissionConfirm(
                                                                  data.item
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("変更")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-modal",
                                                      {
                                                        ref: "alertAdminChanging",
                                                        attrs: {
                                                          "hide-header": "",
                                                          centered: "",
                                                          "no-fade": "",
                                                          lazy: "",
                                                        },
                                                        on: {
                                                          ok: function (
                                                            $event
                                                          ) {
                                                            return _vm.changePermission(
                                                              data.item
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("p", [
                                                          _vm._v(
                                                            "Wiki の管理者に設定できるのは一名のみです。"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("p", [
                                                          _vm._v(
                                                            "このユーザーに管理者を交代すると、現在の管理者は自動的に副管理者に降格します。"
                                                          ),
                                                        ]),
                                                        _vm._v(" "),
                                                        _c("p", [
                                                          _vm._v(
                                                            "本当に続行してもよろしいですか？"
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-col",
                                              [
                                                _c(
                                                  "Can",
                                                  {
                                                    attrs: {
                                                      I: "",
                                                      do: "ACTION_wiki_member_delete",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        attrs: {
                                                          variant:
                                                            "outline-danger",
                                                        },
                                                        on: {
                                                          click: () => {
                                                            _vm.$refs[
                                                              `alertMemberDeleting_${data.index}`
                                                            ].show()
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "メンバーから外す"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "b-modal",
                                                  {
                                                    ref: `alertMemberDeleting_${data.index}`,
                                                    attrs: {
                                                      "hide-header": "",
                                                      centered: "",
                                                      "no-fade": "",
                                                      lazy: "",
                                                    },
                                                    on: {
                                                      ok: function ($event) {
                                                        return _vm.deleteMember(
                                                          data.index,
                                                          data.item
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("p", [
                                                      _vm._v(
                                                        "\n                        Wiki 編集メンバーから外すことで、このユーザーはこの Wiki\n                        への編集等の作業をおこなえなくなります。\n                      "
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("p", [
                                                      _vm._v(
                                                        "本当に続行してもよろしいですか？"
                                                      ),
                                                    ]),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                },
                              },
                              {
                                key: "cell(lastLoggedInAt)",
                                fn: function (data) {
                                  return [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.dateFormat(
                                            new Date(
                                              data.item.member.user.lastLoggedInAt
                                            ),
                                            "yyyy/MM/dd HH:mm:ss"
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                },
                              },
                            ]),
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text-center my-2",
                                attrs: { slot: "table-busy" },
                                slot: "table-busy",
                              },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle",
                                }),
                                _vm._v(" "),
                                _c("strong", [_vm._v("読み込み中...")]),
                              ],
                              1
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }