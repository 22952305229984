
import Component from 'vue-class-component';
import LiquorTree from 'vls-liquor-tree';
import { Prop } from 'vue-property-decorator';

import KamigameVue from '@/KamigameVue';
import { components } from '@/api-client/schema';

@Component({
  name: 'kamigame-wiki-page-partial-select',
  components: {
    [LiquorTree.name]: LiquorTree,
  },
})
export default class WikiPagePartialSelect extends KamigameVue {
  @Prop()
  modalRef!: string;

  @Prop()
  modalTitle!: string;

  filter = '';
  searchString = '';
  wikiPagePartials: any[] = [];
  selectedWikiPagePartial: components['schemas']['v1WikiPagePartialName'] = {};
  wikiPagePartialsOptions = {
    filter: {
      emptyText: '指定された記事部品が見つかりませんでした',
      matcher: (query: string, node: any) => node.text.includes(query),
    },
  };

  async created() {
    this.wikiPagePartials = (
      (
        await this.apiClient
          .GET('/admin/wiki/{wikiName}/page-partial/titles', {
            params: {
              path: {
                wikiName: this.wikiName,
              },
              query: {
                isArchived: false,
              },
            },
          })
          .then((r) => {
            if (r.error) {
              throw r.error;
            }

            return r.data;
          })
      ).wikiPagePartial || []
    ).map((e) => {
      return this.transformWikiPagePartialsToTreeItem(e);
    });
  }

  transformWikiPagePartialsToTreeItem(wikiPageTemplateTitleAndBody: components['schemas']['v1WikiPagePartialName']) {
    return {
      text: wikiPageTemplateTitleAndBody.name,
      data: wikiPageTemplateTitleAndBody,
    };
  }

  onWikiPagePartialSelected(node: any) {
    this.searchString = node.text;
    this.selectedWikiPagePartial = node.data;
  }

  onSelectionSaved() {
    const selected = this.wikiPagePartialTree.selected();
    if (!selected) {
      return;
    }

    this.$emit('selectionSaved', this.selectedWikiPagePartial);
    this.clear();
  }

  onSearchStringChanged() {
    const input = this.$refs.input as Vue;
    if (document.activeElement !== input.$el) {
      return;
    }

    this.filter = this.searchString;
  }

  focusTree(event: KeyboardEvent) {
    if (event.isComposing) {
      return;
    }

    const firstNode = (this.$refs.wikiPagePartialsTree as any).find({ state: { visible: true } })[0];
    if (!firstNode) {
      return;
    }

    firstNode.vm.focus();
  }

  onSelectionCanceled() {
    this.clear();
  }

  clear() {
    this.filter = '';
    this.searchString = '';
    const selected = this.wikiPagePartialTree.selected();
    if (selected) {
      selected.unselect();
    }
  }

  onShownModal() {
    (this.$refs.input as HTMLElement).focus();
  }

  get wikiPagePartialTree() {
    return this.$refs.wikiPagePartialsTree as any;
  }
}
