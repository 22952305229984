
import KamigameVue from '@/KamigameVue';
import ButtonBulkReplaceImageUrl from '@/components/Button/ButtonBulkReplaceImageUrl.vue';
import ButtonBulkPublishDrafts from '@/components/Button/ButtonBulkPublishDrafts.vue';
import ButtonBulkReplaceContentHeader from '@/components/Button/ButtonBulkReplaceContentHeader.vue';
import ButtonBulkTransferArticlesForGameVillage from '@/components/Button/ButtonBulkTransferArticlesForGameVillage.vue';
import ButtonDownloadCsvForDeferenceFromSource from '@/components/Button/ButtonDownloadCsvForDeferenceFromSource.vue';
import Component from 'vue-class-component';

@Component({
  name: 'set-up-global-wiki',
  components: {
    ButtonBulkReplaceImageUrl,
    ButtonBulkPublishDrafts,
    ButtonBulkReplaceContentHeader,
    ButtonBulkTransferArticlesForGameVillage,
    ButtonDownloadCsvForDeferenceFromSource,
  },
})
export default class SetUpGameVillage extends KamigameVue {}
