const parseSpreadsheetIdFromUrl = (spreadsheetUrl: string) => {
  return (/\/spreadsheets\/d\/([a-zA-Z0-9-_]+)/.exec(spreadsheetUrl) || ['', ''])[1] || '';
};

const extractSameSpreadsheetSheetReferences = (
  referenceRegExpPattern: RegExp,
  sheetData: (string | number)[][],
  spreadsheetId: string
) => {
  const references: { [key: string]: string }[] = [];
  sheetData.forEach(row => {
    row.forEach(cell => {
      if (typeof cell !== 'string') {
        return;
      }

      let matched = referenceRegExpPattern.exec(cell);
      while (matched !== null) {
        if (!references.find(reference => matched !== null && reference.sheetName === matched[1])) {
          references.push({
            spreadsheetId,
            sheetName: matched[1],
          });
        }
        matched = referenceRegExpPattern.exec(cell);
      }
    });
  });

  return references;
};

const extractImportRangeSheetReferences = (sheetData: (string | number)[][]) => {
  const referenceRegExpPattern = /IMPORTRANGE\("(.+)", "(.+)!.*"\)/gi;
  const references: { [key: string]: string }[] = [];

  sheetData.forEach(row => {
    row.forEach(cell => {
      if (typeof cell !== 'string') {
        return;
      }

      let matched = referenceRegExpPattern.exec(cell);

      while (matched !== null) {
        if (!references.find(reference => matched !== null && reference.sheetName === matched[1])) {
          references.push({
            spreadsheetId: parseSpreadsheetIdFromUrl(matched[1]),
            sheetName: matched[2],
          });
        }
        matched = referenceRegExpPattern.exec(cell);
      }
    });
  });

  return references;
};

export function extractSpreadsheetSheetReference(sheetData: (string | number)[][], spreadsheetId: string) {
  // IMPORTRANGE("https://spreadcheet", "abc!A1") などを余計に引っ掛けてしまう。が、回避の方法も難しそうなので、呼び出し元側でシート名判別と重複判別することで除外する
  const onlyAlphaNumericReferenceRegExp = /([a-zA-Z0-9]+?)!/g;
  const sameSpreadsheetReferenceRegExp = /'(.+?)'!/g;

  const references = extractImportRangeSheetReferences(sheetData).concat(
    extractSameSpreadsheetSheetReferences(onlyAlphaNumericReferenceRegExp, sheetData, spreadsheetId),
    extractSameSpreadsheetSheetReferences(sameSpreadsheetReferenceRegExp, sheetData, spreadsheetId)
  );

  return references.filter(
    (reference, index, self) =>
      self.findIndex(
        (ref: { [key: string]: string }) =>
          ref.sheetName === reference.sheetName && ref.spreadsheetId === reference.spreadsheetId
      ) === index
  );
}
