
import Component from 'vue-class-component';
import KamigameVue from '@/KamigameVue';
import 'bootstrap-icons/font/bootstrap-icons.css';
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import FileSaver from 'file-saver';
import * as StringConverter from '@/service/StringConverter';
import format from 'date-fns/format';
import { adjustOcrResults, runOCR } from './ocr';
import { adjustImageRecognitionResults, runImageRecognition } from './imageRecognition';
import { loadImagesFromURLs } from './loadImage';
import { fetchSpreadsSheetData } from './spreadsSheet';
import { adjustImageUrlExtractionResults, runImageUrlExtraction } from './imageUrlExtraction';

type CanvasData = {
  width: number;
  height: number;
  naturalHeight: number;
  naturalWidth: number;
  left: number;
  top: number;
};

type SectionRule = 'not-selected' | 'ocr' | 'image-recognition' | 'image-url-extraction';

type TrimData = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export type RecognitionRegion = TrimData & {
  threshold: number;
  name: string;
};

export type SectionValue = {
  name: string;
  rule: SectionRule;
  trimData: TrimData;
  canPreview: boolean;
  spreadsSheetURL: string;
};

type SectionCropBoxData = {
  width: number;
  height: number;
  top: number;
  left: number;
};

type CurrentView = 'main' | 'preview' | 'executed-view';

type CurrentTable = 'ocr' | 'image-recognition' | 'image-url-extraction';

export type OcrResult = {
  result: boolean;
  ocr: Record<string, { best_threshold: number; text: string }>;
  requested?: { threshold: number; image: string }[];
};

export type ImageRecognitionResult = {
  imageRecognition: Record<string, { text: string }>;
};

export type ImageUrlExtractionResult = {
  imageUrlExtraction: Record<
    string,
    {
      id: string;
      name: string;
      url: string;
      wikiId: string;
    }
  >;
};

@Component({
  name: 'main-image-modal',
  components: {
    VueCropper,
  },
  props: {
    imageUrls: {
      type: Array as () => String[],
    },
    imageUrl: {
      type: String,
    },
    file: {
      type: Array as () => File[],
    },
    fetchedSectionValues: {
      type: Array as () => SectionValue[],
    },
  },
})
export default class MainImageModal extends KamigameVue {
  blobURL = '';

  cropperCanvasData: CanvasData = { width: 0, height: 0, naturalWidth: 0, naturalHeight: 0, top: 0, left: 0 };
  cropBoxData: SectionCropBoxData[] = [];
  currentImageIndex: number = 0;

  activeSectionIndex: number = 0;
  inputSectionName: string = '';
  inputRule: SectionRule = 'not-selected';
  inputSpreadsheetURL: string = '';
  inputTrimData: TrimData = { x: 0, y: 0, width: 0, height: 0 };
  sectionValues: SectionValue[] = [
    {
      name: 'セクション 1',
      rule: 'not-selected',
      trimData: { x: 120, y: 270, width: 400, height: 240 },
      canPreview: false,
      spreadsSheetURL: '',
    },
  ];
  checkSectionsModeIsEnabled: boolean = false;
  checkInputValuesMsgs: string[] = [];

  threshold: number = 0;
  currentView: CurrentView = 'main';
  currentTable: CurrentTable = 'ocr';

  ocrSectionValues: SectionValue[] = [];
  ocrResults: OcrResult[] = [];
  ocrDemoResultText: string = '';
  ocrPreviews: Record<string, string>[] = [];
  ocrErrMsgs: string[] = [];
  ocrErrFiles: File[] = [];

  imageRecognitionSectionValues: SectionValue[] = [];
  imageRecognitionResults: ImageRecognitionResult[] = [];
  imageRecognitionDemoResultText: string = '';
  imageRecognitionPreviews: Record<string, string>[] = [];

  imageUrlExtractionSectionValues: SectionValue[] = [];
  imageUrlExtractionDemoResultText: string = '';
  imageUrlExtractionResults: ImageUrlExtractionResult[] = [];
  imageUrlExtractionPreviews: Record<string, string>[] = [];

  processRequiredMinutes: number = 0;
  processProgressMaxValue: number = 0;
  processProgressCurrentValue: number = 0;
  processIsLoading: boolean = false;

  abortController: AbortController | null = null;

  onShown() {
    this.cropper.replace(this.$props.imageUrls[this.currentImageIndex]);

    if (0 < this.$props.fetchedSectionValues.length) {
      this.applySectionValuesFromFetchedData();
    } else {
      this.resetSectionValues();
    }
  }

  onReady() {
    this.cropper.initCrop();
    this.cropper.setData(this.sectionValues[this.activeSectionIndex].trimData);
    this.cropperCanvasData = this.cropper.getCanvasData();
  }

  controlCheckSectionsMode(e: Event) {
    if (e.target instanceof HTMLInputElement && e.target.tagName === 'INPUT') {
      if (e.target.checked) {
        this.enableCheckingSections();
      } else {
        this.disableCheckingSections();
      }
    }
  }

  enableCheckingSections() {
    this.resetCropperImageSize();

    const currentCropBoxData = this.sectionValues.map((sectionValue) => {
      this.cropper.setData(sectionValue.trimData);
      return sectionValue.canPreview ? this.cropper.getCropBoxData() : { width: 0, height: 0, top: 0, left: 0 };
    });
    this.cropBoxData = currentCropBoxData;

    this.cropper.setData({ x: 0, y: 0, width: 0, height: 0 });
    this.cropper.disable();
    this.checkSectionsModeIsEnabled = true;
  }

  disableCheckingSections() {
    this.cropper.enable();
    this.cropper.setData(this.sectionValues[this.activeSectionIndex].trimData);
    this.checkSectionsModeIsEnabled = false;
  }

  resetCropperImageSize() {
    this.cropper.setCanvasData(this.cropperCanvasData);
  }

  getCurrentImageUrl() {
    return this.$props.imageUrls[this.currentImageIndex];
  }

  nextImage() {
    if (this.currentImageIndex < this.$props.imageUrls.length - 1) {
      this.currentImageIndex++;
    } else {
      this.currentImageIndex = 0;
    }
    this.cropper.replace(this.$props.imageUrls[this.currentImageIndex]);
  }

  previousImage() {
    if (this.currentImageIndex > 0) {
      this.currentImageIndex--;
    } else {
      this.currentImageIndex = this.$props.imageUrls.length - 1;
    }
    this.cropper.replace(this.$props.imageUrls[this.currentImageIndex]);
  }

  addSection() {
    this.sectionValues.push({
      name: `セクション ${this.sectionValues.length + 1}`,
      rule: 'not-selected',
      trimData: { x: 120, y: 270, width: 400, height: 240 },
      canPreview: false,
      spreadsSheetURL: '',
    });
    const nextPos = this.sectionValues.length - 1;
    this.resetCropperImageSize();
    this.toggleSection(nextPos);
  }

  toggleSection(sectionIndex: number) {
    this.activeSectionIndex = sectionIndex;
    this.inputSectionName = this.sectionValues[this.activeSectionIndex].name;
    this.inputRule = this.sectionValues[this.activeSectionIndex].rule;
    this.inputSectionName = this.sectionValues[this.activeSectionIndex].name;
    this.cropper.setData(this.sectionValues[this.activeSectionIndex].trimData);
    this.checkInputValuesMsgs = [];
  }

  removeSection() {
    this.sectionValues = this.sectionValues.filter((_, i) => i !== this.activeSectionIndex);
  }

  confirmSectionValue() {
    this.inputTrimData = this.cropper.getData();
    this.sectionValues[this.activeSectionIndex].name = this.inputSectionName;
    this.sectionValues[this.activeSectionIndex].rule = this.inputRule;
    this.sectionValues[this.activeSectionIndex].trimData = this.inputTrimData;
    this.sectionValues[this.activeSectionIndex].spreadsSheetURL = this.inputSpreadsheetURL;
    this.sectionValues[this.activeSectionIndex].canPreview = true;
  }

  applySectionValuesFromFetchedData() {
    this.sectionValues = this.$props.fetchedSectionValues;

    const initPos = 0;
    this.toggleSection(initPos);
  }

  resetSectionValues() {
    this.inputSectionName = 'セクション 1';
    this.inputTrimData = { x: 0, y: 0, width: 0, height: 0 };
    this.inputRule = 'not-selected';
    this.inputSpreadsheetURL = '';
    this.sectionValues = [
      {
        name: `セクション 1`,
        rule: 'not-selected',
        trimData: { x: 120, y: 270, width: 400, height: 240 },
        canPreview: false,
        spreadsSheetURL: '',
      },
    ];
    this.activeSectionIndex = 0;
  }

  saveSectionValuesAsCSV() {
    if (!this.checkSectionsCanBePreviewed()) {
      alert('まだ確定していないセクションがあります。');
      return;
    }

    if (this.sectionValues.length === 0) {
      alert('セクションが追加されていません。');
      return;
    }

    const filename = window.prompt('ファイル名を入力してください', '');

    if (filename) {
      const csvHeader = [
        'ラベル',
        '認識ルール',
        'スプレッドシートURL',
        '領域座標x',
        '領域座標y',
        '領域横幅',
        '領域縦幅',
      ];
      const csvData = this.sectionValues.map((obj) => {
        return [
          `"${obj.name}"`,
          `"${obj.rule}"`,
          `"${obj.spreadsSheetURL}"`,
          `"${obj.trimData.x}"`,
          `"${obj.trimData.y}"`,
          `"${obj.trimData.width}"`,
          `"${obj.trimData.height}"`,
        ];
      });
      const csvRows = [csvHeader, ...csvData];
      const csv = csvRows.map((row) => row.join('\t')).join('\r\n');
      const blob = new Blob([StringConverter.convertStringToUTF16LEArray(csv)], {
        type: 'text/csv;charset=utf-16',
      });
      FileSaver.saveAs(blob, `${filename}-${format(new Date(), 'yyyyMMddHHmmss')}.csv`);
    } else {
      window.alert('キャンセルされました');
    }
  }

  handleConfirmSection() {
    if (!this.checkInputValues()) {
      return;
    }

    this.confirmSectionValue();
  }

  handleRemoveSection() {
    if (confirm(`このセクションを削除しますか?`)) {
      this.removeSection();

      if (this.sectionValues.length > 0) {
        this.resetCropperImageSize();

        const prevPos = this.activeSectionIndex - 1;
        this.toggleSection(prevPos);
      }
    }
  }

  show() {
    const mainImageModal = this.$refs.mainImageModal as any;
    mainImageModal.show();
  }

  hide(e: Event) {
    if (confirm('入力されたデータは破棄されます。')) {
      if (this.abortController) {
        this.abortController.abort();
        this.switchView('main');
      }

      this.disableCheckingSections();

      const imageUploaderModal = this.$refs.mainImageModal as any;
      imageUploaderModal.hide();
    } else {
      e.preventDefault();
    }
  }

  switchView(view: CurrentView) {
    this.currentView = view;
  }

  switchTable(table: CurrentTable) {
    this.currentTable = table;
  }

  checkInputValues() {
    this.checkInputValuesMsgs = [];
    const inputTrimData: TrimData = this.cropper.getData();

    if (inputTrimData.width * inputTrimData.height === 0) {
      this.checkInputValuesMsgs.push('領域が指定されていません。');
    }

    if (this.inputRule === 'not-selected') {
      this.checkInputValuesMsgs.push('認識方法が選択されていません。');
    }

    if (this.inputSectionName === '') {
      this.checkInputValuesMsgs.push('ラベルが入力されていません。');
    }

    if (this.inputRule === 'image-recognition' && this.inputSpreadsheetURL === '') {
      this.checkInputValuesMsgs.push('スプレッドシートのURLが入力されていません。');
    }

    const pattern = /^(https?|ftp)(:\/\/[\w\/:%#\$&\?\(\)~\.=\+\-]+)/;
    if (this.inputRule === 'image-recognition' && !pattern.test(this.inputSpreadsheetURL)) {
      this.checkInputValuesMsgs.push('スプレッドシートのURLが正しくない可能性があります。');
    }

    if (
      this.sectionValues
        .filter((_, i) => i !== this.activeSectionIndex)
        .some((section) => section.name === this.inputSectionName)
    ) {
      this.checkInputValuesMsgs.push('他のセクションと同じラベルをつけることはできません。');
    }

    if (this.checkInputValuesMsgs.length > 0) {
      return false;
    }

    return true;
  }

  checkSectionsCanBePreviewed() {
    return this.sectionValues.filter((section) => section.canPreview).length === this.sectionValues.length;
  }

  modifyNumberOfFiles(baseFiles: File[]): File[][] {
    const modifiedFiles: File[][] = [[]];
    let index = 0;
    let count = 0;
    const requestsCriteriaPerThrottling = 100; // クライアントごとの制限値
    const regionsNumber = this.sectionValues.length;

    baseFiles.forEach((value) => {
      const expectedRequestsPerThrottling = (count + 1) * regionsNumber;
      if (requestsCriteriaPerThrottling < expectedRequestsPerThrottling) {
        index++;
        count = 0;
        modifiedFiles[index] = [];
      }

      if (modifiedFiles[index] !== undefined || Array.isArray(modifiedFiles[index])) {
        modifiedFiles[index].push(value);
        count++;
      }
    });

    return modifiedFiles;
  }

  parseRegionInput(regions: RecognitionRegion[]): Record<string, [number, number, number, number, number]> {
    return regions.reduce((acc, data, i) => {
      const regionString = `${data.x},${data.y},${data.width},${data.height},${data.threshold}`;
      const values = regionString.split(',').map((v) => parseInt(v.trim(), 10));
      acc[data.name] = values as [number, number, number, number, number];
      return acc;
    }, {} as Record<string, [number, number, number, number, number]>);
  }

  cancelProcess() {
    if (this.abortController) {
      if (confirm('処理の実行中です。キャンセルしますか？')) {
        this.abortController.abort();
        this.switchView('main');
      }
    } else {
      this.switchView('main');
    }
  }

  async handleDemoSection() {
    if (!this.checkSectionsCanBePreviewed()) {
      alert('まだ確定していないセクションがあります。');
      return;
    }

    if (this.sectionValues.length === 0) {
      alert('セクションが追加されていません。');
      return;
    }

    this.ocrDemoResultText = '';
    this.imageRecognitionDemoResultText = '';
    this.imageUrlExtractionDemoResultText = '';

    const activeSectionValue = this.sectionValues[this.activeSectionIndex];
    const activeSectionValueArray = [activeSectionValue];
    const targetFile = this.$props.file.slice(0, 1);

    this.ocrSectionValues = activeSectionValueArray.filter((section) => section.rule === 'ocr');
    this.imageRecognitionSectionValues = activeSectionValueArray.filter(
      (section) => section.rule === 'image-recognition'
    );
    this.imageUrlExtractionSectionValues = activeSectionValueArray.filter(
      (section) => section.rule === 'image-url-extraction'
    );

    this.abortController = new AbortController();
    const { signal } = this.abortController;
    this.processIsLoading = true;

    if (this.ocrSectionValues.length > 0) {
      await this.applyOCRToSections(targetFile, this.ocrSectionValues, signal);
      this.ocrDemoResultText = this.ocrResults[0].ocr[activeSectionValue.name].text;
    }

    if (this.imageRecognitionSectionValues.length > 0) {
      await this.applyImageRecognitionToSections(targetFile, this.imageRecognitionSectionValues);
      this.imageRecognitionPreviews = adjustImageRecognitionResults(
        this.imageRecognitionResults,
        this.imageRecognitionSectionValues
      );
      this.imageRecognitionDemoResultText = this.imageRecognitionPreviews[0][activeSectionValue.name];
    }

    if (this.imageUrlExtractionSectionValues.length > 0) {
      await this.applyImageUrlExtractionToSections(targetFile, this.imageUrlExtractionSectionValues);
      this.imageUrlExtractionPreviews = adjustImageUrlExtractionResults(
        this.imageUrlExtractionResults,
        this.imageUrlExtractionSectionValues
      );
      this.imageUrlExtractionDemoResultText = this.imageUrlExtractionPreviews[0][activeSectionValue.name];
    }

    this.processIsLoading = false;
    this.abortController = null;
  }

  async preview() {
    if (!this.checkSectionsCanBePreviewed()) {
      alert('まだ確定していないセクションがあります。');
      return;
    }

    if (this.sectionValues.length === 0) {
      alert('セクションが追加されていません。');
      return;
    }

    this.switchView('preview');

    const previewFilesNum = 15;
    const targetFiles = this.$props.file.slice(0, previewFilesNum);

    this.ocrSectionValues = this.sectionValues.filter((section) => section.rule === 'ocr');
    this.imageRecognitionSectionValues = this.sectionValues.filter((section) => section.rule === 'image-recognition');
    this.imageUrlExtractionSectionValues = this.sectionValues.filter(
      (section) => section.rule === 'image-url-extraction'
    );

    this.abortController = new AbortController();
    const { signal } = this.abortController;
    this.processIsLoading = true;

    if (this.ocrSectionValues.length > 0) {
      await this.applyOCRToSections(targetFiles, this.ocrSectionValues, signal);
      this.ocrPreviews = adjustOcrResults(this.ocrResults, this.ocrSectionValues);
    }

    if (this.imageRecognitionSectionValues.length > 0) {
      await this.applyImageRecognitionToSections(targetFiles, this.imageRecognitionSectionValues);
      this.imageRecognitionPreviews = adjustImageRecognitionResults(
        this.imageRecognitionResults,
        this.imageRecognitionSectionValues
      );
    }

    if (this.imageUrlExtractionSectionValues.length > 0) {
      await this.applyImageUrlExtractionToSections(targetFiles, this.imageUrlExtractionSectionValues);
      this.imageUrlExtractionPreviews = adjustImageUrlExtractionResults(
        this.imageUrlExtractionResults,
        this.imageUrlExtractionSectionValues
      );
    }

    this.processIsLoading = false;
    this.abortController = null;
  }

  async execute() {
    this.switchView('executed-view');

    const targetFiles = this.$props.file.slice();

    const ocrSectionValues = this.sectionValues.filter((section) => section.rule === 'ocr');
    const imageRecognitionSectionValues = this.sectionValues.filter((section) => section.rule === 'image-recognition');
    const imageUrlExtractionSectionValues = this.sectionValues.filter(
      (section) => section.rule === 'image-url-extraction'
    );

    this.abortController = new AbortController();
    const { signal } = this.abortController;
    this.processIsLoading = true;

    if (ocrSectionValues.length > 0) {
      await this.applyOCRToSections(targetFiles, ocrSectionValues, signal);
    }

    if (imageRecognitionSectionValues.length > 0) {
      await this.applyImageRecognitionToSections(targetFiles, imageRecognitionSectionValues);
    }

    if (imageUrlExtractionSectionValues.length > 0) {
      await this.applyImageUrlExtractionToSections(targetFiles, imageUrlExtractionSectionValues);
    }

    this.processIsLoading = false;
    this.abortController = null;
  }

  async applyOCRToSections(targetFiles: File[], ocrSectionValues: SectionValue[], signal: AbortSignal, mode?: 'retry') {
    const ocrRegions = ocrSectionValues.map((section) => {
      const trimData = section.trimData;
      return {
        ...trimData,
        threshold: this.threshold,
        name: section.name,
      } as RecognitionRegion;
    });

    const modifiedFiles = this.modifyNumberOfFiles(targetFiles);
    this.processRequiredMinutes = this.calcProcessRequiredMinutes(modifiedFiles.length);
    this.processProgressMaxValue = modifiedFiles.length;
    this.processProgressCurrentValue = 0;

    this.ocrErrFiles.splice(0);
    const ocrAllResults = [];
    const parseRegions = this.parseRegionInput(ocrRegions);
    for (const [index, files] of modifiedFiles.entries()) {
      const { results, ocrErr } = await runOCR(files, parseRegions, signal);

      ocrAllResults.push(results);

      this.ocrErrMsgs = [...ocrErr.msgs, ...this.ocrErrMsgs];
      this.ocrErrFiles = [...ocrErr.files, ...this.ocrErrFiles];

      this.processProgressCurrentValue = ocrAllResults.length;
      if (index < modifiedFiles.length - 1) {
        await new Promise((resolve) => setTimeout(resolve, 45 * 1000));
      }
    }

    if (mode === 'retry') {
      const filterOcrResult = this.ocrResults.filter((item) => item.result);
      const retryOcrResult = ocrAllResults.flat();
      retryOcrResult.forEach((result) => {
        filterOcrResult.push(result);
      });
      this.ocrResults = filterOcrResult;
    } else {
      this.ocrResults = ocrAllResults.flat();
    }

    if (this.ocrErrMsgs.length > 0) {
      alert(
        `${this.ocrErrMsgs.join(
          '\n'
        )}\nしばらく経ってからもう一度お試しいただき、それでも解決しなければ開発チームに連絡ください。`
      );
      this.ocrErrMsgs.splice(0);
    }
  }

  async applyImageRecognitionToSections(targetFiles: File[], imageRecognitionSectionValues: SectionValue[]) {
    const imageRecognitionRegions = imageRecognitionSectionValues.map((section) => {
      const trimData = section.trimData;
      return {
        ...trimData,
        threshold: this.threshold,
        name: section.name,
      };
    });
    const spreadsSheetURLs = imageRecognitionSectionValues.map((section) => section.spreadsSheetURL);

    const modifiedFiles = this.modifyNumberOfFiles(targetFiles);
    this.processRequiredMinutes = this.calcProcessRequiredMinutes(modifiedFiles.length);
    this.processProgressMaxValue = modifiedFiles.length;
    this.processProgressCurrentValue = 0;

    const imageRecognitionResults: ImageRecognitionResult[] = [];

    // TODO: 正式なスプレッドシートが来たら、それに合わせる
    for (const files of modifiedFiles) {
      const fetchedSheetData = await fetchSpreadsSheetData(spreadsSheetURLs[0]);
      if (fetchedSheetData === undefined) {
        break;
      }

      const sheetData = fetchedSheetData.slice(1).map((data) => {
        return {
          name: data[0],
          imageURL: data[1],
        };
      });

      const names = sheetData.map((data) => data.name);
      const imageURLs = sheetData.map((data) => data.imageURL);
      const templateImages = loadImagesFromURLs(imageURLs);
      const parseRegions = this.parseRegionInput(imageRecognitionRegions);

      const results = await runImageRecognition(files, parseRegions, templateImages);

      results.forEach((result) => {
        const sectionsPerFile = result;
        const obj: Record<string, { text: string }> = {};
        let namesString = '';

        Object.keys(sectionsPerFile).forEach((sectionKey) => {
          const matchings = sectionsPerFile[sectionKey];

          Object.keys(matchings).forEach((matchingKey) => {
            const num = matchings[matchingKey];
            if (0 < num) {
              namesString += `[${names[Number(matchingKey)]}]x${num}、`;
            }
          });

          obj[sectionKey] = { text: namesString };
        });

        imageRecognitionResults.push({ imageRecognition: obj });
      });
    }

    this.imageRecognitionResults = imageRecognitionResults;
  }

  async retryOCRToErrFiles() {
    const targetFiles = this.ocrErrFiles.slice();

    const ocrSectionValues = this.sectionValues.filter((section) => section.rule === 'ocr');

    this.abortController = new AbortController();
    const { signal } = this.abortController;
    this.processIsLoading = true;

    if (ocrSectionValues.length > 0) {
      await this.applyOCRToSections(targetFiles, ocrSectionValues, signal, 'retry');
    }

    this.processIsLoading = false;
    this.abortController = null;
  }

  async applyImageUrlExtractionToSections(targetFiles: File[], imageUrlExtractionSectionValues: SectionValue[]) {
    const imageUrlExtractionRegions = imageUrlExtractionSectionValues.map((section) => {
      const trimData = section.trimData;
      return {
        ...trimData,
        name: section.name,
        threshold: this.threshold,
      };
    });
    const modifiedFiles = this.modifyNumberOfFiles(targetFiles);
    this.processRequiredMinutes = this.calcProcessRequiredMinutes(modifiedFiles.length);
    this.processProgressMaxValue = modifiedFiles.length;
    this.processProgressCurrentValue = 0;
    const parseRegions = this.parseRegionInput(imageUrlExtractionRegions);
    for (const files of modifiedFiles) {
      const imageUrlExtractionResults = await runImageUrlExtraction(
        this.$store.getters.sessionId,
        this.wikiName,
        true,
        files,
        parseRegions,
        this.apiClient
      );
      this.imageUrlExtractionResults.push(...imageUrlExtractionResults);
    }
  }

  calcProcessRequiredMinutes(length: number) {
    return Math.floor((60 * length + 30) / 60);
  }

  calcProcessProgressRatio() {
    return Math.floor((this.processProgressCurrentValue / this.processProgressMaxValue) * 100);
  }

  generateCSV() {
    const filename = window.prompt('ファイル名を入力してください', '');

    const ocrOutputs = adjustOcrResults(this.ocrResults, this.ocrSectionValues);
    const imageRecognitionOutputs = adjustImageRecognitionResults(
      this.imageRecognitionResults,
      this.imageRecognitionSectionValues
    );
    const imageUrlExtractionOutputs = adjustImageUrlExtractionResults(
      this.imageUrlExtractionResults,
      this.imageUrlExtractionSectionValues
    );
    const combinedOutputs = (this.$props.file as File[]).map((file, i) => {
      return {
        fileName: file.name,
        ...ocrOutputs[i],
        ...imageRecognitionOutputs[i],
        ...imageUrlExtractionOutputs[i],
      };
    });

    if (filename) {
      const csvHeader = [
        'ファイル名',
        ...(ocrOutputs.length > 0 ? Object.keys(ocrOutputs[0]) : []),
        ...(imageRecognitionOutputs.length > 0 ? Object.keys(imageRecognitionOutputs[0]) : []),
        ...(imageUrlExtractionOutputs.length > 0 ? Object.keys(imageUrlExtractionOutputs[0]) : []),
      ];

      const csvData = combinedOutputs.map((obj) => {
        return Object.values(obj).map((value) => {
          return `"${value}"`;
        });
      });
      const csvRows = [csvHeader, ...csvData];
      const csv = csvRows.map((row) => row.join('\t')).join('\r\n');
      const blob = new Blob([StringConverter.convertStringToUTF16LEArray(csv)], {
        type: 'text/csv;charset=utf-16',
      });
      FileSaver.saveAs(blob, `${filename}-${format(new Date(), 'yyyyMMddHHmmss')}.csv`);
    } else {
      window.alert('キャンセルされました');
    }
  }

  private get cropper() {
    return this.$refs.cropper as any;
  }

  get wikiName(): string {
    return this.route.params.name || '';
  }
}
