
import format from 'date-fns/format';
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';

import KamigameVue from '@/KamigameVue';
import { CategoryTreeSelectModal, Paging } from '@/components';
import { components } from '@/api-client/schema';

type SortedBy = 'lastUpdatedAt' | 'title';
type SortOrder = 'ASC' | 'DESC';
type SortCondition = { sortedBy: SortedBy; sortOrder: SortOrder };

@Component({
  name: 'wiki-page-draft-list',
  components: {
    'kamigame-category-tree-select-modal': CategoryTreeSelectModal,
    'kamigame-paging': Paging,
  },
})
export default class WikiPageDraftList extends KamigameVue {
  dateFormat = format;

  loading = false;
  filteredByKeywords = false;
  filteredByEmptyCategory = false;

  drafts: components['schemas']['v1WikiPageDraft'][] = [];
  fields = [
    { thStyle: { width: '35%' }, key: 'title', label: '記事名' },
    { thStyle: { width: '15%' }, key: 'category', label: 'カテゴリ' },
    { key: 'privilege', label: '編集権限' },
    { key: 'lastUpdatedAt', label: '最終更新日時' },
    { key: '_operation', label: '操作' },
  ];
  sortConditions: { text: string; value: SortCondition }[] = [
    { text: '最終更新日が新しい順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' } },
    { text: '最終更新日が古い順', value: { sortedBy: 'lastUpdatedAt', sortOrder: 'ASC' } },
    { text: 'タイトル順', value: { sortedBy: 'title', sortOrder: 'ASC' } },
  ];
  selectedCategory: components['schemas']['v1WikiPageCategory'] = {};
  perPageNum = 20;
  inputWords = '';
  searchWords = '';
  selectedSortCondition: SortCondition = { sortedBy: 'lastUpdatedAt', sortOrder: 'DESC' };
  startAt = 0;

  async mounted() {
    this.loading = true;

    try {
      const res = await this.apiClient
        .GET('/admin/wiki/{wikiName}/page/draft', {
          params: {
            path: {
              wikiName: this.wikiName,
            },
          },
        })
        .then((r) => {
          if (r.error) {
            throw r.error;
          }

          return r.data;
        });
      this.drafts = res.drafts || [];
    } catch (e: any) {
      if (e.code && e.code === 404) {
        this.drafts = [];
      }
    } finally {
      this.loading = false;
    }
  }

  clearCategory() {
    this.selectedCategory = {};
  }

  onCategorySelected(category: components['schemas']['v1WikiPageCategory']) {
    this.selectedCategory = category;
  }

  async deleteDraft(pageId: string, index: number) {
    await this.apiClient
      .DELETE('/admin/wiki/{wikiName}/page/{pageId}/draft', {
        params: {
          path: {
            wikiName: this.wikiName,
            pageId,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
    this.drafts.splice(index, 1);
    this.setFlashMessage('success', '下書きを削除しました。');
  }

  showDeleteModal(index: number) {
    const modal = this.$refs[`deleteDraft_${index}`] as BModal;
    modal.show();
  }

  changeDataRange(startAt: number) {
    this.startAt = startAt;
  }

  get totalPageNum() {
    return this.drafts.length || 0;
  }

  get endAt() {
    return this.startAt + this.perPageNum;
  }

  get selectedCategoryId() {
    return this.selectedCategory.id ?? '';
  }

  get pagedDrafts() {
    const categoryFilter = (draft: components['schemas']['v1WikiPageDraft']) => {
      if (this.filteredByEmptyCategory) {
        return !draft.category;
      }

      if (this.selectedCategory.id) {
        return draft.category?.id === this.selectedCategory.id;
      }

      return true;
    };

    const keywordFilter = (draft: components['schemas']['v1WikiPageDraft']) => {
      return draft.title?.includes(this.searchWords);
    };

    const sortFn = (a: components['schemas']['v1WikiPageDraft'], b: components['schemas']['v1WikiPageDraft']) => {
      const diff = {
        lastUpdatedAt: () => {
          if (!a.updatedAt || !b.updatedAt) {
            return 0;
          }

          return new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime();
        },
        title: () => {
          if (!a.title || !b.title) {
            return 0;
          }

          return a.title.localeCompare(b.title);
        },
      }[this.selectedSortCondition.sortedBy]();

      return this.selectedSortCondition.sortOrder === 'ASC' ? diff : -diff;
    };

    return this.drafts.filter((d) => categoryFilter(d) && keywordFilter(d)).sort(sortFn);
  }
}
