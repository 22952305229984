function getColorTableSize(hasColorTable: number, sizeOfColorTable: number): number {
  return hasColorTable ? Math.pow(2, sizeOfColorTable + 1) * 3 : 0;
}

function getDataSize(arr: Uint8Array, blockBodyOffset: number, index: number): number {
  let dataLength = 0;
  while (arr[index + blockBodyOffset + dataLength]) {
    dataLength += arr[index + blockBodyOffset + dataLength] + 1;
  }
  return blockBodyOffset + dataLength;
}

export async function detectAnimationGif(file: File): Promise<boolean> {
  if (file.type !== 'image/gif') {
    return false;
  }

  // thanks to https://www.tohoho-web.com/wwwgif.htm
  const buffer = await file.arrayBuffer();
  const arr = new Uint8Array(buffer);

  // Detect the magic byte is for GIF89a (it begins GIF89a)
  if (arr[0] !== 0x47 || arr[1] !== 0x49 || arr[2] !== 0x46 || arr[3] !== 0x38 || arr[4] !== 0x39 || arr[5] !== 0x61) {
    return false;
  }
  const globalColorTableSize = getColorTableSize(arr[10] & 0x80, arr[10] & 0x07);
  // signature(3B) + version(3B) + logicalScreenWidth(2B) + logicalScreenHeight(2B) +globalColorTableInfomations(1B) + backgroundColorIndex(1B) + pixelAspectRatio(1B) + globalColorTable(?B)
  const blockStartOffset = 13 + globalColorTableSize;
  let frames = 0;
  for (let index = blockStartOffset; index < arr.length && frames < 2; ++index) {
    if (arr[index] === 0x2c) {
      const localColorTableSize = getColorTableSize(arr[index + 9] & 0x80, arr[index + 9] & 0x07);
      // separator(1B) + leftPosition(2B) + topPosition(2B) + width(2B) + height(2B) +localColorTableInfomations(1B) + localColorTable(?B) + lzwMinimumCodeSize(1B)
      const blockBodyOffset = 11 + localColorTableSize;
      index += getDataSize(arr, blockBodyOffset, index);
      frames++;
    } else if (arr[index] === 0x21) {
      const blockBodyOffset = 2;
      index += getDataSize(arr, blockBodyOffset, index);
    } else {
      break;
    }
  }

  return frames > 1;
}
