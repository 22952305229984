var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          staticStyle: { overflow: "auto", "max-width": "100%" },
          attrs: { "no-key-nav": "" },
          on: { input: _vm.switchTab },
        },
        [
          _vm._l(_vm.data.sheets, function (title, index) {
            return _vm.isSheetSelected(index)
              ? _c(
                  "b-tab",
                  { key: index },
                  [
                    _c(
                      "template",
                      { slot: "title" },
                      [
                        _vm.data.sheets[index].spreadsheetId
                          ? _c("i", { staticClass: "fa fa-refresh" })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.data.sheets[index].isForTemplateCode
                          ? _c("i", { staticClass: "icon-rocket mx-1" })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            class: {
                              "bg-success":
                                _vm.updatedDataPosConfig[index] &&
                                _vm.updatedDataPosConfig[index].isUpdated,
                            },
                          },
                          [_vm._v(_vm._s(_vm.data.sheets[index].sheetName))]
                        ),
                        _vm._v(" "),
                        _vm.isOperationableSheet
                          ? _c(
                              "b-dropdown",
                              {
                                attrs: {
                                  variant: "link",
                                  "toggle-class": "text-decoration-none",
                                  "no-caret": "",
                                },
                              },
                              [
                                _c("template", { slot: "button-content" }, [
                                  _c("i", {
                                    staticClass:
                                      "fa fa-caret-down sheet-config-edit-caret",
                                  }),
                                ]),
                                _vm._v(" "),
                                !_vm.data.sheets[index].spreadsheetId &&
                                _vm.route.name === "wiki_data_common"
                                  ? _c(
                                      "b-dropdown-item",
                                      { on: { click: _vm.editSheetTitle } },
                                      [_vm._v("シートのタイトルを変更する")]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "b-dropdown-item",
                                  { on: { click: _vm.deleteDataSheet } },
                                  [_vm._v("シートを削除する")]
                                ),
                              ],
                              2
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  2
                )
              : _vm._e()
          }),
          _vm._v(" "),
          _c(
            "Can",
            { attrs: { I: "", do: "ACTION_wiki_db_update" } },
            [
              _vm.isAddableDataSheet
                ? _c("b-tab", {
                    attrs: { title: "+" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.createTab.apply(null, arguments)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _vm.isUpdateSheet || _vm.selectedSheetIds.length
            ? _c(
                "div",
                { staticClass: "sheet-editing" },
                [
                  _vm._t("default", null, {
                    dataSheet: (_vm.data.sheets || [])[_vm.selectedSheetIndex],
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "edit" }, [
                    _c(
                      "div",
                      { staticClass: "data-table-wrapper mt-3" },
                      [
                        _c("HotTable", {
                          ref: "dataSheet",
                          attrs: { settings: _vm.tableSettings },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }