import * as JsDiff from 'diff';

export function calcDiffLines(base: string, previous: string) {
  let addedLines = 0;
  let removedLines = 0;

  JsDiff.diffLines(previous, base).forEach(l => {
    if (l.added) {
      addedLines += l.count || 0;
    } else if (l.removed) {
      removedLines += l.count || 0;
    }
  });

  return Math.max(addedLines, removedLines);
}
