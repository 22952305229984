export function normalizeSpreadsheetSheetRowLength(data: string[][] | undefined): string[][] {
  if (!data) {
    return [[]];
  }

  const colNum = Math.max(...data.map((row) => row.length));
  return data.map((row: string[]) => {
    const lackColNum = colNum - row.length;

    return row.concat(new Array(lackColNum).fill(''));
  });
}
