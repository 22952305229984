import storage from 'local-storage-fallback';

const configKey = 'kamigame-markdown-editor-scroll-sync-config';

export default function kamigameScrollSyncExtension(editor: any) {
  let isActive = true;
  if (storage.getItem(configKey)) {
    try {
      isActive = !!JSON.parse(storage.getItem(configKey) || '');
    } catch (e) {
      // ignore
    }
  }

  editor.getUI().name = 'kamigame';

  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem('divider');
  toolbar.addItem({
    type: 'button',
    options: {
      className: `tui-editor-custom-button tui-scrollsync kamigame-scroll-sync ${isActive ? 'active' : ''}`,
      command: 'scrollSyncToggle',
      tooltip: `自動スクロールが${isActive ? '有効' : '無効'}です`,
      name: 'kamigame-scroll-sync',
    },
  });

  const items = toolbar.getItems();
  const button = items.filter((v: any) => v.getName() === 'kamigame-scroll-sync').pop();
  if (button) {
    editor.addCommand('markdown', {
      name: 'scrollSyncToggle',
      exec() {
        isActive = !isActive;
        if (isActive) {
          button.el.classList.add('active');
          button.setTooltip('自動スクロールが有効です');
        } else {
          button.el.classList.remove('active');
          button.setTooltip('自動スクロールが無効です');
        }

        storage.setItem(configKey, JSON.stringify(isActive));
      },
    });
  }

  function getElementByMarkdownBlockLine(line: number) {
    const encoded = line.toString(36);
    const selector = `*[data-md-block^="${encoded},"]`;

    return document.querySelector<HTMLElement>(selector);
  }

  const codeMirror = editor.getCodeMirror();
  codeMirror.on('scroll', (event: any) => {
    const preview = document.getElementsByClassName('kg-preview')[0];
    if (!isActive || !preview) {
      return;
    }

    const line = event.lineAtHeight(event.getScrollInfo().top, 'local');
    if (line === 0) {
      preview.scrollTo(0, 0);
      return;
    }

    const lineCount = codeMirror.getDoc().lineCount();
    let prevPos = 0;
    let prevLine = 0;
    let nextPos = preview.clientHeight;
    let nextLine = lineCount;

    for (let i = line; i >= 0; i--) {
      const element = getElementByMarkdownBlockLine(i);
      if (element) {
        const top = element.getBoundingClientRect().top - preview.getBoundingClientRect().top;
        prevPos = top;
        prevLine = i;
        break;
      }
    }

    for (let i = line; i <= lineCount; i++) {
      const element = getElementByMarkdownBlockLine(i);
      if (element) {
        const top = element.getBoundingClientRect().top - preview.getBoundingClientRect().top;
        nextPos = top;
        nextLine = i;
        break;
      }
    }

    const lineDiff = line - prevLine;
    const heightPerLine = lineDiff ? (nextPos - prevPos) / (nextLine - prevLine) : 0;

    preview.scrollTop += prevPos + lineDiff * heightPerLine;
  });
}
