var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "app-header navbar" },
    [
      _c(
        "button",
        {
          staticClass: "navbar-toggler mobile-sidebar-toggler d-lg-none",
          attrs: { type: "button" },
          on: { click: _vm.mobileSidebarToggle },
        },
        [_c("span", { staticClass: "navbar-toggler-icon" })]
      ),
      _vm._v(" "),
      _c("b-link", { staticClass: "navbar-brand", attrs: { to: "#" } }),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "navbar-toggler sidebar-toggler d-md-down-none mr-auto",
          attrs: { type: "button" },
          on: { click: _vm.sidebarToggle },
        },
        [_c("span", { staticClass: "navbar-toggler-icon" })]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "navbar-toggler aside-menu-toggler d-md-down-none",
          attrs: { type: "button" },
          on: { click: _vm.asideToggle },
        },
        [_c("span", { staticClass: "navbar-toggler-icon" })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }