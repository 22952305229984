var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("記事閲覧許可 IP アドレス設定")]),
                      ]),
                      _vm._v(" "),
                      _c("p", [
                        _vm._v(
                          "\n            この画面で設定された IP アドレスであれば、プライベートモードに設定された Wiki でも、 Wiki\n            編集メンバーに招待することなく記事を限定公開することが可能です。\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "許可対象の IP アドレス",
                            description:
                              "この Wiki の閲覧を許可する IP アドレスを指定します。複数指定する場合は改行してください",
                          },
                        },
                        [
                          _c("b-form-textarea", {
                            model: {
                              value: _vm.allowedIPAddress,
                              callback: function ($$v) {
                                _vm.allowedIPAddress = $$v
                              },
                              expression: "allowedIPAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "footer" }, slot: "footer" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: { click: _vm.save },
                            },
                            [_vm._v("保存する")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: _vm.existsUnsavedChanges },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }