const toolBarName = 'toggle-search-bar';
const eventName = 'toggle-search-bar';

function KamigameSearchBar(editor: any) {
  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem({
    type: 'button',
    options: {
      name: toolBarName,
      className: 'tui-editor-custom-button fa fa-search',
      event: eventName,
      tooltip: '本文検索 / 置換',
    },
  });

  editor.eventManager.addEventType(eventName);
  editor.commandManager.addCommand('markdown', {
    name: eventName,
    exec() {
      editor.eventManager.emit(eventName);
    },
  });
}

export default {
  plugin: KamigameSearchBar,
  toolBarName,
  eventName,
};
