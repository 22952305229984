
import Component from 'vue-class-component';
import KamigameVue from '@/KamigameVue';
import { Prop, Watch } from 'vue-property-decorator';

@Component({
  name: 'link-assist-modal',
})
export default class linkAssistModal extends KamigameVue {
  @Prop()
  linkAssistUrls!: {
    title: string;
    url: string;
  }[];

  inputLinkTabIndex = 0;
  linkText = '';
  linkURL = '';

  searchString = '';
  isLinkPageSelected = false;

  reset() {
    this.inputLinkTabIndex = 0;
    this.linkText = '';
    this.linkURL = '';
    this.searchString = '';
    this.isLinkPageSelected = false;
  }

  onSearchStringChanged(text: string) {
    const match = this.linkAssistUrls.filter(({ title }) => title === text);
    if (match.length > 0) {
      if (this.linkText === '') {
        this.linkText = match[0].title.replace('(未公開) ', '');
      }
      this.linkURL = match[0].url;
      this.isLinkPageSelected = true;
      return;
    }
    this.linkURL = '';
    this.isLinkPageSelected = false;
  }

  insertLinkText() {
    this.$emit('selected', `[${this.linkText}](${this.linkURL})`);
  }

  shownDefaultFocus() {
    (this.$refs.searchStringInput as any).focus();
  }

  show(linkText: string) {
    this.linkText = linkText;
    this.searchString = linkText ? `${linkText} ` : '';
    const linkAssist = this.$refs.linkAssist as any;
    linkAssist.show();
  }
}
