import Vuex from 'vuex';
import Vue from 'vue';
import storage from 'local-storage-fallback';
import { Ability, Rule } from '@casl/ability';

import { createAPIClient } from '@/service/WikiAPIClientFactory';
import { components } from '@/api-client/schema';

export const ability = new Ability();

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    flashes: {} as any,
    clearFlashTimerId: 0,
    user: {} as any,
    wiki: new Map<string, components['schemas']['v1Wiki']>(),
    ability: new Map<string | undefined, Ability>(),
    session: {
      id: storage.getItem('session.id') || '',
      exp: new Date(storage.getItem('session.exp') || Date.now()),
    },
    documentTitle: '',
  },
  getters: {
    getWiki:
      (state) =>
      (name: string): Promise<components['schemas']['v1Wiki']> => {
        const cached = state.wiki.get(name);
        if (cached) {
          return Promise.resolve(cached);
        }

        if (!state.user) {
          return Promise.reject(new Error('User is not authorized.'));
        }

        const api = createAPIClient({ token: store.getters.sessionId });
        return api
          .GET('/wiki/{name}', {
            params: {
              path: {
                name,
              },
            },
          })
          .then((r) => {
            if (r.error) {
              throw r.error;
            }

            return r.data;
          })
          .then((wiki: components['schemas']['v1Wiki']) => {
            store.commit('setWiki', wiki);
            return wiki;
          });
      },
    getAbility:
      (state) =>
      async (name: string | undefined, force: boolean = false): Promise<Ability> => {
        const cached = state.ability.get(name);
        if (cached && !!force) {
          return Promise.resolve(cached);
        }

        const api = createAPIClient({ token: store.getters.sessionId });
        const permision = !name
          ? await api.GET('/permission').then((r) => {
              if (r.error) {
                throw r.error;
              }

              return r.data;
            })
          : await api
              .GET('/wiki/{name}/permission', {
                params: {
                  path: {
                    name,
                  },
                },
              })
              .then((r) => {
                if (r.error) {
                  throw r.error;
                }

                return r.data;
              });

        ability.update(permision.rules as Rule[]);
        store.commit('setAbility', { name, ability });

        return ability;
      },
    sessionId(state): string {
      if (state.session.exp && state.session.exp < new Date()) {
        return '';
      }

      return state.session.id;
    },
    clearFlashTimerId(state): number {
      return state.clearFlashTimerId;
    },
  },
  mutations: {
    setSession(state, session) {
      state.session = {
        id: session.id || '',
        exp: session.exp || new Date(),
      };

      storage.setItem('session.id', state.session.id);
      storage.setItem('session.exp', String(state.session.exp.getTime()));
    },
    setWiki(state, wiki: components['schemas']['v1Wiki']) {
      if (!wiki.name) {
        return;
      }
      state.wiki.set(wiki.name, wiki);
    },
    setAbility(state, params: { name: string | undefined; ability: Ability }) {
      state.ability.set(params.name, params.ability);
    },
    setUser(state, user) {
      state.user = user;
    },
    setFlashMessage(state, payload) {
      state.flashes = Object.assign({}, state.flashes, {
        [payload.flashId]: {
          variant: payload.variant || '',
          message: payload.message || '',
          linkLabel: payload.linkLabel || '',
          linkTo: payload.linkTo || '',
          linkNewTab: payload.linkNewTab || '',
        },
      });
    },
    clearFlashMessage(state, id) {
      state.flashes[id] = {};
    },
    clearAllFlashMessages(state) {
      state.flashes = {};
    },
    setDocumentTitle(state, title) {
      state.documentTitle = title;
      document.title = state.documentTitle;
    },
  },
});

export type Store = typeof store;
