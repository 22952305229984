
import { components } from '@/api-client/schema';
import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';

interface BaseConfigElement {
  head: string;
  data: string;
}

@Component({
  name: 'base-config-card',
})
export default class BaseConfigCard extends KamigameVue {
  @Prop()
  cols!: number;
  @Prop()
  wikiForDashboard!: components['schemas']['v1WikiForDashboard'];

  baseConfig: BaseConfigElement[] = [];

  @Watch('wikiForDashboard')
  onChangeWikiForDashboard(newValue: components['schemas']['v1WikiForDashboard']) {
    const convertBoolToString = (bool: boolean | undefined): string => {
      return bool ? '有効' : '無効';
    };

    this.baseConfig.push({ head: 'アイコン', data: newValue.iconImgURL ?? '' });
    this.baseConfig.push({ head: 'Wiki のパス', data: newValue.name ?? '' });
    this.baseConfig.push({ head: 'サイト名', data: newValue.title ?? '' });
    this.baseConfig.push({ head: '説明文', data: newValue.description ?? '' });
    this.baseConfig.push({ head: 'noindex設定', data: convertBoolToString(newValue.noindex) });
    this.baseConfig.push({ head: 'プライベート設定', data: convertBoolToString(newValue.isPrivate) });
    this.baseConfig.push({ head: '作成者', data: newValue.author?.nickname ?? '' });
  }
}
