var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("基本情報設定")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "サイト名の設定",
                            description: "Wiki のサイト名を設定します。",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              placeholder: "サイト名を入力してください",
                            },
                            model: {
                              value: _vm.title,
                              callback: function ($$v) {
                                _vm.title = $$v
                              },
                              expression: "title",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "Wiki 説明文の設定",
                            description:
                              "この Wiki についての説明文を設定します。はじめて訪問する方や、編集に参加する方にとってわかりやすい文章にしましょう。",
                          },
                        },
                        [
                          _c("b-form-textarea", {
                            attrs: {
                              placeholder: "説明文を入力してください",
                              rows: 6,
                            },
                            model: {
                              value: _vm.description,
                              callback: function ($$v) {
                                _vm.description = $$v
                              },
                              expression: "description",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "title タグに追加する文字列の設定",
                            description:
                              "記事ページの title タグに設定する文字列を追加できます。 ＜例＞『〇〇攻略Wiki』を設定した場合は、 '(記事タイトル)【〇〇攻略Wiki】 - 神ゲー攻略' のような title タグになります。",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              placeholder:
                                "title タグに追加する文字を入力してください",
                            },
                            model: {
                              value: _vm.titleSuffix,
                              callback: function ($$v) {
                                _vm.titleSuffix = $$v
                              },
                              expression: "titleSuffix",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "title タグの先頭に追加する文字列の設定",
                            description:
                              "記事ページの title タグの先頭に文字列を追加できます。 ＜例＞『神ゲー』を設定した場合は、 '【神ゲー】(記事タイトル)【〇〇攻略Wiki】 - 神ゲー攻略' のような title タグになります。",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: {
                              placeholder: "プレフィックスを入力してください",
                            },
                            model: {
                              value: _vm.pageTitlePrefix,
                              callback: function ($$v) {
                                _vm.pageTitlePrefix = $$v
                              },
                              expression: "pageTitlePrefix",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "編集部名の設定",
                            description: "記事の執筆者情報として表示されます。",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: "〇〇攻略班" },
                            model: {
                              value: _vm.teamName,
                              callback: function ($$v) {
                                _vm.teamName = $$v
                              },
                              expression: "teamName",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "編集部 SNS アカウントの設定",
                            description: "記事の執筆者情報として表示されます。",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: "https://twitter.com/xxxxx" },
                            model: {
                              value: _vm.teamSnsUrl,
                              callback: function ($$v) {
                                _vm.teamSnsUrl = $$v
                              },
                              expression: "teamSnsUrl",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "追加の Google Analytics 測定 ID の設定",
                            description:
                              "追加で別の Google Analytics にデータを送信したいときに設定します",
                          },
                        },
                        [
                          _c("b-form-input", {
                            attrs: { placeholder: "G-XXXXXXXXXX" },
                            model: {
                              value: _vm.gaMeasurementId,
                              callback: function ($$v) {
                                _vm.gaMeasurementId = $$v
                              },
                              expression: "gaMeasurementId",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "footer" }, slot: "footer" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: { click: _vm.save },
                            },
                            [_vm._v("保存する")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: _vm.existsUnsavedChanges },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }