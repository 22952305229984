var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn" },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "header-tag": "header", "footer-tag": "footer" },
                    },
                    [
                      _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                        _c("strong", [_vm._v("公開設定")]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "更新停止のお知らせ",
                            description:
                              "この Wiki の更新を停止したことを利用者に告知します。",
                          },
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { value: "true", "unchecked-value": "" },
                              model: {
                                value: _vm.deprecated,
                                callback: function ($$v) {
                                  _vm.deprecated = $$v
                                },
                                expression: "deprecated",
                              },
                            },
                            [_vm._v("Wiki の更新停止を利用者に告知する")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "更新日時",
                            description:
                              "この Wiki の記事更新日時を表示しないようにします",
                          },
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: { value: "true", "unchecked-value": "" },
                              model: {
                                value: _vm.isUpdatedAtHidden,
                                callback: function ($$v) {
                                  _vm.isUpdatedAtHidden = $$v
                                },
                                expression: "isUpdatedAtHidden",
                              },
                            },
                            [_vm._v("更新日時を非表示にする")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "Can",
                        { attrs: { I: "", do: "ACTION_wiki_config_no_ad" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                label: "広告非表示",
                                description:
                                  "この Wiki のページ上で広告を表示しないようにします",
                              },
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    value: "true",
                                    "unchecked-value": "",
                                  },
                                  model: {
                                    value: _vm.isNoAd,
                                    callback: function ($$v) {
                                      _vm.isNoAd = $$v
                                    },
                                    expression: "isNoAd",
                                  },
                                },
                                [_vm._v("広告を非表示にする")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "b-form-group",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            label: "プライベートモード",
                            description:
                              "この Wiki をプライベートモード (Wiki 編集参加メンバー以外の閲覧ができなくなります) に設定します",
                          },
                        },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "private-checkbox",
                              model: {
                                value: _vm.isPrivate,
                                callback: function ($$v) {
                                  _vm.isPrivate = $$v
                                },
                                expression: "isPrivate",
                              },
                            },
                            [_vm._v("この Wiki をプライベートモードに設定する")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "Can",
                        { attrs: { I: "", do: "ACTION_wiki_config_noindex" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                label: "noindex 設定 (内部スタッフ向け設定)",
                                description:
                                  "この Wiki のページに noindex を指定するかどうか (=検索エンジンにインデックスさせるかどうか) を設定します",
                              },
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  model: {
                                    value: _vm.noindex,
                                    callback: function ($$v) {
                                      _vm.noindex = $$v
                                    },
                                    expression: "noindex",
                                  },
                                },
                                [_vm._v("この Wiki に noindex を設定する")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm.isRootPath && _vm.isPrivate
                        ? _c(
                            "b-alert",
                            { attrs: { show: "", variant: "danger" } },
                            [
                              _vm._v(
                                "プライベートモードがオンの状態でプレフィックスを外すとページの確認ができなくなることがあります"
                              ),
                              _c("br"),
                              _vm._v(
                                "/wiki\n            を外した状態でプライベートモードを有効にしたい事情がある場合は開発までお問い合わせください"
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "Can",
                        {
                          attrs: { I: "", do: "ACTION_wiki_config_root_path" },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                label:
                                  "/wiki プレフィックス設定 (内部スタッフ向け設定)",
                                description:
                                  "この Wiki のパスから /wiki を取り除きます",
                              },
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  model: {
                                    value: _vm.isRootPath,
                                    callback: function ($$v) {
                                      _vm.isRootPath = $$v
                                    },
                                    expression: "isRootPath",
                                  },
                                },
                                [
                                  _vm._v(
                                    "パスから /wiki プレフィックスを取り除く "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "Can",
                        {
                          attrs: {
                            I: "",
                            do: "ACTION_wiki_config_should_sitemap_shrink",
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mb-3",
                              attrs: {
                                label:
                                  "サイトマップ縮小設定 (内部スタッフ向け設定)",
                                description:
                                  "この Wiki のサイトマップに掲載される記事が「トップページ または グローバルナビに設定されている記事 または 最終更新から 3 ヶ月以内の記事」に変更されます",
                              },
                            },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  model: {
                                    value: _vm.shouldSitemapShrink,
                                    callback: function ($$v) {
                                      _vm.shouldSitemapShrink = $$v
                                    },
                                    expression: "shouldSitemapShrink",
                                  },
                                },
                                [_vm._v("サイトマップを正規化する ")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { attrs: { slot: "footer" }, slot: "footer" },
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "success" },
                              on: { click: _vm.save },
                            },
                            [_vm._v("保存する")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-unsaved-changes-confirmer", {
        ref: "kamigameUnsavedChangesConfirmer",
        attrs: { existsUnsavedChanges: _vm.existsUnsavedChanges },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }