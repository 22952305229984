var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c(
        "div",
        { staticClass: "animated fadeIn wiki-page-template-list" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12" } },
            [
              _c(
                "b-card",
                { attrs: { "header-tag": "header", "footer-tag": "footer" } },
                [
                  _c("div", { attrs: { slot: "header" }, slot: "header" }, [
                    _c("strong", [_vm._v("記事テンプレート一覧")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "b-container",
                    { staticClass: "mb-3", attrs: { fluid: "" } },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { lg: "6" } },
                            [
                              _c("b-form-select", {
                                staticClass: "w-100 mt-3",
                                attrs: { options: _vm.sortConditions },
                                on: {
                                  input: function ($event) {
                                    return _vm.getWikiPageTemplates()
                                  },
                                },
                                model: {
                                  value: _vm.selectedSortCondition,
                                  callback: function ($$v) {
                                    _vm.selectedSortCondition = $$v
                                  },
                                  expression: "selectedSortCondition",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-col",
                            { attrs: { lg: "6" } },
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function ($event) {
                                      $event.preventDefault()
                                      return _vm.getWikiPageTemplates()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { staticClass: "mt-3" },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          placeholder: "検索ワードを入力",
                                        },
                                        model: {
                                          value: _vm.searchString,
                                          callback: function ($$v) {
                                            _vm.searchString = $$v
                                          },
                                          expression: "searchString",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "b-button",
                                        { attrs: { type: "submit" } },
                                        [_vm._v("検索")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "kamigame-paging",
                    {
                      ref: "kamigamePaging",
                      attrs: {
                        dataLength: _vm.totalTemplatesNum,
                        "parent-per-page": _vm.perRequestWikiPageTemplatesNum,
                      },
                      on: { "change-data-range": _vm.changeDataRange },
                    },
                    [
                      _c(
                        "b-table",
                        {
                          attrs: {
                            slot: "data-list",
                            responsive: "",
                            fields: _vm.fields,
                            items: _vm.templates.template,
                            "empty-text":
                              "表示可能なテンプレートが見つかりません",
                            "show-empty": "",
                            busy: _vm.loading,
                          },
                          slot: "data-list",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "cell(title)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "b-link",
                                      {
                                        staticClass: "edit-template",
                                        attrs: {
                                          to: {
                                            name: "wiki_page_template_edit",
                                            params: {
                                              templateId: data.item.id,
                                            },
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s(data.item.title))]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(category)",
                                fn: function (data) {
                                  return [
                                    data.item.category
                                      ? _c(
                                          "span",
                                          [
                                            _vm._v(
                                              _vm._s(
                                                data.item.category.displayName
                                              ) + " ("
                                            ),
                                            _c(
                                              "b-link",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.unlinkWikiPageCategory(
                                                      data.item
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-times",
                                                }),
                                              ]
                                            ),
                                            _vm._v(")"),
                                          ],
                                          1
                                        )
                                      : _c(
                                          "span",
                                          [
                                            _c(
                                              "b-link",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-modal",
                                                    rawName:
                                                      "v-b-modal.setPageCategory",
                                                    modifiers: {
                                                      setPageCategory: true,
                                                    },
                                                  },
                                                ],
                                                on: {
                                                  click: function ($event) {
                                                    _vm.categorySeclectTemplateItemIndex =
                                                      data.index
                                                  },
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass: "fa fa-plus",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                  ]
                                },
                              },
                              {
                                key: "cell(lastAuthor)",
                                fn: function (data) {
                                  return data.item.lastAuthor
                                    ? [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              data.item.lastAuthor.nickname
                                            ) +
                                            "\n            "
                                        ),
                                      ]
                                    : undefined
                                },
                              },
                              {
                                key: "cell(creator)",
                                fn: function (data) {
                                  return data.item.creator
                                    ? [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(data.item.creator.nickname) +
                                            "\n            "
                                        ),
                                      ]
                                    : undefined
                                },
                              },
                              {
                                key: "cell(lastUpdatedAt)",
                                fn: function (data) {
                                  return [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.dateFormat(
                                            new Date(data.item.updatedAt),
                                            "yyyy/MM/dd HH:mm:ss"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(_operation)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "b-button",
                                      {
                                        staticClass:
                                          "create-template-button mr-3",
                                        attrs: {
                                          size: "sm",
                                          variant: "primary",
                                          to: {
                                            name: "wiki_page_create",
                                            query: { templateId: data.item.id },
                                          },
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "fa fa-plus",
                                          attrs: { "aria-hidden": "true" },
                                        }),
                                        _vm._v(" テンプレートを使う"),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "Can",
                                      {
                                        attrs: {
                                          I: "",
                                          do: "ACTION_wiki_page_template_delete",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            staticClass:
                                              "delete-template-button mt-2",
                                            attrs: {
                                              size: "sm",
                                              variant: "outline-danger",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showDeleteModal(
                                                  data.index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-trash-o",
                                              attrs: { "aria-hidden": "true" },
                                            }),
                                            _vm._v(" 削除"),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-modal",
                                      {
                                        ref: `alertDelete_${data.index}`,
                                        attrs: {
                                          "hide-header": "",
                                          centered: "",
                                          "no-fade": "",
                                          lazy: "",
                                        },
                                        on: {
                                          ok: function ($event) {
                                            return _vm.deleteTemplate(data.item)
                                          },
                                        },
                                      },
                                      [
                                        _c("p", [
                                          _vm._v(
                                            "指定されたテンプレートを削除します。"
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("p", [
                                          _vm._v(
                                            "本当に続行してもよろしいですか？"
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "text-center my-2",
                              attrs: { slot: "table-busy" },
                              slot: "table-busy",
                            },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _vm._v(" "),
                              _c("strong", [_vm._v("読み込み中...")]),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("kamigame-category-tree-select-modal", {
        attrs: { modalId: "setPageCategory", modalTitle: "カテゴリを設定" },
        on: {
          selectionSaved: function ($event) {
            return _vm.onPageCategorySelected(
              $event,
              _vm.categorySeclectTemplateItemIndex
            )
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }