var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c("div", { staticClass: "card" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "card-body" },
        [_c("ButtonCopyWiki", { staticClass: "button" })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("header", { staticClass: "card-header" }, [
      _c("strong", [_vm._v(" Wiki コピー ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }