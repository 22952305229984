var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "li",
    { class: _vm.classList, on: { click: _vm.hideMobile } },
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }