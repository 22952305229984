import { fetchSpreadsheetMeta, fetchSpreadsheetSheetByTitle } from '@/service/SpreadsheetApi';
import { normalizeSpreadsheetSheetRowLength } from '@/service/SpreadsheetSheetFormatter';
import { parseSpreadsheetIdFromUrl } from '@/service/SpreadsheetUrl';

export const fetchSpreadsSheetData = async (spreadsSheetURL: string) => {
  const spreadsSheetId = parseSpreadsheetIdFromUrl(spreadsSheetURL);
  if (!spreadsSheetId) {
    alert('シートIDの取得に失敗しました。');
    return;
  }

  const meta = await fetchSpreadsheetMeta(spreadsSheetId);
  if (!meta.result.sheets || !meta.result.sheets[0].properties || !meta.result.sheets[0].properties.title) {
    alert('シートの取得に失敗しました。');
    return;
  }

  const sheetTitle = meta.result.sheets[0].properties.title;
  const resp = await fetchSpreadsheetSheetByTitle(spreadsSheetId, sheetTitle);

  return normalizeSpreadsheetSheetRowLength(resp.result.values);
};
