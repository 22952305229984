
import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'kamigame-wiki-page-keyboard-shortcut-help-modal',
})
export default class WikiPageKeyboardShortcutHelpModal extends KamigameVue {
  @Prop()
  shortcuts!: { [key: string]: string[] };

  convertCommandNameToExplanation(commandName: string): string {
    switch (commandName) {
      case 'Bold':
        return '文字を赤太字化';
      case 'show-image-modal':
        return '画像を挿入';
      case 'Blockquote':
        return '引用タグを挿入';
      case 'show-link-modal':
        return '記事のリンクを挿入';
      case 'UL':
        return '箇条書きを挿入';
      case 'OL':
        return '順序付きリストを挿入';
      case 'Task':
        return 'タスクリストを挿入';
      case 'Code':
        return 'インラインコードを挿入';
      case 'CodeBlock':
        return 'コードブロックを挿入';
      case 'toggle-search-bar':
        return '検索・置換バーを表示';
      case 'save-editor-content':
        return '記事の下書き保存';
      case 'Strike':
        return '打ち消し文を挿入';
      case 'show-wiki-page-partial-insert-modal':
        return '記事部品を挿入';
      case 'show-wiki-page-keyboard-shortcut-help-modal':
        return 'キーボードショートカットヘルプを表示';
      case 'toggle-full-screen':
        return 'フルスクリーンモードの切り替え';
      case 'toggle-light-mode':
        return '軽量モードの切り替え';
      case 'load-gametool':
        return 'ゲームツール読み込み';
      default:
        return commandName;
    }
  }

  convertCommandKeyToElement(commandKey: string): string {
    const keys = commandKey.split('+');
    const keyString = keys
      .map((key) => (key.length === 1 ? key.toLowerCase() : key))
      .map((key) => `<span class="key">${key}</span>`)
      .join(' + ');
    return keyString;
  }
}
