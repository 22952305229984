
import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';

import ButtonCopyWiki from '@/components/Button/ButtonCopyWiki.vue';

@Component({
  name: 'set-up-wiki',
  components: {
    ButtonCopyWiki,
  },
})
export default class SetUpWiki extends KamigameVue {}
