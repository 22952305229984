function kamigameSaveExtension(editor: any) {
  editor.eventManager.addEventType('save-editor-content');
  editor.commandManager.addCommand('markdown', {
    name: 'save-editor-content',
    exec() {
      editor.eventManager.emit('save-editor-content');
    },
  });
}

export default kamigameSaveExtension;
