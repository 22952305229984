const toolBarName = 'partial-generate-code-modal';
const eventName = 'show-partial-generate-code-modal';

function KamigamePartialGenerateCode(editor: any) {
  const toolbar = editor.getUI().getToolbar();
  toolbar.addItem({
    type: 'button',
    options: {
      name: toolBarName,
      className: 'tui-editor-custom-button fa fa-code',
      event: eventName,
      tooltip: '記事部品コード生成',
    },
  });

  editor.eventManager.addEventType(eventName);
  editor.commandManager.addCommand('markdown', {
    name: eventName,
    exec() {
      editor.eventManager.emit(eventName);
    },
  });
}

export default {
  plugin: KamigamePartialGenerateCode,
  toolBarName,
  eventName,
};
