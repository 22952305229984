
import BaseConfigCard from '@/components/Dashboard/BaseConfigCard.vue';
import MemberRequestListCard from '@/components/Dashboard/MemberRequestListCard.vue';
import Histories from '@/components/Dashboard/Histories.vue';
import KamigameVue from '@/KamigameVue';
import Component from 'vue-class-component';
import { components } from '@/api-client/schema';

@Component({
  name: 'dashboard',

  components: {
    'base-config-card': BaseConfigCard,
    'member-request-list-card': MemberRequestListCard,
    histories: Histories,
  },
})
export default class Dashboard extends KamigameVue {
  wikiForDashboard: components['schemas']['v1WikiForDashboard'] = {};
  wikiMemberRequestArray: components['schemas']['v1WikiMemberRequest'][] = [];
  wikiPageHistories: components['schemas']['v1WikiPageHistory'][] = [];

  readonly wikiPageHistoriesLimit = 10;

  async mounted() {
    const getWikiForDashboardResponse = await this.apiClient
      .GET('/admin/wiki/{name}/dashboard/wiki', {
        params: {
          path: {
            name: this.wikiName,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
    this.wikiForDashboard = getWikiForDashboardResponse;

    const listWikiMemberRequestResponse = await this.apiClient
      .GET('/admin/wiki/{wikiName}/member-request', {
        params: {
          path: {
            wikiName: this.wikiName,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
    this.wikiMemberRequestArray = listWikiMemberRequestResponse.requests ?? [];

    const listWikiPageHistoriesResponse = await this.apiClient
      .GET('/admin/wiki/{wikiName}/history', {
        params: {
          path: {
            wikiName: this.wikiName,
          },
        },
      })
      .then((r) => {
        if (r.error) {
          throw r.error;
        }

        return r.data;
      });
    this.wikiPageHistories = listWikiPageHistoriesResponse.history ?? [];
  }
}
