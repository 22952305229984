const templateMatcher = /{template ([^\s]+(?: [^\s:=]+:[^\s:=]+=[^\s:=]+)*)}/g;

export const extractTemplate = (content) => {
  const templateMatches = [];
  const templateConditionMatches = [];

  let matched;
  while ((matched = templateMatcher.exec(content)) != null) {
    templateMatches.push(matched[0]);
    templateConditionMatches.push(matched[1]);
  }

  return { templateMatches, templateConditionMatches };
};
