const clientId = '43349465599-31fk2a8bkikf6uv0pcmk19fqsv99erpt.apps.googleusercontent.com';
const apiKey = 'AIzaSyAbVQynUk5jUrTZk-PuM4UClcctIHY7cCc';
const discoveryDocs = ['https://sheets.googleapis.com/$discovery/rest?version=v4'];
const scopes = 'https://www.googleapis.com/auth/spreadsheets https://www.googleapis.com/auth/devstorage.full_control';

let tokenClient: any;

const initializeGapiClient = async () => {
  await window.gapi.client.init({
    apiKey: apiKey,
    discoveryDocs: discoveryDocs,
  });
};

window.addEventListener('load', () => {
  window.gapi.load('client', initializeGapiClient);

  tokenClient = window.google.accounts.oauth2.initTokenClient({
    client_id: clientId,
    scope: scopes,
  });
});

export const isInvalidToken = (err: any) => {
  return (
    err.result.error.code == 401 || (err.result.error.code == 403 && err.result.error.status == 'PERMISSION_DENIED')
  );
};

export const getToken = async () => {
  await new Promise((resolve, reject) => {
    tokenClient.callback = (resp: any) => {
      if (resp.error !== undefined) {
        reject(resp);
      }

      resolve(resp);
    };

    tokenClient.requestAccessToken({ prompt: '' });
  });
};
