import localforage from 'localforage';
const datasheetsDBKeyName = 'datasheets_db';
const updatedAtKeyName = 'datasheets_updated_at';

type TempDatasheets = { [key: string]: { [key: string]: string }[] } | null;
export default class DatasheetsDB {
  private static tempDatasheetsDB: TempDatasheets = null;
  private static tempUpdatedAt: Date | null = null;
  private static disabled: boolean = false;

  static async set(data: any, wikiId: string, updatedAt: Date): Promise<void> {
    await localforage.setItem(datasheetsDBKeyName + '_' + wikiId, data);
    await localforage.setItem(updatedAtKeyName + '_' + wikiId, updatedAt.toString());
    DatasheetsDB.tempDatasheetsDB = data;
    DatasheetsDB.tempUpdatedAt = updatedAt;
    return;
  }

  static async load(wikiId: string): Promise<void> {
    const data = (await localforage.getItem(datasheetsDBKeyName + '_' + wikiId)) as TempDatasheets;
    let updatedAt = (await localforage.getItem(updatedAtKeyName + '_' + wikiId)) as string | null;
    if (data !== null) {
      DatasheetsDB.tempDatasheetsDB = data;
    }
    if (updatedAt !== null) {
      DatasheetsDB.tempUpdatedAt = new Date(updatedAt);
    }
  }

  static setDisabled(flag: boolean) {
    DatasheetsDB.disabled = flag;
  }

  // getHistory は先に load() を行いDBから読み込む必要があります
  static getHistory(): Date | null {
    if (DatasheetsDB.disabled) {
      return null;
    }
    if (DatasheetsDB.tempUpdatedAt !== null) {
      return DatasheetsDB.tempUpdatedAt;
    }
    return null;
  }

  // getDatasheetsDB は先に load() を行いDBから読み込む必要があります
  static getDatasheetsDB(): TempDatasheets {
    if (DatasheetsDB.disabled) {
      return null;
    }
    if (DatasheetsDB.tempDatasheetsDB !== null) {
      return DatasheetsDB.tempDatasheetsDB;
    }
    return null;
  }
}
