
import Component from 'vue-class-component';
import VueRouter from 'vue-router';
import { Watch } from 'vue-property-decorator';

import { SortableTable } from '@/components';
import KamigameVue from '@/KamigameVue';
import { WikiPageTitleSearchModal } from '@/components';
import UnsavedChangesConfirmer from '@/components/UnsavedChangesConfimer.vue';
import { components } from '@/api-client/schema';
import { format, parse } from 'date-fns';

@Component({
  name: 'wiki-event-notification',
  components: {
    'kamigame-sortable-table': SortableTable,
    'kamigame-wiki-page-titles-search-modal': WikiPageTitleSearchModal,
    'kamigame-unsaved-changes-confirmer': UnsavedChangesConfirmer,
  },
})
export default class WikiEventNotification extends KamigameVue {
  items: [symbol, components['schemas']['v1EventNotification'] & { deadlineDatetime: string }][] = [];
  wikiPageTitles: components['schemas']['v1WikiPageTitle'][] = [];
  selectedWikiPageTitle: components['schemas']['v1WikiPageTitle'] = {};

  draftItems: (components['schemas']['v1EventNotification'] & { deadlineDatetime: string })[] = [];
  savedItems: (components['schemas']['v1EventNotification'] & { deadlineDatetime: string })[] = [];

  @Watch('items', { deep: true })
  onUpdateItems(newValue: [symbol, components['schemas']['v1WikiNav']][]) {
    this.draftItems = JSON.parse(JSON.stringify(newValue.map(([_, v]) => v)));
  }

  async mounted() {
    const response = await this.apiClient.GET('/wiki/{name}/event-notification', {
      params: { path: { name: this.wikiName } },
    });
    if (response.error) {
      throw response.error;
    }

    this.items = response.data.eventNotifications.map((nav) => [
      Symbol(),
      {
        ...nav,
        deadlineDatetime: format(new Date(nav.deadline), "yyyy-MM-dd'T'HH:mm"),
      },
    ]);
    const itemsJson = JSON.stringify(this.items.map(([_, v]) => v));
    this.savedItems = JSON.parse(itemsJson);
    this.draftItems = JSON.parse(itemsJson);
  }

  addRow() {
    const deadline = new Date();
    this.items.push([
      Symbol(),
      {
        id: '',
        title: '',
        linkUrl: '',
        deadline: deadline.toISOString(),
        deadlineDatetime: format(deadline, 'yyyy-MM-dd"T"HH:mm'),
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      },
    ]);
  }

  removeRow(id: symbol) {
    const renew = new Map(this.items);
    const toBeRemoved = renew.get(id);
    if (!toBeRemoved || !window.confirm(`本当にこの項目を削除してもよろしいですか？\n項目名: ${toBeRemoved.title}`)) {
      return;
    }
    renew.delete(id);

    this.items = Array.from(renew.entries());
  }

  async save() {
    const added = this.draftItems.filter((v) => !this.savedItems.some((s) => s.id === v.id));
    const updated = this.draftItems.filter((v) => {
      const saved = this.savedItems.find((s) => s.id === v.id);
      return (
        saved &&
        (saved.title !== v.title ||
          saved.linkUrl !== v.linkUrl ||
          saved.deadline !== v.deadline ||
          saved.deadlineDatetime !== v.deadlineDatetime)
      );
    });
    const deleted = this.savedItems.filter((s) => !this.draftItems.some((v) => v.id === s.id));

    try {
      await Promise.all(
        [
          added.map((a) =>
            this.apiClient.POST('/admin/wiki/{wikiName}/event-notification', {
              params: { path: { wikiName: this.wikiName } },
              body: {
                title: a.title,
                linkUrl: a.linkUrl,
                deadline: parse(a.deadlineDatetime, "yyyy-MM-dd'T'HH:mm", new Date()).toISOString(),
              },
            })
          ),
          updated.map((u) =>
            this.apiClient.PUT('/admin/wiki/{wikiName}/event-notification/{id}', {
              params: { path: { wikiName: this.wikiName, id: u.id } },
              body: {
                title: u.title,
                linkUrl: u.linkUrl,
                deadline: parse(u.deadlineDatetime, "yyyy-MM-dd'T'HH:mm", new Date()).toISOString(),
              },
            })
          ),
          deleted.map((d) =>
            this.apiClient.DELETE('/admin/wiki/{wikiName}/event-notification/{id}', {
              params: { path: { wikiName: this.wikiName, id: d.id } },
            })
          ),
        ].flat()
      );

      const itemsJson = JSON.stringify(this.items.map(([_, v]) => v));
      this.savedItems = JSON.parse(itemsJson);
      this.draftItems = JSON.parse(itemsJson);
      this.setFlashMessage('success', '変更を保存しました。', this.wikiTopUrl, 'サイト上で内容を確認する', true);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        this.setFlashMessage('danger', '変更の保存に失敗しました。' + e.message);
      }
    }
  }

  beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    (this.$refs.kamigameUnsavedChangesConfirmer as UnsavedChangesConfirmer).handleBeforeRouteLeave(to, from, next);
  }

  get existsUnsavedChanges() {
    if (this.draftItems.length !== this.savedItems.length) {
      return true;
    }

    for (let i = 0; i < this.draftItems.length; ++i) {
      if (
        this.draftItems[i].title !== this.savedItems[i].title ||
        this.draftItems[i].deadline !== this.savedItems[i].deadline ||
        this.draftItems[i].deadlineDatetime !== this.savedItems[i].deadlineDatetime
      ) {
        return true;
      }
    }

    return false;
  }
}
