var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-tabs",
    { attrs: { id: "analytics-chart" } },
    _vm._l(_vm.analyticsDurations, function (analyticsDuration, idx) {
      return _c(
        "b-tab",
        _vm._b(
          { key: idx, attrs: { title: `${analyticsDuration}日間` } },
          "b-tab",
          { active: idx === 0 },
          false
        ),
        [
          _c("canvas", {
            attrs: { id: `${_vm.analyticsIdPrefix}${analyticsDuration}` },
          }),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }