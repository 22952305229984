declare const cv: any;

function convertToGrayscale(r: number, g: number, b: number): number {
  const redFactor = 0.3;
  const greenFactor = 0.59;
  const blueFactor = 0.11;
  return r * redFactor + g * greenFactor + b * blueFactor;
}

function convertToBinary(color: number, threshold: number): number {
  return color < threshold ? 0 : 255;
}

function getThresholdCandidates(threshold?: number): number[] {
  return threshold ? [threshold] : [64, 96, 128, 160, 192, 224];
}

function binarizeImageDataWithThreshold(data: Uint8ClampedArray, threshold: number): Uint8ClampedArray {
  const clonedData = new Uint8ClampedArray(data);
  for (let i = 0; i < clonedData.length; i += 4) {
    const grayscale = convertToGrayscale(clonedData[i], clonedData[i + 1], clonedData[i + 2]);
    const binaryColor = convertToBinary(grayscale, threshold);
    clonedData[i] = clonedData[i + 1] = clonedData[i + 2] = binaryColor;
  }
  return clonedData;
}

/** Applies OCR processing to image regions. */
export async function applyOCRToRegions(
  image: HTMLImageElement,
  regions: Record<string, [number, number, number, number, number]>,
  signal?: AbortSignal
): Promise<{ result: boolean; ocr: Record<string, { best_threshold: number; text: string }> }> {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as CanvasRenderingContext2D;
  canvas.width = image.width;
  canvas.height = image.height;
  ctx.drawImage(image, 0, 0, image.width, image.height);

  const result = {
    result: true,
    ocr: {} as Record<string, { best_threshold: number; text: string }>,
    requested: [] as { threshold: number; image: string }[],
  };

  for (const regionName in regions) {
    let [x, y, w, h, threshold] = regions[regionName];
    const imageData = ctx.getImageData(x, y, w, h);
    const modified = document.createElement('canvas');
    const modifiedCtx = modified.getContext('2d') as CanvasRenderingContext2D;
    modified.width = imageData.width;
    modified.height = imageData.height;

    const thresholdCandidates = getThresholdCandidates(threshold);

    const threadholdResults = thresholdCandidates.map((threshold) => {
      const clonedImageData = new ImageData(
        binarizeImageDataWithThreshold(imageData.data, threshold),
        imageData.width,
        imageData.height
      );
      modifiedCtx.putImageData(clonedImageData, 0, 0);

      return { threshold, image: modified.toDataURL('image/png') };
    });

    const response = await fetch('https://asia-northeast1-vls-kamigame-wiki.cloudfunctions.net/image-scanner-ocr', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ images: threadholdResults }),
      signal: signal,
    }).then((response) => response.json());

    result.ocr[regionName] = {
      best_threshold: response.best_threshold,
      text: response.ocr,
    };

    result.result = result.result && response.result;
    result.requested.push(...threadholdResults);
  }

  return result;
}

function calculateSizeToFitSpecifiedBox(
  width: number,
  height: number,
  maxWidth: number,
  maxHeight: number
): [number, number] {
  const aspect = width / height;

  let nw = width;
  let nh = height;
  if (nw > maxWidth) {
    nw = maxWidth;
    nh = Math.round(nw / aspect);
  } else {
    nh = maxHeight;
    nw = Math.round(nh * aspect);
  }

  return [nw, nh];
}

function applyPatternMatchingPreprocessing(roi: any): any[] {
  const grayscale = new cv.Mat();
  const binRoi = new cv.Mat();
  const kernel = cv.Mat.ones(3, 3, cv.CV_8U);
  const dilateRoi = new cv.Mat();
  const contours = new cv.MatVector();
  const hierarchy = new cv.Mat();

  let croppedImages = [];

  cv.cvtColor(roi, grayscale, cv.COLOR_BGR2GRAY);
  cv.threshold(grayscale, binRoi, 0, 255, cv.THRESH_OTSU);
  cv.dilate(binRoi, dilateRoi, kernel, new cv.Point(-1, -1), 3, cv.BORDER_CONSTANT, cv.morphologyDefaultBorderValue());
  cv.findContours(dilateRoi, contours, hierarchy, cv.RETR_EXTERNAL, cv.CHAIN_APPROX_SIMPLE);

  for (let i = 0; i < contours.size(); ++i) {
    if (hierarchy.intPtr(0, i)[3] === -1) {
      const rect = cv.boundingRect(contours.get(i));
      const croppedImage = roi.roi(new cv.Rect(rect.x, rect.y, rect.width, rect.height));
      croppedImages.push(croppedImage);
    }
  }
  return croppedImages;
}

function applyPatternMatchingToSpecifiedRegionAndTemplateImage(
  croppedImage: any,
  templateImage: HTMLImageElement
): number {
  const scale = 1.0;
  const threshold = 0.815 + Math.abs(1.0 - scale) * 0.2;

  const template = cv.imread(templateImage);
  const output = new cv.Mat();
  const contours = new cv.MatVector();
  const hierarchy = new cv.Mat();

  cv.resize(template, template, new cv.Size(template.cols * scale, template.rows * scale), 0, 0, cv.INTER_AREA);

  if (croppedImage.cols < template.cols || croppedImage.rows < template.rows) {
    const [nw, nh] = calculateSizeToFitSpecifiedBox(template.cols, template.rows, croppedImage.cols, croppedImage.rows);
    cv.resize(template, template, new cv.Size(nw, nh), 0, 0, cv.INTER_AREA);
  }

  try {
    cv.matchTemplate(croppedImage, template, output, cv.TM_CCOEFF_NORMED);
    cv.threshold(output, output, threshold, 1.0, cv.THRESH_BINARY);
    output.convertTo(output, cv.CV_8U);
    cv.findContours(output, contours, hierarchy, cv.RETR_CCOMP, cv.CHAIN_APPROX_SIMPLE);

    return contours.size();
  } catch (e) {
    console.error(`Failed to apply pattern matching to specified region and template image`, e);

    return 0;
  } finally {
    template.ptr && template.delete();
    output.ptr && output.delete();
    contours.ptr && contours.delete();
    hierarchy.ptr && hierarchy.delete();
  }
}

/** Applies Template Matching to image regions. */
export async function applyPatternMatchingToRegions(
  image: HTMLImageElement,
  templateImages: HTMLImageElement[],
  regions: Record<string, [number, number, number, number, number]>
): Promise<Record<string, Record<string, number>>> {
  const targetImage = cv.imread(image);
  const result: Record<string, Record<string, number>> = {};

  for (const regionName in regions) {
    const [x, y, w, h] = regions[regionName];
    const roi = targetImage.roi(new cv.Rect(x, y, w, h));
    const croppedImages: any[] = applyPatternMatchingPreprocessing(roi);
    result[regionName] = {};
    for (let i = 0; i < croppedImages.length; i++) {
      for (let j = 0; j < templateImages.length; j++) {
        const resultMatching = applyPatternMatchingToSpecifiedRegionAndTemplateImage(
          croppedImages[i],
          templateImages[j]
        );

        if (!result[regionName][j]) {
          result[regionName][j] = 0;
        }
        result[regionName][j] += resultMatching;
      }
    }
    roi.ptr && roi.delete();
  }

  targetImage.ptr && targetImage.delete();

  return result;
}
