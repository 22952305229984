
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';

import KamigameVue from '@/KamigameVue';

@Component({
  name: 'kamigame-sortable-table',
  components: {
    draggable,
  },
})
export default class SortableTable extends KamigameVue {
  list: [symbol, any][] = [];

  @Prop()
  value!: [symbol, any][];

  @Watch('value')
  onValueUpdated() {
    this.list = this.value.slice();
  }

  onChange() {
    this.$emit('input', this.list);
  }
}
