
import { components } from '@/api-client/schema';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({
  name: 'kamigame-wiki-select-modal',
})
export default class WikiSelectModal extends Vue {
  @Prop()
  wikiList!: components['schemas']['v1Wiki'][];

  search = '';
  selectedWiki: components['schemas']['v1Wiki'] | null = null;
  fields = [
    { key: 'title', label: '名前' },
    { key: 'name', label: 'パス' },
  ];
  showsArchivedWiki = false;

  open() {
    this.$bvModal.show('modal');
  }

  onWikiSelected(wiki: components['schemas']['v1Wiki']) {
    this.reset();
    this.$emit('wikiSelected', wiki);
    this.$bvModal.hide('modal');
  }

  onRowSelected(selected: components['schemas']['v1Wiki'][]) {
    const selectedWiki = selected.pop();
    if (!selectedWiki) {
      return;
    }

    this.selectedWiki = selectedWiki;
  }

  reset() {
    this.search = '';
    this.selectedWiki = null;
  }

  get filteredWikiList() {
    const pattern = new RegExp(this.search, 'i');
    return this.wikiList
      .filter((w) => {
        return this.showsArchivedWiki || !w.isArchived;
      })
      .filter((w) => {
        const nameIncludesKeyword = w.name && pattern.test(w.name);
        const titleIncludesKeyword = w.title && pattern.test(w.title);

        return nameIncludesKeyword || titleIncludesKeyword;
      });
  }
}
