var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal",
        title: "移動する Wiki を選択",
        "ok-disabled": !_vm.selectedWiki,
        "ok-title": "開く",
        "cancel-title": "キャンセル",
      },
      on: {
        hidden: _vm.reset,
        ok: function ($event) {
          return _vm.onWikiSelected(_vm.selectedWiki)
        },
      },
    },
    [
      _c(
        "b-link",
        {
          staticClass: "new-wiki",
          attrs: { to: { name: "wiki_create" }, target: "_blank" },
        },
        [
          _c("i", {
            staticClass: "fa fa-plus",
            attrs: { "aria-hidden": "true" },
          }),
          _vm._v("新しく Wiki を作成\n  "),
        ]
      ),
      _vm._v(" "),
      _c("b-form-input", {
        attrs: { placeholder: "Wiki を検索" },
        model: {
          value: _vm.search,
          callback: function ($$v) {
            _vm.search = $$v
          },
          expression: "search",
        },
      }),
      _vm._v(" "),
      _c(
        "b-form-checkbox",
        {
          staticClass: "mt-3",
          model: {
            value: _vm.showsArchivedWiki,
            callback: function ($$v) {
              _vm.showsArchivedWiki = $$v
            },
            expression: "showsArchivedWiki",
          },
        },
        [_vm._v("非表示にされたWikiも表示する")]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wiki-list" },
        [
          _c("b-table", {
            ref: "selectableTable",
            attrs: {
              small: true,
              selectable: "",
              "select-mode": "single",
              items: _vm.filteredWikiList,
              fields: _vm.fields,
            },
            on: { "row-selected": _vm.onRowSelected },
            scopedSlots: _vm._u([
              {
                key: "cell(title)",
                fn: function (data) {
                  return [
                    _c(
                      "a",
                      {
                        attrs: { href: "#" },
                        on: { click: () => _vm.onWikiSelected(data.item) },
                      },
                      [_vm._v(_vm._s(data.value))]
                    ),
                  ]
                },
              },
              {
                key: "cell(name)",
                fn: function (data) {
                  return [_vm._v(" /" + _vm._s(data.value) + "/ ")]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }