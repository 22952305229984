export default (CodeMirror: any) => {
  CodeMirror.defineMode('kamigame-markdown', (config: any, parserConfig: any) => {
    const allowedFrontMatter = [
      'updated_at',
      'title',
      'writer',
      'description',
      'keywords',
      'autolink',
      'disable_autolink',
      'noindex',
      'url',
    ];
    const overlay = {
      startState: function() {
        return {
          inFrontMatter: false,
          inTable: false,
        };
      },
      token: function(stream: any, state: any) {
        if (state.inFrontMatter) {
          if (stream.sol() && stream.match(/^---+/)) {
            state.inFrontMatter = false;
          }
          if (stream.sol() && stream.match(/^[a-z_0-9]+:/)) {
            const frontMatterKey = stream.string.split(':').shift();
            return allowedFrontMatter.includes(frontMatterKey) ? 'front-matter-key' : 'front-matter-invalid-key';
          }
          stream.next();
          return 'front-matter';
        }

        if (state.inTable) {
          if (stream.sol()) {
            if (stream.match(/^\|/)) {
              return 'table-delimiter';
            } else {
              state.inTable = false;
              return null;
            }
          }

          if (stream.match('|')) {
            return 'table-delimiter';
          }
        }

        if (stream.sol() && stream.match(/^---+/) && stream.lineOracle.line === 0) {
          stream.skipToEnd();
          state.inFrontMatter = true;
          return 'front-matter';
        }

        const afterALine = stream.lookAhead(1);
        if (stream.sol() && stream.match(/^\|/) && afterALine && afterALine.match(/^\| ?:?-+:? ?\|/)) {
          state.inTable = true;
          return 'table-delimiter';
        }

        stream.next();
        return null;
      },
    };
    return CodeMirror.overlayMode(CodeMirror.getMode(config, parserConfig.backdrop || 'gfm'), overlay);
  });
};
