
import Component from 'vue-class-component';
import { BModal } from 'bootstrap-vue';

import KamigameVue from '@/KamigameVue';
import { WikiPageAdvice } from '@/comment-api-client/models/WikiPageAdvice';

@Component({
  name: 'wiki-page-advice-list',
})
export default class WikiPageAdviceList extends KamigameVue {
  loading: boolean = false;
  isHandled: boolean = false;
  advices: WikiPageAdvice[] = [];
  perPage: number = 20;
  currentPage: number = 1;

  fields = [
    { thStyle: { width: '30%' }, key: 'title', label: '記事名' },
    { key: 'body', label: '指摘内容' },
    { thStyle: { width: '18%' }, key: '_operation', label: '操作' },
  ];

  mounted() {
    this.listWikiPageAdvice();
  }

  listWikiPageAdvice() {
    this.commentApi.listWikiPageAdvice(this.wikiName, this.isHandled).then((advices) => {
      this.advices = advices;
    });
  }

  switchHandled() {
    this.isHandled = !this.isHandled;
    this.listWikiPageAdvice();
  }

  handleAdvice(id: string) {
    this.commentApi
      .handleWikiPageAdvice(id)
      .then(() => {
        this.advices = this.advices.filter((advice) => advice.id !== id);
        this.setFlashMessage('success', '対応完了しました');
      })
      .catch(() => {
        this.setFlashMessage('danger', 'エラーが発生しました。しばらくしてからお試しください。');
      });
  }

  showHandleAdviceModal(index: number) {
    const modal = this.$refs[`handleAdvice_${index}`] as BModal;
    modal.show();
  }
}
